import _ from 'lodash';

export const postcodeCustomerIds = ['8086a1cd-3d33-40e6-907b-2bd0a8abc841', '96e0c0c3-de76-42ef-9910-abe813b74367', 'b861241d-b582-4f72-be27-2dd2275e7b60'];

export const validatePostalRange = (range: string) => {

  if (!/\d{4}(?:-\d{4})?(?:,\d{4}(?:-\d{4})?)*/.test(range)) {

    return false;

  }
  const splitRanges = range.split(',');
  if (splitRanges.length === 0) {

    return false;

  }
  for (const splitRange of splitRanges) {

    const currRange = splitRange.trim();
    const ranges = currRange.split('-');
    if (ranges.length > 2) {

      return false;

    }
    if (ranges.length === 2) {

      if (parseInt(ranges[0], 10) > parseInt(ranges[1], 10)) {

        return false;

      }

    }

  }
  return true;

};

export const sanitisePostalRange = (range: string) => {

  return range.replace(/[a-zA-Z]/g, '');

};

export const postalCodeRangeToArr = (range: string) => {

  const splitRanges = range.split(',');
  const postalCodes: string[] = [];
  for (const splitRange of splitRanges) {

    const currRange = splitRange.trim();
    const ranges = currRange.split('-');
    if (ranges.length === 1) {

      postalCodes.push(ranges[0]);

    }
    if (ranges.length === 2) {

      const start = parseInt(ranges[0], 10);
      const end = parseInt(ranges[1], 10);
      for (let i = start; i <= end; i++) {

        postalCodes.push(i.toString());

      }

    }

  }
  return _.uniq(postalCodes).sort();

};
