import {
  SimpleForm,
  TextInput,
  EditButton,
  required,
  useTranslate,
  FieldProps,
  useRecordContext,
  BooleanInput,
  FormDataConsumer,
  BooleanField,
  Button,
  Identifier,
  TextField,
  useNotify,
  useRefresh,
} from 'react-admin';
import {
  Datagrid, IfCanAccess,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, FormControlLabel,
  IconButton, Radio,
  RadioGroup,
  TextField as MuiTextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { ListLive } from '@react-admin/ra-realtime';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useListContext } from 'ra-core';
import {
  StaticResponder, StaticResponderRelation, ZoneRef,
} from '@x-guard/xgac-types/xgac';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import _ from 'lodash';
import { CustomToolbar } from '../../../components/CustomToolBar';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import ActiveBooleanField from '../../../components/fields/ActiveBooleanField';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { exporter } from '../../../utils/customExporter';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { LIST_DEBOUNCE } from '../../../config';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumberInput';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { ScheduleInput } from '../../../components/inputs/ScheduleInput';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';
import { daysOfWeek } from '../../../lib/constants/selectChoices';
import { getCurrentCustomer } from '../../../lib/currentCustomer';
import {
  postalCodeRangeToArr,
  postcodeCustomerIds,
  sanitisePostalRange, validatePostalRange,
} from '../../../lib/constants/staticResponderPostcode';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import authProvider from '../../../utils/authProvider';

const transform = (data: Record<string, any>) => {

  delete data.share;
  return {
    ...data,
    externalId: data.externalId || null,
    contact: {
      ...data.contact,
      email: data.contact.email || null,
      camera: data.contact.camera || null,
      sms: data.contact.sms || null,
      phone: data.contact.phone || null,
    },
  };

};

const HasScheduleField = (props: FieldProps) => {

  const record = useRecordContext();
  const translate = useTranslate();
  if (!record) {

    return null;

  }

  if (record[props.source]?.entries?.length > 0) {

    const TooltipTitle = () => (
      <>
        <table>
          {record[props.source]?.entries?.map((entry: any) => {

            return entry.daysOfWeek.map((dayNumber: number) => (
              <tr>
                <td>{translate(daysOfWeek.find((day) => day.id === dayNumber)?.name || '')}</td>
                <td>{entry.timeStart} - {entry.timeEnd}</td>
              </tr>
            ));

          })}
        </table>
      </>
    );

    return (
      <Tooltip title={<TooltipTitle/>} arrow>
        <div>
          <ActiveBooleanField {...props}/>
        </div>
      </Tooltip>
    );

  }
  return (
    <ActiveBooleanField {...props}/>
  );

};

type StaticResponderWithZone = StaticResponder & {
  zones: ZoneRef & {
    name: string;
  }[];
};
type ZoneNameAndResponder = {
  id: Identifier;
  _id: string;
  name: string;
  staticResponders: StaticResponderRelation[];
};

const zoneNamesToRange = (zones: ZoneNameAndResponder[], allZoneNames: ZoneNameAndResponder[]) => {

  const sortedNames = zones.map((zone) => zone.name).sort();
  const allNamesSorted = allZoneNames.map((zone) => zone.name).sort();
  const currIndex = allZoneNames.findIndex((zone) => zone.name === sortedNames[0]);
  let range = '';
  let lastZone = sortedNames[0];
  let lastZoneIndex = currIndex;
  for (let i = 1; i <= sortedNames.length; i++) {

    const currZone = sortedNames[i];
    const currZoneIndex = allNamesSorted.findIndex((zone) => zone === currZone);
    if (currZoneIndex !== lastZoneIndex + 1) {

      if (range.length > 0) {

        range += ', ';

      }
      range += lastZone;
      if (lastZone !== sortedNames[i - 1]) {

        range += `-${sortedNames[i - 1]}`;

      }
      lastZone = currZone;

    }
    lastZoneIndex = currZoneIndex;

  }
  return range;

};

const StaticResponderZoneDialog = () => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [respondersLoading, setRespondersLoading] = useState(false);
  const [selectedResponders, setSelectedResponders] = useState<StaticResponderWithZone[]>([]);
  const [zonesLoading, setZonesLoading] = useState(false);
  const [zones, setZones] = useState<ZoneNameAndResponder[]>([]);
  const [saving, setSaving] = useState(false);
  const [action, setAction] = useState<'add' | 'overwrite'>('add');
  const [postCodeRangeValue, setPostCodeRangeValue] = useState('');
  const { selectedIds } = useListContext();

  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {

    const getStaticResponders = async () => {

      setRespondersLoading(true);
      const responders = await xgacDataProvider.getMany('static-responders', { ids: selectedIds });

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const mappedResponders: StaticResponderWithZone[] = responders.data.map((responder: StaticResponder) => {

        return {
          ...responder,
          zones: zones.filter((zone: ZoneNameAndResponder) => {

            return zone.staticResponders.some((staticResponder: StaticResponderRelation) => staticResponder.staticResponder._id === responder._id);

          }).map((zone: ZoneNameAndResponder) => {

            return {
              _id: zone._id,
              _ref: 'zone',
              name: zone.name,
            };

          }),
        };

      });
      setRespondersLoading(false);
      setSelectedResponders(mappedResponders);

    };
    if (dialogOpen) {

      getStaticResponders();

    }

  }, [dialogOpen, zones]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    const getAllZones = async () => {

      setZonesLoading(true);
      const zoneRequest = await xgacDataProvider.getList('zones', {
        pagination: { page: 1, perPage: -1 },
        sort: { field: 'name', order: 'ASC' },
        filter: {},
        meta: {
          $select: ['name', 'staticResponders'],
        },
      });
      setZones(zoneRequest.data);
      setZonesLoading(false);

    };

    if (dialogOpen && zones.length === 0) {

      getAllZones();

    }

  }, [dialogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeDialog = () => {

    setDialogOpen(false);
    setStep(0);
    setAction('add');
    setPostCodeRangeValue('');
    setSelectedResponders([]);
    setRespondersLoading(false);
    setSaving(false);

  };

  const onBack = () => {

    switch (step) {

    case 1:
      setStep(0);
      setPostCodeRangeValue('');
      setAction('add');
      break;
    default:
      setStep(step - 1);
      break;

    }

  };

  const rangeOfMultiResponders = (responders: StaticResponderWithZone[]) => {

    const combinedZones = _.uniq(_.flatten(responders.map((responder) => responder.zones.map((zone) => zone.name))));
    return zoneNamesToRange(combinedZones.map((zoneName) => ({
      name: zoneName, _id: '', id: '', staticResponders: [],
    })), zones);

  };

  const savePostCodeRange = async () => {

    if (!validatePostalRange(postCodeRangeValue)) {

      notify('resources.static-responders.notifications.invalid_postcode', { type: 'error' });
      return;

    }
    setSaving(true);
    const range = postalCodeRangeToArr(sanitisePostalRange(postCodeRangeValue));
    const promises = [];
    if (action === 'overwrite') {

      const zonesInResponders = _.uniq(_.flatten(selectedResponders.map((responder) => responder.zones.map((zone) => zone.name))));
      for (const currCode of zonesInResponders) {

        if (range.includes(currCode)) {

          continue;

        }

        const currZone = zones.find((zone) => zone.name === currCode);
        if (currZone) {

          const newResponders = currZone.staticResponders.filter((staticResponder: StaticResponderRelation) => {

            return !selectedResponders.some((responder) => responder._id === staticResponder.staticResponder._id);

          });
          if (newResponders.length !== currZone.staticResponders.length) {

            promises.push(xgacDataProvider.update('zones', {
              id: currZone._id,
              data: { staticResponders: newResponders },
              previousData: currZone,
            }));

          }

        }

      }

    }

    for (const currCode of range) {

      const currZone = zones.find((zone) => zone.name === currCode);
      if (currZone) {

        let lastPriority = currZone.staticResponders.length > 0 ? currZone.staticResponders[currZone.staticResponders.length - 1].priority : 0;
        let added = false;
        const newResponders = [...currZone.staticResponders];
        for (const responder of selectedResponders) {

          if (!newResponders.some((staticResponder: StaticResponderRelation) => staticResponder.staticResponder._id === responder._id)) {

            newResponders.push({ staticResponder: { _id: responder._id, _ref: 'StaticResponder' }, priority: lastPriority + 1 });
            lastPriority++;
            added = true;

          }

        }
        if (added) {

          promises.push(xgacDataProvider.update('zones', { id: currZone._id, data: { staticResponders: newResponders }, previousData: currZone }));

        }

      }

    }
    if (promises.length > 0) {

      const promiseResults = await Promise.all(promises);
      const newZones = [...zones];
      for (const result of promiseResults) {

        if (result.data) {

          newZones[newZones.findIndex((zone) => zone._id === result.data._id)] = {
            id: result.data._id,
            _id: result.data._id,
            name: result.data.name,
            staticResponders: result.data.staticResponders,
          };

        }

      }
      setSaving(false);
      setZones(newZones);
      notify('resources.static-responders.notifications.postcodes_updated', { type: 'info' });
      closeDialog();

    } else {

      notify('resources.static-responders.notifications.no_changes', { type: 'info' });
      closeDialog();

    }
    refresh();

  };

  const ZoneRangeField = (props: FieldProps) => {

    const record = useRecordContext();

    const zonesForResponder = _.get(record, props.source, []);
    const range = zoneNamesToRange(zonesForResponder, zones);
    return (
      <TextField source="zones" record={{ zones: range }}/>
    );

  };

  return (
    <>
      <Button
        onClick={() => setDialogOpen(true)}
        label={'resources.static-responders.text.postcodes'}
      />
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth={'lg'} fullWidth>
        <DialogTitle className={'flex-in-between'}>
          {step > 0 && (
            <IconButton onClick={onBack}>
              <ArrowBackIcon/>
            </IconButton>
          )}
          <span>{translate('resources.static-responders.text.postcodes')}</span>
          <IconButton onClick={closeDialog}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        {step === 0 && (
          <>
            <DialogContent>
              <Datagrid
                data={(respondersLoading || zonesLoading) ? [] : selectedResponders}
                empty={<div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                }}>
                  <CircularProgress/>
                </div>}
                bulkActionButtons={false}
                selectedIds={[]}
                rowClick={false}
              >
                <TextFieldNullable source="name"/>
                <ZoneRangeField source={'zones'}/>
              </Datagrid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setDialogOpen(false)}
                label={translate('ra.action.cancel')}
              />
              <Button
                disabled={respondersLoading || zonesLoading}
                onClick={() => {

                  setPostCodeRangeValue(rangeOfMultiResponders(selectedResponders));
                  setStep(1);

                }}
                label={translate('general.text.continue')}
              />
            </DialogActions>
          </>
        )}
        {step === 1 && (
          <>
            {saving ? (

              <DialogContent style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
                <CircularProgress/>
              </DialogContent>
            ) : (
              <>
                <DialogContent>
                  <div style={{
                    marginTop: '10px',
                  }}>
                    <MuiTextField
                      value={postCodeRangeValue}
                      onChange={(e) => setPostCodeRangeValue(sanitisePostalRange(e.target.value))}
                      fullWidth
                      label={translate('resources.zones.fields.postCodeRange_label')}
                      helperText={translate('resources.zones.fields.postCodeRange_helper')}
                      error={postCodeRangeValue.length > 0 && !/\d{4}(?:-\d{4})?(?:,\d{4}(?:-\d{4})?)*/.test(postCodeRangeValue)}
                    />
                    <RadioGroup
                      value={action}
                      onChange={(e) => setAction(e.target.value as 'add' | 'overwrite')}
                      row
                    >
                      <FormControlLabel value="add" control={<Radio />} label={translate('general.text.add')} />
                      <FormControlLabel value="overwrite" control={<Radio />} label={translate('general.text.overwrite')} />
                    </RadioGroup>

                  </div>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setDialogOpen(false)}
                    label={translate('ra.action.cancel')}
                  />
                  <Button
                    onClick={savePostCodeRange}
                    label={translate('general.text.continue')}
                    disabled={postCodeRangeValue.length === 0 || !/\d{4}(?:-\d{4})?(?:,\d{4}(?:-\d{4})?)*/.test(postCodeRangeValue)}
                  />
                </DialogActions>
              </>
            )}
          </>
        )}
      </Dialog>
    </>
  );

};

export const StaticResponderList = () => {

  const currentCustomer = getCurrentCustomer();
  const isAdmin = authProvider.isAdmin();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <ListLive filters={SearchFields} debounce={LIST_DEBOUNCE} exporter={exporter} title="resources.static-responders.text.title">
        <>
          <FilterResetter/>
          <Datagrid rowClick="toggleSelection" bulkActionButtons={<>
            {(isAdmin && postcodeCustomerIds.includes(currentCustomer?.value || '')) && (
              <StaticResponderZoneDialog/>
            )}
            <CustomBulkActionButtons/>
          </>}>
            <TextFieldNullable source="name" label="general.fields.name"/>
            <TextFieldNullable source="contact.email" label="resources.assets.fields.email" privacy/>
            <TextFieldNullable source="contact.phone" label="resources.assets.fields.phoneNumber" privacy/>
            <TextFieldNullable source="contact.sms" label="resources.assets.fields.smsNumber" privacy/>
            <TextFieldNullable source="contact.camera" privacy/>
            <TextFieldNullable source="externalId" privacy/>
            <HasScheduleField source="schedule" label="resources.static-responders.fields.schedule.name" />
            <BooleanField source={'integrations.sequrix.enabled'} label={'resources.static-responders.fields.integrations.sequrix.enabled'} looseValue={true}/>
            <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
            <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
            <IfCanAccess action="edit">
              <EditButton/>
            </IfCanAccess>
            <HasRoles anyOf={['developer_admin']}>
              <AuditlogButton/>
            </HasRoles>
          </Datagrid>
        </>
      </ListLive>
    </>
  );

};

export const StaticResponderEdit = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <EditWithSuccess transform={transform} title="resources.static-responders.text.title">
        <SimpleForm toolbar={<CustomToolbar/>}>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.general')}
          </Typography>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.contact_options')}
          </Typography>
          <TextInput source="externalId"/>
          <TextInput source="contact.email" label="resources.assets.fields.email"/>
          <FormDataConsumer>
            {({ formData }) => (
              <PhoneNumberInput source="contact.phone" label="resources.assets.fields.phoneNumber" required={formData.integrations?.sequrix?.enabled || false}/>
            )}
          </FormDataConsumer>

          <PhoneNumberInput source="contact.sms" label="resources.assets.fields.smsNumber"/>
          <TextInput source="contact.camera"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.availability')}
          </Typography>
          <ScheduleInput source={'schedule.entries'}/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.professional_response')}
          </Typography>
          <BooleanInput source={'integrations.sequrix.enabled'}/>
          <FormDataConsumer>
            {({ formData }) => (
              formData.integrations?.sequrix?.enabled && (
                <>
                  <TextInput source={'integrations.sequrix.objectCode'} shouldUnregister/>
                </>
              )
            )}
          </FormDataConsumer>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const StaticResponderCreate = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <CreateWithSuccess transform={transform} title="resources.static-responders.text.title">
        <SimpleForm>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.general')}
          </Typography>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.contact_options')}
          </Typography>
          <TextInput source="externalId"/>
          <TextInput source="contact.email" label="resources.assets.fields.email"/>
          <FormDataConsumer>
            {({ formData }) => (
              <PhoneNumberInput source="contact.phone" label="resources.assets.fields.phoneNumber" required={formData.integrations?.sequrix?.enabled || false}/>
            )}
          </FormDataConsumer>
          <PhoneNumberInput source="contact.sms" label="resources.assets.fields.smsNumber"/>
          <TextInput source="contact.camera"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.availability')}
          </Typography>
          <ScheduleInput source={'schedule.entries'}/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.professional_response')}
          </Typography>
          <BooleanInput source={'integrations.sequrix.enabled'}/>
          <FormDataConsumer>
            {({ formData }) => (
              formData.integrations?.sequrix?.enabled && (
                <>
                  <TextInput source={'integrations.sequrix.objectCode'} shouldUnregister/>
                </>
              )
            )}
          </FormDataConsumer>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};
