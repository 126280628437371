import moment from 'moment-timezone';
import { TimeGranularity } from '@x-guard/xgac-types/xgac';
import { getCurrentCustomer } from '../lib/currentCustomer';
import { xgacDataProvider } from '../dataProviders/xgacDataProvider';

const unhealthyBeaconDefaults = (
  customerId: string,
  channels: any[],
  interval: TimeGranularity,
  extraConfig?: any,
) => ({
  customer: {
    _id: customerId,
    _ref: 'Customer',
  },
  config: {
    ...extraConfig,
    type: 'unhealthyBeacons',
    lastSeenThreshold: {
      type: 'week',
      value: 1,
    },
    filter: {},
  },
  channels,
  scheduleTrigger: {
    interval,
    nextAt: new Date().toISOString(),
    lastAt: null,
    lastAttemptedAt: null,
  },
  enabled: true,
});

const alarmReportDefaults = (customerId: string, channels: any[], interval: TimeGranularity, extraConfig?: any) => ({
  customer: {
    _id: customerId,
    _ref: 'Customer',
  },
  config: {
    ...extraConfig,
    type: 'alarms',
    excelFormat: 'default',
    severities: [
      'red',
      'orange',
    ],
    alarmTypes: null,
    timeSeries: {
      granularity: {
        type: 'day',
        value: 1,
      },
      timeframe: {
        type: 'week',
        value: 1,
        floorTo: 'day',
      },
    },
  },
  channels,
  scheduleTrigger: {
    interval,
    nextAt: new Date().toISOString(),
    lastAt: null,
    lastAttemptedAt: null,
  },
  eventTrigger: null,
  enabled: true,
});

const alarmCenterStatusDefaults = (customerId: string, channels: any[], interval: TimeGranularity, extraConfig?: any) => (
  {
    customer: {
      _id: customerId,
      _ref: 'Customer',
    },
    config: {
      ...extraConfig,
      type: 'alarmCenterStatus',
      exclude: [],
      timeframe: {
        type: 'month',
        value: 1,
        floorTo: 'month',
      },
    },
    channels,
    scheduleTrigger: {
      interval,
      nextAt: new Date().toISOString(),
      lastAt: null,
      lastAttemptedAt: null,
    },
    eventTrigger: null,
    enabled: true,
  });

const newAlarmDefaults = (customerId: string, channels: any[], config: any) => ({
  customer: {
    _id: customerId,
    _ref: 'Customer',
  },
  config: {
    type: 'newAlarm',
    ...config,
  },
  channels,
  scheduleTrigger: null,
  eventTrigger: {
    events: [
      'Alarm.create',
    ],
  },
  enabled: true,
  locale: 'nl',
});

const inactivityDefaults = (customerId: string, channels: any[], config: any, interval: TimeGranularity) => ({
  customer: {
    _id: customerId,
    _ref: 'Customer',
  },
  config: {
    type: 'inactivityMonitor',
    ...config,
  },
  channels,
  scheduleTrigger: {
    interval,
    nextAt: new Date().toISOString(),
    lastAt: null,
    lastAttemptedAt: null,
  },
  eventTrigger: null,
  enabled: true,
});

export const appUsageDefaults = (
  customerId: string,
  channels: any[],
  usageMode: string,
  timezone: string,
  sendForCurrentMonth?: boolean,
  extraConfig?: any,
) => {

  const nextAt = sendForCurrentMonth ? moment.tz(timezone).startOf('month').toISOString() : moment.tz(timezone).add(1, 'month').startOf('month').toISOString();
  return {
    customer: {
      _id: customerId,
      _ref: 'Customer',
    },
    config: {
      ...extraConfig,
      type: 'appUsage',
      mode: usageMode,
      timeSeries: {
        granularity: {
          value: 1,
          type: 'hour',
        },
        timeframe: {
          floorTo: 'month',
          type: 'month',
          value: 1,
        },
      },
      filter: {},
    },
    channels,
    scheduleTrigger: {
      interval: {
        value: 1,
        type: 'month',
      },

      nextAt,
      lastAt: null,
      lastAttemptedAt: null,
    },
    eventTrigger: null,
    enabled: true,
  };

};

export const simpleReportTransformer = async (data: Record<string, any>) => {

  const currentCustomer = getCurrentCustomer()?.value;
  let type = data.config.type;
  if (!type && data._id) {

    const record = await xgacDataProvider.getOne('report-triggers', { id: data._id });
    type = record.data.config.type;

  }
  switch (type) {

  case 'unhealthyBeacons':
    return unhealthyBeaconDefaults(
      currentCustomer || '',
      data.channels,
      data.scheduleTrigger.interval,
      data.config,
    );
  case 'alarms':
    return alarmReportDefaults(
      currentCustomer || '',
      data.channels,
      data.scheduleTrigger.interval,
      data.config,
    );
  case 'alarmCenterStatus':
    return alarmCenterStatusDefaults(
      currentCustomer || '',
      data.channels,
      data.scheduleTrigger.interval,
      data.config,
    );
  case 'newAlarm':
    return newAlarmDefaults(
      currentCustomer || '',
      data.channels,
      data.config,
    );
  case 'inactivityMonitor':
    return inactivityDefaults(
      currentCustomer || '',
      data.channels,
      data.config,
      data.scheduleTrigger.interval,
    );
  case 'appUsage':

    // eslint-disable-next-line no-case-declarations
    let timezone = 'Europe/Amsterdam';
    if (currentCustomer) {

      try {

        const fullCustomer = await xgacDataProvider.getOne('customers', { id: currentCustomer });
        if (fullCustomer && fullCustomer.data?.timeZone) {

          timezone = fullCustomer.data.timeZone;

        }

      } catch (e) {

        console.log('error getting timezone, defaulting...', e);

      }

    }
    return appUsageDefaults(
      currentCustomer || '',
      data.channels,
      data.config.mode,
      timezone,
      data.sendForCurrentMonth,
      data.config,
    );
  default:
    return data;

  }

};
