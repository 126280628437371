import { useEffect, useState } from 'react';
import {
  Button, DeleteButton, Identifier, useGetIdentity, useRecordContext, useTranslate,
} from 'react-admin';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Tooltip,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { ObservationTrigger } from '@x-guard/xgac-types/xgac';
import _ from 'lodash';
import authProvider from '../../utils/authProvider';
import { getCurrentCustomer } from '../../lib/currentCustomer';
import { xgacDataProvider } from '../../dataProviders/xgacDataProvider';
import { atsDataProvider } from '../../dataProviders/atsDataProvider';
import { KioskUser } from '../../lib/constants/customTypes';

export const useLinksTriggersAndKiosks = () => {

  const [links, setLinks] = useState<any[]>([]);
  const [triggers, setTriggers] = useState<ObservationTrigger[]>([]);
  const [kiosks, setKiosks] = useState<KioskUser[]>([]);
  const [lastSavedCustomer, setLastSavedCustomer] = useState<string | null>(null);

  const identity = useGetIdentity();
  const currentCustomer = getCurrentCustomer();
  const fetchLinksTriggersAndKiosks = async () => {

    const triggerRequest = await xgacDataProvider.getList('observation-triggers', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    setTriggers(triggerRequest.data);

    const linkRequest = await atsDataProvider.getList('customer-links', {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    setLinks(linkRequest.data);

    const kioskRequest = await xgacDataProvider.getList('specials/kiosk-user', {
      pagination: { page: 1, perPage: -1 },
      sort: { field: 'createdAt', order: 'ASC' },
      filter: {},
    });
    setKiosks(kioskRequest.data);

  };

  useEffect(() => {

    if (currentCustomer && lastSavedCustomer !== currentCustomer?.value) {

      fetchLinksTriggersAndKiosks();
      setLastSavedCustomer(currentCustomer?.value);

    }

  }, [currentCustomer, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  return { links, triggers, kiosks };

};

export const useIsUsedInLinkTriggerOrKiosk = (ids: Identifier[]) => {

  const { links, triggers, kiosks } = useLinksTriggersAndKiosks();
  const [isUsed, setIsUsed] = useState(true);

  useEffect(() => {

    if (ids) {

      let tempIsUsed = false;
      for (const id of ids) {

        const linksAndTriggers = [...links, ...triggers];
        const isUsedInLinkOrTrigger = linksAndTriggers.some((linkOrTrigger) => {

          return JSON.stringify(linkOrTrigger).includes(id as string);

        });
        const isUsedInKiosk = kiosks.some((kiosk) => {

          const kioskConfigFields = ['exposeAssets', 'exposeLabels'];

          for (const field of kioskConfigFields) {

            const fieldValue = _.get(kiosk.kioskConfig, field);

            if (Array.isArray(fieldValue)) {

              for (const value of fieldValue) {

                if (value._id === id) {

                  return true;

                }

              }

            }

          }
          return false;

        });

        tempIsUsed = tempIsUsed || isUsedInLinkOrTrigger || isUsedInKiosk;
        if (tempIsUsed) break;

      }
      setIsUsed(tempIsUsed);

    }

  }, [links, triggers, ids, kiosks]);
  return isUsed;

};

export const AssetGroupDeleteButton = () => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteEnabled, setDeleteEnabled] = useState(false);
  const translate = useTranslate();
  const record = useRecordContext();
  const isUsed = useIsUsedInLinkTriggerOrKiosk([record?.id || '']);
  const isAdmin = authProvider.isAdmin();
  const customMessage = `${translate('ra.action.delete')} ${record?.name}`;
  const closeDialog = () => {

    setDialogOpen(false);
    setDeleteEnabled(false);

  };
  if (isAdmin) {

    return (
      <>
        {isUsed ? (
          <>
            <Button onClick={() => setDialogOpen(true)} color="error" label="ra.action.delete" startIcon={<DeleteIcon/>}/>
            <Dialog open={dialogOpen} onClose={closeDialog}>
              <DialogTitle>{customMessage}</DialogTitle>
              <DialogContent>
                <div>
                  <Typography variant={'body1'}>{translate(
                    'ra.message.delete_content',
                  )}</Typography>
                  <br/>
                  <Typography
                    fontWeight="fontWeightBold"
                    variant={'body1'}
                    color="error">
                    {translate('resources.asset-groups.text.delete_warning')}
                  </Typography>
                  <br/>
                </div>
                <FormControlLabel control={
                  <Checkbox checked={deleteEnabled} onChange={(event, checked) => setDeleteEnabled(checked)}/>
                } label={translate('resources.asset-groups.text.delete_confirmation')}/>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} label={translate('ra.action.cancel')}/>
                <DeleteButton confirmTitle={customMessage} disabled={!deleteEnabled}/>
              </DialogActions>
            </Dialog>
          </>
        ) : (
          <DeleteButton confirmTitle={customMessage}/>
        )}
      </>
    );

  }
  if (isUsed) {

    return (
      <Tooltip title={translate('resources.asset-groups.text.delete_warning')}>
        <div>
          <DeleteButton disabled={isUsed} confirmTitle={customMessage}/>
        </div>
      </Tooltip>
    );

  }

  return <DeleteButton disabled={isUsed} confirmTitle={customMessage}/>;

};
