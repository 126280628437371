import { useEffect, useState } from 'react';
import { useRecordContext } from 'ra-core';
import {
  Button, ButtonProps, useNotify, useTranslate,
} from 'react-admin';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField as MuiTextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CopyAll } from '@mui/icons-material';
import QRCode from 'qrcode.react';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { StyledButton } from '../../apps/bhvk/buttons/StyledButton';
import { typedHttpClient } from '../../utils/httpClient';

const api = typedHttpClient();

type TwoFactorMethodsType = Awaited<ReturnType<typeof api.users.usersControllerGet2FaStatus>>['data']['twoFactorMethods'];

export const TwoFactorButton = (props: {
  isAsset?: boolean;
  buttonProps?: ButtonProps;
  username?: string;
}) => {

  const [twoFactorMethods, setTwoFactorMethods] = useState<TwoFactorMethodsType>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sharedSecret, setSharedSecret] = useState('');
  const [twoFactorValue, setTwoFactorValue] = useState('');
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);
  const record = useRecordContext();
  console.log('record', record);
  const usedId = props.isAsset ? record?.user._id : record?._id;
  const notify = useNotify();
  const translate = useTranslate();

  useEffect(() => {

    const fetchTwoFaStatus = () => {

      if (!usedId) {

        return;

      }
      api.users.usersControllerGet2FaStatus(usedId).then((response) => {

        setTwoFactorMethods(response.data.twoFactorMethods);

      });

    };

    if (usedId) {

      fetchTwoFaStatus();

    }

  }, [record, dialogOpen, usedId]);

  useEffect(() => {

    if (dialogOpen && usedId) {

      api.users.usersControllerGet2FaSharedSecret(usedId).then((response) => {

        setSharedSecret(response.data.secretBase32Encoded);

      });

    }

  }, [dialogOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const closeDialog = () => {

    setDialogOpen(false);
    setTwoFactorValue('');
    setRecoveryCodes([]);
    setSharedSecret('');

  };

  const submitEnableTwoFactor = async () => {

    if (!usedId) {

      return;

    }
    try {

      const recoveryCodeRequest = await api.users.usersControllerPost2FaEnable(usedId, {
        method: 'authenticator',
        code: twoFactorValue,
        secretBase32Encoded: sharedSecret,
      });
      notify('resources.users.text.2fa_enabled', { type: 'success' });
      if (recoveryCodeRequest.data.recoveryCodes) {

        setRecoveryCodes(recoveryCodeRequest.data.recoveryCodes);

      } else {

        closeDialog();

      }

    } catch (error) {

      notify('resources.users.text.2fa_enable_failed', { type: 'warning' });

    }

  };

  const submitDisableTwoFactor = async () => {

    if (!usedId) {

      return;

    }
    try {

      for (const method of twoFactorMethods) {

        await api.users.usersControllerPost2FaDisable(usedId, {
          methodId: method.id,
          code: twoFactorValue,
        });

      }
      notify('resources.users.text.2fa_disabled', { type: 'success' });
      closeDialog();

    } catch (error) {

      notify('resources.users.text.2fa_disable_failed', { type: 'warning' });

    }

  };

  return (
    <>
      <StyledButton label="resources.users.text.2fa_title" onClick={() => setDialogOpen(true)} {...props.buttonProps} startIcon={twoFactorMethods?.length > 0
        ? <CheckIcon/> : <CloseIcon/>
      }/>
      <Dialog open={dialogOpen} onClose={closeDialog} maxWidth={'sm'} fullWidth>
        <DialogTitle className="flex-in-between">
          <span>{translate('resources.users.text.2fa_title')}</span>
          <IconButton onClick={closeDialog}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        {twoFactorMethods?.length > 0
          ? (
            <>
              <DialogContent>
                <span>{translate('resources.users.text.2fa_already_enabled')}</span>
                <div style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                  flexDirection: 'column',
                  gap: '1em',
                  alignItems: 'center',
                }}>
                  <MuiTextField
                    value={twoFactorValue}
                    onChange={(e) => setTwoFactorValue(e.target.value.trim())}
                    label={translate('resources.users.text.2fa_code')}
                    onKeyDown={(e) => e.key === 'Enter' && submitDisableTwoFactor()}
                    autoFocus={true}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} label="ra.action.cancel"/>
                <Button
                  disabled={!twoFactorValue || twoFactorValue?.length === 0}
                  onClick={submitDisableTwoFactor}
                  label="resources.users.text.2fa_disable"
                />
              </DialogActions>
            </>
          ) : (
            <>
              {recoveryCodes?.length > 0 ? (
                <>
                  <DialogContent>
                    <span>{translate('resources.users.text.2fa_recovery_codes')}</span>
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '20px',
                      flexDirection: 'column',
                      gap: '1em',
                      alignItems: 'center',
                    }}>
                      {recoveryCodes.map((code, index) => (
                        <span key={index}>{code}</span>
                      ))}
                      <IconButton
                        onClick={() => {

                          navigator.clipboard.writeText(recoveryCodes.join('\n'));
                          notify('resources.users.text.2fa_recovery_codes_copied', { type: 'success' });

                        }}
                      >
                        <CopyAll/>
                      </IconButton>
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeDialog} label="ra.action.close"/>
                  </DialogActions>
                </>
              ) : (
                <>
                  <DialogContent>
                    <span>{translate('resources.users.text.2fa_enable_description')}</span>
                    <div style={{
                      display: 'flex', justifyContent: 'center', marginTop: '20px', flexDirection: 'column', gap: '1em', alignItems: 'center',
                    }}>
                      {(sharedSecret && record) ? (
                        <>
                          <QRCode value={`otpauth://totp/${encodeURIComponent(`${props.isAsset ? 'BHV-Knop.nl' : 'X-Guard'}: ${props.username || record.username}`)}?secret=${sharedSecret}`}/>
                          <MuiTextField
                            value={twoFactorValue}
                            onChange={(e) => setTwoFactorValue(e.target.value.trim())}
                            label={translate('resources.users.text.2fa_code')}
                            onKeyDown={(e) => e.key === 'Enter' && submitEnableTwoFactor()}
                            autoFocus
                          />
                        </>
                      ) : (
                        <CircularProgress/>
                      )}
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeDialog} label="ra.action.cancel"/>
                    <Button
                      disabled={!twoFactorValue || twoFactorValue?.length === 0}
                      onClick={submitEnableTwoFactor}
                      label="resources.users.text.2fa_enable"/>
                  </DialogActions>
                </>
              )}
            </>
          )}
      </Dialog>
    </>
  );

};
