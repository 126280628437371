import Grid2 from '@mui/material/Unstable_Grid2';
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  Button,
  Confirm,
  Datagrid,
  Form,
  FormDataConsumer,
  Identifier,
  ImageField,
  NullableBooleanInput,
  NumberInput,
  PasswordInput,
  ReferenceArrayInput,
  ReferenceInput,
  TextField,
  TextInput,
  useExpanded,
  useGetIdentity,
  UseGetListHookValue,
  useGetOne,
  useInput,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import CircularProgress from '@mui/material/CircularProgress';
import * as React from 'react';
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Alert,
  Card,
  CardContent,
  Checkbox as MuiCheckbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField as MuiTextField,
  Typography,
  Tabs,
  Tab,
  useTheme,
  SvgIcon,
  Tooltip,
  Autocomplete, InputAdornment,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import L, { Map } from 'leaflet';
import {
  ClientConfigMapping,
  ClientConfigOverrideType,
  Customer, KioskAlarmSoundName, KioskConfig, Zone,
} from '@x-guard/xgac-types/xgac';
import * as turf from '@turf/turf';
import _ from 'lodash';
import { useGetList, useTranslate } from 'ra-core';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { useFormContext } from 'react-hook-form';
import copy from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Position } from '@turf/turf';
import ReactMarkdown from 'react-markdown';
import { Monitor } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { EmptyHeader } from '../apps/bhvk/entities/button';
import { KioskUser } from '../lib/constants/customTypes';
import { KioskMap } from './maps/kioskMap';
import { useOverlay } from '../utils/useOverlay';
import { getCurrentCustomer } from '../lib/currentCustomer';
import { KioskFormBlock } from './baseForms/kioskFormBlock';
import { ImageInput } from './inputs/ImageInput';
import { sendPicture, xgacDataProvider } from '../dataProviders/xgacDataProvider';
import { HasRoles } from './HasRoles';
import { XGAC_KIOSK_URL, XGAC_MAIN_API_URL } from '../config';
import { httpClient, typedHttpClient } from '../utils/httpClient';
import ReactSelect from '../lib/react-select/reactSelect';
import authProvider, { refreshContext } from '../utils/authProvider';
import { CustomConfirmDialog } from './CustomConfirmDialog';
import { ScheduleInput } from './inputs/ScheduleInput';
import { IncidentCounterField } from './icons/IncidentCounterField';
import { wait } from '../lib/websockets/webSocketHelpers';
import { ArrayElement } from '../lib/constants/typedApiReturnTypes';

const Grid = Grid2;
const apiUrl = XGAC_MAIN_API_URL;

const timeValidator = (value?: string) => {

  if (!value) return false;
  return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(value);

};

const pinInputValidator = (value?: string | number) => {

  if (typeof value === 'string') {

    try {

      value = parseInt(value, 10);

    } catch (e) {

      return 'resources.customers.fields.kiosk_config.manage_availability_pin_error';

    }

  }
  if (typeof value !== 'number') {

    return 'resources.customers.fields.kiosk_config.manage_availability_pin_error';

  }
  if (value && (value.toString().length < 4 || value.toString().length > 6)) {

    return 'resources.customers.fields.kiosk_config.manage_availability_pin_error';

  }
  return undefined;

};

const api = typedHttpClient();

const getFocusConfig = (values: any) => {

  return {
    flash: values.kioskConfig.alarm.flash,
    sound: values.kioskConfig.alarm.audio !== undefined ? {
      enabled: values.kioskConfig.alarm.audio || false,
      volume: values.kioskConfig.alarm.focus?.sound?.volume || 100,
      sound: values.kioskConfig.alarm.focus?.sound?.sound,
      stopAfterSeconds: values.kioskConfig.alarm.focus?.sound?.stopAfterSeconds,
      stopAfterTouch: values.kioskConfig.alarm.focus?.sound?.stopAfterTouch,
    } : undefined,
  };

};

const kioskConfigTransformer = (values: any) => {

  if (!values || !values.kioskConfig) {

    return {};

  }
  const refOrStringToRef = (value: any, ref: string) => {

    if (value._ref) {

      return {
        _id: value._id,
        _ref: value._ref,
      };

    }
    return {
      _id: value,
      _ref: ref,
    };

  };

  const arrayToNullOrRefArray = (value: any[]) => {

    if (value.length > 0) {

      return value.map((assetGroup: any) => {

        return refOrStringToRef(assetGroup, 'AssetGroup');

      });

    }
    return null;

  };

  const exposeLabels = () => {

    if (values.kioskConfig.overwrite_general) {

      if (values.kioskConfig.exposeAssets?.length > 0 && values.kioskConfig.countLabelSeparately !== undefined) {

        return values.kioskConfig.exposeAssets?.map((assetGroup: any) => {

          return {
            ...refOrStringToRef(assetGroup, 'AssetGroup'),
            countLabel: values.kioskConfig.countLabelSeparately,
          };

        });

      }
      return null;

    }
    return undefined;

  };
  const exposedLabels = exposeLabels();

  const config = {
    ...values.kioskConfig,
    homepage: values.kioskConfig.homepage ? {
      ...(values.kioskConfig.overwrite_general ? {
        ...values.kioskConfig.homepage,
        anonymous: values?.kioskConfig?.homepage?.anonymous,
        logo: values.kioskConfig.homepage?.logo || undefined,
      } : {}),
      incidentCounter: values.kioskConfig.overwrite_incidentCounter ? {
        ...values.kioskConfig.homepage.incidentCounter,
        enabled: values.kioskConfig.homepage.incidentCounter.enabled,
        countFromDate: values.kioskConfig.homepage.incidentCounter.countFromDate
          ? new Date(values.kioskConfig.homepage.incidentCounter.countFromDate)
          : undefined,
        threshold: values.kioskConfig.homepage.incidentCounter.threshold,
      } : undefined,
    } : undefined,
    manageAvailability: values.kioskConfig.overwrite_manageAvailability ? {
      ...values.kioskConfig.manageAvailability,
      enabled: values.kioskConfig.manageAvailability.enabled,
      pin: values.kioskConfig.manageAvailability.pin?.toString(),
      showAppBanner: true,
      forceShowSearch: true,
      forceShowLabelFilter: false,
    } : undefined,
    alarm: values.kioskConfig.overwrite_alarm ? {
      ...values.kioskConfig.alarm,
      enabled: values.kioskConfig.alarm.enabled,
      anonymous: values.kioskConfig.alarm.anonymous,
      focus: values.kioskConfig.alarm.audio !== undefined ? {
        red: getFocusConfig(values),
        orange: getFocusConfig(values),
        green: getFocusConfig(values),
      } : undefined,
      acknowledge: values.kioskConfig.alarm?.acknowledge ? {
        ...values.kioskConfig.alarm.acknowledge,
        enabled: values.kioskConfig.alarm?.acknowledge?.enabled,
        pin: values.kioskConfig.alarm?.acknowledge.pin?.toString(),
      } : undefined,
      showPositionLabels: true,
    } : undefined,
    exposeAssets: values.kioskConfig.overwrite_general ? arrayToNullOrRefArray(values.kioskConfig.exposeAssets) : undefined,
    exposeLabels: exposedLabels,
    // eslint-disable-next-line no-nested-ternary
    schedule: values.kioskConfig.overwrite_screenSchedule ? values.kioskConfig?.schedule?.enabled ? {
      entries: values.kioskConfig.schedule.entries.filter((entry: any) => {

        return entry.daysOfWeek?.length > 0 && timeValidator(entry.timeStart) && timeValidator(entry.timeEnd);

      }),
    } : null : undefined,

  };

  if (config.alarm?.audio) {

    delete config.alarm.audio;

  }
  delete config.overwrite_general;
  delete config.overwrite_manageAvailability;
  delete config.overwrite_alarm;
  delete config.overwrite_incidentCounter;
  delete config.overwrite_screenSchedule;
  delete config.countLabelSeparately;
  return config;

};

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
} & React.HTMLAttributes<HTMLDivElement>;

const KioskConfigAlarmEnabler = (props: {
  kioskFrame: any;
  kioskConfigSource: string;
  tabValue: number;
}) => {

  const alarmInput = useInput({ source: `${props.kioskConfigSource}.alarm` });
  const { kioskFrame, kioskConfigSource, tabValue } = props;
  const { watch } = useFormContext();
  const alarmEnabled = watch(`${kioskConfigSource}.alarm.enabled`);
  useEffect(() => {

    if (kioskFrame) {

      if (tabValue === 2) {

        kioskFrame.current?.contentWindow?.postMessage({
          type: 'previewAlarm',
          enabled: alarmEnabled,
        }, '*');

      } else {

        kioskFrame.current?.contentWindow?.postMessage({
          type: 'previewAlarm',
          enabled: false,
        }, '*');

      }

    }

    if (alarmEnabled && (_.isEqual(alarmInput.field.value, {}) || _.isEqual(alarmInput.field.value, {
      enabled: undefined,
    }))) {

      wait(1).then(() => {

        alarmInput.field.onChange({
          enabled: true,
          flash: true,
          audio: true,
          anonymous: false,
          focus: {
            sound: {
              enabled: true,
              volume: 100,
              sound: KioskAlarmSoundName.BeepBeep,
              stopAfterSeconds: 0,
            },
          },
          acknowledge: false,
        });

      });

    }

  }, [tabValue, alarmEnabled]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;

};

const KioskSharedFields = (props: { hasOverwrite: boolean; source: string; openCustomerConfig?: () => void; kioskFrame?: any }) => {

  const { hasOverwrite, source, kioskFrame } = props;
  const translate = useTranslate();

  const TabPanel = (tabPanelProps: TabPanelProps) => {

    const {
      children, value, index, ...other
    } = tabPanelProps;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {children}
      </div>
    );

  };

  const formContent = [
    <KioskFormBlock
      title={translate('general.text.general')}
      hasOverwrite={hasOverwrite}
      fields={[
        `${source}.homepage.anonymous`,
        `${source}.exposeAssets`,
        `${source}.homepage.logo`,
      ]}
      overwriteSource={`${source}.overwrite_general`}
      openCustomerDialog={props.openCustomerConfig}
    >
      <h4>{translate('resources.customers.fields.kiosk_config.anonymise_data')}</h4>
      <p>{translate('resources.customers.fields.kiosk_config.anonymise_data_description')}</p>
      <NullableBooleanInput
        source={`${source}.homepage.anonymous`}
        label="resources.customers.fields.kiosk_config.anonymise_data"
        fullWidth
      />

      <h4>{translate('resources.customers.fields.kiosk_config.what_groups')}</h4>
      <p>{translate('resources.customers.fields.kiosk_config.what_groups_description')}</p>
      <ReferenceArrayInput source={`${source}.exposeAssets`} reference='asset-groups' f>
        <AutocompleteArrayInput label="menu.items.groups" fullWidth/>
      </ReferenceArrayInput>
      <FormDataConsumer>
        {({ formData }) => {

          if (formData[source]?.exposeAssets?.length > 0) {

            return (
              <>
                <h4>{translate('resources.customers.fields.kiosk_config.count_label_separately')}</h4>
                <p>{translate('resources.customers.fields.kiosk_config.count_label_separately_description')}</p>
                <NullableBooleanInput
                  source={`${source}.countLabelSeparately`}
                  label="resources.customers.fields.kiosk_config.count_label_separately"
                  fullWidth
                />
              </>
            );

          }
          return null;

        }}
      </FormDataConsumer>
      <h4>{translate('resources.customers.fields.kiosk_config.logo')}</h4>
      <p>{translate('resources.customers.fields.kiosk_config.logo_description')}</p>
      <ImageInput source={`${source}.homepage.logo`} label="general.text.logo">
        <ImageField source="src" title="title"/>
      </ImageInput>
    </KioskFormBlock>,
    <KioskFormBlock
      title={translate('resources.kiosks.text.general_settings_dialog.switch_availability')}
      hasOverwrite={hasOverwrite}
      fields={[
        `${source}.manageAvailability`,
      ]}
      overwriteSource={`${source}.overwrite_manageAvailability`}
      openCustomerDialog={props.openCustomerConfig}
    >
      <h4>{translate('resources.customers.fields.kiosk_config.manage_availability')}</h4>
      <p>{translate('resources.customers.fields.kiosk_config.manage_availability_description')}</p>
      <NullableBooleanInput source={`${source}.manageAvailability.enabled`} label="general.text.enabled" fullWidth/>

      <FormDataConsumer>
        {({ formData }) => {

          if (_.get(formData, `${source}.manageAvailability.enabled`)) {

            return (
              <>
                <h4>{translate('resources.customers.fields.kiosk_config.manage_availability_pin')}</h4>
                <p>{translate('resources.customers.fields.kiosk_config.manage_availability_pin_description')}</p>
                <NumberInput
                  source={`${source}.manageAvailability.pin`}
                  label="resources.customers.fields.kiosk_config.manage_availability_pin"
                  shouldUnregister
                  validate={pinInputValidator}
                />
              </>
            );

          }
          return null;

        }}
      </FormDataConsumer>

    </KioskFormBlock>,
    <KioskFormBlock
      title={translate('resources.customers.fields.kiosk_config.show_alarms')}
      hasOverwrite={hasOverwrite}
      fields={[
        `${source}.alarm`,
      ]}
      overwriteSource={`${source}.overwrite_alarm`}
      openCustomerDialog={props.openCustomerConfig}
    >
      <h4>{translate('resources.customers.fields.kiosk_config.show_alarms')}</h4>
      <p>{translate('resources.customers.fields.kiosk_config.show_alarms_description')}</p>
      <NullableBooleanInput source={`${source}.alarm.enabled`} label="general.text.enabled" fullWidth/>

      <FormDataConsumer>
        {({ formData }) => {

          if (_.get(formData, `${source}.alarm.enabled`)) {

            return (
              <>
                <h4>{translate('resources.customers.fields.kiosk_config.alarm_anonymous')}</h4>
                <p>{translate('resources.customers.fields.kiosk_config.alarm_anonymous_description')}</p>
                <NullableBooleanInput source={`${source}.alarm.anonymous`}
                  label="resources.customers.fields.kiosk_config.alarm_anonymous" fullWidth
                  shouldUnregister/>

                <h4>{translate('resources.customers.fields.kiosk_config.flash')}</h4>
                <p>{translate('resources.customers.fields.kiosk_config.flash_description')}</p>
                <NullableBooleanInput source={`${source}.alarm.flash`}
                  label="resources.customers.fields.kiosk_config.flash" fullWidth
                  shouldUnregister/>

                <h4>{translate('resources.customers.fields.kiosk_config.sound')}</h4>
                <p>{translate('resources.customers.fields.kiosk_config.sound_description')}</p>
                <NullableBooleanInput source={`${source}.alarm.audio`}
                  label="resources.customers.fields.kiosk_config.sound" fullWidth
                  shouldUnregister/>

                <FormDataConsumer>
                  {({ formData: innerFormData }) => {

                    if (_.get(innerFormData, `${source}.alarm.audio`)) {

                      return (
                        <>
                          <h4>{translate('resources.customers.fields.kiosk_config.alarm_sound')}</h4>
                          <p>{translate('resources.customers.fields.kiosk_config.alarm_sound_description')}</p>
                          <AutocompleteInput
                            source={`${source}.alarm.focus.sound.sound`}
                            label={'resources.customers.fields.kiosk_config.alarm_sound'}
                            fullWidth
                            choices={Object.values(KioskAlarmSoundName).map((soundName) => {

                              return { id: soundName, name: soundName };

                            })}/>

                          <h4>{translate('resources.customers.fields.kiosk_config.alarm_sound_volume')}</h4>
                          <p>{translate('resources.customers.fields.kiosk_config.alarm_sound_volume_description')}</p>
                          <NumberInput
                            source={`${source}.alarm.focus.sound.volume`}
                            label={'resources.customers.fields.kiosk_config.alarm_sound_volume'}
                            fullWidth
                            max={100}
                            min={0}
                            shouldUnregister
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">%</InputAdornment>
                              ),
                            }}
                          />

                          <h4>{translate('resources.customers.fields.kiosk_config.alarm_sound_stop_after_seconds')}</h4>
                          <p>{translate('resources.customers.fields.kiosk_config.alarm_sound_stop_after_seconds_description')}</p>
                          <NumberInput source={`${source}.alarm.focus.sound.stopAfterSeconds`}
                            label={'resources.customers.fields.kiosk_config.alarm_sound_stop_after_seconds'}
                            fullWidth
                            min={0}
                            shouldUnregister/>
                          <h4>{translate('resources.customers.fields.kiosk_config.alarm_sound_stop_after_touch')}</h4>
                          <p>{translate('resources.customers.fields.kiosk_config.alarm_sound_stop_after_touch_description')}</p>
                          <NullableBooleanInput source={`${source}.alarm.focus.sound.stopAfterTouch`}
                            label={'resources.customers.fields.kiosk_config.alarm_sound_stop_after_touch'}
                            fullWidth
                            shouldUnregister/>
                        </>
                      );

                    }
                    return null;

                  }}
                </FormDataConsumer>

                <h4>{translate('resources.customers.fields.kiosk_config.alarm_acknowledge')}</h4>
                <p>{translate('resources.customers.fields.kiosk_config.alarm_acknowledge_description')}</p>
                <NullableBooleanInput source={`${source}.alarm.acknowledge.enabled`}
                  label={'resources.customers.fields.kiosk_config.alarm_acknowledge'} fullWidth
                  shouldUnregister/>

                <FormDataConsumer>
                  {({ formData: innerFormData }) => {

                    if (_.get(innerFormData, `${source}.alarm.acknowledge.enabled`)) {

                      return (
                        <>
                          <h4>{translate('resources.customers.fields.kiosk_config.alarm_acknowledge_pin')}</h4>
                          <p>{translate('resources.customers.fields.kiosk_config.alarm_acknowledge_pin_description')}</p>
                          <NumberInput
                            source={`${source}.alarm.acknowledge.pin`}
                            label={'resources.customers.fields.kiosk_config.alarm_acknowledge_pin'} fullWidth
                            shouldUnregister
                            validate={pinInputValidator}
                          />
                        </>
                      );

                    }
                    return null;

                  }}
                </FormDataConsumer>
              </>
            );

          }
          return null;

        }}
      </FormDataConsumer>

    </KioskFormBlock>,
    <KioskFormBlock
      title={translate('resources.customers.fields.kiosk_config.incident_counter')}
      hasOverwrite={hasOverwrite}
      fields={[
        `${source}.homepage.incidentCounter`,
      ]}
      overwriteSource={`${source}.overwrite_incidentCounter`}
      openCustomerDialog={props.openCustomerConfig}
    >
      <h4>{translate('resources.customers.fields.kiosk_config.incident_counter')}</h4>
      <p>{translate('resources.customers.fields.kiosk_config.incident_counter_description')}</p>
      <NullableBooleanInput
        source={`${source}.homepage.incidentCounter.enabled`}
        label="general.text.enabled"
        fullWidth
      />

      <FormDataConsumer>
        {({ formData }) => {

          if (_.get(formData, `${source}.homepage.incidentCounter.enabled`)) {

            return (
              <>
                <h4>{translate('resources.customers.fields.kiosk_config.incident_counter_count_from_date')}</h4>
                <p>{translate('resources.customers.fields.kiosk_config.incident_counter_count_from_date_description')}</p>
                <IncidentCounterField source={`${source}.homepage.incidentCounter`}/>
              </>
            );

          }
          return null;

        }}
      </FormDataConsumer>
    </KioskFormBlock>,
    <KioskFormBlock
      title={translate('resources.customers.fields.kiosk_config.screen_schedule')}
      hasOverwrite={hasOverwrite}
      overwriteSource={`${source}.overwrite_screenSchedule`}
      fields={[
        `${source}.schedule`,
      ]}
      openCustomerDialog={props.openCustomerConfig}
    >
      <h4>{translate('resources.customers.fields.kiosk_config.screen_schedule')}</h4>
      <p>{translate('resources.customers.fields.kiosk_config.screen_schedule_description')}</p>
      <NullableBooleanInput source={`${source}.schedule.enabled`} label="general.text.enabled" fullWidth/>

      <FormDataConsumer>
        {({ formData }) => {

          if (_.get(formData, `${source}.schedule.enabled`)) {

            return (
              <>
                <h4>{translate('resources.customers.fields.kiosk_config.schedule_entries')}</h4>
                <p>{translate('resources.customers.fields.kiosk_config.schedule_entries_description')}</p>
                <ScheduleInput source={`${source}.schedule.entries`} label={false} resource="kioskConfig"/>
              </>
            );

          }
          return null;

        }}
      </FormDataConsumer>

    </KioskFormBlock>,
  ];

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {

    setValue(newValue);

  };

  if (!hasOverwrite) {

    return <div>
      <Tabs value={value} onChange={handleChange}>
        {formContent.map((content, index) => {

          return <Tab key={index} label={content.props.title}/>;

        })}
      </Tabs>
      {formContent.map((content, index) => {

        return <TabPanel
          value={value}
          index={index}
          key={index}
          className={'kiosk-create-form'}>{content}</TabPanel>;

      })}
      <KioskConfigAlarmEnabler kioskFrame={kioskFrame} kioskConfigSource={source} tabValue={value}/>
    </div>;

  }

  return (
    <div className={'kiosk-edit-form'}>
      {formContent}
    </div>
  );

};

const KioskUrlDialog = (props: {
  open: boolean;
  onClose: () => void;
  url: string;
}) => {

  const { open, onClose, url } = props;
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [showOnceChecked, setShowOnceChecked] = useState(false);
  const [useOnce, setUseOnce] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();

  const CopyIconButton = () => {

    return <IconButton onClick={() => {

      copy(url || '');
      notify('general.text.copied');

    }
    }><ContentCopyIcon/></IconButton>;

  };

  return (
    <Dialog open={open} fullWidth maxWidth="sm">
      <DialogTitle>
        {translate('resources.kiosk-users.url_dialog_title')}
      </DialogTitle>
      <DialogContent>
        <MuiTextField
          label={false}
          disabled
          value={url}
          fullWidth
          InputProps={{ endAdornment: <CopyIconButton/> }}
          hiddenLabel
          sx={{ mb: 2 }}
        />
        <Grid container>
          <Grid sm={1}>
            <MuiCheckbox value={privacyChecked} onChange={(e) => setPrivacyChecked(e.target.checked)}/>
          </Grid>
          <Grid sm={11}>
            <Typography fontSize="12px" display='inline' variant="body1">{translate('resources.kiosk-users.url_dialog_privacy')}</Typography>
          </Grid>
          <Grid sm={1}>
            <MuiCheckbox value={showOnceChecked} onChange={(e) => setShowOnceChecked(e.target.checked)}/>
          </Grid>
          <Grid sm={11}>
            <Typography fontSize="12px" display='inline' variant="body1">{translate('resources.kiosk-users.url_dialog_show_once')}</Typography>
          </Grid>
          <Grid sm={1}>
            <MuiCheckbox value={useOnce} onChange={(e) => setUseOnce(e.target.checked)}/>
          </Grid>
          <Grid sm={11}>
            <Typography fontSize="12px" display='inline' variant="body1">{translate('resources.kiosk-users.url_dialog_use_once')}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {

          onClose();

        }} disabled={!showOnceChecked || !privacyChecked || !useOnce} color="primary" label="general.text.close"/>
      </DialogActions>
    </Dialog>
  );

};

const KioskConfigSender = (props: {
  kioskFrame: any;
  kioskConfigSource: string;
  selectedZone?: string;
  loadingDivRef?: React.RefObject<HTMLDivElement>;
}) => {

  const { watch, formState } = useFormContext();
  const kioskFrame = props.kioskFrame?.current;
  const kioskConfig = watch(props.kioskConfigSource);
  const customerId = getCurrentCustomer()?.value;
  const [lastSentConfig, setLastSentConfig] = useState<KioskConfig | null>(null);

  const sendConfigToKiosk = (transformedConfig: any) => {

    const token = localStorage.getItem('token');

    const envelope = {
      type: 'preview',
      jwt: token,
      customerId,
      config: transformedConfig,
    };

    kioskFrame.contentWindow?.postMessage(envelope, '*');
    setLastSentConfig(transformedConfig);
    props.loadingDivRef?.current?.classList.remove('visible');

  };

  const debouncedSender = _.debounce((transformedConfig: any) => {

    refreshContext().then(() => {

      sendConfigToKiosk(transformedConfig);

    });

  }, 500);

  // eslint-disable-next-line
  const sendConfig = (withDebounce: boolean) => {

    const formValidState = formState.isValid;
    if (kioskFrame && kioskConfig && customerId && props.selectedZone && formValidState) {

      const transformedConfig = {
        zone: {
          _id: props.selectedZone,
          _ref: 'Zone',
        },
        ...kioskConfigTransformer({ kioskConfig }),
      };

      if (transformedConfig.homepage?.logo?.src) {

        transformedConfig.homepage.logo = transformedConfig.homepage?.logo?.src;

      }

      if (!_.isEqual(lastSentConfig, transformedConfig)) {

        if (withDebounce) {

          props.loadingDivRef?.current?.classList.add('visible');
          debouncedSender(transformedConfig);

        } else {

          sendConfigToKiosk(transformedConfig);

        }

      }

    }

  };

  window.addEventListener('message', (event) => {

    if (event.data.type === 'previewModeReady') {

      sendConfig(false);

    }

    if (event.data.type === 'expiredAccessToken') {

      refreshContext().then(() => {

        sendConfig(false);

      });

    }

  });

  useEffect(() => {

    sendConfig(true);

  }, [kioskConfig, props.selectedZone, sendConfig]);

  return null;

};

type KioskDefinitionType = Awaited<ReturnType<typeof api.clientConfig.clientConfigControllerResolveKioskCustomerConfigV0>>['data']['definition'];

const compareAndReturnKioskConfig = async (
  fieldValues: Partial<KioskUser['kioskConfig']>,
  record: KioskUser | Customer,
  type: 'kioskUser' | 'customer',
  clientConfig: ClientConfigMapping,
  definition: KioskDefinitionType,
) => {

  const previousValues = record.kioskConfig;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let deviceKioskConfig: {
    key: string;
    type: ClientConfigOverrideType;
    value: any;
  }[] = [
    ...clientConfig?.kiosk || [],
  ];
  for (const currentDefKey of definition) {

    const oldValue = _.get(previousValues, currentDefKey.key);
    let newValue = _.get(fieldValues, currentDefKey.key);

    const definitionKey: ArrayElement<KioskDefinitionType> | undefined = _.find(definition, { key: currentDefKey.key });

    if (!definitionKey) {

      continue;

    }
    if (newValue === undefined || newValue === null) {

      deviceKioskConfig = deviceKioskConfig.filter((config) => config.key !== currentDefKey.key);
      continue;

    }
    if (currentDefKey.key === 'homepage.logo' && newValue.rawFile) {

      const logoUrl = await sendPicture(newValue.rawFile, record._id, type === 'kioskUser' ? 'kiosk-users' : 'customers');
      if (logoUrl) {

        newValue = logoUrl;

      } else {

        newValue = null;

      }

    }
    const lastMerge = definitionKey.mergeHistory[definitionKey.mergeHistory.length - 1];
    if (!['Device', 'Customer'].includes(lastMerge.sources[lastMerge.sources.length - 1]._ref)) {

      deviceKioskConfig?.push({
        type: ClientConfigOverrideType.Key,
        key: currentDefKey.key,
        value: newValue,
      });

    } // eslint-disable-line @typescript-eslint/brace-style

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    else if (definitionKey.mergeHistory.length > 1 && definitionKey.mergeHistory[definitionKey.mergeHistory.length - 2].value === newValue) {

      deviceKioskConfig = deviceKioskConfig.filter((config) => config.key !== currentDefKey.key);

    } else {

      deviceKioskConfig = deviceKioskConfig.filter((config) => config.key !== currentDefKey.key);
      deviceKioskConfig?.push({
        type: ClientConfigOverrideType.Key,
        key: currentDefKey.key,
        value: newValue,
      });

    }
    console.log('Changed field:', currentDefKey.key, 'Old value:', oldValue, 'New value:', newValue);

  }
  return deviceKioskConfig;

};

const normaliseEditValues = (kioskValues: ClientConfigMapping['kiosk']) => {

  const kioskConfig: Partial<KioskConfig> = {};

  if (!kioskValues) {

    return {};

  }
  kioskValues.forEach((key) => {

    if (key.type === ClientConfigOverrideType.Group) {

      return;

    }
    if (Array.isArray(key.value)) {

      key.value = key.value.map((value) => {

        if (value._ref) {

          return value._id;

        }
        return value;

      });

    }
    if (key.value?._ref) {

      key.value = key.value._id;

    }
    if (key.key === 'homepage.logo' && typeof key.value === 'string') {

      key.value = { title: 'image', src: key.value };

    }
    _.set(kioskConfig, key.key, key.value);

  });

  return kioskConfig;

};

export const KioskGeneralSettings = (props: {
  customer: Customer | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  zones: Zone[];
  numberOfKiosks?: number;
}) => {

  const { customer, open, setOpen } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const currentCustomer = getCurrentCustomer()?.value;
  const identity = useGetIdentity();
  const theme = useTheme();
  const kioskFrame = useRef(null);
  const loadingDivRef = useRef(null);
  const [lastSavedCustomer, setLastSavedCustomer] = useState<Identifier | null>(null);
  const [definition, setDefinition] = useState<KioskDefinitionType>([]);
  const [selectedZone, setSelectedZone] = useState<{ id: string; label: string } | null>();

  useEffect(() => {

    if (currentCustomer && lastSavedCustomer !== currentCustomer) {

      setSelectedZone(null);
      setLastSavedCustomer(currentCustomer);

    }

  }, [currentCustomer, identity]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    const getKioskConfig = async () => {

      if (!customer?._id) {

        return;

      }
      const kioskConfig = await api.clientConfig.clientConfigControllerResolveKioskCustomerConfigV0(customer?._id);
      setDefinition(kioskConfig.data.definition);

    };

    if (customer && customer._id) {

      getKioskConfig();

    }

  }, [customer]);

  const record = useMemo(() => {

    if (!customer) {

      return undefined;

    }

    const kioskConfig = normaliseEditValues(customer.clientConfig?.kiosk || []);
    return { ...customer, kioskConfig };

  }, [customer]);

  useEffect(() => {

    if (selectedZone) {

      localStorage.setItem(`selectedZone-${customer?._id}`, JSON.stringify(selectedZone));

    }

  }, [selectedZone]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    if (!lastSavedCustomer) {

      return;

    }
    const storedZone = localStorage.getItem(`selectedZone-${lastSavedCustomer}`);

    if (storedZone) {

      const parsedZone = JSON.parse(storedZone);
      const foundZone = props.zones.find((zone) => zone._id === parsedZone.id);
      if (foundZone) {

        setSelectedZone({ id: foundZone._id, label: foundZone.name });
        return;

      }

    }
    if (props.zones.length > 0) {

      setSelectedZone({ id: props.zones[0]._id, label: props.zones[0].name });
      return;

    }
    setSelectedZone(null);

  }, [lastSavedCustomer, props.zones]); // eslint-disable-line react-hooks/exhaustive-deps
  const submitForm = async (values: any) => {

    const transformedValues = kioskConfigTransformer(values);
    if (!record) {

      return;

    }
    if (definition) {

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const returnedConfig = await compareAndReturnKioskConfig(transformedValues, record, 'customer', record.clientConfig, definition);
      if (!_.isEqual(record.clientConfig, returnedConfig)) {

        await api.customers.customersControllerUpdate(record._id, {
          clientConfig: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            kiosk: returnedConfig,
          },
        });

        notify('resources.kiosks.text.general_settings_updated', { type: 'success' });
        refresh();

      } else {

        notify('general.text.no_changes', { type: 'info' });

      }

    }

    setOpen(false);

  };

  return <Dialog open={open} maxWidth={'md'} fullWidth style={{ marginRight: '50%' }} classes={{ paper: 'kiosk-config-dialog' }}>
    <Form id="default-kiosk-config" onSubmit={submitForm} record={record} mode="onChange" reValidateMode="onChange">
      <DialogTitle sx={{ height: '60px', lineHeight: '60px' }}>
        <span>{translate('resources.kiosks.text.general_settings_dialog.title')}</span>
      </DialogTitle>
      <DialogContent>
        <KioskSharedFields hasOverwrite={false} source="kioskConfig" kioskFrame={kioskFrame}/>
      </DialogContent>
      <DialogActions>
        <Button label="ra.action.cancel" onClick={() => setOpen(false)} variant="contained" color="error" sx={{ marginTop: '10px' }}/>
        <Button
          type={'submit'}
          label={props.numberOfKiosks ? translate('resources.kiosks.text.publish', { smart_count: props.numberOfKiosks }) : 'ra.action.publish'}
          color={'primary'}
          variant="contained"
          sx={{ marginTop: '10px' }}
        />
      </DialogActions>
      <KioskConfigSender
        kioskFrame={kioskFrame}
        kioskConfigSource={'kioskConfig'}
        selectedZone={selectedZone?.id}
        loadingDivRef={loadingDivRef}
      />
    </Form>
    <div className={'kiosk-preview'}>
      <div>
        <h2 style={{ backgroundColor: theme.palette.primary.main }}>
          <Autocomplete
            renderInput={(params) => (
              <MuiTextField {...params} size={'small'} margin={'dense'} variant={'outlined'} />
            )}
            size={'small'}
            options={props.zones.map((zone) => ({
              id: zone._id,
              label: zone.name,
            }))}
            value={selectedZone}
            onChange={(event, newValue) => {

              setSelectedZone(newValue);

            }}
          />
        </h2>
        <div className={'kiosk-preview-frame-loader'} ref={loadingDivRef}>
          <div>
            <CircularProgress />
          </div>
        </div>
        <iframe src={`${XGAC_KIOSK_URL}/preview`} title="Kiosk Preview" width="100%" height="100%" ref={kioskFrame}/>
      </div>
    </div>
  </Dialog>;

};

const KioskCreateForm = (props: {
  createMode: false | 'kiosk' | 'screen';
  setOpen: (open: false | 'kiosk' | 'screen') => void;
  zones: Zone[];
  setExpandedId: (id: Identifier) => void;
}) => {

  const translate = useTranslate();
  const notify = useNotify();
  const customer = getCurrentCustomer();
  const isAdmin = authProvider.isAdmin();
  const refresh = useRefresh();
  const [screenCreateResultOpen, setScreenCreateResultOpen] = useState(false);
  const [url, setUrl] = useState('');

  const handleCreate = (values: any) => {

    const foundZone = props.zones.find((zone) => zone._id === values.kioskConfig.zone._id);
    if (!foundZone) {

      notify('resources.kiosks.text.zone_not_found', { type: 'error' });

    }

    const centerOfZone = turf.centerOfMass(foundZone?.location as any).geometry.coordinates;
    const data = {
      ...values,
      user: {
        ...values.user,
        properties: {
          ...values.user?.properties,
        },
      },
      device: {
        kioskConfig: {
          managed: props.createMode === 'kiosk',
          zone: {
            _id: foundZone?._id,
            _ref: 'Zone',
          },
        },
      },
      asset: {
        ...values.asset,
        position: {
          ...values.asset?.position,
          properties: {
            provider: 'static',
            ...(foundZone?.location.properties || {}),
          },
          type: 'Point',
          coordinates: centerOfZone,
        },
      },
      customer: {
        _id: customer?.value,
        _ref: 'Customer',
      },
    };
    xgacDataProvider.create('specials/kiosk-user', { data }).then((response) => {

      const copiedOpen = props.createMode;
      if (copiedOpen === 'kiosk') {

        props.setExpandedId(response.data._id);
        props.setOpen(false);

      } else {

        props.setOpen(false);
        setUrl(`${XGAC_KIOSK_URL}/${response.data.user.username}/${response.data.password}`);
        props.setExpandedId(response.data._id);
        setScreenCreateResultOpen(true);

      }
      refresh();
      notify(`resources.kiosks.text.kiosk_created${copiedOpen === 'screen' ? '_screen' : ''}`, { type: 'success' });

    });

  };

  if (!isAdmin) {

    return (
      <>
        <Dialog open={props.createMode !== false} onClose={() => props.setOpen(false)} maxWidth={'lg'} fullWidth>
          <DialogTitle className="flex-in-between">
            <span>{translate(`resources.kiosks.text.create_dialog_title${props.createMode === 'screen' ? '_screen' : ''}`)}</span>
            <IconButton onClick={() => props.setOpen(false)}>
              <CloseIcon/>
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <ReactMarkdown className='add-kiosk-md'>
              {translate('resources.kiosks.text.customer_admin_create_dialog_content')}
            </ReactMarkdown>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => props.setOpen(false)} label="general.text.close"/>
          </DialogActions>
        </Dialog>
      </>
    );

  }

  return (
    <>
      <Dialog open={props.createMode !== false} onClose={() => props.setOpen(false)} maxWidth={'lg'} fullWidth>
        <DialogTitle className="flex-in-between">
          <span>{translate(`resources.kiosks.text.create_dialog_title${props.createMode === 'screen' ? '_screen' : ''}`)}</span>
          <IconButton onClick={() => props.setOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Form onSubmit={handleCreate}>
            <TextInput source="asset.name" label="general.fields.name" required/>
            <ReferenceInput reference="zones" source='kioskConfig.zone._id' shouldUnregister queryOptions={{
              meta: {
                size: '5',
              },
            }} >
              <AutocompleteInput
                autoHighlight={true}
                label="resources.zones.text.title_single"
                isRequired
                disableClearable
              />
            </ReferenceInput>
            {props.createMode === 'kiosk' && (
              <>
                <TextInput source="user.username" label="resources.kiosk-users.fields.fully_android_id" required shouldUnregister/>
                <div>
                  <PasswordInput source="password" label="resources.kiosk-users.fields.fully_device_id" required shouldUnregister/>
                </div>
              </>
            )}
            <NumberInput source={'externalId'} label={'resources.kiosk-users.kiosk_number'}/>
            <div className={'flex-in-between'}>
              <Button type={'submit'} label="ra.action.save" variant="contained"/>
            </div>
          </Form>
        </DialogContent>
      </Dialog>
      <KioskUrlDialog open={screenCreateResultOpen} onClose={() => {

        props.setOpen(false);
        setScreenCreateResultOpen(false);

      }} url={url}/>
    </>
  );

};

const KioskOnlineStatusIcon = () => {

  const record = useRecordContext();
  const theme = useTheme();
  const translate = useTranslate();

  if (!record) {

    return null;

  }
  const lastObservationAt = _.get(record, 'asset.lastObservationAt');
  const currentTimestamp = new Date().getTime();
  const lastObservationTimestamp = new Date(lastObservationAt || 0).getTime();

  if ((currentTimestamp - lastObservationTimestamp) < 1000 * 60 * 30) {

    return <Tooltip title={translate('resources.kiosks.text.status.online')} placement="top" arrow>
      <CheckCircleIcon
        style={{
          width: '17px',
          color: theme.palette.success.main,
        }} />
    </Tooltip>;

  }
  return <Tooltip title={translate('resources.kiosks.text.status.offline')} placement="top" arrow>
    <NotInterestedIcon
      style={{
        width: '17px',
        color: theme.palette.error.main,
      }} />
  </Tooltip>;

};

export const KioskSideBar = (props: {
  expandedId: Identifier;
  toggleItemExpansion: (id: Identifier) => void;
  loadedKiosks?: Array<KioskUser>;
  isLoaded: boolean;
  KioskEditForm: (props: { openCustomerDialog: () => void }) => JSX.Element;
  customer: Customer | null;
  zones: Zone[];
}) => {

  const {
    expandedId,
    toggleItemExpansion,
    loadedKiosks,
    isLoaded,
    KioskEditForm,
    customer,
    zones,
  } = props;

  const [generalSettingsDialogOpen, setGeneralSettingsDialogOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState<false | 'screen' | 'kiosk'>(false);
  const [createModeDialogOpen, setCreateModeDialogOpen] = useState(false);
  const translate = useTranslate();

  return (
    <>
      <div className="map-sidebar-div">
        <div className="map-sidebar-header">
          <Typography variant="h6" fontWeight="bold">{translate('resources.kiosks.text.manage')}</Typography>
          <div>
            <Button
              variant="contained"
              disabled={createOpen !== false}
              label="resources.kiosks.text.add_kiosk"
              startIcon={<AddIcon/>}
              onClick={() => {

                setCreateModeDialogOpen(true);

              }}
              sx={{
                marginRight: '10px', borderRadius: '5px', fontWeight: 'bolder', marginTop: '10px',
              }}
            />
            {zones.length > 0 ? (
              <Button
                variant="contained"
                disabled={createOpen !== false}
                label="resources.kiosks.text.default_config"
                startIcon={<SettingsIcon/>}
                onClick={() => {

                  setGeneralSettingsDialogOpen(true);

                }}
                sx={{ borderRadius: '5px', fontWeight: 'bolder', marginTop: '10px' }}
              />
            ) : (
              <Tooltip title={translate('resources.zones.text.no_zones')} arrow>
                <div style={{
                  display: 'inline-block',
                }}>
                  <Button
                    variant="contained"
                    disabled={true}
                    label="resources.kiosks.text.default_config"
                    startIcon={<SettingsIcon/>}
                    sx={{ borderRadius: '5px', fontWeight: 'bolder', marginTop: '10px' }}
                  />
                </div>
              </Tooltip>
            )}
          </div>
        </div>
        <div className="map-sidebar-grid">
          <Datagrid
            className={'map-sidebar-datagrid'}
            resource="kiosks"
            data={loadedKiosks}
            expandSingle={true}
            bulkActionButtons={false}
            selectedIds={[]}
            empty={isLoaded
              ? <div>
                <span style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '10px',
                  fontWeight: 'bolder',
                }}>{translate('resources.kiosks.text.none_yet')}</span>
              </div> : (
                <div style={{
                  height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                }}>
                  <CircularProgress/>
                </div>
              )}
            header={<EmptyHeader/>}
            expand={<KioskEditForm openCustomerDialog={() => setGeneralSettingsDialogOpen(true)}/>}
            rowClick={'toggleSelection'}
            onToggleItem={(id) => toggleItemExpansion(id)}
            rowSx={(record) => ({
              display: record._id === expandedId ? 'none' : undefined,
              borderBottom: '1px solid darkgray',
              padding: '0px 5px',
            })}
            cellPadding={0}
            cellSpacing={0}
          >
            <Grid container spacing={0} sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <Grid xs={1.5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                <KioskOnlineStatusIcon/>
              </Grid>
              <Grid xs={10.5}>
                <div className="flex-in-between">
                  <TextField source="asset.name" label="general.fields.name" sx={{
                    display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bolder',
                  }}/>
                  <IconButton size="small">
                    <EditIcon spacing={0}/>
                  </IconButton>
                </div>
              </Grid>
            </Grid>
          </Datagrid>
        </div>
      </div>
      <KioskGeneralSettings
        customer={customer}
        open={generalSettingsDialogOpen}
        setOpen={setGeneralSettingsDialogOpen}
        zones={zones}
        numberOfKiosks={loadedKiosks?.length}/>
      <CustomConfirmDialog
        open={createModeDialogOpen}
        handleClose={() => setCreateModeDialogOpen(false)}
        dialogTitle="resources.kiosks.text.create_mode_dialog_title"
        icon1={{
          title: 'resources.kiosks.text.add_kiosk',
          subTitle: 'resources.kiosks.text.add_kiosk_subtext',
          action: () => {

            setCreateOpen('kiosk');
            setCreateModeDialogOpen(false);

          },
          icon: <SvgIcon>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 0 30 30" strokeWidth='0px' stroke='currentColor'>
              <g>
                <g>
                  {/* eslint-disable-next-line max-len */}
                  <path d="M13.84,0H2.16C.97,0,0,.97,0,2.17v17.76c0,1.19.97,2.17,2.16,2.17h3.28v6.82h-2.75c-.44,0-.8.36-.8.8s.36.8.8.8h10.62c.44,0,.8-.36.8-.8s-.36-.8-.8-.8h-2.75v-6.82h3.28c1.19,0,2.16-.97,2.16-2.17V2.17c0-1.19-.97-2.17-2.16-2.17ZM15,19.93c0,.64-.52,1.17-1.16,1.17h-4.28v7.82h-3.11v-7.82H2.16c-.64,0-1.16-.52-1.16-1.17V2.17c0-.64.52-1.17,1.16-1.17h11.68c.64,0,1.16.52,1.16,1.17v17.76Z"/>
                </g>
              </g>
            </svg>
          </SvgIcon>,
        }}
        icon2={{
          title: 'resources.kiosks.text.add_screen',
          subTitle: 'resources.zones.text.add_screen_subtext',
          action: () => {

            setCreateOpen('screen');
            setCreateModeDialogOpen(false);

          },
          icon: <Monitor/>,
        }}/>
      <KioskCreateForm createMode={createOpen} setOpen={setCreateOpen} zones={zones} setExpandedId={toggleItemExpansion}/>
    </>
  );

};
const KioskAdminActions = (props: {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  setExpandedId: (id: Identifier) => void;
}) => {

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [moveOpen, setMoveOpen] = useState(false);
  const [regenerateOpen, setRegenerateOpen] = useState(false);
  const [regenerateAgree, setRegenerateAgree] = useState(false);
  const [newUrlDialogOpen, setNewUrlDialogOpen] = useState(false);
  const [newUrl, setNewUrl] = useState('');
  const [chosenCustomer, setChosenCustomer] = useState<Identifier | null>(null);
  const { dialogOpen, setDialogOpen, setExpandedId } = props;
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  if (!record) {

    return null;

  }
  const currentCustomer = getCurrentCustomer();

  const customersString = localStorage.getItem('customers') || '[]';
  const customers = JSON.parse(customersString);

  const closeDialog = () => {

    setDeleteOpen(false);
    setChosenCustomer(null);
    setMoveOpen(false);
    setDialogOpen(false);
    setRegenerateOpen(false);
    setRegenerateAgree(false);

  };

  const deleteKiosk = () => {

    closeDialog();
    setExpandedId('');
    xgacDataProvider.delete('specials/kiosk-user', { id: record._id }).then(() => {

      notify('resources.kiosks.text.kiosk_deleted', { type: 'success' });
      refresh();

    });

  };
  const moveKiosk = () => {

    httpClient(`${apiUrl}/specials/kiosk-user/${record.device._id}/move`, {
      method: 'POST',
    }, {
      customer: {
        _id: chosenCustomer,
        _ref: 'Customer',
      },
    }).then(() => {

      closeDialog();
      setExpandedId('');
      notify('resources.kiosks.text.kiosk_moved', { type: 'success' });
      refresh();

    });

  };

  const regenerateKiosk = () => {

    httpClient(`${apiUrl}/specials/kiosk-user/${record.device._id}/regenerate-credentials`, {
      method: 'POST',
    }, {}).then((response) => {

      setNewUrl(`${XGAC_KIOSK_URL}/${response.json.username}/${response.json.password}`);
      setNewUrlDialogOpen(true);
      closeDialog();
      notify('resources.kiosks.text.kiosk_regenerated', { type: 'success' });

    });

  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="lg" fullWidth>
        <DialogTitle className="flex-in-between">
          <span>"{record.asset.name}"</span>
          <IconButton onClick={() => setDialogOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center" alignItems="center" >
            <Grid xs={4}>
              <Button onClick={() => setDeleteOpen(true)} variant="contained" color="error" label="ra.action.delete"/>
            </Grid>
            <Grid xs={4}>
              <Button onClick={() => setMoveOpen(true)} variant="contained" label="general.text.move"/>
            </Grid>
            <Grid xs={4}>
              <Button
                onClick={() => setRegenerateOpen(true)}
                variant="contained"
                label="resources.kiosks.text.kiosk_regenerate_button"
                disabled={record.kioskConfig?.managed}/>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Confirm
        isOpen={deleteOpen}
        content="ra.message.delete_content"
        onClose={() => setDeleteOpen(false)}
        onConfirm={deleteKiosk}
        title={translate('resources.kiosks.text.kiosk_delete_title', { name: record.asset.name || '' })}
      />
      <Dialog
        open={moveOpen}
        onClose={() => setMoveOpen(false)}
        maxWidth="lg"
        fullWidth
        PaperProps={{
          sx: {
            overflowY: 'visible',
          },
        }}>
        <DialogTitle className="flex-in-between">
          <span>{translate('resources.kiosks.text.kiosk_move_title', { name: record.asset.name || '' })}</span>
          <IconButton onClick={() => setMoveOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ overflowY: 'visible' }}>
          <ReactSelect
            onChange={(e: any) => setChosenCustomer(e.value)}
            options={customers}
            defaultValue={currentCustomer}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {

            moveKiosk();

          }} label="general.text.move"/>
        </DialogActions>
      </Dialog>
      <Dialog
        open={regenerateOpen}
        onClose={() => setRegenerateOpen(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle className="flex-in-between">
          <span>{translate('resources.kiosks.text.kiosk_regenerate_title', { name: record.asset.name || '' })}</span>
          <IconButton onClick={() => setRegenerateOpen(false)}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>

          <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', padding: '20px',
          }}>
            <MuiCheckbox size="small" value={regenerateAgree} onChange={(e) => setRegenerateAgree(e.target.checked)}/>
            <Typography fontSize="12px" display='inline' variant="body1">{translate('resources.kiosk-users.url_dialog_agree')}</Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {

            regenerateKiosk();

          }} disabled={!regenerateAgree } color="primary" label="resources.kiosks.text.kiosk_regenerate_button"/>
        </DialogActions>
      </Dialog>
      <KioskUrlDialog open={newUrlDialogOpen} onClose={ () => {

        closeDialog();
        setNewUrlDialogOpen(false);
        setNewUrl('');

      }} url={newUrl}/>
    </>
  );

};

const KioskPositionZoneWatcher = (props: {
  setZoneWarning: (warning: boolean) => void;
  zones: Zone[];
}) => {

  const record = useRecordContext();
  const { watch } = useFormContext();
  const zoneId = watch('kioskConfig.zone._id');

  useEffect(() => {

    if (!record || !record.kioskConfig?.zone?._id) {

      props.setZoneWarning(false);
      return;

    }

    if (zoneId && zoneId !== record.kioskConfig?.zone?._id) {

      const newZone = props.zones.find((zone) => zone._id === zoneId);
      const originalZone = props.zones.find((zone) => zone._id === record.kioskConfig.zone._id);

      if (newZone && originalZone) {

        const zoneCenter = turf.centerOfMass(newZone.location).geometry.coordinates;
        if (!turf.inside(zoneCenter, originalZone.location)) {

          props.setZoneWarning(true);
          return;

        }

      }

    }
    props.setZoneWarning(false);

  }, [zoneId]); // eslint-disable-line react-hooks/exhaustive-deps

  return <></>;

};

export const KioskBeta = () => {

  const [expandedId, setExpandedId] = useState<Identifier>('');
  const [zonesInKiosks, setZonesInKiosks] = useState<Array<Zone>>([]);
  const [zonesBiggerThanMarker, setZonesBiggerThanMarker] = useState<Array<Zone>>([]);
  const [selectedFloors, setSelectedFloors] = useState<number[]>([]);
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [, toggleExpanded] = useExpanded('kiosks', expandedId, true);
  const [map, setMap] = useState<Map | null>(null);
  const currentCustomer = getCurrentCustomer();
  const translate = useTranslate();
  const refresh = useRefresh();
  const kioskRequest: UseGetListHookValue<KioskUser> = useGetList('specials/kiosk-user', {
    pagination: { page: 1, perPage: -1 },
    sort: { field: 'createdAt', order: 'ASC' },
    filter: {},
  });
  const zoneRequest: UseGetListHookValue<Zone & { id: Identifier }> = useGetList('zones', {
    pagination: { page: 1, perPage: -1 },
    sort: { field: 'createdAt', order: 'ASC' },
    filter: { name: '!Opvolging wereld' },
  });
  const customerRequest = useGetOne('customers', { id: currentCustomer?.value });

  useEffect(() => {

    if (customerRequest.data && !_.isEqual(customerRequest.data, customer)) {

      setCustomer(customerRequest.data);

    }

  }, [customerRequest.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    toggleExpanded();

  }, [expandedId]); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredKiosks = useMemo(() => {

    if (!kioskRequest.data) {

      return undefined;

    }
    if (selectedFloors.length === 0) {

      return kioskRequest.data;

    }
    return kioskRequest.data?.filter((kiosk) => {

      return selectedFloors.includes(kiosk.asset?.position?.properties?.address?.floor || 0);

    });

  }, [kioskRequest.data, selectedFloors]);

  const toggleItemExpansion = (id: Identifier) => {

    setExpandedId(id === expandedId ? '' : id);

  };

  const KioskEditForm = (props: {
    openCustomerDialog: () => void;
  }) => {

    const [adminActionsOpen, setAdminActionsOpen] = useState(false);
    const [zoneWarning, setZoneWarning] = useState(false);
    const [definition, setDefinition] = useState<KioskDefinitionType | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    // const record = filteredKiosks?.find((kiosk) => kiosk._id === expandedId);
    const notify = useNotify();

    const record = useMemo(() => {

      const foundRecord = filteredKiosks?.find((kiosk) => kiosk._id === expandedId);
      if (!foundRecord) {

        return undefined;

      }

      const kioskConfig = normaliseEditValues(foundRecord.device.clientConfig?.kiosk || []);
      return { ...foundRecord, kioskConfig };

    }, []);

    useEffect(() => {

      const getKioskConfig = async () => {

        if (!record?._id) {

          return;

        }
        const kioskConfig = await api.clientConfig.clientConfigControllerResolveKioskDeviceConfigV0(record?._id);
        setDefinition(kioskConfig.data.definition);

      };

      if (record && record._id) {

        getKioskConfig();

      }

    }, [record]);
    if (!record) {

      return <></>;

    }

    const submitForm = async (values: any) => {

      await wait(1);
      setLoading(true);
      const newZoneId = _.get(values, 'kioskConfig.zone._id');

      let newPositionCoordinates: Position | undefined;
      if (newZoneId && newZoneId !== record.kioskConfig?.zone?._id) {

        const newZone = zoneRequest.data?.find((zone) => zone._id === newZoneId);
        if (newZone) {

          newPositionCoordinates = turf.centerOfMass(newZone.location).geometry.coordinates;

        }

      }
      if (!newPositionCoordinates) {

        map?.eachLayer((layer: any) => {

          if (layer instanceof L.Marker) {

            if (layer.toGeoJSON().id === record._id) {

              const latLng = layer.getLatLng();
              newPositionCoordinates = [latLng.lng, latLng.lat];

            }

          }

        });

      }

      const transformedValues = kioskConfigTransformer(values);
      if (definition) {

        const deviceKioskConfig = await compareAndReturnKioskConfig(transformedValues, record, 'kioskUser', record.device.clientConfig, definition);
        if (!_.isEqual(record.device.clientConfig, deviceKioskConfig)) {

          await api.devices.devicesControllerUpdate(record.device._id, {
            clientConfig: {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              kiosk: deviceKioskConfig,
            },
          });

        }

      }

      const data = {
        ...record,
        asset: {
          ...record.asset,
          name: values.asset.name,
          position: {
            ...record.asset.position,
            coordinates: newPositionCoordinates || record.asset.position?.coordinates,
          },
        },
      };

      xgacDataProvider.update('specials/kiosk-user', { id: record._id, data, previousData: {} }).then(() => {

        notify('resources.kiosks.text.general_settings_updated', { type: 'success' });
        if (newPositionCoordinates) {

          const pointInBounds = map?.getBounds().contains(L.latLng(newPositionCoordinates[1], newPositionCoordinates[0]));

          if (!pointInBounds) {

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            map?.setView(newPositionCoordinates[1], newPositionCoordinates[0]);

          }

        }

      }).finally(() => {

        setExpandedId('');
        setLoading(false);
        refresh();

      });

    };

    return (
      <>
        <Card sx={{ borderRadius: '5px', boxShadow: 1 }} id="open_zone_form">
          <CardContent sx={{ p: '10px' }}>
            <Form onSubmit={submitForm} record={record}>
              <div className={'flex-in-between'}>
                <TextField source="asset.name" label="general.fields.name" sx={{
                  width: '100%',
                  fontWeight: 'bolder',
                  fontSize: '17px',
                }}/>
                <HasRoles anyOf={['admin']}>
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      size={'small'}
                      variant={'text'}
                      onClick={() => setAdminActionsOpen(true)}
                      startIcon={<SettingsIcon/>}
                      label={'resources.kiosks.text.admin_actions.button'}
                    />
                  </div>
                </HasRoles>
              </div>
              <TextInput source="asset.name" label="general.fields.name" required shouldUnregister fullWidth/>
              <ReferenceInput
                reference="zones"
                source='kioskConfig.zone._id'
                shouldUnregister
                isRequired
                queryOptions={{
                  meta: {
                    size: '5',
                  },
                }}
              >
                <AutocompleteInput
                  autoHighlight={true}
                  label="resources.zones.text.title_single"
                  isRequired
                  helperText={zoneWarning ? false : undefined}
                  fullWidth
                  disableClearable
                />
              </ReferenceInput>
              {zoneWarning && <Alert severity="warning" sx={{
                marginBottom: '10px',
              }}>{translate('resources.kiosks.text.zone_warning')}</Alert>}
              <strong>{translate('resources.kiosks.text.general_settings.overwrite')}</strong>
              <KioskSharedFields hasOverwrite={true} source="kioskConfig" openCustomerConfig={props.openCustomerDialog}/>
              <div style={{ width: '100%' }}>
                <Grid container spacing={1} sx={{ marginTop: '10px' }}>
                  <Grid md={6}>
                    <Button
                      onClick={() => toggleItemExpansion('')}
                      label='ra.action.cancel'
                      variant={'contained'}
                      sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                      color={'error'}
                    />
                  </Grid>
                  <Grid md={6}>
                    <Button type={'submit'}
                      label="ra.action.save"
                      variant="contained"
                      sx={{ width: '100%', borderRadius: '5px', fontWeight: 'bolder' }}
                      disabled={loading}
                    />
                  </Grid>
                </Grid>
              </div>
              <KioskPositionZoneWatcher zones={zoneRequest.data || []} setZoneWarning={setZoneWarning}/>
            </Form>
          </CardContent>
        </Card>
        <KioskAdminActions dialogOpen={adminActionsOpen} setDialogOpen={setAdminActionsOpen} setExpandedId={setExpandedId}/>
      </>
    );

  };

  const overlays = useOverlay(map, selectedFloors);
  useEffect(() => {

    if (zoneRequest.data && filteredKiosks) {

      const biggerZones = zoneRequest.data.filter((zone) => {

        return turf.area(zone.location) > 5 && turf.area(zone.location) < 20000 * 1000000;

      });
      const filteredZones = biggerZones.filter((zone) => {

        return filteredKiosks.some((kiosk) => {

          return kiosk.kioskConfig.zone?._id === zone._id;

        });

      });
      setZonesInKiosks(_.uniqBy(filteredZones, '_id'));
      setZonesBiggerThanMarker(biggerZones);

    }

  }, [zoneRequest.data, filteredKiosks]);

  const possibleFloors = useMemo(() => {

    const overlayFloors = overlays.map((overlay) => overlay.level || 0);
    const zoneFloors = zonesInKiosks.map((zone) => zone.location?.properties?.address?.floor || 0);
    return _.uniq([...overlayFloors, ...zoneFloors]);

  }, [overlays, zonesInKiosks]);
  return <>
    <Grid container sx={{ height: '100%', marginLeft: '-16px' }}>
      <Grid md={9}>
        <KioskMap
          kiosks={filteredKiosks}
          map={map}
          setMap={setMap}
          expandedId={expandedId}
          zonesInKiosks={zonesInKiosks}
          selectedFloors={selectedFloors}
          possibleFloors={possibleFloors}
          setSelectedFloors={setSelectedFloors}
          handleClickOnMarker={toggleItemExpansion}
        />
      </Grid>
      <Grid md={3}>
        <KioskSideBar
          expandedId={expandedId}
          toggleItemExpansion={toggleItemExpansion}
          loadedKiosks={filteredKiosks}
          KioskEditForm={KioskEditForm}
          isLoaded={!kioskRequest.isLoading}
          customer={customer}
          zones={zonesBiggerThanMarker || []}
        />
      </Grid>
    </Grid>
  </>;

};
