import {
  AlarmType,
  Asset, Beacon, ClientConfigMapping, CustomerRef, Device, DeviceType, KioskConfig, User, UserRef, Zone,
} from '@x-guard/xgac-types/xgac';
import { Identifier } from 'react-admin';

export type UploadedCSV = {
  value_external_id: string;
  value_personal_available: string;
  value_personal_firstname: string;
  value_personal_lastname: string;
  value_personal_phone_country: string;
  value_personal_phone_number: string;
  value_personal_locale: string;
  value_personal_timezone: string;
  value_user_email: string;
  value_user_username: string;
  locked_external_id: string;
  locked_personal_available: string;
  locked_personal_firstname: string;
  locked_personal_lastname: string;
  locked_personal_phone_country: string;
  locked_personal_phone_number: string;
  locked_personal_locale: string;
  locked_personal_timezone: string;
  locked_user_email: string;
  download_after_import: string;
  register_after_import: string;
};

export type ZoneWithBeacons = Zone & {
  beacons: Beacon[];
  zoneSize: number;
  fingerprintCount?: number;
};

export enum DeviceStatus {
  Active = 'active',
  Unpaid = 'unpaid',
  Demo = 'demo',
}
export type DeviceFilter = {
  floors: number[];
  deviceType: DeviceType[];
  activeStatus: DeviceStatus[];
};

export type KioskUser = {
  id: string;
  _id: string;
  user: User;
  device: Device & {
    kioskConfig: KioskConfig;
    clientConfig: ClientConfigMapping;
  };
  asset: Asset;
  kioskConfig: KioskConfig;
  password?: string;
};

export type DeviceConnectionStatus = 'connected' | 'disconnected' | 'empty' | 'highNoise' | 'lowRSSI' | 'unknown';

export type BeaconHealth = Beacon & { healthy: true | 'lowBattery' | 'inactive' };

export type RoomCalcSizeConfig = {
  maxSquareMeters: number;
  beaconNumber: number;
  fingerPrintTime: number;
};
export type RoomCalcConfig = {
  small: RoomCalcSizeConfig;
  medium: RoomCalcSizeConfig;
  large: RoomCalcSizeConfig;
};

export type RoomCalcResultRow = {
  roomNumber: number;
  beaconNumber: number;
  totalSquareMeters: number;
  zones: ZoneWithBeacons[];
  fingerPrintTime: number;
  beaconPlaceTime: number;
  totalTime: number;
};
export type RoomCalcResult = {
  small: RoomCalcResultRow;
  medium: RoomCalcResultRow;
  large: RoomCalcResultRow;
  total: RoomCalcResultRow;
};

export type ReportAuditLog = {
  customer: CustomerRef;
  filter: any;
  limit: number;
  operation: string;
  skip: number;
  sort: any;
  subject: string;
  timestamp: string;
  user: UserRef | null;
  userLabel: string;
  _id: Identifier;
};
export type ProcessTemplate = {
  id: string;
  processName: string;
  scheduleTasks: {
    delay: number;
    id: string;
    metaScript: {
      name: string;
      meta: {
        filter: any;
        xgacFilter: any;
      };
    };
    taskTemplate: {
      tasks: any[];
      templateName: string;
    };
  }[];
  createdAt: string;
  updatedAt: string;
};
export type CustomerLink = {
  id: string;
  alarmLevels: {
    green: boolean;
    orange: boolean;
    red: boolean;
  };
  alarmTypes: AlarmType[];
  groups: string[];
  notXgacGroups: string[];
  xgacGroups: string[];
  processTemplate: ProcessTemplate;
  xgacCustomerId: string;
  createdAt: string;
};
