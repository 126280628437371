import {
  ArrayInput,
  AutocompleteArrayInput,
  AutocompleteInput,
  Button,
  DateTimeInput, FormDataConsumer,
  maxValue,
  minValue,
  NullableBooleanInput,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  regex,
  SelectArrayInput,
  SimpleFormIterator,
  TextInput,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import _ from 'lodash';
import { ArrayElement, DefinitionType } from './constants/typedApiReturnTypes';

const indentBorderStyle = '2px solid #e0e0e0';

const translateResource = (resource: string) => {

  const splitResource = resource.split(/(?=[A-Z])/);
  const joinedResource = splitResource.join('-');

  return `${joinedResource.toLowerCase()}s`;

};

const isColor = (strColor: string) => {

  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';

};

const getDepth = (key: string, allKeys: string[]) => {

  const currentSplitKey = key.split('.');
  let depth = 0;
  for (let i = currentSplitKey.length - 1; i > 0; i--) {

    const currentKey = currentSplitKey.slice(0, i).join('.');
    const hasSiblings = allKeys.some((innerKey) => innerKey.startsWith(currentKey) && innerKey !== key);
    if (hasSiblings) {

      depth = i;
      break;

    }

  }

  return depth;

};

const getTitleFromKey = (key: string, index?: number) => {

  const splitKey = key.split('.');
  const title = splitKey[index !== undefined ? index : splitKey.length - 1]?.replace(/([A-Z])/g, ' $1');
  if (!title) {

    return '';

  }
  return title[0].toUpperCase() + title.slice(1).toLowerCase();

};

const ConfigFieldByType = (props: {
  definition: ArrayElement<DefinitionType>;
}) => {

  const translate = useTranslate();
  const { definition } = props;

  const generalProps: {
    source: string;
    required: boolean;
    fullWidth: boolean;
    helperText?: string | false;
    size?: 'small' | 'medium';
    label: string;
    sx: any;
  } = {
    source: definition.key,
    required: definition.schema.nullable !== true,
    fullWidth: true,
    helperText: false,
    size: 'small',
    label: getTitleFromKey(definition.key),
    sx: { marginBottom: '5px', marginTop: '5px' },
  };

  const refInputProps: {
    helperText: false | string;
    sx: any;
    size: 'small' | 'medium';
    fullWidth: boolean;
  } = {
    helperText: false,
    sx: { marginBottom: '5px', marginTop: '5px' },
    size: 'small',
    fullWidth: true,
  };
  if (definition.schema.type === 'string') {

    if (definition.schema.format === 'date-time') {

      return (
        <DateTimeInput {...generalProps} />
      );

    }

    if (definition.schema.enum) {

      return (
        <AutocompleteInput {...generalProps} choices={definition.schema.enum} />
      );

    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (isColor(definition.mergeHistory[0].value)) {

      return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '0.5em' }}>
          <FormDataConsumer>
            {({ formData }) => {

              const value = _.get(formData, definition.key);
              if (isColor(value)) {

                return (
                  <div className="app-defaults-color-diff" style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}>
                    <div className={'app-defaults-color-diff-split'} style={{
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      backgroundColor: definition.mergeHistory[0].value,

                    }}
                    />
                    <div className={'app-defaults-color-diff-split'} style={{
                      backgroundColor: value,
                    }}/>
                  </div>
                );

              }
              return (
                <div className="app-defaults-color-diff" style={{
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  backgroundColor: definition.mergeHistory[0].value,
                }}/>
              );

            }}
          </FormDataConsumer>
          <TextInput {...generalProps} />
        </div>
      );

    }
    return (
      <TextInput
        {...generalProps}
        validate={definition.schema.pattern
          ? regex(new RegExp(definition.schema.pattern), translate('ra.validation.regex', { pattern: definition.schema.pattern }))
          : undefined}
      />
    );

  }
  if (definition.schema.type === 'number' || definition.schema.type === 'integer') {

    const validation = [];
    if (definition.schema.minimum !== undefined) {

      validation.push(minValue(definition.schema.minimum));

    }
    if (definition.schema.maximum !== undefined) {

      validation.push(maxValue(definition.schema.maximum));

    }
    return (
      <NumberInput {...generalProps} min={definition.schema.minimum} max={definition.schema.maximum} validate={validation} parse={(v) => {

        if (definition.schema.type === 'integer') {

          return parseInt(v, 10);

        }
        return parseFloat(v);

      }} />
    );

  }
  if (definition.schema.type === 'boolean') {

    return (
      <NullableBooleanInput {...generalProps} />
    );

  }
  if (definition.schema.type === 'object') {

    if (definition.schema.properties._ref) {

      return (
        <ReferenceInput {...generalProps} reference={translateResource(definition.schema.properties._ref.enum[0])}>
          <AutocompleteInput disabled={true} label={definition.key} {...refInputProps} />
        </ReferenceInput>
      );

    }

    if (definition.schema?.properties) {

      const allKeys = Object.keys(definition.schema.properties);
      let depth = 0;
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {Object.keys(definition.schema.properties).map((key) => {

            const fullKey = `${definition.key}.${key}`;
            const calcDepth = getDepth(fullKey, allKeys);
            let hasTitle = '';
            if (depth < calcDepth) {

              hasTitle = getTitleFromKey(fullKey, calcDepth - 1);

            }
            depth = calcDepth;
            return (
              <div
                style={{
                  paddingLeft: `${calcDepth}em`,
                  borderLeft: calcDepth > 0 ? indentBorderStyle : 'none',
                  marginTop: hasTitle ? '1em' : 0,
                  order: calcDepth,
                }}
                className={`margin-${calcDepth}`}
                key={key}
              >
                {hasTitle && <h2 style={{ margin: '0 0 0.5em 0', fontSize: '18px', order: 0 }}>{hasTitle}</h2>}
                <ConfigFieldByType definition={{
                  schema: definition.schema.properties[key],
                  key: `${definition.key}.${key}`,
                  editable: definition.schema.properties[key].editable,
                  mergeHistory: definition.schema.mergeHistory,
                }}/>
              </div>
            );

          })}
        </div>
      );

    }

  }
  if (definition.schema.type === 'array') {

    if (definition.schema.items.type === 'object') {

      if (definition.schema.items.properties._ref) {

        return (
          <div>
            <ReferenceArrayInput {...generalProps} reference={translateResource(definition.schema.items.properties._ref.enum[0])}>
              <AutocompleteArrayInput disabled={true} {...refInputProps} />
            </ReferenceArrayInput>
          </div>
        );

      }

    }

    if (definition.schema.items.enum) {

      return (
        <AutocompleteInput {...generalProps} choices={definition.schema.items.enum}/>
      );

    }
    if (definition.schema.items.properties) {

      let currDepth = 0;
      const allKeys = Object.keys(definition.schema.items.properties);
      return (
        <div
          style={{
            paddingLeft: '1em',
            borderLeft: indentBorderStyle,
          }}>
          <h2 style={{ margin: '1em 0 0.5em 0', fontSize: '18px', order: 0 }}>{getTitleFromKey(definition.key)}</h2>
          <ArrayInput source={definition.key} label={false}>
            <SimpleFormIterator>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'white',
                  padding: '1em',
                  border: '1px dashed #ccc',
                }}
              >
                {Object.keys(definition.schema.items.properties).map((key) => {

                  const calcDepth = getDepth(`${definition.key}.${key}`, allKeys);
                  let hasTitle = '';
                  if (currDepth < calcDepth) {

                    hasTitle = getTitleFromKey(key, calcDepth - 1);

                  }
                  currDepth = calcDepth;
                  return (
                    <div style={{
                      paddingLeft: `${Math.max(calcDepth - 1, 0)}em`,
                      borderLeft: calcDepth > 0 ? indentBorderStyle : 'none',
                      marginTop: hasTitle ? '1em' : 0,
                      order: calcDepth,

                    }}
                    className={`margin-${calcDepth}`}
                    key={key}
                    >
                      {hasTitle && <h2 style={{ margin: '0 0 0.5em 0', fontSize: '18px', order: 0 }}>{hasTitle}</h2>}
                      <ConfigFieldByType definition={{
                        key,
                        schema: definition.schema.items.properties[key],
                        editable: definition.editable,
                        mergeHistory: definition.mergeHistory,
                      }}/>
                    </div>
                  );

                })
                }
              </div>
            </SimpleFormIterator>
          </ArrayInput>
        </div>
      );

    }
    if (definition.schema.items.type === 'number' && definition.schema.items.minimum !== undefined && definition.schema.items.maximum !== undefined) {

      const options = [];
      for (let i = definition.schema.items.minimum; i <= definition.schema.items.maximum; i++) {

        options.push({ id: i, name: i });

      }
      return (
        <SelectArrayInput {...generalProps} source={definition.key} choices={options}/>
      );

    }

    return (
      <ArrayInput source={definition.key}>
        <SimpleFormIterator>
          <ConfigFieldByType definition={{
            key: definition.key,
            schema: definition.schema.items,
            editable: definition.editable,
            mergeHistory: definition.mergeHistory,
          }}/>
        </SimpleFormIterator>
      </ArrayInput>
    );

  }

  return (
    <div><span>
      unsupported type: {definition.schema.type}
    </span></div>
  );

};

export const ClientConfigFormGenerator = (props: {
  definitions: DefinitionType;
}) => {

  const keyByGroup = props.definitions.reduce((acc, definition) => {

    const splitKey = definition.key.split('.')[0];
    const group = acc.find((inner) => inner.group === splitKey);

    if (!group) {

      acc.push({
        group: splitKey,
        definitions: [definition],
      });

    } else {

      group.definitions.push(definition);

    }

    return acc;

  }, [] as {
    group: string;
    definitions: ArrayElement<DefinitionType>[];
  }[]);

  const allKeys = props.definitions.map((def) => def.key);
  return (
    <div>
      {keyByGroup.map((definition) => (
        <div style={{
          borderRadius: '4px',
          padding: '1em',
          margin: '0 0 1em 0',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
        }}>
          <h1 style={{ margin: '0 0 0.5em 0', fontSize: '20px', order: 0 }}>
            {getTitleFromKey(definition.group)}
          </h1>
          {definition.definitions.map((def, index) => {

            const calcDepth = getDepth(def.key, allKeys);
            let hasTitle = '';
            if (index !== 0) {

              const lastDef = definition.definitions[index - 1].key;
              const currentDef = def.key;
              const lastSplit = lastDef.split('.');
              const currentSplit = currentDef.split('.');
              const diff = currentSplit.filter((item, innerIndex) => item !== lastSplit[innerIndex]);
              const diffLastRemoved = diff.slice(0, diff.length - 1);
              if (diffLastRemoved.length > 0) {

                const title = diffLastRemoved.join(' ').replace(/([A-Z])/g, ' $1');
                hasTitle = title[0].toUpperCase() + title.slice(1).toLowerCase();

              }

            } else if (calcDepth > 1) {

              hasTitle = getTitleFromKey(def.key, def.key.split('.').length - 2);

            }

            return (
              <div style={{
                paddingLeft: `${Math.max(calcDepth - 1, 0)}em`,
                borderLeft: calcDepth > 1 ? indentBorderStyle : 'none',
                marginTop: hasTitle ? '1em' : 0,
                order: def.schema.type === 'array' ? calcDepth + 1 : calcDepth,
              }}
              className={`margin-${calcDepth}`}
              key={def.key}
              >
                {hasTitle && <h2 style={{ margin: '0 0 0.5em 0', fontSize: '18px' }}>{hasTitle}</h2>}
                <ConfigFieldByType key={def.key} definition={def} />
              </div>
            );

          })}
        </div>
      ))}
      <Button type={'submit'} label={'ra.action.save'} variant={'contained'}/>
    </div>
  );

};
