/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AlarmAcknowledgeDto {
  comment?: string;
  systemAck?: boolean;
}

export interface AlarmAcknowledgementDto {
  /** @default true */
  needed?: boolean;
  /** @default false */
  value?: boolean;
  /** @default null */
  ackedBy?: {
    /** @format uuid */
    _id: string;
    _ref: "User";
  } | null;
  /** @default null */
  comment?: string | null;
  /** @default null */
  ackedAt?: string | null;
}

export interface AlarmCountResponseDto {
  open: number;
  closed: number;
}

export interface AlarmCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
  asset: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  };
  /** @default {"needed":true,"value":false,"ackedBy":null,"comment":null,"ackedAt":null} */
  ack?: {
    /** @default true */
    needed?: boolean;
    /** @default false */
    value?: boolean;
    /** @default null */
    ackedBy?: {
      /** @format uuid */
      _id: string;
      _ref: "User";
    } | null;
    /** @default null */
    comment?: string | null;
    /** @default null */
    ackedAt?: string | null;
  };
  /** @default null */
  position?: {
    type: "Point";
    coordinates: number[];
    properties: {
      accuracy?: number;
      heading?: number;
      headingAccuracy?: number;
      speed?: number;
      speedAccuracy?: number;
      altitude?: number;
      altitudeAccuracy?: number;
      address?: {
        /** @maxLength 1024 */
        countryCode?: string | null;
        /** @maxLength 1024 */
        state?: string | null;
        /** @maxLength 1024 */
        city?: string | null;
        /** @maxLength 1024 */
        streetName?: string | null;
        /** @maxLength 1024 */
        streetNumber?: string | null;
        floor?: number | null;
        /** @maxLength 1024 */
        formattedAddress?: string;
      };
      /** @format date-time */
      dateTime?: string;
      /** @maxLength 1024 */
      provider?: string;
    };
  };
  severity: "red" | "orange" | "green";
  /** @maxLength 4096 */
  procedure: string | null;
  meta?: {
    observationTrigger?: {
      /** @format uuid */
      _id: string;
      /** @format date-time */
      createdAt: string;
      /** @format date-time */
      updatedAt: string;
      telluId?: number | null;
      migrationFinalised?: boolean | null;
      /** @format date-time */
      migrationFinalisedDate?: string | null;
      customer: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      };
      /** @default {"type":"parents","extra":[]} */
      share?: {
        type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
        extra?: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        }[];
      };
      /** @maxLength 1024 */
      name: string;
      eventCodes: string[];
      /** @maxLength 1024 */
      description: string;
      status: "active" | "inactive";
      actions: (
        | {
            type: "alarm";
            config: {
              severity: "red" | "orange" | "green";
              type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
              /** @maxLength 4096 */
              procedure: string | null;
              /** @maxLength 1024 */
              name: string;
            };
          }
        | {
            type: "sms";
            config: {
              toRaw: string[];
              toContactList: {
                /** @format uuid */
                _id: string;
                _ref: "ContactList";
              }[];
              toTriggerUser: boolean;
              /** @maxLength 1024 */
              message: string;
            };
          }
        | {
            type: "email";
            config: {
              toRaw: string[];
              toContactList: {
                /** @format uuid */
                _id: string;
                _ref: "ContactList";
              }[];
              toTriggerUser: boolean;
              /** @maxLength 1024 */
              message: string;
              /** @maxLength 1024 */
              subject: string;
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale?: string;
            };
          }
      )[];
      filter:
        | {
            type: "operator/and";
            operands: (
              | {
                  type: "operator/or";
                  operands: (
                    | {
                        type: "operator/not";
                        operand:
                          | {
                              type: "constraint/asset";
                              assets: {
                                /** @format uuid */
                                _id: string;
                                _ref: "Asset";
                              }[];
                            }
                          | {
                              type: "constraint/assetGroup";
                              groups: {
                                /** @format uuid */
                                _id: string;
                                _ref: "AssetGroup";
                              }[];
                            }
                          | {
                              type: "constraint/zone";
                              zone: {
                                /** @format uuid */
                                _id: string;
                                _ref: "Zone";
                              };
                            }
                          | {
                              type: "constraint/schedule";
                              schedule: {
                                entries: {
                                  daysOfWeek: number[];
                                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                  timeStart: string;
                                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                  timeEnd: string;
                                }[];
                              };
                            };
                      }
                    | {
                        type: "constraint/asset";
                        assets: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Asset";
                        }[];
                      }
                    | {
                        type: "constraint/assetGroup";
                        groups: {
                          /** @format uuid */
                          _id: string;
                          _ref: "AssetGroup";
                        }[];
                      }
                    | {
                        type: "constraint/zone";
                        zone: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Zone";
                        };
                      }
                    | {
                        type: "constraint/schedule";
                        schedule: {
                          entries: {
                            daysOfWeek: number[];
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeStart: string;
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeEnd: string;
                          }[];
                        };
                      }
                  )[];
                }
              | {
                  type: "constraint/asset";
                  assets: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  }[];
                }
              | {
                  type: "constraint/assetGroup";
                  groups: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[];
                }
              | {
                  type: "constraint/zone";
                  zone: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  };
                }
              | {
                  type: "constraint/schedule";
                  schedule: {
                    entries: {
                      daysOfWeek: number[];
                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                      timeStart: string;
                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                      timeEnd: string;
                    }[];
                  };
                }
            )[];
          }
        | {
            type: "constraint/asset";
            assets: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
          }
        | {
            type: "constraint/assetGroup";
            groups: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
          }
        | {
            type: "constraint/zone";
            zone: {
              /** @format uuid */
              _id: string;
              _ref: "Zone";
            };
          }
        | {
            type: "constraint/schedule";
            schedule: {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            };
          };
    };
    observation?: {
      /** @format uuid */
      _id: string;
      /** @maxLength 1024 */
      event: string;
      asset: {
        /** @format uuid */
        _id: string;
        /** @format date-time */
        createdAt: string;
        /** @format date-time */
        updatedAt: string;
        telluId?: number | null;
        migrationFinalised?: boolean | null;
        /** @format date-time */
        migrationFinalisedDate?: string | null;
        customer: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        };
        /** @default {"type":"parents","extra":[]} */
        share?: {
          type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
          extra?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          }[];
        };
        user: {
          /** @format uuid */
          _id: string;
          _ref: "User";
        } | null;
        /** @maxLength 1024 */
        name: string;
        /** @maxLength 1024 */
        externalId?: string | null;
        propertiesOwnership?: "override" | "inherit";
        /** @default {} */
        properties?: {
          /** @maxLength 1024 */
          phoneNumber?: string | null;
          /** @maxLength 1024 */
          smsNumber?: string | null;
          /** @maxLength 1024 */
          email?: string | null;
          /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
          locale?: string | null;
          /** @maxLength 1024 */
          image?: string | null;
          rSim?: {
            /** @maxLength 1024 */
            euicc: string;
            primary: {
              /** @maxLength 1024 */
              msisdn: string;
              /** @maxLength 1024 */
              iccid: string;
            };
            secondary: {
              /** @maxLength 1024 */
              msisdn: string;
              /** @maxLength 1024 */
              iccid: string;
            };
            enabled: boolean;
          } | null;
        };
        /** @default null */
        position?: {
          type: "Point";
          coordinates: number[];
          properties: {
            accuracy?: number;
            heading?: number;
            headingAccuracy?: number;
            speed?: number;
            speedAccuracy?: number;
            altitude?: number;
            altitudeAccuracy?: number;
            address?: {
              /** @maxLength 1024 */
              countryCode?: string | null;
              /** @maxLength 1024 */
              state?: string | null;
              /** @maxLength 1024 */
              city?: string | null;
              /** @maxLength 1024 */
              streetName?: string | null;
              /** @maxLength 1024 */
              streetNumber?: string | null;
              floor?: number | null;
              /** @maxLength 1024 */
              formattedAddress?: string;
            };
            /** @format date-time */
            dateTime?: string;
            /** @maxLength 1024 */
            provider?: string;
          };
        };
        available: boolean;
        /** @default true */
        allowContactOnOwnAlarms?: boolean;
        app?: {
          mode: "off" | "on" | "risk";
        } | null;
        insideZones: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        }[];
        assetGroups: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        /** @default [] */
        staticResponders?: {
          staticResponder: {
            /** @format uuid */
            _id: string;
            _ref: "StaticResponder";
          };
          priority: number;
        }[];
        /** @format date-time */
        lastObservationAt?: string;
        /** @default false */
        invisibleResponder?: boolean;
        /** @maxLength 4096 */
        description?: string;
        type?: "default" | "kiosk";
        _ref: "Asset";
      };
      customer: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      };
      device: {
        /** @format uuid */
        _id: string;
        _ref: "Device";
      };
      user: {
        /** @format uuid */
        _id: string;
        _ref: "User";
      };
      position?: {
        type: "Point";
        coordinates: number[];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      };
      beacons?:
        | {
            /** @maxLength 1024 */
            code: string;
            rssi: number;
            battery?: number;
            /** @format date-time */
            dateTime: string;
            beacon: {
              /** @format uuid */
              _id: string;
              _ref: "Beacon";
            } | null;
            calc: number | null;
            valid: boolean;
          }[]
        | {
            /** @maxLength 1024 */
            code: string;
            rssi: number;
            battery?: number;
            /** @format date-time */
            dateTime: string;
          }[];
      meta?: {
        asset?: {
          /** @format uuid */
          _id: string;
          /** @format date-time */
          createdAt: string;
          /** @format date-time */
          updatedAt: string;
          telluId?: number | null;
          migrationFinalised?: boolean | null;
          /** @format date-time */
          migrationFinalisedDate?: string | null;
          customer: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          /** @default {"type":"parents","extra":[]} */
          share?: {
            type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
            extra?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
          };
          user: {
            /** @format uuid */
            _id: string;
            _ref: "User";
          } | null;
          /** @maxLength 1024 */
          name: string;
          /** @maxLength 1024 */
          externalId?: string | null;
          propertiesOwnership?: "override" | "inherit";
          /** @default {} */
          properties?: {
            /** @maxLength 1024 */
            phoneNumber?: string | null;
            /** @maxLength 1024 */
            smsNumber?: string | null;
            /** @maxLength 1024 */
            email?: string | null;
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale?: string | null;
            /** @maxLength 1024 */
            image?: string | null;
            rSim?: {
              /** @maxLength 1024 */
              euicc: string;
              primary: {
                /** @maxLength 1024 */
                msisdn: string;
                /** @maxLength 1024 */
                iccid: string;
              };
              secondary: {
                /** @maxLength 1024 */
                msisdn: string;
                /** @maxLength 1024 */
                iccid: string;
              };
              enabled: boolean;
            } | null;
          };
          /** @default null */
          position?: {
            type: "Point";
            coordinates: number[];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          };
          available: boolean;
          /** @default true */
          allowContactOnOwnAlarms?: boolean;
          app?: {
            mode: "off" | "on" | "risk";
          } | null;
          insideZones: {
            /** @format uuid */
            _id: string;
            _ref: "Zone";
          }[];
          assetGroups: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
          /** @default [] */
          staticResponders?: {
            staticResponder: {
              /** @format uuid */
              _id: string;
              _ref: "StaticResponder";
            };
            priority: number;
          }[];
          /** @format date-time */
          lastObservationAt?: string;
          /** @default false */
          invisibleResponder?: boolean;
          /** @maxLength 4096 */
          description?: string;
          type?: "default" | "kiosk";
        };
        battery?: number;
        rssi?: number;
        snr?: number;
        /** @format date-time */
        dateTime?: string;
        /** @format date-time */
        deviceDateTime?: string;
        /** @format date-time */
        gatewayDateTime?: string;
        insideGpsZones?: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        }[];
        insideIpsZones?: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        }[];
        zone?: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        };
        callRequest?: boolean;
        forceLoraCustomer?: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        };
        knnResult?: {
          distance: number;
          debugInfo?: any;
        };
        available?: boolean;
      };
      deltas?: {
        main: {
          /** @format date-time */
          start: string;
          /** @format date-time */
          end: string;
          value?: number;
        };
        steps?: {
          gps?: {
            /** @format date-time */
            start: string;
            /** @format date-time */
            end: string;
            value?: number;
          };
          ips?: {
            /** @format date-time */
            start: string;
            /** @format date-time */
            end: string;
            value?: number;
          };
          context?: {
            /** @format date-time */
            start: string;
            /** @format date-time */
            end: string;
            value?: number;
          };
        };
      };
      validPosition?: {
        value: boolean;
        /** @maxLength 1024 */
        reason?: string;
      };
      isSystemEvent?: boolean;
    };
    frozenInfo?: {
      staticResponse: {
        type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
        priority: number;
        zone?: {
          /** @format uuid */
          _id: string;
          customer?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
          location:
            | {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              }
            | {
                type: "Polygon";
                coordinates: number[][][];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              }
            | {
                type: "MultiPolygon";
                coordinates: number[][][][];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
          /** @maxLength 1024 */
          name: string;
          staticResponders: {
            staticResponder: {
              /** @format uuid */
              _id: string;
              _ref: "StaticResponder";
            };
            priority: number;
          }[];
        };
        responders: {
          info: {
            /** @format uuid */
            _id: string;
            _ref: "Asset" | "StaticResponder";
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            image: string | null;
            position: {
              type: "Point";
              coordinates: number[];
              properties: {
                accuracy?: number;
                heading?: number;
                headingAccuracy?: number;
                speed?: number;
                speedAccuracy?: number;
                altitude?: number;
                altitudeAccuracy?: number;
                address?: {
                  /** @maxLength 1024 */
                  countryCode?: string | null;
                  /** @maxLength 1024 */
                  state?: string | null;
                  /** @maxLength 1024 */
                  city?: string | null;
                  /** @maxLength 1024 */
                  streetName?: string | null;
                  /** @maxLength 1024 */
                  streetNumber?: string | null;
                  floor?: number | null;
                  /** @maxLength 1024 */
                  formattedAddress?: string;
                };
                /** @format date-time */
                dateTime?: string;
                /** @maxLength 1024 */
                provider?: string;
              };
            };
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale: string | null;
            /** @maxLength 1024 */
            contactCode: string | null;
          };
          distanceKm: number | null;
          actions: {
            /** @maxLength 1024 */
            call: string | null;
            /** @maxLength 1024 */
            sms: string | null;
            /** @maxLength 1024 */
            email: string | null;
            notification: boolean;
          };
          priority: number;
          professionalResponder?: {
            available: boolean;
            inProgress: boolean;
          };
        }[];
      }[];
      dynamicResponse: {
        type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
        priority: number;
        zone?: {
          /** @format uuid */
          _id: string;
          customer?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
          location:
            | {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              }
            | {
                type: "Polygon";
                coordinates: number[][][];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              }
            | {
                type: "MultiPolygon";
                coordinates: number[][][][];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
          /** @maxLength 1024 */
          name: string;
          staticResponders: {
            staticResponder: {
              /** @format uuid */
              _id: string;
              _ref: "StaticResponder";
            };
            priority: number;
          }[];
        };
        responders: {
          info: {
            /** @format uuid */
            _id: string;
            _ref: "Asset" | "StaticResponder";
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            image: string | null;
            position: {
              type: "Point";
              coordinates: number[];
              properties: {
                accuracy?: number;
                heading?: number;
                headingAccuracy?: number;
                speed?: number;
                speedAccuracy?: number;
                altitude?: number;
                altitudeAccuracy?: number;
                address?: {
                  /** @maxLength 1024 */
                  countryCode?: string | null;
                  /** @maxLength 1024 */
                  state?: string | null;
                  /** @maxLength 1024 */
                  city?: string | null;
                  /** @maxLength 1024 */
                  streetName?: string | null;
                  /** @maxLength 1024 */
                  streetNumber?: string | null;
                  floor?: number | null;
                  /** @maxLength 1024 */
                  formattedAddress?: string;
                };
                /** @format date-time */
                dateTime?: string;
                /** @maxLength 1024 */
                provider?: string;
              };
            };
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale: string | null;
            /** @maxLength 1024 */
            contactCode: string | null;
          };
          distanceKm: number | null;
          actions: {
            /** @maxLength 1024 */
            call: string | null;
            /** @maxLength 1024 */
            sms: string | null;
            /** @maxLength 1024 */
            email: string | null;
            notification: boolean;
          };
          priority: number;
          professionalResponder?: {
            available: boolean;
            inProgress: boolean;
          };
        }[];
      }[];
      professionalResponders?: {
        /** @format uuid */
        _id: string;
        /** @format date-time */
        createdAt: string;
        /** @format date-time */
        updatedAt: string;
        telluId?: number | null;
        migrationFinalised?: boolean | null;
        /** @format date-time */
        migrationFinalisedDate?: string | null;
        customer: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        };
        /** @default {"type":"parents","extra":[]} */
        share?: {
          type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
          extra?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          }[];
        };
        type: "sequrix";
        active: boolean;
        status: "invited" | "accepted" | "rejected" | "started" | "onLocation" | "finished" | "error" | "canceled";
        alarm: {
          /** @format uuid */
          _id: string;
          _ref: "Alarm";
        };
        asset: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        };
        staticResponder: {
          /** @format uuid */
          _id: string;
          /** @format date-time */
          createdAt: string;
          /** @format date-time */
          updatedAt: string;
          telluId?: number | null;
          migrationFinalised?: boolean | null;
          /** @format date-time */
          migrationFinalisedDate?: string | null;
          customer: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          /** @default {"type":"parents","extra":[]} */
          share?: {
            type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
            extra?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
          };
          /** @maxLength 1024 */
          name: string;
          /** @maxLength 1024 */
          externalId: string | null;
          contact: {
            /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
            email: string | null;
            /** @maxLength 1024 */
            phone: string | null;
            /** @maxLength 1024 */
            sms: string | null;
            /** @maxLength 1024 */
            camera: string | null;
          };
          schedule: {
            entries: {
              daysOfWeek: number[];
              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
              timeStart: string;
              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
              timeEnd: string;
            }[];
          } | null;
          /** @maxLength 1024 */
          migrationKey?: string;
          integrations?: {
            sequrix: {
              enabled: boolean;
              /** @maxLength 1024 */
              objectCode: string;
            };
          };
          statistics?: {
            /**
             * @maxItems 10
             * @minItems 0
             */
            acceptSpeeds: {
              /** @format date-time */
              timestamp: string;
              /**
               * @min 0
               * @exclusiveMin false
               */
              speedMs: number;
            }[];
            /**
             * @maxItems 10
             * @minItems 0
             */
            onLocationSpeeds: {
              /** @format date-time */
              timestamp: string;
              /**
               * @min 0
               * @exclusiveMin false
               */
              speedMs: number;
            }[];
            /**
             * @maxItems 10
             * @minItems 0
             */
            finishSpeeds: {
              /** @format date-time */
              timestamp: string;
              /**
               * @min 0
               * @exclusiveMin false
               */
              speedMs: number;
            }[];
            /**
             * @min 0
             * @exclusiveMin false
             */
            amountRejected: number;
            /**
             * @min 0
             * @exclusiveMin false
             */
            amountAccepted: number;
          };
          _ref: "StaticResponder";
        };
        statistics: {
          /** @format date-time */
          invitedAt: string;
          /** @format date-time */
          acceptedAt: string | null;
          /** @format date-time */
          rejectedAt: string | null;
          /** @format date-time */
          startedAt: string | null;
          /** @format date-time */
          onLocationAt: string | null;
          /** @format date-time */
          finishedAt: string | null;
        };
        meta: {
          /** @maxLength 4096 */
          comment?: string;
          photos?: {
            /** @format date-time */
            timestamp: string;
            /** @maxLength 1024 */
            url: string;
          }[];
          /** @maxLength 1024 */
          overridePhoneNumber?: string;
        };
        supersededBy: {
          /** @format uuid */
          _id: string;
          _ref: "AlarmProfessionalResponder";
        } | null;
      }[];
    };
  };
}

export type AlarmCreateProfessionalResponderDto = object;

export interface AlarmEventCreateBase {
  text: string;
  /** @default "user" */
  type?: "user" | "system" | "action" | "hidden";
  /** @default "ACTION_CHAT" */
  action?: string;
  /** @default {} */
  meta?: Record<string, any>;
}

export interface AlarmHelperDto {
  confirmedHelping: boolean;
}

export interface AlarmHelperPutDto {
  asset?: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  };
  /** @default null */
  confirmedHelping?: boolean | null;
}

export interface AlarmMassAcknowledgeDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  comment?: string;
  alarmIds?: string[];
}

export interface AlarmOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
    /** @maxLength 1024 */
    name: string;
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
  asset: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    customer: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    };
    /** @default {"type":"parents","extra":[]} */
    share?: {
      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
      extra?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      }[];
    };
    user: {
      /** @format uuid */
      _id: string;
      _ref: "User";
    } | null;
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    externalId?: string | null;
    propertiesOwnership?: "override" | "inherit";
    /** @default {} */
    properties?: {
      /** @maxLength 1024 */
      phoneNumber?: string | null;
      /** @maxLength 1024 */
      smsNumber?: string | null;
      /** @maxLength 1024 */
      email?: string | null;
      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
      locale?: string | null;
      /** @maxLength 1024 */
      image?: string | null;
      rSim?: {
        /** @maxLength 1024 */
        euicc: string;
        primary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        secondary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        enabled: boolean;
      } | null;
    };
    /** @default null */
    position?: {
      type: "Point";
      coordinates: number[];
      properties: {
        accuracy?: number;
        heading?: number;
        headingAccuracy?: number;
        speed?: number;
        speedAccuracy?: number;
        altitude?: number;
        altitudeAccuracy?: number;
        address?: {
          /** @maxLength 1024 */
          countryCode?: string | null;
          /** @maxLength 1024 */
          state?: string | null;
          /** @maxLength 1024 */
          city?: string | null;
          /** @maxLength 1024 */
          streetName?: string | null;
          /** @maxLength 1024 */
          streetNumber?: string | null;
          floor?: number | null;
          /** @maxLength 1024 */
          formattedAddress?: string;
        };
        /** @format date-time */
        dateTime?: string;
        /** @maxLength 1024 */
        provider?: string;
      };
    };
    available: boolean;
    /** @default true */
    allowContactOnOwnAlarms?: boolean;
    app?: {
      mode: "off" | "on" | "risk";
    } | null;
    insideZones: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    }[];
    assetGroups: {
      /** @format uuid */
      _id: string;
      _ref: "AssetGroup";
    }[];
    /** @default [] */
    staticResponders?: {
      staticResponder: {
        /** @format uuid */
        _id: string;
        _ref: "StaticResponder";
      };
      priority: number;
    }[];
    /** @format date-time */
    lastObservationAt?: string;
    /** @default false */
    invisibleResponder?: boolean;
    /** @maxLength 4096 */
    description?: string;
    type?: "default" | "kiosk";
  };
  ack: {
    needed: boolean;
    value: boolean;
    ackedBy: {
      /** @format uuid */
      _id: string;
      _ref: "User";
      /** @maxLength 1024 */
      name: string;
    } | null;
    /** @maxLength 1024 */
    comment: string | null;
    /** @format date-time */
    ackedAt: string | null;
  };
  position: {
    type: "Point";
    coordinates: number[];
    properties: {
      accuracy?: number;
      heading?: number;
      headingAccuracy?: number;
      speed?: number;
      speedAccuracy?: number;
      altitude?: number;
      altitudeAccuracy?: number;
      address?: {
        /** @maxLength 1024 */
        countryCode?: string | null;
        /** @maxLength 1024 */
        state?: string | null;
        /** @maxLength 1024 */
        city?: string | null;
        /** @maxLength 1024 */
        streetName?: string | null;
        /** @maxLength 1024 */
        streetNumber?: string | null;
        floor?: number | null;
        /** @maxLength 1024 */
        formattedAddress?: string;
      };
      /** @format date-time */
      dateTime?: string;
      /** @maxLength 1024 */
      provider?: string;
    };
  };
  severity: "red" | "orange" | "green";
  /** @maxLength 4096 */
  procedure: string | null;
  meta?: {
    observationTrigger?: {
      /** @format uuid */
      _id: string;
      /** @format date-time */
      createdAt: string;
      /** @format date-time */
      updatedAt: string;
      telluId?: number | null;
      migrationFinalised?: boolean | null;
      /** @format date-time */
      migrationFinalisedDate?: string | null;
      customer: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      };
      /** @default {"type":"parents","extra":[]} */
      share?: {
        type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
        extra?: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        }[];
      };
      /** @maxLength 1024 */
      name: string;
      eventCodes: string[];
      /** @maxLength 1024 */
      description: string;
      status: "active" | "inactive";
      actions: (
        | {
            type: "alarm";
            config: {
              severity: "red" | "orange" | "green";
              type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
              /** @maxLength 4096 */
              procedure: string | null;
              /** @maxLength 1024 */
              name: string;
            };
          }
        | {
            type: "sms";
            config: {
              toRaw: string[];
              toContactList: {
                /** @format uuid */
                _id: string;
                _ref: "ContactList";
              }[];
              toTriggerUser: boolean;
              /** @maxLength 1024 */
              message: string;
            };
          }
        | {
            type: "email";
            config: {
              toRaw: string[];
              toContactList: {
                /** @format uuid */
                _id: string;
                _ref: "ContactList";
              }[];
              toTriggerUser: boolean;
              /** @maxLength 1024 */
              message: string;
              /** @maxLength 1024 */
              subject: string;
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale?: string;
            };
          }
      )[];
      filter:
        | {
            type: "operator/and";
            operands: (
              | {
                  type: "operator/or";
                  operands: (
                    | {
                        type: "operator/not";
                        operand:
                          | {
                              type: "constraint/asset";
                              assets: {
                                /** @format uuid */
                                _id: string;
                                _ref: "Asset";
                              }[];
                            }
                          | {
                              type: "constraint/assetGroup";
                              groups: {
                                /** @format uuid */
                                _id: string;
                                _ref: "AssetGroup";
                              }[];
                            }
                          | {
                              type: "constraint/zone";
                              zone: {
                                /** @format uuid */
                                _id: string;
                                _ref: "Zone";
                              };
                            }
                          | {
                              type: "constraint/schedule";
                              schedule: {
                                entries: {
                                  daysOfWeek: number[];
                                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                  timeStart: string;
                                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                  timeEnd: string;
                                }[];
                              };
                            };
                      }
                    | {
                        type: "constraint/asset";
                        assets: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Asset";
                        }[];
                      }
                    | {
                        type: "constraint/assetGroup";
                        groups: {
                          /** @format uuid */
                          _id: string;
                          _ref: "AssetGroup";
                        }[];
                      }
                    | {
                        type: "constraint/zone";
                        zone: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Zone";
                        };
                      }
                    | {
                        type: "constraint/schedule";
                        schedule: {
                          entries: {
                            daysOfWeek: number[];
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeStart: string;
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeEnd: string;
                          }[];
                        };
                      }
                  )[];
                }
              | {
                  type: "constraint/asset";
                  assets: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  }[];
                }
              | {
                  type: "constraint/assetGroup";
                  groups: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[];
                }
              | {
                  type: "constraint/zone";
                  zone: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  };
                }
              | {
                  type: "constraint/schedule";
                  schedule: {
                    entries: {
                      daysOfWeek: number[];
                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                      timeStart: string;
                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                      timeEnd: string;
                    }[];
                  };
                }
            )[];
          }
        | {
            type: "constraint/asset";
            assets: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
          }
        | {
            type: "constraint/assetGroup";
            groups: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
          }
        | {
            type: "constraint/zone";
            zone: {
              /** @format uuid */
              _id: string;
              _ref: "Zone";
            };
          }
        | {
            type: "constraint/schedule";
            schedule: {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            };
          };
    };
    observation?: {
      /** @format uuid */
      _id: string;
      /** @maxLength 1024 */
      event: string;
      asset: {
        /** @format uuid */
        _id: string;
        /** @format date-time */
        createdAt: string;
        /** @format date-time */
        updatedAt: string;
        telluId?: number | null;
        migrationFinalised?: boolean | null;
        /** @format date-time */
        migrationFinalisedDate?: string | null;
        customer: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        };
        /** @default {"type":"parents","extra":[]} */
        share?: {
          type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
          extra?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          }[];
        };
        user: {
          /** @format uuid */
          _id: string;
          _ref: "User";
        } | null;
        /** @maxLength 1024 */
        name: string;
        /** @maxLength 1024 */
        externalId?: string | null;
        propertiesOwnership?: "override" | "inherit";
        /** @default {} */
        properties?: {
          /** @maxLength 1024 */
          phoneNumber?: string | null;
          /** @maxLength 1024 */
          smsNumber?: string | null;
          /** @maxLength 1024 */
          email?: string | null;
          /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
          locale?: string | null;
          /** @maxLength 1024 */
          image?: string | null;
          rSim?: {
            /** @maxLength 1024 */
            euicc: string;
            primary: {
              /** @maxLength 1024 */
              msisdn: string;
              /** @maxLength 1024 */
              iccid: string;
            };
            secondary: {
              /** @maxLength 1024 */
              msisdn: string;
              /** @maxLength 1024 */
              iccid: string;
            };
            enabled: boolean;
          } | null;
        };
        /** @default null */
        position?: {
          type: "Point";
          coordinates: number[];
          properties: {
            accuracy?: number;
            heading?: number;
            headingAccuracy?: number;
            speed?: number;
            speedAccuracy?: number;
            altitude?: number;
            altitudeAccuracy?: number;
            address?: {
              /** @maxLength 1024 */
              countryCode?: string | null;
              /** @maxLength 1024 */
              state?: string | null;
              /** @maxLength 1024 */
              city?: string | null;
              /** @maxLength 1024 */
              streetName?: string | null;
              /** @maxLength 1024 */
              streetNumber?: string | null;
              floor?: number | null;
              /** @maxLength 1024 */
              formattedAddress?: string;
            };
            /** @format date-time */
            dateTime?: string;
            /** @maxLength 1024 */
            provider?: string;
          };
        };
        available: boolean;
        /** @default true */
        allowContactOnOwnAlarms?: boolean;
        app?: {
          mode: "off" | "on" | "risk";
        } | null;
        insideZones: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        }[];
        assetGroups: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        /** @default [] */
        staticResponders?: {
          staticResponder: {
            /** @format uuid */
            _id: string;
            _ref: "StaticResponder";
          };
          priority: number;
        }[];
        /** @format date-time */
        lastObservationAt?: string;
        /** @default false */
        invisibleResponder?: boolean;
        /** @maxLength 4096 */
        description?: string;
        type?: "default" | "kiosk";
        _ref: "Asset";
      };
      customer: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      };
      device: {
        /** @format uuid */
        _id: string;
        _ref: "Device";
      };
      user: {
        /** @format uuid */
        _id: string;
        _ref: "User";
      };
      position?: {
        type: "Point";
        coordinates: number[];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      };
      beacons?:
        | {
            /** @maxLength 1024 */
            code: string;
            rssi: number;
            battery?: number;
            /** @format date-time */
            dateTime: string;
            beacon: {
              /** @format uuid */
              _id: string;
              _ref: "Beacon";
            } | null;
            calc: number | null;
            valid: boolean;
          }[]
        | {
            /** @maxLength 1024 */
            code: string;
            rssi: number;
            battery?: number;
            /** @format date-time */
            dateTime: string;
          }[];
      meta?: {
        asset?: {
          /** @format uuid */
          _id: string;
          /** @format date-time */
          createdAt: string;
          /** @format date-time */
          updatedAt: string;
          telluId?: number | null;
          migrationFinalised?: boolean | null;
          /** @format date-time */
          migrationFinalisedDate?: string | null;
          customer: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          /** @default {"type":"parents","extra":[]} */
          share?: {
            type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
            extra?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
          };
          user: {
            /** @format uuid */
            _id: string;
            _ref: "User";
          } | null;
          /** @maxLength 1024 */
          name: string;
          /** @maxLength 1024 */
          externalId?: string | null;
          propertiesOwnership?: "override" | "inherit";
          /** @default {} */
          properties?: {
            /** @maxLength 1024 */
            phoneNumber?: string | null;
            /** @maxLength 1024 */
            smsNumber?: string | null;
            /** @maxLength 1024 */
            email?: string | null;
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale?: string | null;
            /** @maxLength 1024 */
            image?: string | null;
            rSim?: {
              /** @maxLength 1024 */
              euicc: string;
              primary: {
                /** @maxLength 1024 */
                msisdn: string;
                /** @maxLength 1024 */
                iccid: string;
              };
              secondary: {
                /** @maxLength 1024 */
                msisdn: string;
                /** @maxLength 1024 */
                iccid: string;
              };
              enabled: boolean;
            } | null;
          };
          /** @default null */
          position?: {
            type: "Point";
            coordinates: number[];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          };
          available: boolean;
          /** @default true */
          allowContactOnOwnAlarms?: boolean;
          app?: {
            mode: "off" | "on" | "risk";
          } | null;
          insideZones: {
            /** @format uuid */
            _id: string;
            _ref: "Zone";
          }[];
          assetGroups: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
          /** @default [] */
          staticResponders?: {
            staticResponder: {
              /** @format uuid */
              _id: string;
              _ref: "StaticResponder";
            };
            priority: number;
          }[];
          /** @format date-time */
          lastObservationAt?: string;
          /** @default false */
          invisibleResponder?: boolean;
          /** @maxLength 4096 */
          description?: string;
          type?: "default" | "kiosk";
        };
        battery?: number;
        rssi?: number;
        snr?: number;
        /** @format date-time */
        dateTime?: string;
        /** @format date-time */
        deviceDateTime?: string;
        /** @format date-time */
        gatewayDateTime?: string;
        insideGpsZones?: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        }[];
        insideIpsZones?: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        }[];
        zone?: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        };
        callRequest?: boolean;
        forceLoraCustomer?: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        };
        knnResult?: {
          distance: number;
          debugInfo?: any;
        };
        available?: boolean;
      };
      deltas?: {
        main: {
          /** @format date-time */
          start: string;
          /** @format date-time */
          end: string;
          value?: number;
        };
        steps?: {
          gps?: {
            /** @format date-time */
            start: string;
            /** @format date-time */
            end: string;
            value?: number;
          };
          ips?: {
            /** @format date-time */
            start: string;
            /** @format date-time */
            end: string;
            value?: number;
          };
          context?: {
            /** @format date-time */
            start: string;
            /** @format date-time */
            end: string;
            value?: number;
          };
        };
      };
      validPosition?: {
        value: boolean;
        /** @maxLength 1024 */
        reason?: string;
      };
      isSystemEvent?: boolean;
    };
    frozenInfo?: {
      staticResponse: {
        type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
        priority: number;
        zone?: {
          /** @format uuid */
          _id: string;
          customer?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
          location:
            | {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              }
            | {
                type: "Polygon";
                coordinates: number[][][];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              }
            | {
                type: "MultiPolygon";
                coordinates: number[][][][];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
          /** @maxLength 1024 */
          name: string;
          staticResponders: {
            staticResponder: {
              /** @format uuid */
              _id: string;
              _ref: "StaticResponder";
            };
            priority: number;
          }[];
        };
        responders: {
          info: {
            /** @format uuid */
            _id: string;
            _ref: "Asset" | "StaticResponder";
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            image: string | null;
            position: {
              type: "Point";
              coordinates: number[];
              properties: {
                accuracy?: number;
                heading?: number;
                headingAccuracy?: number;
                speed?: number;
                speedAccuracy?: number;
                altitude?: number;
                altitudeAccuracy?: number;
                address?: {
                  /** @maxLength 1024 */
                  countryCode?: string | null;
                  /** @maxLength 1024 */
                  state?: string | null;
                  /** @maxLength 1024 */
                  city?: string | null;
                  /** @maxLength 1024 */
                  streetName?: string | null;
                  /** @maxLength 1024 */
                  streetNumber?: string | null;
                  floor?: number | null;
                  /** @maxLength 1024 */
                  formattedAddress?: string;
                };
                /** @format date-time */
                dateTime?: string;
                /** @maxLength 1024 */
                provider?: string;
              };
            };
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale: string | null;
            /** @maxLength 1024 */
            contactCode: string | null;
          };
          distanceKm: number | null;
          actions: {
            /** @maxLength 1024 */
            call: string | null;
            /** @maxLength 1024 */
            sms: string | null;
            /** @maxLength 1024 */
            email: string | null;
            notification: boolean;
          };
          priority: number;
          professionalResponder?: {
            available: boolean;
            inProgress: boolean;
          };
        }[];
      }[];
      dynamicResponse: {
        type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
        priority: number;
        zone?: {
          /** @format uuid */
          _id: string;
          customer?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
          location:
            | {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              }
            | {
                type: "Polygon";
                coordinates: number[][][];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              }
            | {
                type: "MultiPolygon";
                coordinates: number[][][][];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
          /** @maxLength 1024 */
          name: string;
          staticResponders: {
            staticResponder: {
              /** @format uuid */
              _id: string;
              _ref: "StaticResponder";
            };
            priority: number;
          }[];
        };
        responders: {
          info: {
            /** @format uuid */
            _id: string;
            _ref: "Asset" | "StaticResponder";
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            image: string | null;
            position: {
              type: "Point";
              coordinates: number[];
              properties: {
                accuracy?: number;
                heading?: number;
                headingAccuracy?: number;
                speed?: number;
                speedAccuracy?: number;
                altitude?: number;
                altitudeAccuracy?: number;
                address?: {
                  /** @maxLength 1024 */
                  countryCode?: string | null;
                  /** @maxLength 1024 */
                  state?: string | null;
                  /** @maxLength 1024 */
                  city?: string | null;
                  /** @maxLength 1024 */
                  streetName?: string | null;
                  /** @maxLength 1024 */
                  streetNumber?: string | null;
                  floor?: number | null;
                  /** @maxLength 1024 */
                  formattedAddress?: string;
                };
                /** @format date-time */
                dateTime?: string;
                /** @maxLength 1024 */
                provider?: string;
              };
            };
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale: string | null;
            /** @maxLength 1024 */
            contactCode: string | null;
          };
          distanceKm: number | null;
          actions: {
            /** @maxLength 1024 */
            call: string | null;
            /** @maxLength 1024 */
            sms: string | null;
            /** @maxLength 1024 */
            email: string | null;
            notification: boolean;
          };
          priority: number;
          professionalResponder?: {
            available: boolean;
            inProgress: boolean;
          };
        }[];
      }[];
      professionalResponders?: {
        /** @format uuid */
        _id: string;
        /** @format date-time */
        createdAt: string;
        /** @format date-time */
        updatedAt: string;
        telluId?: number | null;
        migrationFinalised?: boolean | null;
        /** @format date-time */
        migrationFinalisedDate?: string | null;
        customer: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        };
        /** @default {"type":"parents","extra":[]} */
        share?: {
          type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
          extra?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          }[];
        };
        type: "sequrix";
        active: boolean;
        status: "invited" | "accepted" | "rejected" | "started" | "onLocation" | "finished" | "error" | "canceled";
        alarm: {
          /** @format uuid */
          _id: string;
          _ref: "Alarm";
        };
        asset: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        };
        staticResponder: {
          /** @format uuid */
          _id: string;
          /** @format date-time */
          createdAt: string;
          /** @format date-time */
          updatedAt: string;
          telluId?: number | null;
          migrationFinalised?: boolean | null;
          /** @format date-time */
          migrationFinalisedDate?: string | null;
          customer: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          /** @default {"type":"parents","extra":[]} */
          share?: {
            type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
            extra?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
          };
          /** @maxLength 1024 */
          name: string;
          /** @maxLength 1024 */
          externalId: string | null;
          contact: {
            /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
            email: string | null;
            /** @maxLength 1024 */
            phone: string | null;
            /** @maxLength 1024 */
            sms: string | null;
            /** @maxLength 1024 */
            camera: string | null;
          };
          schedule: {
            entries: {
              daysOfWeek: number[];
              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
              timeStart: string;
              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
              timeEnd: string;
            }[];
          } | null;
          /** @maxLength 1024 */
          migrationKey?: string;
          integrations?: {
            sequrix: {
              enabled: boolean;
              /** @maxLength 1024 */
              objectCode: string;
            };
          };
          statistics?: {
            /**
             * @maxItems 10
             * @minItems 0
             */
            acceptSpeeds: {
              /** @format date-time */
              timestamp: string;
              /**
               * @min 0
               * @exclusiveMin false
               */
              speedMs: number;
            }[];
            /**
             * @maxItems 10
             * @minItems 0
             */
            onLocationSpeeds: {
              /** @format date-time */
              timestamp: string;
              /**
               * @min 0
               * @exclusiveMin false
               */
              speedMs: number;
            }[];
            /**
             * @maxItems 10
             * @minItems 0
             */
            finishSpeeds: {
              /** @format date-time */
              timestamp: string;
              /**
               * @min 0
               * @exclusiveMin false
               */
              speedMs: number;
            }[];
            /**
             * @min 0
             * @exclusiveMin false
             */
            amountRejected: number;
            /**
             * @min 0
             * @exclusiveMin false
             */
            amountAccepted: number;
          };
          _ref: "StaticResponder";
        };
        statistics: {
          /** @format date-time */
          invitedAt: string;
          /** @format date-time */
          acceptedAt: string | null;
          /** @format date-time */
          rejectedAt: string | null;
          /** @format date-time */
          startedAt: string | null;
          /** @format date-time */
          onLocationAt: string | null;
          /** @format date-time */
          finishedAt: string | null;
        };
        meta: {
          /** @maxLength 4096 */
          comment?: string;
          photos?: {
            /** @format date-time */
            timestamp: string;
            /** @maxLength 1024 */
            url: string;
          }[];
          /** @maxLength 1024 */
          overridePhoneNumber?: string;
        };
        supersededBy: {
          /** @format uuid */
          _id: string;
          _ref: "AlarmProfessionalResponder";
        } | null;
      }[];
    };
  };
  alarmCenter: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
    /** @maxLength 1024 */
    name: string;
  } | null;
  /** @format date-time */
  lastMergedAt?: string;
  /** @format date-time */
  lastPublishedToStreamAt?: string;
  mergeHistory?: {
    /** @format uuid */
    _id: string;
    /** @maxLength 1024 */
    name: string;
    /** @format date-time */
    createdAt: string;
    position: {
      type: "Point";
      coordinates: number[];
      properties: {
        accuracy?: number;
        heading?: number;
        headingAccuracy?: number;
        speed?: number;
        speedAccuracy?: number;
        altitude?: number;
        altitudeAccuracy?: number;
        address?: {
          /** @maxLength 1024 */
          countryCode?: string | null;
          /** @maxLength 1024 */
          state?: string | null;
          /** @maxLength 1024 */
          city?: string | null;
          /** @maxLength 1024 */
          streetName?: string | null;
          /** @maxLength 1024 */
          streetNumber?: string | null;
          floor?: number | null;
          /** @maxLength 1024 */
          formattedAddress?: string;
        };
        /** @format date-time */
        dateTime?: string;
        /** @maxLength 1024 */
        provider?: string;
      };
    };
    meta?: {
      observationTrigger?: {
        /** @format uuid */
        _id: string;
        /** @format date-time */
        createdAt: string;
        /** @format date-time */
        updatedAt: string;
        telluId?: number | null;
        migrationFinalised?: boolean | null;
        /** @format date-time */
        migrationFinalisedDate?: string | null;
        customer: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        };
        /** @default {"type":"parents","extra":[]} */
        share?: {
          type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
          extra?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          }[];
        };
        /** @maxLength 1024 */
        name: string;
        eventCodes: string[];
        /** @maxLength 1024 */
        description: string;
        status: "active" | "inactive";
        actions: (
          | {
              type: "alarm";
              config: {
                severity: "red" | "orange" | "green";
                type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
                /** @maxLength 4096 */
                procedure: string | null;
                /** @maxLength 1024 */
                name: string;
              };
            }
          | {
              type: "sms";
              config: {
                toRaw: string[];
                toContactList: {
                  /** @format uuid */
                  _id: string;
                  _ref: "ContactList";
                }[];
                toTriggerUser: boolean;
                /** @maxLength 1024 */
                message: string;
              };
            }
          | {
              type: "email";
              config: {
                toRaw: string[];
                toContactList: {
                  /** @format uuid */
                  _id: string;
                  _ref: "ContactList";
                }[];
                toTriggerUser: boolean;
                /** @maxLength 1024 */
                message: string;
                /** @maxLength 1024 */
                subject: string;
                /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                locale?: string;
              };
            }
        )[];
        filter:
          | {
              type: "constraint/asset";
              assets: {
                /** @format uuid */
                _id: string;
                _ref: "Asset";
              }[];
            }
          | {
              type: "constraint/assetGroup";
              groups: {
                /** @format uuid */
                _id: string;
                _ref: "AssetGroup";
              }[];
            }
          | {
              type: "constraint/zone";
              zone: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              };
            }
          | {
              type: "constraint/schedule";
              schedule: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              };
            };
      };
      observation?: {
        /** @format uuid */
        _id: string;
        /** @maxLength 1024 */
        event: string;
        asset: {
          /** @format uuid */
          _id: string;
          /** @format date-time */
          createdAt: string;
          /** @format date-time */
          updatedAt: string;
          telluId?: number | null;
          migrationFinalised?: boolean | null;
          /** @format date-time */
          migrationFinalisedDate?: string | null;
          customer: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          /** @default {"type":"parents","extra":[]} */
          share?: {
            type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
            extra?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
          };
          user: {
            /** @format uuid */
            _id: string;
            _ref: "User";
          } | null;
          /** @maxLength 1024 */
          name: string;
          /** @maxLength 1024 */
          externalId?: string | null;
          propertiesOwnership?: "override" | "inherit";
          /** @default {} */
          properties?: {
            /** @maxLength 1024 */
            phoneNumber?: string | null;
            /** @maxLength 1024 */
            smsNumber?: string | null;
            /** @maxLength 1024 */
            email?: string | null;
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale?: string | null;
            /** @maxLength 1024 */
            image?: string | null;
            rSim?: {
              /** @maxLength 1024 */
              euicc: string;
              primary: {
                /** @maxLength 1024 */
                msisdn: string;
                /** @maxLength 1024 */
                iccid: string;
              };
              secondary: {
                /** @maxLength 1024 */
                msisdn: string;
                /** @maxLength 1024 */
                iccid: string;
              };
              enabled: boolean;
            } | null;
          };
          /** @default null */
          position?: {
            type: "Point";
            coordinates: number[];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          };
          available: boolean;
          /** @default true */
          allowContactOnOwnAlarms?: boolean;
          app?: {
            mode: "off" | "on" | "risk";
          } | null;
          insideZones: {
            /** @format uuid */
            _id: string;
            _ref: "Zone";
          }[];
          assetGroups: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
          /** @default [] */
          staticResponders?: {
            staticResponder: {
              /** @format uuid */
              _id: string;
              _ref: "StaticResponder";
            };
            priority: number;
          }[];
          /** @format date-time */
          lastObservationAt?: string;
          /** @default false */
          invisibleResponder?: boolean;
          /** @maxLength 4096 */
          description?: string;
          type?: "default" | "kiosk";
          _ref: "Asset";
        };
        customer: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        };
        device: {
          /** @format uuid */
          _id: string;
          _ref: "Device";
        };
        user: {
          /** @format uuid */
          _id: string;
          _ref: "User";
        };
        position?: {
          type: "Point";
          coordinates: number[];
          properties: {
            accuracy?: number;
            heading?: number;
            headingAccuracy?: number;
            speed?: number;
            speedAccuracy?: number;
            altitude?: number;
            altitudeAccuracy?: number;
            address?: {
              /** @maxLength 1024 */
              countryCode?: string | null;
              /** @maxLength 1024 */
              state?: string | null;
              /** @maxLength 1024 */
              city?: string | null;
              /** @maxLength 1024 */
              streetName?: string | null;
              /** @maxLength 1024 */
              streetNumber?: string | null;
              floor?: number | null;
              /** @maxLength 1024 */
              formattedAddress?: string;
            };
            /** @format date-time */
            dateTime?: string;
            /** @maxLength 1024 */
            provider?: string;
          };
        };
        beacons?:
          | {
              /** @maxLength 1024 */
              code: string;
              rssi: number;
              battery?: number;
              /** @format date-time */
              dateTime: string;
              beacon: {
                /** @format uuid */
                _id: string;
                _ref: "Beacon";
              } | null;
              calc: number | null;
              valid: boolean;
            }[]
          | {
              /** @maxLength 1024 */
              code: string;
              rssi: number;
              battery?: number;
              /** @format date-time */
              dateTime: string;
            }[];
        meta?: {
          asset?: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            telluId?: number | null;
            migrationFinalised?: boolean | null;
            /** @format date-time */
            migrationFinalisedDate?: string | null;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            user: {
              /** @format uuid */
              _id: string;
              _ref: "User";
            } | null;
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            externalId?: string | null;
            propertiesOwnership?: "override" | "inherit";
            /** @default {} */
            properties?: {
              /** @maxLength 1024 */
              phoneNumber?: string | null;
              /** @maxLength 1024 */
              smsNumber?: string | null;
              /** @maxLength 1024 */
              email?: string | null;
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale?: string | null;
              /** @maxLength 1024 */
              image?: string | null;
              rSim?: {
                /** @maxLength 1024 */
                euicc: string;
                primary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                secondary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                enabled: boolean;
              } | null;
            };
            /** @default null */
            position?: {
              type: "Point";
              coordinates: number[];
              properties: {
                accuracy?: number;
                heading?: number;
                headingAccuracy?: number;
                speed?: number;
                speedAccuracy?: number;
                altitude?: number;
                altitudeAccuracy?: number;
                address?: {
                  /** @maxLength 1024 */
                  countryCode?: string | null;
                  /** @maxLength 1024 */
                  state?: string | null;
                  /** @maxLength 1024 */
                  city?: string | null;
                  /** @maxLength 1024 */
                  streetName?: string | null;
                  /** @maxLength 1024 */
                  streetNumber?: string | null;
                  floor?: number | null;
                  /** @maxLength 1024 */
                  formattedAddress?: string;
                };
                /** @format date-time */
                dateTime?: string;
                /** @maxLength 1024 */
                provider?: string;
              };
            };
            available: boolean;
            /** @default true */
            allowContactOnOwnAlarms?: boolean;
            app?: {
              mode: "off" | "on" | "risk";
            } | null;
            insideZones: {
              /** @format uuid */
              _id: string;
              _ref: "Zone";
            }[];
            assetGroups: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
            /** @default [] */
            staticResponders?: {
              staticResponder: {
                /** @format uuid */
                _id: string;
                _ref: "StaticResponder";
              };
              priority: number;
            }[];
            /** @format date-time */
            lastObservationAt?: string;
            /** @default false */
            invisibleResponder?: boolean;
            /** @maxLength 4096 */
            description?: string;
            type?: "default" | "kiosk";
          };
          battery?: number;
          rssi?: number;
          snr?: number;
          /** @format date-time */
          dateTime?: string;
          /** @format date-time */
          deviceDateTime?: string;
          /** @format date-time */
          gatewayDateTime?: string;
          insideGpsZones?: {
            /** @format uuid */
            _id: string;
            _ref: "Zone";
          }[];
          insideIpsZones?: {
            /** @format uuid */
            _id: string;
            _ref: "Zone";
          }[];
          zone?: {
            /** @format uuid */
            _id: string;
            _ref: "Zone";
          };
          callRequest?: boolean;
          forceLoraCustomer?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          knnResult?: {
            distance: number;
            debugInfo?: any;
          };
          available?: boolean;
        };
        deltas?: {
          main: {
            /** @format date-time */
            start: string;
            /** @format date-time */
            end: string;
            value?: number;
          };
          steps?: {
            gps?: {
              /** @format date-time */
              start: string;
              /** @format date-time */
              end: string;
              value?: number;
            };
            ips?: {
              /** @format date-time */
              start: string;
              /** @format date-time */
              end: string;
              value?: number;
            };
            context?: {
              /** @format date-time */
              start: string;
              /** @format date-time */
              end: string;
              value?: number;
            };
          };
        };
        validPosition?: {
          value: boolean;
          /** @maxLength 1024 */
          reason?: string;
        };
        isSystemEvent?: boolean;
      };
      frozenInfo?: {
        staticResponse: {
          type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
          priority: number;
          zone?: {
            /** @format uuid */
            _id: string;
            customer?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
            location:
              | {
                  type: "Point";
                  coordinates: number[];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "Polygon";
                  coordinates: number[][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "MultiPolygon";
                  coordinates: number[][][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                };
            /** @maxLength 1024 */
            name: string;
            staticResponders: {
              staticResponder: {
                /** @format uuid */
                _id: string;
                _ref: "StaticResponder";
              };
              priority: number;
            }[];
          };
          responders: {
            info: {
              /** @format uuid */
              _id: string;
              _ref: "Asset" | "StaticResponder";
              /** @maxLength 1024 */
              name: string;
              /** @maxLength 1024 */
              image: string | null;
              position: {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale: string | null;
              /** @maxLength 1024 */
              contactCode: string | null;
            };
            distanceKm: number | null;
            actions: {
              /** @maxLength 1024 */
              call: string | null;
              /** @maxLength 1024 */
              sms: string | null;
              /** @maxLength 1024 */
              email: string | null;
              notification: boolean;
            };
            priority: number;
            professionalResponder?: {
              available: boolean;
              inProgress: boolean;
            };
          }[];
        }[];
        dynamicResponse: {
          type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
          priority: number;
          zone?: {
            /** @format uuid */
            _id: string;
            customer?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
            location:
              | {
                  type: "Point";
                  coordinates: number[];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "Polygon";
                  coordinates: number[][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "MultiPolygon";
                  coordinates: number[][][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                };
            /** @maxLength 1024 */
            name: string;
            staticResponders: {
              staticResponder: {
                /** @format uuid */
                _id: string;
                _ref: "StaticResponder";
              };
              priority: number;
            }[];
          };
          responders: {
            info: {
              /** @format uuid */
              _id: string;
              _ref: "Asset" | "StaticResponder";
              /** @maxLength 1024 */
              name: string;
              /** @maxLength 1024 */
              image: string | null;
              position: {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale: string | null;
              /** @maxLength 1024 */
              contactCode: string | null;
            };
            distanceKm: number | null;
            actions: {
              /** @maxLength 1024 */
              call: string | null;
              /** @maxLength 1024 */
              sms: string | null;
              /** @maxLength 1024 */
              email: string | null;
              notification: boolean;
            };
            priority: number;
            professionalResponder?: {
              available: boolean;
              inProgress: boolean;
            };
          }[];
        }[];
        professionalResponders?: {
          /** @format uuid */
          _id: string;
          /** @format date-time */
          createdAt: string;
          /** @format date-time */
          updatedAt: string;
          telluId?: number | null;
          migrationFinalised?: boolean | null;
          /** @format date-time */
          migrationFinalisedDate?: string | null;
          customer: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          /** @default {"type":"parents","extra":[]} */
          share?: {
            type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
            extra?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
          };
          type: "sequrix";
          active: boolean;
          status: "invited" | "accepted" | "rejected" | "started" | "onLocation" | "finished" | "error" | "canceled";
          alarm: {
            /** @format uuid */
            _id: string;
            _ref: "Alarm";
          };
          asset: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          };
          staticResponder: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            telluId?: number | null;
            migrationFinalised?: boolean | null;
            /** @format date-time */
            migrationFinalisedDate?: string | null;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            externalId: string | null;
            contact: {
              /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
              email: string | null;
              /** @maxLength 1024 */
              phone: string | null;
              /** @maxLength 1024 */
              sms: string | null;
              /** @maxLength 1024 */
              camera: string | null;
            };
            schedule: {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            } | null;
            /** @maxLength 1024 */
            migrationKey?: string;
            integrations?: {
              sequrix: {
                enabled: boolean;
                /** @maxLength 1024 */
                objectCode: string;
              };
            };
            statistics?: {
              /**
               * @maxItems 10
               * @minItems 0
               */
              acceptSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              onLocationSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              finishSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountRejected: number;
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountAccepted: number;
            };
            _ref: "StaticResponder";
          };
          statistics: {
            /** @format date-time */
            invitedAt: string;
            /** @format date-time */
            acceptedAt: string | null;
            /** @format date-time */
            rejectedAt: string | null;
            /** @format date-time */
            startedAt: string | null;
            /** @format date-time */
            onLocationAt: string | null;
            /** @format date-time */
            finishedAt: string | null;
          };
          meta: {
            /** @maxLength 4096 */
            comment?: string;
            photos?: {
              /** @format date-time */
              timestamp: string;
              /** @maxLength 1024 */
              url: string;
            }[];
            /** @maxLength 1024 */
            overridePhoneNumber?: string;
          };
          supersededBy: {
            /** @format uuid */
            _id: string;
            _ref: "AlarmProfessionalResponder";
          } | null;
        }[];
      };
    };
  }[];
}

export interface AlarmPatchProfessionalResponderDto {
  status: "canceled";
}

export interface AlarmProfessionalRespondersOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  type: "sequrix";
  active: boolean;
  status: "invited" | "accepted" | "rejected" | "started" | "onLocation" | "finished" | "error" | "canceled";
  alarm: {
    /** @format uuid */
    _id: string;
    _ref: "Alarm";
  };
  asset: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  };
  staticResponder: {
    /** @format uuid */
    _id: string;
    _ref: "StaticResponder";
  };
  statistics: {
    /** @format date-time */
    invitedAt: string;
    /** @format date-time */
    acceptedAt: string | null;
    /** @format date-time */
    rejectedAt: string | null;
    /** @format date-time */
    startedAt: string | null;
    /** @format date-time */
    onLocationAt: string | null;
    /** @format date-time */
    finishedAt: string | null;
  };
  meta: {
    /** @maxLength 4096 */
    comment?: string;
    photos?: {
      /** @format date-time */
      timestamp: string;
      /** @maxLength 1024 */
      url: string;
    }[];
    /** @maxLength 1024 */
    overridePhoneNumber?: string;
  };
  supersededBy: {
    /** @format uuid */
    _id: string;
    _ref: "AlarmProfessionalResponder";
  } | null;
}

export interface AlarmResponseResponseDto {
  result: {
    type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
    priority: number;
    zone?: {
      /** @format uuid */
      _id: string;
      customer?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      };
      type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
      location:
        | {
            type: "Point";
            coordinates: number[];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          }
        | {
            type: "Polygon";
            coordinates: number[][][];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          }
        | {
            type: "MultiPolygon";
            coordinates: number[][][][];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          };
      /** @maxLength 1024 */
      name: string;
      staticResponders: {
        staticResponder: {
          /** @format uuid */
          _id: string;
          _ref: "StaticResponder";
        };
        priority: number;
      }[];
    };
    responders: {
      info: {
        /** @format uuid */
        _id: string;
        _ref: "Asset" | "StaticResponder";
        /** @maxLength 1024 */
        name: string;
        /** @maxLength 1024 */
        image: string | null;
        position: {
          type: "Point";
          coordinates: number[];
          properties: {
            accuracy?: number;
            heading?: number;
            headingAccuracy?: number;
            speed?: number;
            speedAccuracy?: number;
            altitude?: number;
            altitudeAccuracy?: number;
            address?: {
              /** @maxLength 1024 */
              countryCode?: string | null;
              /** @maxLength 1024 */
              state?: string | null;
              /** @maxLength 1024 */
              city?: string | null;
              /** @maxLength 1024 */
              streetName?: string | null;
              /** @maxLength 1024 */
              streetNumber?: string | null;
              floor?: number | null;
              /** @maxLength 1024 */
              formattedAddress?: string;
            };
            /** @format date-time */
            dateTime?: string;
            /** @maxLength 1024 */
            provider?: string;
          };
        };
        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
        locale: string | null;
        /** @maxLength 1024 */
        contactCode: string | null;
      };
      distanceKm: number | null;
      actions: {
        /** @maxLength 1024 */
        call: string | null;
        /** @maxLength 1024 */
        sms: string | null;
        /** @maxLength 1024 */
        email: string | null;
        notification: boolean;
      };
      priority: number;
      professionalResponder?: {
        available: boolean;
        inProgress: boolean;
      };
    }[];
  }[];
}

export interface AlarmResponseStaticResponseDto {
  result: {
    type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
    priority: number;
    zone?: {
      /** @format uuid */
      _id: string;
      customer?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      };
      type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
      location:
        | {
            type: "Point";
            coordinates: number[];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          }
        | {
            type: "Polygon";
            coordinates: number[][][];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          }
        | {
            type: "MultiPolygon";
            coordinates: number[][][][];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          };
      /** @maxLength 1024 */
      name: string;
      staticResponders: {
        staticResponder: {
          /** @format uuid */
          _id: string;
          _ref: "StaticResponder";
        };
        priority: number;
      }[];
    };
    responders: {
      info: {
        /** @format uuid */
        _id: string;
        _ref: "Asset" | "StaticResponder";
        /** @maxLength 1024 */
        name: string;
        /** @maxLength 1024 */
        image: string | null;
        position: {
          type: "Point";
          coordinates: number[];
          properties: {
            accuracy?: number;
            heading?: number;
            headingAccuracy?: number;
            speed?: number;
            speedAccuracy?: number;
            altitude?: number;
            altitudeAccuracy?: number;
            address?: {
              /** @maxLength 1024 */
              countryCode?: string | null;
              /** @maxLength 1024 */
              state?: string | null;
              /** @maxLength 1024 */
              city?: string | null;
              /** @maxLength 1024 */
              streetName?: string | null;
              /** @maxLength 1024 */
              streetNumber?: string | null;
              floor?: number | null;
              /** @maxLength 1024 */
              formattedAddress?: string;
            };
            /** @format date-time */
            dateTime?: string;
            /** @maxLength 1024 */
            provider?: string;
          };
        };
        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
        locale: string | null;
        /** @maxLength 1024 */
        contactCode: string | null;
      };
      distanceKm: number | null;
      actions: {
        /** @maxLength 1024 */
        call: string | null;
        /** @maxLength 1024 */
        sms: string | null;
        /** @maxLength 1024 */
        email: string | null;
        notification: boolean;
      };
      priority: number;
      professionalResponder?: {
        available: boolean;
        inProgress: boolean;
      };
    }[];
  }[];
}

export interface AlarmUpdateDto {
  alarmCenter: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
}

export interface AssetAppUpdateDto {
  available?: boolean;
  app?: {
    mode: "off" | "on" | "risk";
  } | null;
}

export interface AssetAppUserDeleteDto {
  /** @format uuid */
  assetId: string;
  /** @default false */
  deleteAllDevices?: boolean;
}

export interface AssetCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  user: {
    /** @format uuid */
    _id: string;
    _ref: "User";
  } | null;
  /** @maxLength 1024 */
  name: string;
  /** @maxLength 1024 */
  externalId?: string | null;
  propertiesOwnership?: "override" | "inherit";
  /** @default {} */
  properties?: {
    /** @maxLength 1024 */
    phoneNumber?: string | null;
    /** @maxLength 1024 */
    smsNumber?: string | null;
    /** @maxLength 1024 */
    email?: string | null;
    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
    locale?: string | null;
    /** @maxLength 1024 */
    image?: string | null;
    rSim?: {
      /** @maxLength 1024 */
      euicc: string;
      primary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      secondary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      enabled: boolean;
    } | null;
  };
  /** @default null */
  position?: {
    type: "Point";
    coordinates: number[];
    properties: {
      accuracy?: number;
      heading?: number;
      headingAccuracy?: number;
      speed?: number;
      speedAccuracy?: number;
      altitude?: number;
      altitudeAccuracy?: number;
      address?: {
        /** @maxLength 1024 */
        countryCode?: string | null;
        /** @maxLength 1024 */
        state?: string | null;
        /** @maxLength 1024 */
        city?: string | null;
        /** @maxLength 1024 */
        streetName?: string | null;
        /** @maxLength 1024 */
        streetNumber?: string | null;
        floor?: number | null;
        /** @maxLength 1024 */
        formattedAddress?: string;
      };
      /** @format date-time */
      dateTime?: string;
      /** @maxLength 1024 */
      provider?: string;
    };
  };
  available: boolean;
  /** @default true */
  allowContactOnOwnAlarms?: boolean;
  app?: {
    mode: "off" | "on" | "risk";
  } | null;
  assetGroups?: {
    /** @format uuid */
    _id: string;
    _ref: "AssetGroup";
  }[];
  /** @default [] */
  staticResponders?: {
    staticResponder: {
      /** @format uuid */
      _id: string;
      _ref: "StaticResponder";
    };
    priority: number;
  }[];
  /** @format date-time */
  lastObservationAt?: string;
  /** @default false */
  invisibleResponder?: boolean;
  /** @maxLength 4096 */
  description?: string;
  type?: "default" | "kiosk";
}

export interface AssetGetAtsResponseDto {
  /** @format uuid */
  alarmId?: string;
  /** @format uuid */
  assetId?: string;
  /** @default true */
  isSimulated?: boolean;
  filter: Record<string, any>;
  geoNear: {
    near:
      | {
          type: "Point";
          coordinates: number[];
        }
      | number[];
    distanceField: string;
    distanceMultiplier?: number;
    includeLocs?: string;
    key?: string;
    maxDistance?: number;
    minDistance?: number;
    query?: Record<string, any>;
    spherical?: boolean;
    uniqueDocs?: boolean;
  } | null;
  contacted?: boolean;
}

export interface AssetGroupAddAssetsDto {
  assets: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  }[];
}

export interface AssetGroupCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  /** @maxLength 1024 */
  description?: string | null;
  assets: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  }[];
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface AssetGroupMassUpdateDto {
  assets: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  }[];
  assetGroups: {
    /** @format uuid */
    _id: string;
    _ref: "AssetGroup";
  }[];
  replace: boolean;
}

export interface AssetGroupOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  /** @maxLength 1024 */
  description?: string | null;
  assets: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  }[];
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface AssetGroupUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name?: string;
  /** @maxLength 1024 */
  description?: string | null;
  assets?: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  }[];
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface AssetMoveDto {
  ids: string[];
  fromCustomer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  toCustomer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
}

export interface AssetOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  user: {
    /** @format uuid */
    _id: string;
    _ref: "User";
  } | null;
  /** @maxLength 1024 */
  name: string;
  /** @maxLength 1024 */
  externalId?: string | null;
  propertiesOwnership?: "override" | "inherit";
  /** @default {} */
  properties?: {
    /** @maxLength 1024 */
    phoneNumber?: string | null;
    /** @maxLength 1024 */
    smsNumber?: string | null;
    /** @maxLength 1024 */
    email?: string | null;
    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
    locale?: string | null;
    /** @maxLength 1024 */
    image?: string | null;
    rSim?: {
      /** @maxLength 1024 */
      euicc: string;
      primary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      secondary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      enabled: boolean;
    } | null;
  };
  /** @default null */
  position?: {
    type: "Point";
    coordinates: number[];
    properties: {
      accuracy?: number;
      heading?: number;
      headingAccuracy?: number;
      speed?: number;
      speedAccuracy?: number;
      altitude?: number;
      altitudeAccuracy?: number;
      address?: {
        /** @maxLength 1024 */
        countryCode?: string | null;
        /** @maxLength 1024 */
        state?: string | null;
        /** @maxLength 1024 */
        city?: string | null;
        /** @maxLength 1024 */
        streetName?: string | null;
        /** @maxLength 1024 */
        streetNumber?: string | null;
        floor?: number | null;
        /** @maxLength 1024 */
        formattedAddress?: string;
      };
      /** @format date-time */
      dateTime?: string;
      /** @maxLength 1024 */
      provider?: string;
    };
  };
  available: boolean;
  /** @default true */
  allowContactOnOwnAlarms?: boolean;
  app?: {
    mode: "off" | "on" | "risk";
  } | null;
  insideZones: {
    /** @format uuid */
    _id: string;
    _ref: "Zone";
  }[];
  assetGroups: {
    /** @format uuid */
    _id: string;
    _ref: "AssetGroup";
  }[];
  /** @default [] */
  staticResponders?: {
    staticResponder: {
      /** @format uuid */
      _id: string;
      _ref: "StaticResponder";
    };
    priority: number;
  }[];
  /** @format date-time */
  lastObservationAt?: string;
  /** @default false */
  invisibleResponder?: boolean;
  /** @maxLength 4096 */
  description?: string;
  type?: "default" | "kiosk";
}

export interface AssetUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  user?: {
    /** @format uuid */
    _id: string;
    _ref: "User";
  } | null;
  /** @maxLength 1024 */
  name?: string;
  /** @maxLength 1024 */
  externalId?: string | null;
  propertiesOwnership?: "override" | "inherit";
  /** @default {} */
  properties?: {
    /** @maxLength 1024 */
    phoneNumber?: string | null;
    /** @maxLength 1024 */
    smsNumber?: string | null;
    /** @maxLength 1024 */
    email?: string | null;
    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
    locale?: string | null;
    /** @maxLength 1024 */
    image?: string | null;
    rSim?: {
      /** @maxLength 1024 */
      euicc: string;
      primary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      secondary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      enabled: boolean;
    } | null;
  };
  /** @default null */
  position?: {
    type: "Point";
    coordinates: number[];
    properties: {
      accuracy?: number;
      heading?: number;
      headingAccuracy?: number;
      speed?: number;
      speedAccuracy?: number;
      altitude?: number;
      altitudeAccuracy?: number;
      address?: {
        /** @maxLength 1024 */
        countryCode?: string | null;
        /** @maxLength 1024 */
        state?: string | null;
        /** @maxLength 1024 */
        city?: string | null;
        /** @maxLength 1024 */
        streetName?: string | null;
        /** @maxLength 1024 */
        streetNumber?: string | null;
        floor?: number | null;
        /** @maxLength 1024 */
        formattedAddress?: string;
      };
      /** @format date-time */
      dateTime?: string;
      /** @maxLength 1024 */
      provider?: string;
    };
  };
  available?: boolean;
  /** @default true */
  allowContactOnOwnAlarms?: boolean;
  app?: {
    mode: "off" | "on" | "risk";
  } | null;
  assetGroups?: {
    /** @format uuid */
    _id: string;
    _ref: "AssetGroup";
  }[];
  /** @default [] */
  staticResponders?: {
    staticResponder: {
      /** @format uuid */
      _id: string;
      _ref: "StaticResponder";
    };
    priority: number;
  }[];
  /** @format date-time */
  lastObservationAt?: string;
  /** @default false */
  invisibleResponder?: boolean;
  /** @maxLength 4096 */
  description?: string;
  type?: "default" | "kiosk";
}

export interface BeaconCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  codes: string[];
  /**
   * @min 0
   * @exclusiveMin false
   */
  rssiAddend: number;
  type: "default" | "tag";
  zone?: {
    /** @format uuid */
    _id: string;
    _ref: "Zone";
  } | null;
  location?:
    | {
        type: "Point";
        coordinates: number[];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "Polygon";
        coordinates: number[][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "MultiPolygon";
        coordinates: number[][][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      };
}

export interface BeaconOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  codes: string[];
  /**
   * @min 0
   * @exclusiveMin false
   */
  rssiAddend: number;
  type: "default" | "tag";
  state: {
    lastSeenBy?: {
      /** @format uuid */
      _id: string;
      _ref: "Asset";
    } | null;
    /** @format date-time */
    lastSeenAt?: string | null;
    battery?: number | null;
  };
  zone?: {
    /** @format uuid */
    _id: string;
    _ref: "Zone";
  } | null;
  location?:
    | {
        type: "Point";
        coordinates: number[];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "Polygon";
        coordinates: number[][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "MultiPolygon";
        coordinates: number[][][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      };
}

export interface BeaconUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  codes?: string[];
  /**
   * @min 0
   * @exclusiveMin false
   */
  rssiAddend?: number;
  type?: "default" | "tag";
  zone?: {
    /** @format uuid */
    _id: string;
    _ref: "Zone";
  } | null;
  location?:
    | {
        type: "Point";
        coordinates: number[];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "Polygon";
        coordinates: number[][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "MultiPolygon";
        coordinates: number[][][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      };
}

export interface BHVKnopAddAddtionalPaidResourcesDto {
  key: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  products: {
    /**
     * @min 0
     * @exclusiveMin false
     */
    button: number;
    /**
     * @min 0
     * @exclusiveMin false
     */
    gateway: number;
  };
  billingAddress: {
    companyName: string;
    postcode: string;
    houseNumber: string;
    street: string;
    city: string;
  };
  shippingAddress?: {
    companyName: string;
    postcode: string;
    houseNumber: string;
    street: string;
    city: string;
  };
  prices: {
    yearly: number;
    single: number;
    vat: number;
    totalNoVat: number;
    totalWithVat: number;
  };
}

export interface BHVKnopAddDevicesDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  devices: {
    name?: string;
    position: {
      type: "Point";
      coordinates: number[];
      properties: {
        accuracy?: number;
        heading?: number;
        headingAccuracy?: number;
        speed?: number;
        speedAccuracy?: number;
        altitude?: number;
        altitudeAccuracy?: number;
        address?: {
          /** @maxLength 1024 */
          countryCode?: string | null;
          /** @maxLength 1024 */
          state?: string | null;
          /** @maxLength 1024 */
          city?: string | null;
          /** @maxLength 1024 */
          streetName?: string | null;
          /** @maxLength 1024 */
          streetNumber?: string | null;
          floor?: number | null;
          /** @maxLength 1024 */
          formattedAddress?: string;
        };
        /** @format date-time */
        dateTime?: string;
        /** @maxLength 1024 */
        provider?: string;
      };
    };
    type: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
  }[];
}

export interface BHVKnopAssignHardwareDto {
  key: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  buttons: {
    /** @pattern ^[a-z0-9](?:[-]?[a-z0-9]){2,}$ */
    devEui: string;
    sequenceNumber: number;
    device?: {
      /** @format uuid */
      _id: string;
      _ref: "Device";
    };
  }[];
  gateways: {
    /** @pattern ^[a-z0-9](?:[-]?[a-z0-9]){2,}$ */
    gatewayEui: string;
    /** @pattern ^\+[0-9]{5,}$ */
    phoneNumber: string;
    sequenceNumber: number;
    device?: {
      /** @format uuid */
      _id: string;
      _ref: "Device";
    };
  }[];
}

export interface BHVKnopCreateOrConvertToPaidDto {
  key: string;
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  name: string;
  /** @format email */
  email: string;
  products: {
    /**
     * @min 1
     * @exclusiveMin false
     */
    button: number;
    /**
     * @min 1
     * @exclusiveMin false
     */
    gateway: number;
  };
  billingAddress: {
    companyName: string;
    postcode: string;
    houseNumber: string;
    street: string;
    city: string;
  };
  shippingAddress?: {
    companyName: string;
    postcode: string;
    houseNumber: string;
    street: string;
    city: string;
  };
  sepa: {
    iban: string;
    bankName: string;
  };
  prices: {
    yearly: number;
    single: number;
    vat: number;
    totalNoVat: number;
    totalWithVat: number;
  };
}

export interface BHVKnopCreateOrConvertToPaidResponseDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  converted?: boolean;
  token?: string;
  refreshToken?: string;
}

export interface BHVKnopCreateTrialDto {
  key: string;
  /** @format email */
  email: string;
  companyName: string;
}

export interface BHVKnopCreateTrialResponseDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  token: string;
  refreshToken: string;
}

export interface BHVKnopGetNextSequenceNumberDto {
  key: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  type: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
}

export interface BHVKnopGetNextSequenceNumberResponseDto {
  /**
   * @min 1
   * @exclusiveMin false
   */
  next: number;
}

export interface BHVKnopGetSequenceNumbersDto {
  key: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  assets: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  }[];
}

export interface BHVKnopGetSequenceNumbersResponseDto {
  assets: {
    asset: {
      /** @format uuid */
      _id: string;
      _ref: "Asset";
    };
    sequenceNumber: number;
  }[];
}

export interface BHVKnopRemoveDevicesDto {
  assets: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  }[];
}

export interface BHVKnopUpdateMonitoredZonesDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
}

export interface ClientConfigGroupCreateDto {
  name: string;
  clientConfig: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
}

export interface ClientConfigGroupOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  name: string;
  clientConfig: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
}

export interface ClientConfigGroupUpdateDto {
  name?: string;
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface ClientConfigKeyCreateDto {
  platform: "app" | "kiosk";
  editable: boolean;
  key: string;
  defaultValue: any;
  version: number;
}

export interface ClientConfigKeyOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  platform: "app" | "kiosk";
  editable: boolean;
  key: string;
  defaultValue: any;
  version: number;
}

export interface ClientConfigKeyUpdateDto {
  editable?: boolean;
  defaultValue?: any;
  version?: number;
}

export interface ClientConfigResolveAppV0Dto {
  config: {
    environment: {
      key: "staging" | "production";
      mainApiUrl: string;
      authApiUrl: string;
      observationApiUrl: string;
      wsApiUrl: string;
      fusionAuthClientId: string;
      xguard: {
        privacy: {
          nl: string;
          en: string;
        };
        termsAndConditions: {
          nl: string;
          en: string;
        };
      };
      bhvknop: {
        privacy: {
          nl: string;
          en: string;
        };
        termsAndConditions: {
          nl: string;
          en: string;
        };
      };
    };
    otherEnvironment: {
      key: "staging" | "production";
      mainApiUrl: string;
    };
    registration: {
      supportedCCA2: {
        code: string;
        enabled: boolean;
      }[];
      /**
       * @min 0
       * @exclusiveMin true
       */
      policyMaxAge: number;
    };
    notifier: {
      /**
       * @min 0
       * @exclusiveMin true
       */
      duration: number;
    };
    alarms: {
      allowWhenOff: boolean;
      buttons: {
        severity: "red" | "orange" | "green";
        type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
        visible?: boolean;
        order?: number;
        height?: "small" | "large";
        width?: "full" | "half";
        overrideColor?: "green" | "blue" | "yellow" | "orange" | "red";
        overrideLabel?: string;
        allowWhenOff?: boolean;
      }[];
      /**
       * @min 0
       * @exclusiveMin true
       */
      buttonPressDuration: number;
    };
    palettes: {
      sizes: {
        headerFont: number;
        normalFont: number;
        underLineFont: number;
        wideBoxIcon: number;
      };
      opacities: {
        lower: number;
        low: number;
        medium: number;
        high: number;
      };
      xguard: {
        light: {
          colors: {
            appBg: string;
            appHeaderBg: string;
            appHeaderColor: string;
            overlayBg: string;
            overlayColor: string;
            divider: string;
            inputBg: string;
            text: string;
            lightText: string;
            alternativeBg: string;
            alternativeBgDark: string;
            alternativeColor: string;
            alternativeLightText: string;
            alternativeHr: string;
            togglesBackground: string;
            toggleOff: string;
            toggleOffText: string;
            toggleOn: string;
            toggleOnText: string;
            toggleRisk: string;
            toggleRiskText: string;
            panelBackground: string;
            panelBorder: string;
            qrCodeMask: string;
            buttonWhiteBg: string;
            buttonWhiteBgHover: string;
            buttonWhiteText: string;
            buttonGreenBg: string;
            buttonGreenBgHover: string;
            buttonGreenText: string;
            buttonBlueBg: string;
            buttonBlueBgHover: string;
            buttonBlueText: string;
            buttonYellowBg: string;
            buttonYellowBgHover: string;
            buttonYellowText: string;
            buttonOrangeBg: string;
            buttonOrangeBgHover: string;
            buttonOrangeText: string;
            buttonRedBg: string;
            buttonRedBgHover: string;
            buttonRedText: string;
            messageNeutralBg: string;
            messageNeutralBgHover: string;
            messageNeutralText: string;
            messageGreenBg: string;
            messageGreenBgHover: string;
            messageGreenText: string;
            messageBlueBg: string;
            messageBlueBgHover: string;
            messageBlueText: string;
            messageYellowBg: string;
            messageYellowBgHover: string;
            messageYellowText: string;
            messageOrangeBg: string;
            messageOrangeBgHover: string;
            messageOrangeText: string;
            messageRedBg: string;
            messageRedBgHover: string;
            messageRedText: string;
            loginHeaderBg: string;
            shadowColor: string;
            chatColor1: string;
            chatColor2: string;
            chatColor3: string;
            chatColor4: string;
            chatColor5: string;
            chatColor6: string;
            chatColor7: string;
            chatColor8: string;
            chatColor9: string;
            chatColor10: string;
            chatColor11: string;
            chatColor12: string;
            chatColor13: string;
            chatColor14: string;
            chatColor15: string;
          };
          logos: {
            tradeMark: string;
            horizontalPayoff: string;
            horizontal: string;
            vertical: string;
            verticalPayoff: string;
            appName: string;
          };
        };
        dark: {
          colors: {
            appBg: string;
            appHeaderBg: string;
            appHeaderColor: string;
            overlayBg: string;
            overlayColor: string;
            divider: string;
            inputBg: string;
            text: string;
            lightText: string;
            alternativeBg: string;
            alternativeBgDark: string;
            alternativeColor: string;
            alternativeLightText: string;
            alternativeHr: string;
            togglesBackground: string;
            toggleOff: string;
            toggleOffText: string;
            toggleOn: string;
            toggleOnText: string;
            toggleRisk: string;
            toggleRiskText: string;
            panelBackground: string;
            panelBorder: string;
            qrCodeMask: string;
            buttonWhiteBg: string;
            buttonWhiteBgHover: string;
            buttonWhiteText: string;
            buttonGreenBg: string;
            buttonGreenBgHover: string;
            buttonGreenText: string;
            buttonBlueBg: string;
            buttonBlueBgHover: string;
            buttonBlueText: string;
            buttonYellowBg: string;
            buttonYellowBgHover: string;
            buttonYellowText: string;
            buttonOrangeBg: string;
            buttonOrangeBgHover: string;
            buttonOrangeText: string;
            buttonRedBg: string;
            buttonRedBgHover: string;
            buttonRedText: string;
            messageNeutralBg: string;
            messageNeutralBgHover: string;
            messageNeutralText: string;
            messageGreenBg: string;
            messageGreenBgHover: string;
            messageGreenText: string;
            messageBlueBg: string;
            messageBlueBgHover: string;
            messageBlueText: string;
            messageYellowBg: string;
            messageYellowBgHover: string;
            messageYellowText: string;
            messageOrangeBg: string;
            messageOrangeBgHover: string;
            messageOrangeText: string;
            messageRedBg: string;
            messageRedBgHover: string;
            messageRedText: string;
            loginHeaderBg: string;
            shadowColor: string;
            chatColor1: string;
            chatColor2: string;
            chatColor3: string;
            chatColor4: string;
            chatColor5: string;
            chatColor6: string;
            chatColor7: string;
            chatColor8: string;
            chatColor9: string;
            chatColor10: string;
            chatColor11: string;
            chatColor12: string;
            chatColor13: string;
            chatColor14: string;
            chatColor15: string;
          };
          logos: {
            tradeMark: string;
            horizontalPayoff: string;
            horizontal: string;
            vertical: string;
            verticalPayoff: string;
            appName: string;
          };
        };
      };
      bhvknop: {
        light: {
          colors: {
            appBg: string;
            appHeaderBg: string;
            appHeaderColor: string;
            overlayBg: string;
            overlayColor: string;
            divider: string;
            inputBg: string;
            text: string;
            lightText: string;
            alternativeBg: string;
            alternativeBgDark: string;
            alternativeColor: string;
            alternativeLightText: string;
            alternativeHr: string;
            togglesBackground: string;
            toggleOff: string;
            toggleOffText: string;
            toggleOn: string;
            toggleOnText: string;
            toggleRisk: string;
            toggleRiskText: string;
            panelBackground: string;
            panelBorder: string;
            qrCodeMask: string;
            buttonWhiteBg: string;
            buttonWhiteBgHover: string;
            buttonWhiteText: string;
            buttonGreenBg: string;
            buttonGreenBgHover: string;
            buttonGreenText: string;
            buttonBlueBg: string;
            buttonBlueBgHover: string;
            buttonBlueText: string;
            buttonYellowBg: string;
            buttonYellowBgHover: string;
            buttonYellowText: string;
            buttonOrangeBg: string;
            buttonOrangeBgHover: string;
            buttonOrangeText: string;
            buttonRedBg: string;
            buttonRedBgHover: string;
            buttonRedText: string;
            messageNeutralBg: string;
            messageNeutralBgHover: string;
            messageNeutralText: string;
            messageGreenBg: string;
            messageGreenBgHover: string;
            messageGreenText: string;
            messageBlueBg: string;
            messageBlueBgHover: string;
            messageBlueText: string;
            messageYellowBg: string;
            messageYellowBgHover: string;
            messageYellowText: string;
            messageOrangeBg: string;
            messageOrangeBgHover: string;
            messageOrangeText: string;
            messageRedBg: string;
            messageRedBgHover: string;
            messageRedText: string;
            loginHeaderBg: string;
            shadowColor: string;
            chatColor1: string;
            chatColor2: string;
            chatColor3: string;
            chatColor4: string;
            chatColor5: string;
            chatColor6: string;
            chatColor7: string;
            chatColor8: string;
            chatColor9: string;
            chatColor10: string;
            chatColor11: string;
            chatColor12: string;
            chatColor13: string;
            chatColor14: string;
            chatColor15: string;
          };
          logos: {
            tradeMark: string;
            horizontalPayoff: string;
            horizontal: string;
            vertical: string;
            verticalPayoff: string;
            appName: string;
          };
        };
        dark: {
          colors: {
            appBg: string;
            appHeaderBg: string;
            appHeaderColor: string;
            overlayBg: string;
            overlayColor: string;
            divider: string;
            inputBg: string;
            text: string;
            lightText: string;
            alternativeBg: string;
            alternativeBgDark: string;
            alternativeColor: string;
            alternativeLightText: string;
            alternativeHr: string;
            togglesBackground: string;
            toggleOff: string;
            toggleOffText: string;
            toggleOn: string;
            toggleOnText: string;
            toggleRisk: string;
            toggleRiskText: string;
            panelBackground: string;
            panelBorder: string;
            qrCodeMask: string;
            buttonWhiteBg: string;
            buttonWhiteBgHover: string;
            buttonWhiteText: string;
            buttonGreenBg: string;
            buttonGreenBgHover: string;
            buttonGreenText: string;
            buttonBlueBg: string;
            buttonBlueBgHover: string;
            buttonBlueText: string;
            buttonYellowBg: string;
            buttonYellowBgHover: string;
            buttonYellowText: string;
            buttonOrangeBg: string;
            buttonOrangeBgHover: string;
            buttonOrangeText: string;
            buttonRedBg: string;
            buttonRedBgHover: string;
            buttonRedText: string;
            messageNeutralBg: string;
            messageNeutralBgHover: string;
            messageNeutralText: string;
            messageGreenBg: string;
            messageGreenBgHover: string;
            messageGreenText: string;
            messageBlueBg: string;
            messageBlueBgHover: string;
            messageBlueText: string;
            messageYellowBg: string;
            messageYellowBgHover: string;
            messageYellowText: string;
            messageOrangeBg: string;
            messageOrangeBgHover: string;
            messageOrangeText: string;
            messageRedBg: string;
            messageRedBgHover: string;
            messageRedText: string;
            loginHeaderBg: string;
            shadowColor: string;
            chatColor1: string;
            chatColor2: string;
            chatColor3: string;
            chatColor4: string;
            chatColor5: string;
            chatColor6: string;
            chatColor7: string;
            chatColor8: string;
            chatColor9: string;
            chatColor10: string;
            chatColor11: string;
            chatColor12: string;
            chatColor13: string;
            chatColor14: string;
            chatColor15: string;
          };
          logos: {
            tradeMark: string;
            horizontalPayoff: string;
            horizontal: string;
            vertical: string;
            verticalPayoff: string;
            appName: string;
          };
        };
      };
    };
  };
  definition: {
    key: string;
    editable: boolean;
    mergeHistory: (
      | {
          success: boolean;
          sources: (
            | {
                /** @format uuid */
                _id: string;
                _ref: "ClientConfigKey";
              }
            | {
                /** @format uuid */
                _id: string;
                _ref: "ClientConfigGroup";
              }
            | (
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "Device";
                  }
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  }
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }
              )
          )[];
          value: any;
          editable?: boolean;
        }
      | {
          success: boolean;
          sources: (
            | {
                /** @format uuid */
                _id: string;
                _ref: "ClientConfigKey";
              }
            | {
                /** @format uuid */
                _id: string;
                _ref: "ClientConfigGroup";
              }
            | (
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "Device";
                  }
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  }
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }
              )
          )[];
          error: any;
        }
    )[];
    schema?: any;
  }[];
}

export interface ClientConfigResolveKioskV0Dto {
  config: {
    /** @default false */
    managed?: boolean;
    zone?: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    } | null;
    exposeAssets?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[]
      | null;
    exposeLabels?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
          countLabel?: boolean;
        }[]
      | null;
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    } | null;
    homepage?: {
      incidentCounter?: {
        /** @default true */
        enabled?: boolean;
        /** @format date-time */
        countFromDate?: string | null;
        /**
         * @min 0
         * @exclusiveMin false
         * @default 0
         */
        threshold?: number;
      };
      /** @default true */
      anonymous?: boolean;
      /** @format uri */
      logo?: string | null;
    };
    alarm?: {
      /** @default true */
      enabled?: boolean;
      focus?: {
        red?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        orange?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        green?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
      };
      /** @default true */
      showPositionLabels?: boolean;
      /** @default true */
      anonymous?: boolean;
      acknowledge?: {
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        messagePresets?: string[] | null;
      };
      mode?: "zoneBased" | "customerBased" | null;
    };
    manageAvailability?: {
      /** @default true */
      enabled?: boolean;
      /** @pattern ^[0-9]{4,6}$ */
      pin?: string | null;
      /** @default true */
      showAppBanner?: boolean;
      /** @default false */
      forceShowSearch?: boolean;
      /** @default false */
      forceShowLabelFilter?: boolean;
    };
    hardware?: {
      /**
       * @min 0
       * @exclusiveMin false
       * @max 100
       * @exclusiveMax false
       */
      screenBrightness?: number | null;
    };
  };
  definition: {
    key: string;
    editable: boolean;
    mergeHistory: (
      | {
          success: boolean;
          sources: (
            | {
                /** @format uuid */
                _id: string;
                _ref: "ClientConfigKey";
              }
            | {
                /** @format uuid */
                _id: string;
                _ref: "ClientConfigGroup";
              }
            | (
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "Device";
                  }
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  }
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }
              )
          )[];
          value: any;
          editable?: boolean;
        }
      | {
          success: boolean;
          sources: (
            | {
                /** @format uuid */
                _id: string;
                _ref: "ClientConfigKey";
              }
            | {
                /** @format uuid */
                _id: string;
                _ref: "ClientConfigGroup";
              }
            | (
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "Device";
                  }
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  }
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }
              )
          )[];
          error: any;
        }
    )[];
    schema?: any;
  }[];
}

export interface ContactListCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  /**
   * @maxLength 1024
   * @default null
   */
  description?: string | null;
  contacts: {
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    phone: string | null;
    /** @maxLength 1024 */
    email: string | null;
  }[];
}

export interface ContactListOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  /**
   * @maxLength 1024
   * @default null
   */
  description?: string | null;
  contacts: {
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    phone: string | null;
    /** @maxLength 1024 */
    email: string | null;
  }[];
}

export interface ContactListUpdateDto {
  /** @maxLength 1024 */
  name?: string;
  /**
   * @maxLength 1024
   * @default null
   */
  description?: string | null;
  contacts?: {
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    phone: string | null;
    /** @maxLength 1024 */
    email: string | null;
  }[];
}

export interface CustomerAlarmCenterRelationCreateDto {
  /** @maxLength 1024 */
  migrationKey?: string;
  alarmCenter: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  enabled: boolean;
  zones:
    | {
        /** @format uuid */
        _id: string;
        _ref: "Zone";
      }[]
    | null;
  priority?: number;
}

export interface CustomerAlarmCenterRelationOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @maxLength 1024 */
  migrationKey?: string;
  alarmCenter: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  enabled: boolean;
  zones:
    | {
        /** @format uuid */
        _id: string;
        _ref: "Zone";
      }[]
    | null;
  priority?: number;
}

export interface CustomerAlarmCenterRelationUpdateDto {
  /** @maxLength 1024 */
  migrationKey?: string;
  alarmCenter?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  enabled?: boolean;
  zones?:
    | {
        /** @format uuid */
        _id: string;
        _ref: "Zone";
      }[]
    | null;
  priority?: number;
}

export interface CustomerCreateDto {
  /** @maxLength 1024 */
  name: string;
  /** @default null */
  parent?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
  locale: string;
  /**
   * @maxLength 1024
   * @default "Europe/Amsterdam"
   */
  timeZone?: string;
  /** @default null */
  alarmCenter?: {
    enabled: boolean;
    /** @maxLength 1024 */
    queueName?: string;
    /** @maxLength 1024 */
    callbackPhoneNumber?: string;
  } | null;
  survey?: {
    enabled: boolean;
  };
  /** @default null */
  responsePartner?: {
    enabled: boolean;
  } | null;
  /** @default [] */
  responsePartners?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  }[];
  /** @default [] */
  billingContracts?: string[];
  preferences?: {
    alarmAgeLimit?: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    } | null;
    mergeConfig?: {
      enabled: boolean;
      onlyMergeWhenSame: {
        name: boolean;
        severity: boolean;
        type: boolean;
      };
      maxTimeBetweenAlarmsMs: number;
      retriggerAtsAfterMs: number;
    } | null;
    disableAutomaticGenerationOfButtonZones?: boolean | null;
  };
  loraConfig?:
    | (
        | {
            type: "adeunis.arf8123aa";
            config: {
              /** @maxLength 1024 */
              fieldTest: string;
            };
          }
        | {
            type: "iotracker.v2";
            config: {
              /** @maxLength 1024 */
              beaconScan: string;
              /** @maxLength 1024 */
              gps: string;
              /** @maxLength 1024 */
              preMandown: string;
              /** @maxLength 1024 */
              mandown: string;
              /** @maxLength 1024 */
              movement: string;
              /** @maxLength 1024 */
              alarmSeverity: string;
              /** @maxLength 1024 */
              alive: string;
            };
          }
        | {
            type: "milesight.ws101";
            config: {
              /** @maxLength 1024 */
              beaconScan: string;
              /** @maxLength 1024 */
              alarmSeverity: string;
              /** @maxLength 1024 */
              alive: string;
            };
          }
        | {
            type: "sensative.1301001";
            config: {
              /** @maxLength 1024 */
              alarmSeverity: string;
              /** @maxLength 1024 */
              alive: string;
              /** @maxLength 1024 */
              floodDetected: string;
              /** @maxLength 1024 */
              noFloodDetected: string;
            };
          }
      )[]
    | null;
  kioskConfig?: {
    /** @default false */
    managed?: boolean;
    zone?: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    } | null;
    exposeAssets?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[]
      | null;
    exposeLabels?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
          countLabel?: boolean;
        }[]
      | null;
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    } | null;
    homepage?: {
      incidentCounter?: {
        /** @default true */
        enabled?: boolean;
        /** @format date-time */
        countFromDate?: string | null;
        /**
         * @min 0
         * @exclusiveMin false
         * @default 0
         */
        threshold?: number;
      };
      /** @default true */
      anonymous?: boolean;
      /** @format uri */
      logo?: string | null;
    };
    alarm?: {
      /** @default true */
      enabled?: boolean;
      focus?: {
        red?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        orange?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        green?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
      };
      /** @default true */
      showPositionLabels?: boolean;
      /** @default true */
      anonymous?: boolean;
      acknowledge?: {
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        messagePresets?: string[] | null;
      };
      mode?: "zoneBased" | "customerBased" | null;
    };
    manageAvailability?: {
      /** @default true */
      enabled?: boolean;
      /** @pattern ^[0-9]{4,6}$ */
      pin?: string | null;
      /** @default true */
      showAppBanner?: boolean;
      /** @default false */
      forceShowSearch?: boolean;
      /** @default false */
      forceShowLabelFilter?: boolean;
    };
    hardware?: {
      /**
       * @min 0
       * @exclusiveMin false
       * @max 100
       * @exclusiveMax false
       */
      screenBrightness?: number | null;
    };
  };
  type?: "default" | "bhvk_paid" | "bhvk_trial";
  userPolicies?: {
    disablePasswordlessLogin?: boolean;
  };
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface CustomerOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @maxLength 1024 */
  name: string;
  /** @default null */
  parent?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  parents: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  }[];
  children: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  }[];
  /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
  locale: string;
  /**
   * @maxLength 1024
   * @default "Europe/Amsterdam"
   */
  timeZone?: string;
  /** @default null */
  alarmCenter?: {
    enabled: boolean;
    /** @maxLength 1024 */
    queueName?: string;
    /** @maxLength 1024 */
    callbackPhoneNumber?: string;
  } | null;
  survey?: {
    enabled: boolean;
  };
  /** @default null */
  responsePartner?: {
    enabled: boolean;
  } | null;
  /** @default [] */
  responsePartners?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  }[];
  /** @default [] */
  billingContracts?: string[];
  preferences?: {
    alarmAgeLimit?: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    } | null;
    mergeConfig?: {
      enabled: boolean;
      onlyMergeWhenSame: {
        name: boolean;
        severity: boolean;
        type: boolean;
      };
      maxTimeBetweenAlarmsMs: number;
      retriggerAtsAfterMs: number;
    } | null;
    disableAutomaticGenerationOfButtonZones?: boolean | null;
  };
  loraConfig?:
    | (
        | {
            type: "adeunis.arf8123aa";
            config: {
              /** @maxLength 1024 */
              fieldTest: string;
            };
          }
        | {
            type: "iotracker.v2";
            config: {
              /** @maxLength 1024 */
              beaconScan: string;
              /** @maxLength 1024 */
              gps: string;
              /** @maxLength 1024 */
              preMandown: string;
              /** @maxLength 1024 */
              mandown: string;
              /** @maxLength 1024 */
              movement: string;
              /** @maxLength 1024 */
              alarmSeverity: string;
              /** @maxLength 1024 */
              alive: string;
            };
          }
        | {
            type: "milesight.ws101";
            config: {
              /** @maxLength 1024 */
              beaconScan: string;
              /** @maxLength 1024 */
              alarmSeverity: string;
              /** @maxLength 1024 */
              alive: string;
            };
          }
        | {
            type: "sensative.1301001";
            config: {
              /** @maxLength 1024 */
              alarmSeverity: string;
              /** @maxLength 1024 */
              alive: string;
              /** @maxLength 1024 */
              floodDetected: string;
              /** @maxLength 1024 */
              noFloodDetected: string;
            };
          }
      )[]
    | null;
  kioskConfig?: {
    /** @default false */
    managed?: boolean;
    zone?: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    } | null;
    exposeAssets?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[]
      | null;
    exposeLabels?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
          countLabel?: boolean;
        }[]
      | null;
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    } | null;
    homepage?: {
      incidentCounter?: {
        /** @default true */
        enabled?: boolean;
        /** @format date-time */
        countFromDate?: string | null;
        /**
         * @min 0
         * @exclusiveMin false
         * @default 0
         */
        threshold?: number;
      };
      /** @default true */
      anonymous?: boolean;
      /** @format uri */
      logo?: string | null;
    };
    alarm?: {
      /** @default true */
      enabled?: boolean;
      focus?: {
        red?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        orange?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        green?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
      };
      /** @default true */
      showPositionLabels?: boolean;
      /** @default true */
      anonymous?: boolean;
      acknowledge?: {
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        messagePresets?: string[] | null;
      };
      mode?: "zoneBased" | "customerBased" | null;
    };
    manageAvailability?: {
      /** @default true */
      enabled?: boolean;
      /** @pattern ^[0-9]{4,6}$ */
      pin?: string | null;
      /** @default true */
      showAppBanner?: boolean;
      /** @default false */
      forceShowSearch?: boolean;
      /** @default false */
      forceShowLabelFilter?: boolean;
    };
    hardware?: {
      /**
       * @min 0
       * @exclusiveMin false
       * @max 100
       * @exclusiveMax false
       */
      screenBrightness?: number | null;
    };
  };
  type?: "default" | "bhvk_paid" | "bhvk_trial";
  userPolicies?: {
    disablePasswordlessLogin?: boolean;
  };
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface CustomerUpdateDto {
  /** @maxLength 1024 */
  name?: string;
  /** @default null */
  parent?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
  locale?: string;
  /**
   * @maxLength 1024
   * @default "Europe/Amsterdam"
   */
  timeZone?: string;
  /** @default null */
  alarmCenter?: {
    enabled: boolean;
    /** @maxLength 1024 */
    queueName?: string;
    /** @maxLength 1024 */
    callbackPhoneNumber?: string;
  } | null;
  survey?: {
    enabled: boolean;
  };
  /** @default null */
  responsePartner?: {
    enabled: boolean;
  } | null;
  /** @default [] */
  responsePartners?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  }[];
  /** @default [] */
  billingContracts?: string[];
  preferences?: {
    alarmAgeLimit?: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    } | null;
    mergeConfig?: {
      enabled: boolean;
      onlyMergeWhenSame: {
        name: boolean;
        severity: boolean;
        type: boolean;
      };
      maxTimeBetweenAlarmsMs: number;
      retriggerAtsAfterMs: number;
    } | null;
    disableAutomaticGenerationOfButtonZones?: boolean | null;
  };
  loraConfig?:
    | (
        | {
            type: "adeunis.arf8123aa";
            config: {
              /** @maxLength 1024 */
              fieldTest: string;
            };
          }
        | {
            type: "iotracker.v2";
            config: {
              /** @maxLength 1024 */
              beaconScan: string;
              /** @maxLength 1024 */
              gps: string;
              /** @maxLength 1024 */
              preMandown: string;
              /** @maxLength 1024 */
              mandown: string;
              /** @maxLength 1024 */
              movement: string;
              /** @maxLength 1024 */
              alarmSeverity: string;
              /** @maxLength 1024 */
              alive: string;
            };
          }
        | {
            type: "milesight.ws101";
            config: {
              /** @maxLength 1024 */
              beaconScan: string;
              /** @maxLength 1024 */
              alarmSeverity: string;
              /** @maxLength 1024 */
              alive: string;
            };
          }
        | {
            type: "sensative.1301001";
            config: {
              /** @maxLength 1024 */
              alarmSeverity: string;
              /** @maxLength 1024 */
              alive: string;
              /** @maxLength 1024 */
              floodDetected: string;
              /** @maxLength 1024 */
              noFloodDetected: string;
            };
          }
      )[]
    | null;
  kioskConfig?: {
    /** @default false */
    managed?: boolean;
    zone?: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    } | null;
    exposeAssets?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[]
      | null;
    exposeLabels?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
          countLabel?: boolean;
        }[]
      | null;
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    } | null;
    homepage?: {
      incidentCounter?: {
        /** @default true */
        enabled?: boolean;
        /** @format date-time */
        countFromDate?: string | null;
        /**
         * @min 0
         * @exclusiveMin false
         * @default 0
         */
        threshold?: number;
      };
      /** @default true */
      anonymous?: boolean;
      /** @format uri */
      logo?: string | null;
    };
    alarm?: {
      /** @default true */
      enabled?: boolean;
      focus?: {
        red?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        orange?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        green?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
      };
      /** @default true */
      showPositionLabels?: boolean;
      /** @default true */
      anonymous?: boolean;
      acknowledge?: {
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        messagePresets?: string[] | null;
      };
      mode?: "zoneBased" | "customerBased" | null;
    };
    manageAvailability?: {
      /** @default true */
      enabled?: boolean;
      /** @pattern ^[0-9]{4,6}$ */
      pin?: string | null;
      /** @default true */
      showAppBanner?: boolean;
      /** @default false */
      forceShowSearch?: boolean;
      /** @default false */
      forceShowLabelFilter?: boolean;
    };
    hardware?: {
      /**
       * @min 0
       * @exclusiveMin false
       * @max 100
       * @exclusiveMax false
       */
      screenBrightness?: number | null;
    };
  };
  type?: "default" | "bhvk_paid" | "bhvk_trial";
  userPolicies?: {
    disablePasswordlessLogin?: boolean;
  };
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface CustomerUserRelationCreateDto {
  /** @maxLength 1024 */
  migrationKey?: string;
  user: {
    /** @format uuid */
    _id: string;
    _ref: "User";
  };
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  roles: string[];
  assetGroupRestrictions?:
    | {
        /** @format uuid */
        _id: string;
        _ref: "AssetGroup";
      }[]
    | null;
}

export interface CustomerUserRelationOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @maxLength 1024 */
  migrationKey?: string;
  user: {
    /** @format uuid */
    _id: string;
    _ref: "User";
  };
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  roles: string[];
  assetGroupRestrictions?:
    | {
        /** @format uuid */
        _id: string;
        _ref: "AssetGroup";
      }[]
    | null;
}

export interface CustomerUserRelationUpdateDto {
  /** @maxLength 1024 */
  migrationKey?: string;
  user?: {
    /** @format uuid */
    _id: string;
    _ref: "User";
  };
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  roles?: string[];
  assetGroupRestrictions?:
    | {
        /** @format uuid */
        _id: string;
        _ref: "AssetGroup";
      }[]
    | null;
}

export interface DeviceCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  asset: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  } | null;
  /** @maxLength 1024 */
  name?: string;
  /** @maxLength 1024 */
  deviceId: string;
  type: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
  loraConfig?:
    | {
        type: "adeunis.arf8123aa";
        config: {
          /** @maxLength 1024 */
          fieldTest: string;
        };
      }
    | {
        type: "iotracker.v2";
        config: {
          /** @maxLength 1024 */
          beaconScan: string;
          /** @maxLength 1024 */
          gps: string;
          /** @maxLength 1024 */
          preMandown: string;
          /** @maxLength 1024 */
          mandown: string;
          /** @maxLength 1024 */
          movement: string;
          /** @maxLength 1024 */
          alarmSeverity: string;
          /** @maxLength 1024 */
          alive: string;
        };
      }
    | {
        type: "milesight.ws101";
        config: {
          /** @maxLength 1024 */
          beaconScan: string;
          /** @maxLength 1024 */
          alarmSeverity: string;
          /** @maxLength 1024 */
          alive: string;
        };
      }
    | {
        type: "sensative.1301001";
        config: {
          /** @maxLength 1024 */
          alarmSeverity: string;
          /** @maxLength 1024 */
          alive: string;
          /** @maxLength 1024 */
          floodDetected: string;
          /** @maxLength 1024 */
          noFloodDetected: string;
        };
      }
    | null;
  kioskConfig?: {
    /** @default false */
    managed?: boolean;
    zone?: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    } | null;
    exposeAssets?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[]
      | null;
    exposeLabels?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
          countLabel?: boolean;
        }[]
      | null;
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    } | null;
    homepage?: {
      incidentCounter?: {
        /** @default true */
        enabled?: boolean;
        /** @format date-time */
        countFromDate?: string | null;
        /**
         * @min 0
         * @exclusiveMin false
         * @default 0
         */
        threshold?: number;
      };
      /** @default true */
      anonymous?: boolean;
      /** @format uri */
      logo?: string | null;
    };
    alarm?: {
      /** @default true */
      enabled?: boolean;
      focus?: {
        red?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        orange?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        green?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
      };
      /** @default true */
      showPositionLabels?: boolean;
      /** @default true */
      anonymous?: boolean;
      acknowledge?: {
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        messagePresets?: string[] | null;
      };
      mode?: "zoneBased" | "customerBased" | null;
    };
    manageAvailability?: {
      /** @default true */
      enabled?: boolean;
      /** @pattern ^[0-9]{4,6}$ */
      pin?: string | null;
      /** @default true */
      showAppBanner?: boolean;
      /** @default false */
      forceShowSearch?: boolean;
      /** @default false */
      forceShowLabelFilter?: boolean;
    };
    hardware?: {
      /**
       * @min 0
       * @exclusiveMin false
       * @max 100
       * @exclusiveMax false
       */
      screenBrightness?: number | null;
    };
  };
  battery?: number;
  rssi?: number;
  snr?: number;
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface DeviceGetSyncToAmsZonesRequestDto {
  /** @format uuid */
  deviceId: string;
}

export interface DeviceGetSyncToAmsZonesResponseDto {
  geofence: {
    radius: number;
    center: number[];
  };
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  telluId?: number | null;
  migrationFinalised?: boolean | null;
  /** @format date-time */
  migrationFinalisedDate?: string | null;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  type: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
  location:
    | {
        type: "Polygon";
        coordinates: number[][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "MultiPolygon";
        coordinates: number[][][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      };
  /** @maxLength 1024 */
  name: string;
  /**
   * @maxLength 1024
   * @default null
   */
  description?: string | null;
  /** @default [] */
  staticResponders?: {
    staticResponder: {
      /** @format uuid */
      _id: string;
      _ref: "StaticResponder";
    };
    priority: number;
  }[];
  /** @default null */
  responsePriority?: number | null;
  assetsInside: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  }[];
  syncToAms?: boolean;
}

export interface DeviceOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  asset: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  } | null;
  /** @maxLength 1024 */
  name?: string;
  /** @maxLength 1024 */
  deviceId: string;
  type: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
  loraConfig?:
    | {
        type: "adeunis.arf8123aa";
        config: {
          /** @maxLength 1024 */
          fieldTest: string;
        };
      }
    | {
        type: "iotracker.v2";
        config: {
          /** @maxLength 1024 */
          beaconScan: string;
          /** @maxLength 1024 */
          gps: string;
          /** @maxLength 1024 */
          preMandown: string;
          /** @maxLength 1024 */
          mandown: string;
          /** @maxLength 1024 */
          movement: string;
          /** @maxLength 1024 */
          alarmSeverity: string;
          /** @maxLength 1024 */
          alive: string;
        };
      }
    | {
        type: "milesight.ws101";
        config: {
          /** @maxLength 1024 */
          beaconScan: string;
          /** @maxLength 1024 */
          alarmSeverity: string;
          /** @maxLength 1024 */
          alive: string;
        };
      }
    | {
        type: "sensative.1301001";
        config: {
          /** @maxLength 1024 */
          alarmSeverity: string;
          /** @maxLength 1024 */
          alive: string;
          /** @maxLength 1024 */
          floodDetected: string;
          /** @maxLength 1024 */
          noFloodDetected: string;
        };
      }
    | null;
  kioskConfig?: {
    /** @default false */
    managed?: boolean;
    zone?: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    } | null;
    exposeAssets?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[]
      | null;
    exposeLabels?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
          countLabel?: boolean;
        }[]
      | null;
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    } | null;
    homepage?: {
      incidentCounter?: {
        /** @default true */
        enabled?: boolean;
        /** @format date-time */
        countFromDate?: string | null;
        /**
         * @min 0
         * @exclusiveMin false
         * @default 0
         */
        threshold?: number;
      };
      /** @default true */
      anonymous?: boolean;
      /** @format uri */
      logo?: string | null;
    };
    alarm?: {
      /** @default true */
      enabled?: boolean;
      focus?: {
        red?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        orange?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        green?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
      };
      /** @default true */
      showPositionLabels?: boolean;
      /** @default true */
      anonymous?: boolean;
      acknowledge?: {
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        messagePresets?: string[] | null;
      };
      mode?: "zoneBased" | "customerBased" | null;
    };
    manageAvailability?: {
      /** @default true */
      enabled?: boolean;
      /** @pattern ^[0-9]{4,6}$ */
      pin?: string | null;
      /** @default true */
      showAppBanner?: boolean;
      /** @default false */
      forceShowSearch?: boolean;
      /** @default false */
      forceShowLabelFilter?: boolean;
    };
    hardware?: {
      /**
       * @min 0
       * @exclusiveMin false
       * @max 100
       * @exclusiveMax false
       */
      screenBrightness?: number | null;
    };
  };
  battery?: number;
  rssi?: number;
  snr?: number;
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface DeviceReloginNotificationDto {
  deviceId: string;
}

export interface DeviceUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  asset?: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  } | null;
  /** @maxLength 1024 */
  name?: string;
  /** @maxLength 1024 */
  deviceId?: string;
  type?: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
  loraConfig?:
    | {
        type: "adeunis.arf8123aa";
        config: {
          /** @maxLength 1024 */
          fieldTest: string;
        };
      }
    | {
        type: "iotracker.v2";
        config: {
          /** @maxLength 1024 */
          beaconScan: string;
          /** @maxLength 1024 */
          gps: string;
          /** @maxLength 1024 */
          preMandown: string;
          /** @maxLength 1024 */
          mandown: string;
          /** @maxLength 1024 */
          movement: string;
          /** @maxLength 1024 */
          alarmSeverity: string;
          /** @maxLength 1024 */
          alive: string;
        };
      }
    | {
        type: "milesight.ws101";
        config: {
          /** @maxLength 1024 */
          beaconScan: string;
          /** @maxLength 1024 */
          alarmSeverity: string;
          /** @maxLength 1024 */
          alive: string;
        };
      }
    | {
        type: "sensative.1301001";
        config: {
          /** @maxLength 1024 */
          alarmSeverity: string;
          /** @maxLength 1024 */
          alive: string;
          /** @maxLength 1024 */
          floodDetected: string;
          /** @maxLength 1024 */
          noFloodDetected: string;
        };
      }
    | null;
  kioskConfig?: {
    /** @default false */
    managed?: boolean;
    zone?: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    } | null;
    exposeAssets?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[]
      | null;
    exposeLabels?:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
          countLabel?: boolean;
        }[]
      | null;
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    } | null;
    homepage?: {
      incidentCounter?: {
        /** @default true */
        enabled?: boolean;
        /** @format date-time */
        countFromDate?: string | null;
        /**
         * @min 0
         * @exclusiveMin false
         * @default 0
         */
        threshold?: number;
      };
      /** @default true */
      anonymous?: boolean;
      /** @format uri */
      logo?: string | null;
    };
    alarm?: {
      /** @default true */
      enabled?: boolean;
      focus?: {
        red?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        orange?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        green?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
      };
      /** @default true */
      showPositionLabels?: boolean;
      /** @default true */
      anonymous?: boolean;
      acknowledge?: {
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        messagePresets?: string[] | null;
      };
      mode?: "zoneBased" | "customerBased" | null;
    };
    manageAvailability?: {
      /** @default true */
      enabled?: boolean;
      /** @pattern ^[0-9]{4,6}$ */
      pin?: string | null;
      /** @default true */
      showAppBanner?: boolean;
      /** @default false */
      forceShowSearch?: boolean;
      /** @default false */
      forceShowLabelFilter?: boolean;
    };
    hardware?: {
      /**
       * @min 0
       * @exclusiveMin false
       * @max 100
       * @exclusiveMax false
       */
      screenBrightness?: number | null;
    };
  };
  battery?: number;
  rssi?: number;
  snr?: number;
  clientConfig?: {
    app?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
    kiosk?: (
      | {
          type: "key";
          key: string;
          value: any;
          editable?: boolean;
        }
      | {
          type: "group";
          group: {
            /** @format uuid */
            _id: string;
            _ref: "ClientConfigGroup";
          };
        }
    )[];
  };
}

export interface DisableTwoFactorAuthRequestDto {
  methodId: string;
  code: string;
}

export interface DisableTwoFactorAuthResponseDto {
  success: boolean;
}

export interface EmailContactDto {
  to: string[];
  subject: string;
  text?: string;
  html?: string;
}

export interface EnableTwoFactorAuthRequestDto {
  method: "authenticator";
  secretBase32Encoded: string;
  code: string;
}

export interface EnableTwoFactorAuthResponseDto {
  recoveryCodes?: string[];
}

export interface FingerprintCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  zone: {
    /** @format uuid */
    _id: string;
    _ref: "Zone";
  };
  deviceInfo: {
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    osName: string;
    /** @maxLength 1024 */
    osVersion: string;
    /** @maxLength 1024 */
    brand: string;
    /** @maxLength 1024 */
    model: string;
    /** @maxLength 1024 */
    appBundleId: string;
    /** @maxLength 1024 */
    appVersion: string;
  };
  data: {
    /** @maxLength 1024 */
    code: string;
    rssi: number;
  }[][];
  beaconCodes?: string[];
  enabled?: boolean;
  description?: string;
}

export interface FingerPrintCustomerDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
}

export interface FingerprintOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  zone: {
    /** @format uuid */
    _id: string;
    _ref: "Zone";
  };
  deviceInfo: {
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    osName: string;
    /** @maxLength 1024 */
    osVersion: string;
    /** @maxLength 1024 */
    brand: string;
    /** @maxLength 1024 */
    model: string;
    /** @maxLength 1024 */
    appBundleId: string;
    /** @maxLength 1024 */
    appVersion: string;
  };
  /**
   * @maxLength 1024
   * @default null
   */
  description?: string | null;
  data: {
    /** @maxLength 1024 */
    code: string;
    rssi: number;
  }[][];
  beaconCodes?: string[];
  user: {
    /** @format uuid */
    _id: string;
    _ref: "User";
  };
  dataCount: number;
  enabled: boolean;
}

export interface FingerprintUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  zone?: {
    /** @format uuid */
    _id: string;
    _ref: "Zone";
  };
  deviceInfo?: {
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    osName: string;
    /** @maxLength 1024 */
    osVersion: string;
    /** @maxLength 1024 */
    brand: string;
    /** @maxLength 1024 */
    model: string;
    /** @maxLength 1024 */
    appBundleId: string;
    /** @maxLength 1024 */
    appVersion: string;
  };
  /**
   * @maxLength 1024
   * @default null
   */
  description?: string | null;
  beaconCodes?: string[];
  user?: {
    /** @format uuid */
    _id: string;
    _ref: "User";
  };
  enabled?: boolean;
}

export interface GetContextResponse {
  user: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    /** @maxLength 1024 */
    username: string;
    /** @maxLength 1024 */
    name: string;
    properties: {
      /** @maxLength 1024 */
      phoneNumber?: string | null;
      /** @maxLength 1024 */
      smsNumber?: string | null;
      /** @maxLength 1024 */
      email?: string | null;
      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
      locale?: string | null;
      /** @maxLength 1024 */
      image?: string | null;
      rSim?: {
        /** @maxLength 1024 */
        euicc: string;
        primary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        secondary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        enabled: boolean;
      } | null;
    };
    /** @default [] */
    globalRoles?: string[];
    /** @default [] */
    specialPermissions?: string[];
    isServiceAccount?: boolean;
    /** @format date-time */
    lastActiveAt?: string;
    type?: "default" | "serviceAccount" | "kiosk";
    userPolicies?: {
      disablePasswordlessLogin?: boolean;
    };
  };
  asset: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    customer: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    };
    /** @default {"type":"parents","extra":[]} */
    share?: {
      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
      extra?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      }[];
    };
    user: {
      /** @format uuid */
      _id: string;
      _ref: "User";
    } | null;
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    externalId?: string | null;
    propertiesOwnership?: "override" | "inherit";
    /** @default {} */
    properties?: {
      /** @maxLength 1024 */
      phoneNumber?: string | null;
      /** @maxLength 1024 */
      smsNumber?: string | null;
      /** @maxLength 1024 */
      email?: string | null;
      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
      locale?: string | null;
      /** @maxLength 1024 */
      image?: string | null;
      rSim?: {
        /** @maxLength 1024 */
        euicc: string;
        primary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        secondary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        enabled: boolean;
      } | null;
    };
    /** @default null */
    position?: {
      type: "Point";
      coordinates: number[];
      properties: {
        accuracy?: number;
        heading?: number;
        headingAccuracy?: number;
        speed?: number;
        speedAccuracy?: number;
        altitude?: number;
        altitudeAccuracy?: number;
        address?: {
          /** @maxLength 1024 */
          countryCode?: string | null;
          /** @maxLength 1024 */
          state?: string | null;
          /** @maxLength 1024 */
          city?: string | null;
          /** @maxLength 1024 */
          streetName?: string | null;
          /** @maxLength 1024 */
          streetNumber?: string | null;
          floor?: number | null;
          /** @maxLength 1024 */
          formattedAddress?: string;
        };
        /** @format date-time */
        dateTime?: string;
        /** @maxLength 1024 */
        provider?: string;
      };
    };
    available: boolean;
    /** @default true */
    allowContactOnOwnAlarms?: boolean;
    app?: {
      mode: "off" | "on" | "risk";
    } | null;
    insideZones: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    }[];
    assetGroups: {
      /** @format uuid */
      _id: string;
      _ref: "AssetGroup";
    }[];
    /** @default [] */
    staticResponders?: {
      staticResponder: {
        /** @format uuid */
        _id: string;
        _ref: "StaticResponder";
      };
      priority: number;
    }[];
    /** @format date-time */
    lastObservationAt?: string;
    /** @default false */
    invisibleResponder?: boolean;
    /** @maxLength 4096 */
    description?: string;
    type?: "default" | "kiosk";
  };
  device: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    customer: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    };
    /** @default {"type":"parents","extra":[]} */
    share?: {
      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
      extra?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      }[];
    };
    asset: {
      /** @format uuid */
      _id: string;
      _ref: "Asset";
    } | null;
    /** @maxLength 1024 */
    name?: string;
    /** @maxLength 1024 */
    deviceId: string;
    type: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
    loraConfig?:
      | {
          type: "adeunis.arf8123aa";
          config: {
            /** @maxLength 1024 */
            fieldTest: string;
          };
        }
      | {
          type: "iotracker.v2";
          config: {
            /** @maxLength 1024 */
            beaconScan: string;
            /** @maxLength 1024 */
            gps: string;
            /** @maxLength 1024 */
            preMandown: string;
            /** @maxLength 1024 */
            mandown: string;
            /** @maxLength 1024 */
            movement: string;
            /** @maxLength 1024 */
            alarmSeverity: string;
            /** @maxLength 1024 */
            alive: string;
          };
        }
      | {
          type: "milesight.ws101";
          config: {
            /** @maxLength 1024 */
            beaconScan: string;
            /** @maxLength 1024 */
            alarmSeverity: string;
            /** @maxLength 1024 */
            alive: string;
          };
        }
      | {
          type: "sensative.1301001";
          config: {
            /** @maxLength 1024 */
            alarmSeverity: string;
            /** @maxLength 1024 */
            alive: string;
            /** @maxLength 1024 */
            floodDetected: string;
            /** @maxLength 1024 */
            noFloodDetected: string;
          };
        }
      | null;
    kioskConfig?: {
      /** @default false */
      managed?: boolean;
      zone?: {
        /** @format uuid */
        _id: string;
        _ref: "Zone";
      } | null;
      exposeAssets?:
        | {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[]
        | null;
      exposeLabels?:
        | {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
            countLabel?: boolean;
          }[]
        | null;
      schedule?: {
        entries: {
          daysOfWeek: number[];
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeStart: string;
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeEnd: string;
        }[];
      } | null;
      homepage?: {
        incidentCounter?: {
          /** @default true */
          enabled?: boolean;
          /** @format date-time */
          countFromDate?: string | null;
          /**
           * @min 0
           * @exclusiveMin false
           * @default 0
           */
          threshold?: number;
        };
        /** @default true */
        anonymous?: boolean;
        /** @format uri */
        logo?: string | null;
      };
      alarm?: {
        /** @default true */
        enabled?: boolean;
        focus?: {
          red?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
          orange?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
          green?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
        };
        /** @default true */
        showPositionLabels?: boolean;
        /** @default true */
        anonymous?: boolean;
        acknowledge?: {
          enabled?: boolean;
          /** @pattern ^[0-9]{4,6}$ */
          pin?: string | null;
          messagePresets?: string[] | null;
        };
        mode?: "zoneBased" | "customerBased" | null;
      };
      manageAvailability?: {
        /** @default true */
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        /** @default true */
        showAppBanner?: boolean;
        /** @default false */
        forceShowSearch?: boolean;
        /** @default false */
        forceShowLabelFilter?: boolean;
      };
      hardware?: {
        /**
         * @min 0
         * @exclusiveMin false
         * @max 100
         * @exclusiveMax false
         */
        screenBrightness?: number | null;
      };
    };
    battery?: number;
    rssi?: number;
    snr?: number;
    clientConfig?: {
      app?: (
        | {
            type: "key";
            key: string;
            value: any;
            editable?: boolean;
          }
        | {
            type: "group";
            group: {
              /** @format uuid */
              _id: string;
              _ref: "ClientConfigGroup";
            };
          }
      )[];
      kiosk?: (
        | {
            type: "key";
            key: string;
            value: any;
            editable?: boolean;
          }
        | {
            type: "group";
            group: {
              /** @format uuid */
              _id: string;
              _ref: "ClientConfigGroup";
            };
          }
      )[];
    };
  }[];
  customerUserRelations: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    /** @maxLength 1024 */
    migrationKey?: string;
    user: {
      /** @format uuid */
      _id: string;
      _ref: "User";
    };
    customer: {
      /** @format uuid */
      _id: string | null;
      name?: string;
      isAlarmCenter?: boolean;
      isResponsePartner?: boolean;
      children: {
        /** @format uuid */
        _id: string | null;
        name?: string;
        isAlarmCenter?: boolean;
        isResponsePartner?: boolean;
        children: any;
      }[];
    };
    roles: string[];
    assetGroupRestrictions: (
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
          /** @format date-time */
          createdAt: string;
          /** @format date-time */
          updatedAt: string;
          telluId?: number | null;
          migrationFinalised?: boolean | null;
          /** @format date-time */
          migrationFinalisedDate?: string | null;
          customer: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          /** @default {"type":"parents","extra":[]} */
          share?: {
            type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
            extra?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
          };
          /** @maxLength 1024 */
          name: string;
          /** @maxLength 1024 */
          description?: string | null;
          assets: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          clientConfig?: {
            app?: (
              | {
                  type: "key";
                  key: string;
                  value: any;
                  editable?: boolean;
                }
              | {
                  type: "group";
                  group: {
                    /** @format uuid */
                    _id: string;
                    _ref: "ClientConfigGroup";
                  };
                }
            )[];
            kiosk?: (
              | {
                  type: "key";
                  key: string;
                  value: any;
                  editable?: boolean;
                }
              | {
                  type: "group";
                  group: {
                    /** @format uuid */
                    _id: string;
                    _ref: "ClientConfigGroup";
                  };
                }
            )[];
          };
        }
    )[];
  }[];
  customerTree: {
    /** @format uuid */
    _id: string | null;
    name?: string;
    isAlarmCenter?: boolean;
    isResponsePartner?: boolean;
    children: any;
  }[];
  customerTreeFlat: {
    /** @format uuid */
    _id: string | null;
    name?: string;
    isAlarmCenter?: boolean;
    isResponsePartner?: boolean;
    children: any;
  }[];
  customerTreeById: Record<
    string,
    {
      /** @format uuid */
      _id: string | null;
      name?: string;
      isAlarmCenter?: boolean;
      isResponsePartner?: boolean;
      children: any;
    }
  >;
  permissions: {
    action: string | string[];
    subject?: string | string[];
    conditions: any;
  }[];
}

export interface ImageUploadDto {
  image: string | null;
}

export interface IntersectsPointDto {
  position: {
    type: "Point";
    coordinates: number[];
  };
  forceCustomer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
}

export interface KioskMoveCustomerDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
}

export interface KioskUserCreateDto {
  user: {
    /** @maxLength 1024 */
    username?: string;
    properties: {
      /** @maxLength 1024 */
      phoneNumber?: string | null;
      /** @maxLength 1024 */
      smsNumber?: string | null;
      /** @maxLength 1024 */
      email?: string | null;
      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
      locale?: string | null;
      /** @maxLength 1024 */
      image?: string | null;
      rSim?: {
        /** @maxLength 1024 */
        euicc: string;
        primary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        secondary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        enabled: boolean;
      } | null;
    };
  };
  device: {
    kioskConfig?: {
      /** @default false */
      managed?: boolean;
      zone?: {
        /** @format uuid */
        _id: string;
        _ref: "Zone";
      } | null;
      exposeAssets?:
        | {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[]
        | null;
      exposeLabels?:
        | {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
            countLabel?: boolean;
          }[]
        | null;
      schedule?: {
        entries: {
          daysOfWeek: number[];
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeStart: string;
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeEnd: string;
        }[];
      } | null;
      homepage?: {
        incidentCounter?: {
          /** @default true */
          enabled?: boolean;
          /** @format date-time */
          countFromDate?: string | null;
          /**
           * @min 0
           * @exclusiveMin false
           * @default 0
           */
          threshold?: number;
        };
        /** @default true */
        anonymous?: boolean;
        /** @format uri */
        logo?: string | null;
      };
      alarm?: {
        /** @default true */
        enabled?: boolean;
        focus?: {
          red?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
          orange?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
          green?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
        };
        /** @default true */
        showPositionLabels?: boolean;
        /** @default true */
        anonymous?: boolean;
        acknowledge?: {
          enabled?: boolean;
          /** @pattern ^[0-9]{4,6}$ */
          pin?: string | null;
          messagePresets?: string[] | null;
        };
        mode?: "zoneBased" | "customerBased" | null;
      };
      manageAvailability?: {
        /** @default true */
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        /** @default true */
        showAppBanner?: boolean;
        /** @default false */
        forceShowSearch?: boolean;
        /** @default false */
        forceShowLabelFilter?: boolean;
      };
      hardware?: {
        /**
         * @min 0
         * @exclusiveMin false
         * @max 100
         * @exclusiveMax false
         */
        screenBrightness?: number | null;
      };
    };
  };
  asset: {
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    externalId?: string | null;
    /** @default null */
    position?: {
      type: "Point";
      coordinates: number[];
      properties: {
        accuracy?: number;
        heading?: number;
        headingAccuracy?: number;
        speed?: number;
        speedAccuracy?: number;
        altitude?: number;
        altitudeAccuracy?: number;
        address?: {
          /** @maxLength 1024 */
          countryCode?: string | null;
          /** @maxLength 1024 */
          state?: string | null;
          /** @maxLength 1024 */
          city?: string | null;
          /** @maxLength 1024 */
          streetName?: string | null;
          /** @maxLength 1024 */
          streetNumber?: string | null;
          floor?: number | null;
          /** @maxLength 1024 */
          formattedAddress?: string;
        };
        /** @format date-time */
        dateTime?: string;
        /** @maxLength 1024 */
        provider?: string;
      };
    };
    /** @default {} */
    properties?: {
      /** @maxLength 1024 */
      phoneNumber?: string | null;
      /** @maxLength 1024 */
      smsNumber?: string | null;
      /** @maxLength 1024 */
      email?: string | null;
      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
      locale?: string | null;
      /** @maxLength 1024 */
      image?: string | null;
      rSim?: {
        /** @maxLength 1024 */
        euicc: string;
        primary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        secondary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        enabled: boolean;
      } | null;
    };
  };
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  password?: string;
}

export interface KioskUserObserveAsset {
  asset: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  };
  available: boolean;
}

export interface KioskUserOutputDto {
  _id: string;
  user: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    /** @maxLength 1024 */
    username: string;
    /** @maxLength 1024 */
    name: string;
    properties: {
      /** @maxLength 1024 */
      phoneNumber?: string | null;
      /** @maxLength 1024 */
      smsNumber?: string | null;
      /** @maxLength 1024 */
      email?: string | null;
      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
      locale?: string | null;
      /** @maxLength 1024 */
      image?: string | null;
      rSim?: {
        /** @maxLength 1024 */
        euicc: string;
        primary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        secondary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        enabled: boolean;
      } | null;
    };
    /** @default [] */
    globalRoles?: string[];
    /** @default [] */
    specialPermissions?: string[];
    isServiceAccount?: boolean;
    /** @format date-time */
    lastActiveAt?: string;
    type?: "default" | "serviceAccount" | "kiosk";
    userPolicies?: {
      disablePasswordlessLogin?: boolean;
    };
  };
  device: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    customer: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    };
    /** @default {"type":"parents","extra":[]} */
    share?: {
      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
      extra?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      }[];
    };
    asset: {
      /** @format uuid */
      _id: string;
      _ref: "Asset";
    } | null;
    /** @maxLength 1024 */
    name?: string;
    /** @maxLength 1024 */
    deviceId: string;
    type: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
    loraConfig?:
      | {
          type: "adeunis.arf8123aa";
          config: {
            /** @maxLength 1024 */
            fieldTest: string;
          };
        }
      | {
          type: "iotracker.v2";
          config: {
            /** @maxLength 1024 */
            beaconScan: string;
            /** @maxLength 1024 */
            gps: string;
            /** @maxLength 1024 */
            preMandown: string;
            /** @maxLength 1024 */
            mandown: string;
            /** @maxLength 1024 */
            movement: string;
            /** @maxLength 1024 */
            alarmSeverity: string;
            /** @maxLength 1024 */
            alive: string;
          };
        }
      | {
          type: "milesight.ws101";
          config: {
            /** @maxLength 1024 */
            beaconScan: string;
            /** @maxLength 1024 */
            alarmSeverity: string;
            /** @maxLength 1024 */
            alive: string;
          };
        }
      | {
          type: "sensative.1301001";
          config: {
            /** @maxLength 1024 */
            alarmSeverity: string;
            /** @maxLength 1024 */
            alive: string;
            /** @maxLength 1024 */
            floodDetected: string;
            /** @maxLength 1024 */
            noFloodDetected: string;
          };
        }
      | null;
    kioskConfig?: {
      /** @default false */
      managed?: boolean;
      zone?: {
        /** @format uuid */
        _id: string;
        _ref: "Zone";
      } | null;
      exposeAssets?:
        | {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[]
        | null;
      exposeLabels?:
        | {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
            countLabel?: boolean;
          }[]
        | null;
      schedule?: {
        entries: {
          daysOfWeek: number[];
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeStart: string;
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeEnd: string;
        }[];
      } | null;
      homepage?: {
        incidentCounter?: {
          /** @default true */
          enabled?: boolean;
          /** @format date-time */
          countFromDate?: string | null;
          /**
           * @min 0
           * @exclusiveMin false
           * @default 0
           */
          threshold?: number;
        };
        /** @default true */
        anonymous?: boolean;
        /** @format uri */
        logo?: string | null;
      };
      alarm?: {
        /** @default true */
        enabled?: boolean;
        focus?: {
          red?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
          orange?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
          green?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
        };
        /** @default true */
        showPositionLabels?: boolean;
        /** @default true */
        anonymous?: boolean;
        acknowledge?: {
          enabled?: boolean;
          /** @pattern ^[0-9]{4,6}$ */
          pin?: string | null;
          messagePresets?: string[] | null;
        };
        mode?: "zoneBased" | "customerBased" | null;
      };
      manageAvailability?: {
        /** @default true */
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        /** @default true */
        showAppBanner?: boolean;
        /** @default false */
        forceShowSearch?: boolean;
        /** @default false */
        forceShowLabelFilter?: boolean;
      };
      hardware?: {
        /**
         * @min 0
         * @exclusiveMin false
         * @max 100
         * @exclusiveMax false
         */
        screenBrightness?: number | null;
      };
    };
    battery?: number;
    rssi?: number;
    snr?: number;
    clientConfig?: {
      app?: (
        | {
            type: "key";
            key: string;
            value: any;
            editable?: boolean;
          }
        | {
            type: "group";
            group: {
              /** @format uuid */
              _id: string;
              _ref: "ClientConfigGroup";
            };
          }
      )[];
      kiosk?: (
        | {
            type: "key";
            key: string;
            value: any;
            editable?: boolean;
          }
        | {
            type: "group";
            group: {
              /** @format uuid */
              _id: string;
              _ref: "ClientConfigGroup";
            };
          }
      )[];
    };
  };
  asset: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    customer: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    };
    /** @default {"type":"parents","extra":[]} */
    share?: {
      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
      extra?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      }[];
    };
    user: {
      /** @format uuid */
      _id: string;
      _ref: "User";
    } | null;
    /** @maxLength 1024 */
    name: string;
    /** @maxLength 1024 */
    externalId?: string | null;
    propertiesOwnership?: "override" | "inherit";
    /** @default {} */
    properties?: {
      /** @maxLength 1024 */
      phoneNumber?: string | null;
      /** @maxLength 1024 */
      smsNumber?: string | null;
      /** @maxLength 1024 */
      email?: string | null;
      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
      locale?: string | null;
      /** @maxLength 1024 */
      image?: string | null;
      rSim?: {
        /** @maxLength 1024 */
        euicc: string;
        primary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        secondary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        enabled: boolean;
      } | null;
    };
    /** @default null */
    position?: {
      type: "Point";
      coordinates: number[];
      properties: {
        accuracy?: number;
        heading?: number;
        headingAccuracy?: number;
        speed?: number;
        speedAccuracy?: number;
        altitude?: number;
        altitudeAccuracy?: number;
        address?: {
          /** @maxLength 1024 */
          countryCode?: string | null;
          /** @maxLength 1024 */
          state?: string | null;
          /** @maxLength 1024 */
          city?: string | null;
          /** @maxLength 1024 */
          streetName?: string | null;
          /** @maxLength 1024 */
          streetNumber?: string | null;
          floor?: number | null;
          /** @maxLength 1024 */
          formattedAddress?: string;
        };
        /** @format date-time */
        dateTime?: string;
        /** @maxLength 1024 */
        provider?: string;
      };
    };
    available: boolean;
    /** @default true */
    allowContactOnOwnAlarms?: boolean;
    app?: {
      mode: "off" | "on" | "risk";
    } | null;
    insideZones: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    }[];
    assetGroups: {
      /** @format uuid */
      _id: string;
      _ref: "AssetGroup";
    }[];
    /** @default [] */
    staticResponders?: {
      staticResponder: {
        /** @format uuid */
        _id: string;
        _ref: "StaticResponder";
      };
      priority: number;
    }[];
    /** @format date-time */
    lastObservationAt?: string;
    /** @default false */
    invisibleResponder?: boolean;
    /** @maxLength 4096 */
    description?: string;
    type?: "default" | "kiosk";
  };
  kioskConfig: {
    /** @default false */
    managed?: boolean;
    zone: {
      /** @format uuid */
      _id: string;
      _ref: "Zone";
    } | null;
    exposeAssets:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[]
      | null;
    exposeLabels:
      | {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
          countLabel?: boolean;
        }[]
      | null;
    schedule: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    } | null;
    homepage: {
      incidentCounter: {
        /** @default true */
        enabled?: boolean;
        /** @format date-time */
        countFromDate: string | null;
        /**
         * @min 0
         * @exclusiveMin false
         * @default 0
         */
        threshold?: number;
      };
      /** @default true */
      anonymous?: boolean;
      /** @format uri */
      logo: string | null;
    };
    alarm: {
      /** @default true */
      enabled?: boolean;
      focus?: {
        red?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        orange?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
        green?: {
          flash?: boolean | null;
          sound?: {
            enabled: boolean;
            /**
             * @min 0
             * @exclusiveMin false
             * @max 100
             * @exclusiveMax false
             */
            volume?: number | null;
            sound?:
              | "alarm"
              | "alarmClock"
              | "alarmRetro"
              | "announcement"
              | "attention"
              | "bassPulse"
              | "beepBeep"
              | "beepBurglar"
              | "beepContinuous"
              | "beepHighSoft"
              | "beepIncremental"
              | "bell"
              | "car"
              | "chickens"
              | "chime"
              | "chirpSoft"
              | "clockMelody"
              | "countdownSonar"
              | "doorbell"
              | "escalate"
              | "fancy"
              | "fastAndNervous"
              | "harp"
              | "incomingPhone"
              | "introduction"
              | "intrusive"
              | "invasion"
              | "jungle"
              | "lofiChords"
              | "loopLow"
              | "melodySoft"
              | "notifySubtle"
              | "onOff"
              | "orchestral"
              | "pling"
              | "plingHigh"
              | "plingLow"
              | "pluckSong"
              | "quick"
              | "scanning"
              | "shake"
              | "silence"
              | "sirenHigh"
              | "sirenLow"
              | "soft"
              | "spaceship"
              | "startup"
              | "threeTones"
              | "tornado"
              | "wailing"
              | "wakeup"
              | null;
            /**
             * @min 0
             * @exclusiveMin false
             */
            stopAfterSeconds?: number | null;
            stopAfterTouch?: boolean | null;
          };
        };
      };
      /** @default true */
      showPositionLabels?: boolean;
      /** @default true */
      anonymous?: boolean;
      acknowledge?: {
        enabled: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin: string | null;
        messagePresets: string[] | null;
      };
      mode?: "zoneBased" | "customerBased" | null;
    };
    manageAvailability: {
      /** @default true */
      enabled?: boolean;
      /** @pattern ^[0-9]{4,6}$ */
      pin: string | null;
      /** @default true */
      showAppBanner?: boolean;
      /** @default false */
      forceShowSearch?: boolean;
      /** @default false */
      forceShowLabelFilter?: boolean;
    };
    hardware: {
      /**
       * @min 0
       * @exclusiveMin false
       * @max 100
       * @exclusiveMax false
       */
      screenBrightness: number | null;
    };
  };
  password?: string;
}

export interface KioskUserSafetyScoreboardOutputDto {
  /** @format date-time */
  lastCreatedAt: string | null;
}

export interface KioskUserUpdateDto {
  user: {
    properties: {
      /** @maxLength 1024 */
      phoneNumber?: string | null;
      /** @maxLength 1024 */
      smsNumber?: string | null;
      /** @maxLength 1024 */
      email?: string | null;
      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
      locale?: string | null;
      /** @maxLength 1024 */
      image?: string | null;
      rSim?: {
        /** @maxLength 1024 */
        euicc: string;
        primary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        secondary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        enabled: boolean;
      } | null;
    };
  };
  device: {
    kioskConfig?: {
      /** @default false */
      managed?: boolean;
      zone?: {
        /** @format uuid */
        _id: string;
        _ref: "Zone";
      } | null;
      exposeAssets?:
        | {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[]
        | null;
      exposeLabels?:
        | {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
            countLabel?: boolean;
          }[]
        | null;
      schedule?: {
        entries: {
          daysOfWeek: number[];
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeStart: string;
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeEnd: string;
        }[];
      } | null;
      homepage?: {
        incidentCounter?: {
          /** @default true */
          enabled?: boolean;
          /** @format date-time */
          countFromDate?: string | null;
          /**
           * @min 0
           * @exclusiveMin false
           * @default 0
           */
          threshold?: number;
        };
        /** @default true */
        anonymous?: boolean;
        /** @format uri */
        logo?: string | null;
      };
      alarm?: {
        /** @default true */
        enabled?: boolean;
        focus?: {
          red?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
          orange?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
          green?: {
            flash?: boolean | null;
            sound?: {
              enabled: boolean;
              /**
               * @min 0
               * @exclusiveMin false
               * @max 100
               * @exclusiveMax false
               */
              volume?: number | null;
              sound?:
                | "alarm"
                | "alarmClock"
                | "alarmRetro"
                | "announcement"
                | "attention"
                | "bassPulse"
                | "beepBeep"
                | "beepBurglar"
                | "beepContinuous"
                | "beepHighSoft"
                | "beepIncremental"
                | "bell"
                | "car"
                | "chickens"
                | "chime"
                | "chirpSoft"
                | "clockMelody"
                | "countdownSonar"
                | "doorbell"
                | "escalate"
                | "fancy"
                | "fastAndNervous"
                | "harp"
                | "incomingPhone"
                | "introduction"
                | "intrusive"
                | "invasion"
                | "jungle"
                | "lofiChords"
                | "loopLow"
                | "melodySoft"
                | "notifySubtle"
                | "onOff"
                | "orchestral"
                | "pling"
                | "plingHigh"
                | "plingLow"
                | "pluckSong"
                | "quick"
                | "scanning"
                | "shake"
                | "silence"
                | "sirenHigh"
                | "sirenLow"
                | "soft"
                | "spaceship"
                | "startup"
                | "threeTones"
                | "tornado"
                | "wailing"
                | "wakeup"
                | null;
              /**
               * @min 0
               * @exclusiveMin false
               */
              stopAfterSeconds?: number | null;
              stopAfterTouch?: boolean | null;
            };
          };
        };
        /** @default true */
        showPositionLabels?: boolean;
        /** @default true */
        anonymous?: boolean;
        acknowledge?: {
          enabled?: boolean;
          /** @pattern ^[0-9]{4,6}$ */
          pin?: string | null;
          messagePresets?: string[] | null;
        };
        mode?: "zoneBased" | "customerBased" | null;
      };
      manageAvailability?: {
        /** @default true */
        enabled?: boolean;
        /** @pattern ^[0-9]{4,6}$ */
        pin?: string | null;
        /** @default true */
        showAppBanner?: boolean;
        /** @default false */
        forceShowSearch?: boolean;
        /** @default false */
        forceShowLabelFilter?: boolean;
      };
      hardware?: {
        /**
         * @min 0
         * @exclusiveMin false
         * @max 100
         * @exclusiveMax false
         */
        screenBrightness?: number | null;
      };
    };
  };
  asset: {
    /** @maxLength 1024 */
    externalId?: string | null;
    /** @maxLength 1024 */
    name: string;
    /** @default null */
    position?: {
      type: "Point";
      coordinates: number[];
      properties: {
        accuracy?: number;
        heading?: number;
        headingAccuracy?: number;
        speed?: number;
        speedAccuracy?: number;
        altitude?: number;
        altitudeAccuracy?: number;
        address?: {
          /** @maxLength 1024 */
          countryCode?: string | null;
          /** @maxLength 1024 */
          state?: string | null;
          /** @maxLength 1024 */
          city?: string | null;
          /** @maxLength 1024 */
          streetName?: string | null;
          /** @maxLength 1024 */
          streetNumber?: string | null;
          floor?: number | null;
          /** @maxLength 1024 */
          formattedAddress?: string;
        };
        /** @format date-time */
        dateTime?: string;
        /** @maxLength 1024 */
        provider?: string;
      };
    };
  };
}

export interface LastObservationDto {
  secret: string;
  /** Timeframe in which to search for new observations */
  timeframe: number;
  /** Number of assets which have sent a new observation */
  numberOfAssetsWithNewObservation: number;
}

export interface ObservationTriggerCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  eventCodes: string[];
  /** @maxLength 1024 */
  description: string;
  status: "active" | "inactive";
  actions: (
    | {
        type: "alarm";
        config: {
          severity: "red" | "orange" | "green";
          type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
          /** @maxLength 4096 */
          procedure: string | null;
          /** @maxLength 1024 */
          name: string;
        };
      }
    | {
        type: "sms";
        config: {
          toRaw: string[];
          toContactList: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
          toTriggerUser: boolean;
          /** @maxLength 1024 */
          message: string;
        };
      }
    | {
        type: "email";
        config: {
          toRaw: string[];
          toContactList: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
          toTriggerUser: boolean;
          /** @maxLength 1024 */
          message: string;
          /** @maxLength 1024 */
          subject: string;
          /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
          locale?: string;
        };
      }
  )[];
  filter:
    | {
        type: "operator/and";
        operands: (
          | {
              type: "operator/or";
              operands: (
                | {
                    type: "operator/not";
                    operand:
                      | {
                          type: "constraint/asset";
                          assets: {
                            /** @format uuid */
                            _id: string;
                            _ref: "Asset";
                          }[];
                        }
                      | {
                          type: "constraint/assetGroup";
                          groups: {
                            /** @format uuid */
                            _id: string;
                            _ref: "AssetGroup";
                          }[];
                        }
                      | {
                          type: "constraint/zone";
                          zone: {
                            /** @format uuid */
                            _id: string;
                            _ref: "Zone";
                          };
                        }
                      | {
                          type: "constraint/schedule";
                          schedule: {
                            entries: {
                              daysOfWeek: number[];
                              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                              timeStart: string;
                              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                              timeEnd: string;
                            }[];
                          };
                        };
                  }
                | {
                    type: "constraint/asset";
                    assets: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    }[];
                  }
                | {
                    type: "constraint/assetGroup";
                    groups: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                  }
                | {
                    type: "constraint/zone";
                    zone: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    };
                  }
                | {
                    type: "constraint/schedule";
                    schedule: {
                      entries: {
                        daysOfWeek: number[];
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeStart: string;
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeEnd: string;
                      }[];
                    };
                  }
              )[];
            }
          | {
              type: "constraint/asset";
              assets: {
                /** @format uuid */
                _id: string;
                _ref: "Asset";
              }[];
            }
          | {
              type: "constraint/assetGroup";
              groups: {
                /** @format uuid */
                _id: string;
                _ref: "AssetGroup";
              }[];
            }
          | {
              type: "constraint/zone";
              zone: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              };
            }
          | {
              type: "constraint/schedule";
              schedule: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              };
            }
        )[];
      }
    | {
        type: "constraint/asset";
        assets: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
      }
    | {
        type: "constraint/assetGroup";
        groups: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
      }
    | {
        type: "constraint/zone";
        zone: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        };
      }
    | {
        type: "constraint/schedule";
        schedule: {
          entries: {
            daysOfWeek: number[];
            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
            timeStart: string;
            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
            timeEnd: string;
          }[];
        };
      };
}

export interface ObservationTriggerOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  eventCodes: string[];
  /** @maxLength 1024 */
  description: string;
  status: "active" | "inactive";
  actions: (
    | {
        type: "alarm";
        config: {
          severity: "red" | "orange" | "green";
          type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
          /** @maxLength 4096 */
          procedure: string | null;
          /** @maxLength 1024 */
          name: string;
        };
      }
    | {
        type: "sms";
        config: {
          toRaw: string[];
          toContactList: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
          toTriggerUser: boolean;
          /** @maxLength 1024 */
          message: string;
        };
      }
    | {
        type: "email";
        config: {
          toRaw: string[];
          toContactList: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
          toTriggerUser: boolean;
          /** @maxLength 1024 */
          message: string;
          /** @maxLength 1024 */
          subject: string;
          /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
          locale?: string;
        };
      }
  )[];
  filter:
    | {
        type: "operator/and";
        operands: (
          | {
              type: "operator/or";
              operands: (
                | {
                    type: "operator/not";
                    operand:
                      | {
                          type: "constraint/asset";
                          assets: {
                            /** @format uuid */
                            _id: string;
                            _ref: "Asset";
                          }[];
                        }
                      | {
                          type: "constraint/assetGroup";
                          groups: {
                            /** @format uuid */
                            _id: string;
                            _ref: "AssetGroup";
                          }[];
                        }
                      | {
                          type: "constraint/zone";
                          zone: {
                            /** @format uuid */
                            _id: string;
                            _ref: "Zone";
                          };
                        }
                      | {
                          type: "constraint/schedule";
                          schedule: {
                            entries: {
                              daysOfWeek: number[];
                              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                              timeStart: string;
                              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                              timeEnd: string;
                            }[];
                          };
                        };
                  }
                | {
                    type: "constraint/asset";
                    assets: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    }[];
                  }
                | {
                    type: "constraint/assetGroup";
                    groups: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                  }
                | {
                    type: "constraint/zone";
                    zone: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    };
                  }
                | {
                    type: "constraint/schedule";
                    schedule: {
                      entries: {
                        daysOfWeek: number[];
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeStart: string;
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeEnd: string;
                      }[];
                    };
                  }
              )[];
            }
          | {
              type: "constraint/asset";
              assets: {
                /** @format uuid */
                _id: string;
                _ref: "Asset";
              }[];
            }
          | {
              type: "constraint/assetGroup";
              groups: {
                /** @format uuid */
                _id: string;
                _ref: "AssetGroup";
              }[];
            }
          | {
              type: "constraint/zone";
              zone: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              };
            }
          | {
              type: "constraint/schedule";
              schedule: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              };
            }
        )[];
      }
    | {
        type: "constraint/asset";
        assets: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
      }
    | {
        type: "constraint/assetGroup";
        groups: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
      }
    | {
        type: "constraint/zone";
        zone: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        };
      }
    | {
        type: "constraint/schedule";
        schedule: {
          entries: {
            daysOfWeek: number[];
            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
            timeStart: string;
            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
            timeEnd: string;
          }[];
        };
      };
}

export interface ObservationTriggerUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name?: string;
  eventCodes?: string[];
  /** @maxLength 1024 */
  description?: string;
  status?: "active" | "inactive";
  actions?: (
    | {
        type: "alarm";
        config: {
          severity: "red" | "orange" | "green";
          type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
          /** @maxLength 4096 */
          procedure: string | null;
          /** @maxLength 1024 */
          name: string;
        };
      }
    | {
        type: "sms";
        config: {
          toRaw: string[];
          toContactList: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
          toTriggerUser: boolean;
          /** @maxLength 1024 */
          message: string;
        };
      }
    | {
        type: "email";
        config: {
          toRaw: string[];
          toContactList: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
          toTriggerUser: boolean;
          /** @maxLength 1024 */
          message: string;
          /** @maxLength 1024 */
          subject: string;
          /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
          locale?: string;
        };
      }
  )[];
  filter?:
    | {
        type: "operator/and";
        operands: (
          | {
              type: "operator/or";
              operands: (
                | {
                    type: "operator/not";
                    operand:
                      | {
                          type: "constraint/asset";
                          assets: {
                            /** @format uuid */
                            _id: string;
                            _ref: "Asset";
                          }[];
                        }
                      | {
                          type: "constraint/assetGroup";
                          groups: {
                            /** @format uuid */
                            _id: string;
                            _ref: "AssetGroup";
                          }[];
                        }
                      | {
                          type: "constraint/zone";
                          zone: {
                            /** @format uuid */
                            _id: string;
                            _ref: "Zone";
                          };
                        }
                      | {
                          type: "constraint/schedule";
                          schedule: {
                            entries: {
                              daysOfWeek: number[];
                              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                              timeStart: string;
                              /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                              timeEnd: string;
                            }[];
                          };
                        };
                  }
                | {
                    type: "constraint/asset";
                    assets: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    }[];
                  }
                | {
                    type: "constraint/assetGroup";
                    groups: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                  }
                | {
                    type: "constraint/zone";
                    zone: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    };
                  }
                | {
                    type: "constraint/schedule";
                    schedule: {
                      entries: {
                        daysOfWeek: number[];
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeStart: string;
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeEnd: string;
                      }[];
                    };
                  }
              )[];
            }
          | {
              type: "constraint/asset";
              assets: {
                /** @format uuid */
                _id: string;
                _ref: "Asset";
              }[];
            }
          | {
              type: "constraint/assetGroup";
              groups: {
                /** @format uuid */
                _id: string;
                _ref: "AssetGroup";
              }[];
            }
          | {
              type: "constraint/zone";
              zone: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              };
            }
          | {
              type: "constraint/schedule";
              schedule: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              };
            }
        )[];
      }
    | {
        type: "constraint/asset";
        assets: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
      }
    | {
        type: "constraint/assetGroup";
        groups: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
      }
    | {
        type: "constraint/zone";
        zone: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        };
      }
    | {
        type: "constraint/schedule";
        schedule: {
          entries: {
            daysOfWeek: number[];
            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
            timeStart: string;
            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
            timeEnd: string;
          }[];
        };
      };
}

export interface ObserveBeaconsDto {
  device: {
    _id: string;
    _ref: "Device";
  };
  scans: {
    code: string;
    /** @format date-time */
    dateTime: string;
    battery?: number;
  }[];
}

export interface ObserveBeaconsResponse {
  beacons: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    customer: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    };
    /** @default {"type":"parents","extra":[]} */
    share?: {
      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
      extra?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      }[];
    };
    codes: string[];
    /**
     * @min 0
     * @exclusiveMin false
     */
    rssiAddend: number;
    type: "default" | "tag";
    state: {
      lastSeenBy?: {
        /** @format uuid */
        _id: string;
        _ref: "Asset";
      } | null;
      /** @format date-time */
      lastSeenAt?: string | null;
      battery?: number | null;
    };
    zone: {
      /** @format uuid */
      _id: string;
      /** @format date-time */
      createdAt: string;
      /** @format date-time */
      updatedAt: string;
      telluId?: number | null;
      migrationFinalised?: boolean | null;
      /** @format date-time */
      migrationFinalisedDate?: string | null;
      customer: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      };
      /** @default {"type":"parents","extra":[]} */
      share?: {
        type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
        extra?: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        }[];
      };
      type: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
      location:
        | {
            type: "Polygon";
            coordinates: number[][][];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          }
        | {
            type: "MultiPolygon";
            coordinates: number[][][][];
            properties: {
              accuracy?: number;
              heading?: number;
              headingAccuracy?: number;
              speed?: number;
              speedAccuracy?: number;
              altitude?: number;
              altitudeAccuracy?: number;
              address?: {
                /** @maxLength 1024 */
                countryCode?: string | null;
                /** @maxLength 1024 */
                state?: string | null;
                /** @maxLength 1024 */
                city?: string | null;
                /** @maxLength 1024 */
                streetName?: string | null;
                /** @maxLength 1024 */
                streetNumber?: string | null;
                floor?: number | null;
                /** @maxLength 1024 */
                formattedAddress?: string;
              };
              /** @format date-time */
              dateTime?: string;
              /** @maxLength 1024 */
              provider?: string;
            };
          };
      /** @maxLength 1024 */
      name: string;
      /**
       * @maxLength 1024
       * @default null
       */
      description?: string | null;
      /** @default [] */
      staticResponders?: {
        staticResponder: {
          /** @format uuid */
          _id: string;
          _ref: "StaticResponder";
        };
        priority: number;
      }[];
      /** @default null */
      responsePriority?: number | null;
      assetsInside: {
        /** @format uuid */
        _id: string;
        _ref: "Asset";
      }[];
      syncToAms?: boolean;
    };
    location?:
      | {
          type: "Point";
          coordinates: number[];
          properties: {
            accuracy?: number;
            heading?: number;
            headingAccuracy?: number;
            speed?: number;
            speedAccuracy?: number;
            altitude?: number;
            altitudeAccuracy?: number;
            address?: {
              /** @maxLength 1024 */
              countryCode?: string | null;
              /** @maxLength 1024 */
              state?: string | null;
              /** @maxLength 1024 */
              city?: string | null;
              /** @maxLength 1024 */
              streetName?: string | null;
              /** @maxLength 1024 */
              streetNumber?: string | null;
              floor?: number | null;
              /** @maxLength 1024 */
              formattedAddress?: string;
            };
            /** @format date-time */
            dateTime?: string;
            /** @maxLength 1024 */
            provider?: string;
          };
        }
      | {
          type: "Polygon";
          coordinates: number[][][];
          properties: {
            accuracy?: number;
            heading?: number;
            headingAccuracy?: number;
            speed?: number;
            speedAccuracy?: number;
            altitude?: number;
            altitudeAccuracy?: number;
            address?: {
              /** @maxLength 1024 */
              countryCode?: string | null;
              /** @maxLength 1024 */
              state?: string | null;
              /** @maxLength 1024 */
              city?: string | null;
              /** @maxLength 1024 */
              streetName?: string | null;
              /** @maxLength 1024 */
              streetNumber?: string | null;
              floor?: number | null;
              /** @maxLength 1024 */
              formattedAddress?: string;
            };
            /** @format date-time */
            dateTime?: string;
            /** @maxLength 1024 */
            provider?: string;
          };
        }
      | {
          type: "MultiPolygon";
          coordinates: number[][][][];
          properties: {
            accuracy?: number;
            heading?: number;
            headingAccuracy?: number;
            speed?: number;
            speedAccuracy?: number;
            altitude?: number;
            altitudeAccuracy?: number;
            address?: {
              /** @maxLength 1024 */
              countryCode?: string | null;
              /** @maxLength 1024 */
              state?: string | null;
              /** @maxLength 1024 */
              city?: string | null;
              /** @maxLength 1024 */
              streetName?: string | null;
              /** @maxLength 1024 */
              streetNumber?: string | null;
              floor?: number | null;
              /** @maxLength 1024 */
              formattedAddress?: string;
            };
            /** @format date-time */
            dateTime?: string;
            /** @maxLength 1024 */
            provider?: string;
          };
        };
  }[];
}

export interface OpenAlarmDto {
  secret: string;
  /** Time passed since alarm is created (not acknowledged) */
  timePassed: number;
  /** Customer ids that should not be included */
  customerIds?: string[];
}

export interface OverlayCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @format uri */
  imageUrl: string | null;
  /** @default 0 */
  level?: number;
  points: {
    sw: {
      lat: number;
      lng: number;
    };
    ne: {
      lat: number;
      lng: number;
    };
    nw: {
      lat: number;
      lng: number;
    };
    se: {
      lat: number;
      lng: number;
    };
  };
}

export interface OverlayOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @format uri */
  imageUrl: string | null;
  /** @default 0 */
  level?: number;
  points: {
    sw: {
      lat: number;
      lng: number;
    };
    ne: {
      lat: number;
      lng: number;
    };
    nw: {
      lat: number;
      lng: number;
    };
    se: {
      lat: number;
      lng: number;
    };
  };
}

export interface OverlayUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @format uri */
  imageUrl?: string | null;
  /** @default 0 */
  level?: number;
  points?: {
    sw: {
      lat: number;
      lng: number;
    };
    ne: {
      lat: number;
      lng: number;
    };
    nw: {
      lat: number;
      lng: number;
    };
    se: {
      lat: number;
      lng: number;
    };
  };
}

export interface ReportTriggerAdhocPopulateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  channels: (
    | {
        type: "email";
        address?: string[];
        contactLists?: {
          /** @format uuid */
          _id: string;
          _ref: "ContactList";
        }[];
        getters?: {
          /** @maxLength 1024 */
          path: string;
        }[];
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        config?: {
          /** @maxLength 1024 */
          subjectOverride?: string;
          /** @maxLength 4096 */
          headerOverride?: string;
        };
      }
    | {
        type: "sms";
        numbers: string[];
        contactLists: {
          /** @format uuid */
          _id: string;
          _ref: "ContactList";
        }[];
      }
    | {
        type: "alarm";
        config: {
          severity: "red" | "orange" | "green";
          type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
          /** @maxLength 4096 */
          procedure: string | null;
          /** @maxLength 1024 */
          name: string;
        };
      }
    | {
        type: "observation";
      }
  )[];
}

export interface ReportTriggerChannelResultUpdateDto {
  success: boolean;
  data?: any;
}

export interface ReportTriggerCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  config:
    | {
        type: "alarmCenterStatus";
        exclude?: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        }[];
        timeframe: {
          type: "month" | "week" | "day" | "hour" | "minute";
          value: number;
          floorTo?: "month" | "week" | "day" | "hour" | "minute";
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "alarms";
        excelFormat: "default" | "smc";
        excelColumns?: (
          | "customer"
          | "name"
          | "severity"
          | "type"
          | "asset"
          | "createdAt"
          | "timeOfFirstHelper"
          | "timeUntilFirstHelper"
          | "alarmCenter"
          | "ackedAt"
          | "ackMessage"
          | "position"
          | "assetPosition"
          | "helperCount.true"
          | "helperCount.null"
          | "helperCount.false"
          | "helperName.true"
          | "helperName.null"
          | "helperName.false"
          | "alarmManager"
          | "alarmId"
          | "assetId"
          | "hubspotId"
          | "alarmDate"
          | "customerName"
          | "assetName"
          | "simpleDescription"
          | "contractDeliveryDate"
          | "alarmName"
          | "onHelpProtocol"
          | "policeCalled"
        )[];
        filter?: {
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
        };
        severities?: ("red" | "orange" | "green")[] | null;
        alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[] | null;
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "appUsage";
        mode?: "hourlyUsage" | "license";
        filter?: {
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
        };
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week";
            /**
             * @min 1
             * @max 1
             */
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        inactivityThreshold?:
          | {
              type: "minute";
              value: number;
            }
          | {
              type: "hour";
              value: number;
            }
          | {
              type: "day";
              /**
               * @min 1
               * @exclusiveMin false
               * @max 14
               * @exclusiveMax false
               */
              value: number;
            };
        reduceEmailSize?: boolean;
        theme?: "bhvk" | "default";
      }
    | {
        type: "assetHistory";
        asset: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        };
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        aggregatorOptions: {
          includeRaw: boolean;
          includeSimple: boolean;
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "auditLog";
        subject?: (
          | "Alarm"
          | "AlarmEvent"
          | "AlarmHelper"
          | "AlarmPushSubscription"
          | "AlarmProfessionalResponder"
          | "Asset"
          | "AssetGroup"
          | "AuditLog"
          | "Beacon"
          | "ContactList"
          | "Customer"
          | "CustomerAlarmCenterRelation"
          | "CustomerUserRelation"
          | "Device"
          | "Observation"
          | "ObservationTrigger"
          | "Overlay"
          | "ReportTrigger"
          | "ReportTriggerRun"
          | "Role"
          | "StaticResponder"
          | "Webhook"
          | "User"
          | "Zone"
          | "Fingerprint"
          | "Report"
          | "all"
        )[];
        operation?: string[];
        user?: {
          /** @format uuid */
          _id: string;
          _ref: "User";
        }[];
        reasonKey?: string[];
        ids?: string[];
        pagination: {
          /** @format date-time */
          after: string;
          order: "asc" | "desc";
          max: number;
        };
        exposeUsers?: boolean;
        exposeHistory?: boolean;
      }
    | {
        type: "badReviewOnSurvey";
      }
    | {
        type: "gatewayAlive";
        lastSeenThreshold: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
      }
    | {
        type: "inactivityMonitor";
        lastSeenThreshold: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        appMode?: ("off" | "on" | "risk")[];
        timeout?: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "newAlarm";
        filter?: {
          severities?: ("red" | "orange" | "green")[];
          alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[];
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
        };
        procedure?: string;
        info?: string;
        hideOpenInAppButton?: boolean;
        exposeAlarmCreatorProperties?: ("smsNumber" | "phoneNumber" | "email")[];
        theme?: "bhvk" | "default";
      }
    | {
        type: "surveyInvitation";
      }
    | {
        type: "surveyOverview";
        timeframe: {
          type: "month" | "week" | "day" | "hour" | "minute";
          value: number;
          floorTo?: "month" | "week" | "day" | "hour" | "minute";
        };
      }
    | {
        type: "unhealthyBeacons";
        lastSeenThreshold: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "zoneOverview";
        inactivityThreshold?: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        minOccupancy?: {
          /**
           * @min 0
           * @exclusiveMin false
           * @max 1
           * @exclusiveMax false
           */
          ratioThreshold: number;
          /**
           * @min 0
           * @exclusiveMin false
           */
          assetCount: number;
        };
        zones?: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        }[];
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        schedule?:
          | {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                minOccupancy: number;
              }[];
            }
          | {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            };
        theme?: "bhvk" | "default";
        disableCompressTimeseries?: boolean;
      };
  channelOverrides?: {
    unsubscribedEmails: string[];
  };
  channels: (
    | {
        type: "email";
        address?: string[];
        contactLists?: {
          /** @format uuid */
          _id: string;
          _ref: "ContactList";
        }[];
        getters?: {
          /** @maxLength 1024 */
          path: string;
        }[];
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        config?: {
          /** @maxLength 1024 */
          subjectOverride?: string;
          /** @maxLength 4096 */
          headerOverride?: string;
        };
      }
    | {
        type: "sms";
        numbers: string[];
        contactLists: {
          /** @format uuid */
          _id: string;
          _ref: "ContactList";
        }[];
      }
    | {
        type: "alarm";
        config: {
          severity: "red" | "orange" | "green";
          type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
          /** @maxLength 4096 */
          procedure: string | null;
          /** @maxLength 1024 */
          name: string;
        };
      }
    | {
        type: "observation";
      }
  )[];
  /** @default null */
  scheduleTrigger?: {
    interval: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    };
    cooldown?: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    };
    /** @format date-time */
    nextAt: string | null;
    /** @format date-time */
    lastAt: string | null;
    /** @format date-time */
    lastAttemptedAt: string | null;
  };
  /** @default null */
  eventTrigger?: {
    events: string[];
    executionDelay?: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    };
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    };
  } | null;
  enabled: boolean;
  /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
  locale?: string;
  /** @maxLength 1024 */
  timezone?: string;
}

export interface ReportTriggerOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  config:
    | {
        type: "alarmCenterStatus";
        exclude?: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        }[];
        timeframe: {
          type: "month" | "week" | "day" | "hour" | "minute";
          value: number;
          floorTo?: "month" | "week" | "day" | "hour" | "minute";
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "alarms";
        excelFormat: "default" | "smc";
        excelColumns?: (
          | "customer"
          | "name"
          | "severity"
          | "type"
          | "asset"
          | "createdAt"
          | "timeOfFirstHelper"
          | "timeUntilFirstHelper"
          | "alarmCenter"
          | "ackedAt"
          | "ackMessage"
          | "position"
          | "assetPosition"
          | "helperCount.true"
          | "helperCount.null"
          | "helperCount.false"
          | "helperName.true"
          | "helperName.null"
          | "helperName.false"
          | "alarmManager"
          | "alarmId"
          | "assetId"
          | "hubspotId"
          | "alarmDate"
          | "customerName"
          | "assetName"
          | "simpleDescription"
          | "contractDeliveryDate"
          | "alarmName"
          | "onHelpProtocol"
          | "policeCalled"
        )[];
        filter?: {
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
        };
        severities?: ("red" | "orange" | "green")[] | null;
        alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[] | null;
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "appUsage";
        mode?: "hourlyUsage" | "license";
        filter?: {
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
        };
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week";
            /**
             * @min 1
             * @max 1
             */
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        inactivityThreshold?:
          | {
              type: "minute";
              value: number;
            }
          | {
              type: "hour";
              value: number;
            }
          | {
              type: "day";
              /**
               * @min 1
               * @exclusiveMin false
               * @max 14
               * @exclusiveMax false
               */
              value: number;
            };
        reduceEmailSize?: boolean;
        theme?: "bhvk" | "default";
      }
    | {
        type: "assetHistory";
        asset: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        };
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        aggregatorOptions: {
          includeRaw: boolean;
          includeSimple: boolean;
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "auditLog";
        subject?: (
          | "Alarm"
          | "AlarmEvent"
          | "AlarmHelper"
          | "AlarmPushSubscription"
          | "AlarmProfessionalResponder"
          | "Asset"
          | "AssetGroup"
          | "AuditLog"
          | "Beacon"
          | "ContactList"
          | "Customer"
          | "CustomerAlarmCenterRelation"
          | "CustomerUserRelation"
          | "Device"
          | "Observation"
          | "ObservationTrigger"
          | "Overlay"
          | "ReportTrigger"
          | "ReportTriggerRun"
          | "Role"
          | "StaticResponder"
          | "Webhook"
          | "User"
          | "Zone"
          | "Fingerprint"
          | "Report"
          | "all"
        )[];
        operation?: string[];
        user?: {
          /** @format uuid */
          _id: string;
          _ref: "User";
        }[];
        reasonKey?: string[];
        ids?: string[];
        pagination: {
          /** @format date-time */
          after: string;
          order: "asc" | "desc";
          max: number;
        };
        exposeUsers?: boolean;
        exposeHistory?: boolean;
      }
    | {
        type: "badReviewOnSurvey";
      }
    | {
        type: "gatewayAlive";
        lastSeenThreshold: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
      }
    | {
        type: "inactivityMonitor";
        lastSeenThreshold: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        appMode?: ("off" | "on" | "risk")[];
        timeout?: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "newAlarm";
        filter?: {
          severities?: ("red" | "orange" | "green")[];
          alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[];
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
        };
        procedure?: string;
        info?: string;
        hideOpenInAppButton?: boolean;
        exposeAlarmCreatorProperties?: ("smsNumber" | "phoneNumber" | "email")[];
        theme?: "bhvk" | "default";
      }
    | {
        type: "surveyInvitation";
      }
    | {
        type: "surveyOverview";
        timeframe: {
          type: "month" | "week" | "day" | "hour" | "minute";
          value: number;
          floorTo?: "month" | "week" | "day" | "hour" | "minute";
        };
      }
    | {
        type: "unhealthyBeacons";
        lastSeenThreshold: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "zoneOverview";
        inactivityThreshold?: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        minOccupancy?: {
          /**
           * @min 0
           * @exclusiveMin false
           * @max 1
           * @exclusiveMax false
           */
          ratioThreshold: number;
          /**
           * @min 0
           * @exclusiveMin false
           */
          assetCount: number;
        };
        zones?: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        }[];
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        schedule?:
          | {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                minOccupancy: number;
              }[];
            }
          | {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            };
        theme?: "bhvk" | "default";
        disableCompressTimeseries?: boolean;
      };
  channelOverrides?: {
    unsubscribedEmails: string[];
  };
  channels: (
    | {
        type: "email";
        address?: string[];
        contactLists?: {
          /** @format uuid */
          _id: string;
          _ref: "ContactList";
        }[];
        getters?: {
          /** @maxLength 1024 */
          path: string;
        }[];
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        config?: {
          /** @maxLength 1024 */
          subjectOverride?: string;
          /** @maxLength 4096 */
          headerOverride?: string;
        };
      }
    | {
        type: "sms";
        numbers: string[];
        contactLists: {
          /** @format uuid */
          _id: string;
          _ref: "ContactList";
        }[];
      }
    | {
        type: "alarm";
        config: {
          severity: "red" | "orange" | "green";
          type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
          /** @maxLength 4096 */
          procedure: string | null;
          /** @maxLength 1024 */
          name: string;
        };
      }
    | {
        type: "observation";
      }
  )[];
  /** @default null */
  scheduleTrigger?: {
    interval: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    };
    cooldown?: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    };
    /** @format date-time */
    nextAt: string | null;
    /** @format date-time */
    lastAt: string | null;
    /** @format date-time */
    lastAttemptedAt: string | null;
  };
  /** @default null */
  eventTrigger?: {
    events: string[];
    executionDelay?: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    };
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    };
  } | null;
  enabled: boolean;
  /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
  locale?: string;
  /** @maxLength 1024 */
  timezone?: string;
}

export interface ReportTriggerRunCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  reportTrigger: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    customer: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    } | null;
    /** @default {"type":"parents","extra":[]} */
    share?: {
      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
      extra?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      }[];
    };
    config:
      | {
          type: "alarmCenterStatus";
          exclude?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          }[];
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "alarms";
          excelFormat: "default" | "smc";
          excelColumns?: (
            | "customer"
            | "name"
            | "severity"
            | "type"
            | "asset"
            | "createdAt"
            | "timeOfFirstHelper"
            | "timeUntilFirstHelper"
            | "alarmCenter"
            | "ackedAt"
            | "ackMessage"
            | "position"
            | "assetPosition"
            | "helperCount.true"
            | "helperCount.null"
            | "helperCount.false"
            | "helperName.true"
            | "helperName.null"
            | "helperName.false"
            | "alarmManager"
            | "alarmId"
            | "assetId"
            | "hubspotId"
            | "alarmDate"
            | "customerName"
            | "assetName"
            | "simpleDescription"
            | "contractDeliveryDate"
            | "alarmName"
            | "onHelpProtocol"
            | "policeCalled"
          )[];
          filter?: {
            assets?: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            assetGroups?: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
          };
          severities?: ("red" | "orange" | "green")[] | null;
          alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[] | null;
          timeSeries: {
            granularity: {
              type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
              value: number;
            };
            timeframe: {
              type: "month" | "week" | "day" | "hour" | "minute";
              value: number;
              floorTo?: "month" | "week" | "day" | "hour" | "minute";
            };
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "appUsage";
          mode?: "hourlyUsage" | "license";
          filter?: {
            assets?: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            assetGroups?: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
          };
          timeSeries: {
            granularity: {
              type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
              value: number;
            };
            timeframe: {
              type: "month" | "week";
              /**
               * @min 1
               * @max 1
               */
              value: number;
              floorTo?: "month" | "week" | "day" | "hour" | "minute";
            };
          };
          inactivityThreshold?:
            | {
                type: "minute";
                value: number;
              }
            | {
                type: "hour";
                value: number;
              }
            | {
                type: "day";
                /**
                 * @min 1
                 * @exclusiveMin false
                 * @max 14
                 * @exclusiveMax false
                 */
                value: number;
              };
          reduceEmailSize?: boolean;
          theme?: "bhvk" | "default";
        }
      | {
          type: "assetHistory";
          asset: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          };
          timeSeries: {
            granularity: {
              type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
              value: number;
            };
            timeframe: {
              type: "month" | "week" | "day" | "hour" | "minute";
              value: number;
              floorTo?: "month" | "week" | "day" | "hour" | "minute";
            };
          };
          aggregatorOptions: {
            includeRaw: boolean;
            includeSimple: boolean;
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "auditLog";
          subject?: (
            | "Alarm"
            | "AlarmEvent"
            | "AlarmHelper"
            | "AlarmPushSubscription"
            | "AlarmProfessionalResponder"
            | "Asset"
            | "AssetGroup"
            | "AuditLog"
            | "Beacon"
            | "ContactList"
            | "Customer"
            | "CustomerAlarmCenterRelation"
            | "CustomerUserRelation"
            | "Device"
            | "Observation"
            | "ObservationTrigger"
            | "Overlay"
            | "ReportTrigger"
            | "ReportTriggerRun"
            | "Role"
            | "StaticResponder"
            | "Webhook"
            | "User"
            | "Zone"
            | "Fingerprint"
            | "Report"
            | "all"
          )[];
          operation?: string[];
          user?: {
            /** @format uuid */
            _id: string;
            _ref: "User";
          }[];
          reasonKey?: string[];
          ids?: string[];
          pagination: {
            /** @format date-time */
            after: string;
            order: "asc" | "desc";
            max: number;
          };
          exposeUsers?: boolean;
          exposeHistory?: boolean;
        }
      | {
          type: "badReviewOnSurvey";
        }
      | {
          type: "gatewayAlive";
          lastSeenThreshold: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
        }
      | {
          type: "inactivityMonitor";
          lastSeenThreshold: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
          appMode?: ("off" | "on" | "risk")[];
          timeout?: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "newAlarm";
          filter?: {
            severities?: ("red" | "orange" | "green")[];
            alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[];
            assets?: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            assetGroups?: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
          };
          procedure?: string;
          info?: string;
          hideOpenInAppButton?: boolean;
          exposeAlarmCreatorProperties?: ("smsNumber" | "phoneNumber" | "email")[];
          theme?: "bhvk" | "default";
        }
      | {
          type: "surveyInvitation";
        }
      | {
          type: "surveyOverview";
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        }
      | {
          type: "unhealthyBeacons";
          lastSeenThreshold: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "zoneOverview";
          inactivityThreshold?: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeSeries: {
            granularity: {
              type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
              value: number;
            };
            timeframe: {
              type: "month" | "week" | "day" | "hour" | "minute";
              value: number;
              floorTo?: "month" | "week" | "day" | "hour" | "minute";
            };
          };
          minOccupancy?: {
            /**
             * @min 0
             * @exclusiveMin false
             * @max 1
             * @exclusiveMax false
             */
            ratioThreshold: number;
            /**
             * @min 0
             * @exclusiveMin false
             */
            assetCount: number;
          };
          zones?: {
            /** @format uuid */
            _id: string;
            _ref: "Zone";
          }[];
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
          schedule?:
            | {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                  /**
                   * @min 0
                   * @exclusiveMin false
                   */
                  minOccupancy: number;
                }[];
              }
            | {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              };
          theme?: "bhvk" | "default";
          disableCompressTimeseries?: boolean;
        };
    channelOverrides?: {
      unsubscribedEmails: string[];
    };
    channels: (
      | {
          type: "email";
          address?: string[];
          contactLists?: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
          getters?: {
            /** @maxLength 1024 */
            path: string;
          }[];
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
          config?: {
            /** @maxLength 1024 */
            subjectOverride?: string;
            /** @maxLength 4096 */
            headerOverride?: string;
          };
        }
      | {
          type: "sms";
          numbers: string[];
          contactLists: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
        }
      | {
          type: "alarm";
          config: {
            severity: "red" | "orange" | "green";
            type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
            /** @maxLength 4096 */
            procedure: string | null;
            /** @maxLength 1024 */
            name: string;
          };
        }
      | {
          type: "observation";
        }
    )[];
    /** @default null */
    scheduleTrigger?: {
      interval: {
        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
        value: number;
      };
      cooldown?: {
        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
        value: number;
      };
      /** @format date-time */
      nextAt: string | null;
      /** @format date-time */
      lastAt: string | null;
      /** @format date-time */
      lastAttemptedAt: string | null;
    };
    /** @default null */
    eventTrigger?: {
      events: string[];
      executionDelay?: {
        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
        value: number;
      };
      schedule?: {
        entries: {
          daysOfWeek: number[];
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeStart: string;
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeEnd: string;
        }[];
      };
    } | null;
    enabled: boolean;
    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
    locale?: string;
    /** @maxLength 1024 */
    timezone?: string;
  };
  runReason: "schedule" | "event";
  /** @format date-time */
  generatedAt: string;
  output: Record<string, any>;
  channelResults: {
    success: boolean;
    data?: any;
  }[];
}

export interface ReportTriggerRunOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  reportTrigger: {
    /** @format uuid */
    _id: string;
    /** @format date-time */
    createdAt: string;
    /** @format date-time */
    updatedAt: string;
    telluId?: number | null;
    migrationFinalised?: boolean | null;
    /** @format date-time */
    migrationFinalisedDate?: string | null;
    customer: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    } | null;
    /** @default {"type":"parents","extra":[]} */
    share?: {
      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
      extra?: {
        /** @format uuid */
        _id: string;
        _ref: "Customer";
      }[];
    };
    config:
      | {
          type: "alarmCenterStatus";
          exclude?: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          }[];
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "alarms";
          excelFormat: "default" | "smc";
          excelColumns?: (
            | "customer"
            | "name"
            | "severity"
            | "type"
            | "asset"
            | "createdAt"
            | "timeOfFirstHelper"
            | "timeUntilFirstHelper"
            | "alarmCenter"
            | "ackedAt"
            | "ackMessage"
            | "position"
            | "assetPosition"
            | "helperCount.true"
            | "helperCount.null"
            | "helperCount.false"
            | "helperName.true"
            | "helperName.null"
            | "helperName.false"
            | "alarmManager"
            | "alarmId"
            | "assetId"
            | "hubspotId"
            | "alarmDate"
            | "customerName"
            | "assetName"
            | "simpleDescription"
            | "contractDeliveryDate"
            | "alarmName"
            | "onHelpProtocol"
            | "policeCalled"
          )[];
          filter?: {
            assets?: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            assetGroups?: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
          };
          severities?: ("red" | "orange" | "green")[] | null;
          alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[] | null;
          timeSeries: {
            granularity: {
              type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
              value: number;
            };
            timeframe: {
              type: "month" | "week" | "day" | "hour" | "minute";
              value: number;
              floorTo?: "month" | "week" | "day" | "hour" | "minute";
            };
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "appUsage";
          mode?: "hourlyUsage" | "license";
          filter?: {
            assets?: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            assetGroups?: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
          };
          timeSeries: {
            granularity: {
              type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
              value: number;
            };
            timeframe: {
              type: "month" | "week";
              /**
               * @min 1
               * @max 1
               */
              value: number;
              floorTo?: "month" | "week" | "day" | "hour" | "minute";
            };
          };
          inactivityThreshold?:
            | {
                type: "minute";
                value: number;
              }
            | {
                type: "hour";
                value: number;
              }
            | {
                type: "day";
                /**
                 * @min 1
                 * @exclusiveMin false
                 * @max 14
                 * @exclusiveMax false
                 */
                value: number;
              };
          reduceEmailSize?: boolean;
          theme?: "bhvk" | "default";
        }
      | {
          type: "assetHistory";
          asset: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          };
          timeSeries: {
            granularity: {
              type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
              value: number;
            };
            timeframe: {
              type: "month" | "week" | "day" | "hour" | "minute";
              value: number;
              floorTo?: "month" | "week" | "day" | "hour" | "minute";
            };
          };
          aggregatorOptions: {
            includeRaw: boolean;
            includeSimple: boolean;
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "auditLog";
          subject?: (
            | "Alarm"
            | "AlarmEvent"
            | "AlarmHelper"
            | "AlarmPushSubscription"
            | "AlarmProfessionalResponder"
            | "Asset"
            | "AssetGroup"
            | "AuditLog"
            | "Beacon"
            | "ContactList"
            | "Customer"
            | "CustomerAlarmCenterRelation"
            | "CustomerUserRelation"
            | "Device"
            | "Observation"
            | "ObservationTrigger"
            | "Overlay"
            | "ReportTrigger"
            | "ReportTriggerRun"
            | "Role"
            | "StaticResponder"
            | "Webhook"
            | "User"
            | "Zone"
            | "Fingerprint"
            | "Report"
            | "all"
          )[];
          operation?: string[];
          user?: {
            /** @format uuid */
            _id: string;
            _ref: "User";
          }[];
          reasonKey?: string[];
          ids?: string[];
          pagination: {
            /** @format date-time */
            after: string;
            order: "asc" | "desc";
            max: number;
          };
          exposeUsers?: boolean;
          exposeHistory?: boolean;
        }
      | {
          type: "badReviewOnSurvey";
        }
      | {
          type: "gatewayAlive";
          lastSeenThreshold: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
        }
      | {
          type: "inactivityMonitor";
          lastSeenThreshold: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
          appMode?: ("off" | "on" | "risk")[];
          timeout?: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "newAlarm";
          filter?: {
            severities?: ("red" | "orange" | "green")[];
            alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[];
            assets?: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            assetGroups?: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
          };
          procedure?: string;
          info?: string;
          hideOpenInAppButton?: boolean;
          exposeAlarmCreatorProperties?: ("smsNumber" | "phoneNumber" | "email")[];
          theme?: "bhvk" | "default";
        }
      | {
          type: "surveyInvitation";
        }
      | {
          type: "surveyOverview";
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        }
      | {
          type: "unhealthyBeacons";
          lastSeenThreshold: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          theme?: "bhvk" | "default";
        }
      | {
          type: "zoneOverview";
          inactivityThreshold?: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeSeries: {
            granularity: {
              type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
              value: number;
            };
            timeframe: {
              type: "month" | "week" | "day" | "hour" | "minute";
              value: number;
              floorTo?: "month" | "week" | "day" | "hour" | "minute";
            };
          };
          minOccupancy?: {
            /**
             * @min 0
             * @exclusiveMin false
             * @max 1
             * @exclusiveMax false
             */
            ratioThreshold: number;
            /**
             * @min 0
             * @exclusiveMin false
             */
            assetCount: number;
          };
          zones?: {
            /** @format uuid */
            _id: string;
            _ref: "Zone";
          }[];
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
          schedule?:
            | {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                  /**
                   * @min 0
                   * @exclusiveMin false
                   */
                  minOccupancy: number;
                }[];
              }
            | {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              };
          theme?: "bhvk" | "default";
          disableCompressTimeseries?: boolean;
        };
    channelOverrides?: {
      unsubscribedEmails: string[];
    };
    channels: (
      | {
          type: "email";
          address?: string[];
          contactLists?: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
          getters?: {
            /** @maxLength 1024 */
            path: string;
          }[];
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
          config?: {
            /** @maxLength 1024 */
            subjectOverride?: string;
            /** @maxLength 4096 */
            headerOverride?: string;
          };
        }
      | {
          type: "sms";
          numbers: string[];
          contactLists: {
            /** @format uuid */
            _id: string;
            _ref: "ContactList";
          }[];
        }
      | {
          type: "alarm";
          config: {
            severity: "red" | "orange" | "green";
            type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
            /** @maxLength 4096 */
            procedure: string | null;
            /** @maxLength 1024 */
            name: string;
          };
        }
      | {
          type: "observation";
        }
    )[];
    /** @default null */
    scheduleTrigger?: {
      interval: {
        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
        value: number;
      };
      cooldown?: {
        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
        value: number;
      };
      /** @format date-time */
      nextAt: string | null;
      /** @format date-time */
      lastAt: string | null;
      /** @format date-time */
      lastAttemptedAt: string | null;
    };
    /** @default null */
    eventTrigger?: {
      events: string[];
      executionDelay?: {
        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
        value: number;
      };
      schedule?: {
        entries: {
          daysOfWeek: number[];
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeStart: string;
          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
          timeEnd: string;
        }[];
      };
    } | null;
    enabled: boolean;
    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
    locale?: string;
    /** @maxLength 1024 */
    timezone?: string;
  };
  runReason: "schedule" | "event";
  /** @format date-time */
  generatedAt: string;
  output: Record<string, any>;
  channelResults: {
    success: boolean;
    data?: any;
  }[];
}

export interface ReportTriggerUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  config?:
    | {
        type: "alarmCenterStatus";
        exclude?: {
          /** @format uuid */
          _id: string;
          _ref: "Customer";
        }[];
        timeframe: {
          type: "month" | "week" | "day" | "hour" | "minute";
          value: number;
          floorTo?: "month" | "week" | "day" | "hour" | "minute";
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "alarms";
        excelFormat: "default" | "smc";
        excelColumns?: (
          | "customer"
          | "name"
          | "severity"
          | "type"
          | "asset"
          | "createdAt"
          | "timeOfFirstHelper"
          | "timeUntilFirstHelper"
          | "alarmCenter"
          | "ackedAt"
          | "ackMessage"
          | "position"
          | "assetPosition"
          | "helperCount.true"
          | "helperCount.null"
          | "helperCount.false"
          | "helperName.true"
          | "helperName.null"
          | "helperName.false"
          | "alarmManager"
          | "alarmId"
          | "assetId"
          | "hubspotId"
          | "alarmDate"
          | "customerName"
          | "assetName"
          | "simpleDescription"
          | "contractDeliveryDate"
          | "alarmName"
          | "onHelpProtocol"
          | "policeCalled"
        )[];
        filter?: {
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
        };
        severities?: ("red" | "orange" | "green")[] | null;
        alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[] | null;
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "appUsage";
        mode?: "hourlyUsage" | "license";
        filter?: {
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
        };
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week";
            /**
             * @min 1
             * @max 1
             */
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        inactivityThreshold?:
          | {
              type: "minute";
              value: number;
            }
          | {
              type: "hour";
              value: number;
            }
          | {
              type: "day";
              /**
               * @min 1
               * @exclusiveMin false
               * @max 14
               * @exclusiveMax false
               */
              value: number;
            };
        reduceEmailSize?: boolean;
        theme?: "bhvk" | "default";
      }
    | {
        type: "assetHistory";
        asset: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        };
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        aggregatorOptions: {
          includeRaw: boolean;
          includeSimple: boolean;
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "auditLog";
        subject?: (
          | "Alarm"
          | "AlarmEvent"
          | "AlarmHelper"
          | "AlarmPushSubscription"
          | "AlarmProfessionalResponder"
          | "Asset"
          | "AssetGroup"
          | "AuditLog"
          | "Beacon"
          | "ContactList"
          | "Customer"
          | "CustomerAlarmCenterRelation"
          | "CustomerUserRelation"
          | "Device"
          | "Observation"
          | "ObservationTrigger"
          | "Overlay"
          | "ReportTrigger"
          | "ReportTriggerRun"
          | "Role"
          | "StaticResponder"
          | "Webhook"
          | "User"
          | "Zone"
          | "Fingerprint"
          | "Report"
          | "all"
        )[];
        operation?: string[];
        user?: {
          /** @format uuid */
          _id: string;
          _ref: "User";
        }[];
        reasonKey?: string[];
        ids?: string[];
        pagination: {
          /** @format date-time */
          after: string;
          order: "asc" | "desc";
          max: number;
        };
        exposeUsers?: boolean;
        exposeHistory?: boolean;
      }
    | {
        type: "badReviewOnSurvey";
      }
    | {
        type: "gatewayAlive";
        lastSeenThreshold: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
      }
    | {
        type: "inactivityMonitor";
        lastSeenThreshold: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        appMode?: ("off" | "on" | "risk")[];
        timeout?: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "newAlarm";
        filter?: {
          severities?: ("red" | "orange" | "green")[];
          alarmTypes?: ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[];
          assets?: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          }[];
          assetGroups?: {
            /** @format uuid */
            _id: string;
            _ref: "AssetGroup";
          }[];
        };
        procedure?: string;
        info?: string;
        hideOpenInAppButton?: boolean;
        exposeAlarmCreatorProperties?: ("smsNumber" | "phoneNumber" | "email")[];
        theme?: "bhvk" | "default";
      }
    | {
        type: "surveyInvitation";
      }
    | {
        type: "surveyOverview";
        timeframe: {
          type: "month" | "week" | "day" | "hour" | "minute";
          value: number;
          floorTo?: "month" | "week" | "day" | "hour" | "minute";
        };
      }
    | {
        type: "unhealthyBeacons";
        lastSeenThreshold: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        theme?: "bhvk" | "default";
      }
    | {
        type: "zoneOverview";
        inactivityThreshold?: {
          type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
          value: number;
        };
        timeSeries: {
          granularity: {
            type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
            value: number;
          };
          timeframe: {
            type: "month" | "week" | "day" | "hour" | "minute";
            value: number;
            floorTo?: "month" | "week" | "day" | "hour" | "minute";
          };
        };
        minOccupancy?: {
          /**
           * @min 0
           * @exclusiveMin false
           * @max 1
           * @exclusiveMax false
           */
          ratioThreshold: number;
          /**
           * @min 0
           * @exclusiveMin false
           */
          assetCount: number;
        };
        zones?: {
          /** @format uuid */
          _id: string;
          _ref: "Zone";
        }[];
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        schedule?:
          | {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                minOccupancy: number;
              }[];
            }
          | {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            };
        theme?: "bhvk" | "default";
        disableCompressTimeseries?: boolean;
      };
  channelOverrides?: {
    unsubscribedEmails: string[];
  };
  channels?: (
    | {
        type: "email";
        address?: string[];
        contactLists?: {
          /** @format uuid */
          _id: string;
          _ref: "ContactList";
        }[];
        getters?: {
          /** @maxLength 1024 */
          path: string;
        }[];
        assets?: {
          /** @format uuid */
          _id: string;
          _ref: "Asset";
        }[];
        assetGroups?: {
          /** @format uuid */
          _id: string;
          _ref: "AssetGroup";
        }[];
        config?: {
          /** @maxLength 1024 */
          subjectOverride?: string;
          /** @maxLength 4096 */
          headerOverride?: string;
        };
      }
    | {
        type: "sms";
        numbers: string[];
        contactLists: {
          /** @format uuid */
          _id: string;
          _ref: "ContactList";
        }[];
      }
    | {
        type: "alarm";
        config: {
          severity: "red" | "orange" | "green";
          type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
          /** @maxLength 4096 */
          procedure: string | null;
          /** @maxLength 1024 */
          name: string;
        };
      }
    | {
        type: "observation";
      }
  )[];
  /** @default null */
  scheduleTrigger?: {
    interval: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    };
    cooldown?: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    };
    /** @format date-time */
    nextAt: string | null;
    /** @format date-time */
    lastAt: string | null;
    /** @format date-time */
    lastAttemptedAt: string | null;
  };
  /** @default null */
  eventTrigger?: {
    events: string[];
    executionDelay?: {
      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
      value: number;
    };
    schedule?: {
      entries: {
        daysOfWeek: number[];
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeStart: string;
        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
        timeEnd: string;
      }[];
    };
  } | null;
  enabled?: boolean;
  /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
  locale?: string;
  /** @maxLength 1024 */
  timezone?: string;
}

export interface RoleCreateDto {
  /** @maxLength 1024 */
  name: string;
  permissions: {
    subject:
      | "Alarm"
      | "AlarmEvent"
      | "AlarmHelper"
      | "AlarmPushSubscription"
      | "AlarmProfessionalResponder"
      | "Asset"
      | "AssetGroup"
      | "AuditLog"
      | "Beacon"
      | "ContactList"
      | "Customer"
      | "CustomerAlarmCenterRelation"
      | "CustomerUserRelation"
      | "Device"
      | "Observation"
      | "ObservationTrigger"
      | "Overlay"
      | "ReportTrigger"
      | "ReportTriggerRun"
      | "Role"
      | "StaticResponder"
      | "Webhook"
      | "User"
      | "Zone"
      | "Fingerprint"
      | "Report"
      | "all";
    actions: string[];
    limit?: {
      connectedCustomers?: boolean;
      connectedCustomersViaRelation?: boolean;
      alarmsEscalatedToMyAlarmCenter?: boolean;
      alarmAgeLimitOfCustomer?: boolean;
      ownUserId?: boolean;
      ownAssetId?: boolean;
      mode?: "default" | "asUser";
      fields?: string[];
      allowedRoles?: string[];
    };
  }[];
}

export interface RoleOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @maxLength 1024 */
  name: string;
  permissions: {
    subject:
      | "Alarm"
      | "AlarmEvent"
      | "AlarmHelper"
      | "AlarmPushSubscription"
      | "AlarmProfessionalResponder"
      | "Asset"
      | "AssetGroup"
      | "AuditLog"
      | "Beacon"
      | "ContactList"
      | "Customer"
      | "CustomerAlarmCenterRelation"
      | "CustomerUserRelation"
      | "Device"
      | "Observation"
      | "ObservationTrigger"
      | "Overlay"
      | "ReportTrigger"
      | "ReportTriggerRun"
      | "Role"
      | "StaticResponder"
      | "Webhook"
      | "User"
      | "Zone"
      | "Fingerprint"
      | "Report"
      | "all";
    actions: string[];
    limit?: {
      connectedCustomers?: boolean;
      connectedCustomersViaRelation?: boolean;
      alarmsEscalatedToMyAlarmCenter?: boolean;
      alarmAgeLimitOfCustomer?: boolean;
      ownUserId?: boolean;
      ownAssetId?: boolean;
      mode?: "default" | "asUser";
      fields?: string[];
      allowedRoles?: string[];
    };
  }[];
}

export interface RoleUpdateDto {
  permissions: {
    subject:
      | "Alarm"
      | "AlarmEvent"
      | "AlarmHelper"
      | "AlarmPushSubscription"
      | "AlarmProfessionalResponder"
      | "Asset"
      | "AssetGroup"
      | "AuditLog"
      | "Beacon"
      | "ContactList"
      | "Customer"
      | "CustomerAlarmCenterRelation"
      | "CustomerUserRelation"
      | "Device"
      | "Observation"
      | "ObservationTrigger"
      | "Overlay"
      | "ReportTrigger"
      | "ReportTriggerRun"
      | "Role"
      | "StaticResponder"
      | "Webhook"
      | "User"
      | "Zone"
      | "Fingerprint"
      | "Report"
      | "all";
    actions: string[];
    limit?: {
      connectedCustomers?: boolean;
      connectedCustomersViaRelation?: boolean;
      alarmsEscalatedToMyAlarmCenter?: boolean;
      alarmAgeLimitOfCustomer?: boolean;
      ownUserId?: boolean;
      ownAssetId?: boolean;
      mode?: "default" | "asUser";
      fields?: string[];
      allowedRoles?: string[];
    };
  }[];
}

export interface SequrixCallbackRequestDto {
  SessionID: string;
  State: string;
  ObjectCode: string;
  /** @format date-time */
  Received?: string;
  /** @format date-time */
  Accepted?: string;
  /** @format date-time */
  Started?: string;
  /** @format date-time */
  OnLocation?: string;
  /** @format date-time */
  Ended?: string;
  SpentTime: string;
  Subcontractor?: string;
  Comment?: string;
  ContactActions: {
    Timestamp: string;
    Action: string;
  }[];
  Photos: {
    PhotoID: string;
    Timestamp: string;
  }[];
}

export interface SmsContactDto {
  to: string[];
  body: string;
}

export interface StaticResponderCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  /** @maxLength 1024 */
  externalId: string | null;
  contact: {
    /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
    email: string | null;
    /** @maxLength 1024 */
    phone: string | null;
    /** @maxLength 1024 */
    sms: string | null;
    /** @maxLength 1024 */
    camera: string | null;
  };
  schedule: {
    entries: {
      daysOfWeek: number[];
      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
      timeStart: string;
      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
      timeEnd: string;
    }[];
  } | null;
  /** @maxLength 1024 */
  migrationKey?: string;
  integrations?: {
    sequrix: {
      enabled: boolean;
      /** @maxLength 1024 */
      objectCode: string;
    };
  };
  statistics?: {
    /**
     * @maxItems 10
     * @minItems 0
     */
    acceptSpeeds: {
      /** @format date-time */
      timestamp: string;
      /**
       * @min 0
       * @exclusiveMin false
       */
      speedMs: number;
    }[];
    /**
     * @maxItems 10
     * @minItems 0
     */
    onLocationSpeeds: {
      /** @format date-time */
      timestamp: string;
      /**
       * @min 0
       * @exclusiveMin false
       */
      speedMs: number;
    }[];
    /**
     * @maxItems 10
     * @minItems 0
     */
    finishSpeeds: {
      /** @format date-time */
      timestamp: string;
      /**
       * @min 0
       * @exclusiveMin false
       */
      speedMs: number;
    }[];
    /**
     * @min 0
     * @exclusiveMin false
     */
    amountRejected: number;
    /**
     * @min 0
     * @exclusiveMin false
     */
    amountAccepted: number;
  };
}

export interface StaticResponderOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name: string;
  /** @maxLength 1024 */
  externalId: string | null;
  contact: {
    /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
    email: string | null;
    /** @maxLength 1024 */
    phone: string | null;
    /** @maxLength 1024 */
    sms: string | null;
    /** @maxLength 1024 */
    camera: string | null;
  };
  schedule: {
    entries: {
      daysOfWeek: number[];
      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
      timeStart: string;
      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
      timeEnd: string;
    }[];
  } | null;
  /** @maxLength 1024 */
  migrationKey?: string;
  integrations?: {
    sequrix: {
      enabled: boolean;
      /** @maxLength 1024 */
      objectCode: string;
    };
  };
  statistics?: {
    /**
     * @maxItems 10
     * @minItems 0
     */
    acceptSpeeds: {
      /** @format date-time */
      timestamp: string;
      /**
       * @min 0
       * @exclusiveMin false
       */
      speedMs: number;
    }[];
    /**
     * @maxItems 10
     * @minItems 0
     */
    onLocationSpeeds: {
      /** @format date-time */
      timestamp: string;
      /**
       * @min 0
       * @exclusiveMin false
       */
      speedMs: number;
    }[];
    /**
     * @maxItems 10
     * @minItems 0
     */
    finishSpeeds: {
      /** @format date-time */
      timestamp: string;
      /**
       * @min 0
       * @exclusiveMin false
       */
      speedMs: number;
    }[];
    /**
     * @min 0
     * @exclusiveMin false
     */
    amountRejected: number;
    /**
     * @min 0
     * @exclusiveMin false
     */
    amountAccepted: number;
  };
}

export interface StaticResponderUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  /** @maxLength 1024 */
  name?: string;
  /** @maxLength 1024 */
  externalId?: string | null;
  contact?: {
    /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
    email: string | null;
    /** @maxLength 1024 */
    phone: string | null;
    /** @maxLength 1024 */
    sms: string | null;
    /** @maxLength 1024 */
    camera: string | null;
  };
  schedule?: {
    entries: {
      daysOfWeek: number[];
      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
      timeStart: string;
      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
      timeEnd: string;
    }[];
  } | null;
  /** @maxLength 1024 */
  migrationKey?: string;
  integrations?: {
    sequrix: {
      enabled: boolean;
      /** @maxLength 1024 */
      objectCode: string;
    };
  };
  statistics?: {
    /**
     * @maxItems 10
     * @minItems 0
     */
    acceptSpeeds: {
      /** @format date-time */
      timestamp: string;
      /**
       * @min 0
       * @exclusiveMin false
       */
      speedMs: number;
    }[];
    /**
     * @maxItems 10
     * @minItems 0
     */
    onLocationSpeeds: {
      /** @format date-time */
      timestamp: string;
      /**
       * @min 0
       * @exclusiveMin false
       */
      speedMs: number;
    }[];
    /**
     * @maxItems 10
     * @minItems 0
     */
    finishSpeeds: {
      /** @format date-time */
      timestamp: string;
      /**
       * @min 0
       * @exclusiveMin false
       */
      speedMs: number;
    }[];
    /**
     * @min 0
     * @exclusiveMin false
     */
    amountRejected: number;
    /**
     * @min 0
     * @exclusiveMin false
     */
    amountAccepted: number;
  };
}

export interface SurveyQuestionCreateDto {
  /** @maxLength 1024 */
  question: string;
  type: "multipleChoice" | "followUp" | "openEnded" | "likertScale";
  options?: {
    value: number;
    /** @maxLength 1024 */
    label: string;
    variant: "primary" | "danger" | "success";
  }[];
}

export interface SurveyQuestionOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @maxLength 1024 */
  question: string;
  type: "multipleChoice" | "followUp" | "openEnded" | "likertScale";
  options?: {
    value: number;
    /** @maxLength 1024 */
    label: string;
    variant: "primary" | "danger" | "success";
  }[];
}

export interface SurveyQuestionUpdateDto {
  /** @maxLength 1024 */
  question?: string;
  type?: "multipleChoice" | "followUp" | "openEnded" | "likertScale";
  options?: {
    value: number;
    /** @maxLength 1024 */
    label: string;
    variant: "primary" | "danger" | "success";
  }[];
}

export interface SurveyResponseAnswerUpdateDto {
  answers: (
    | {
        type: "multipleChoice";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        };
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "likertScale";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        };
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "followUp";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        }[];
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "openEnded";
        /** @maxLength 1024 */
        answer: string;
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
  )[];
  isCompleted?: boolean;
}

export interface SurveyResponseCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  alarm: {
    /** @format uuid */
    _id: string;
    _ref: "Alarm";
  };
  answers?: (
    | {
        type: "multipleChoice";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        };
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "likertScale";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        };
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "followUp";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        }[];
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "openEnded";
        /** @maxLength 1024 */
        answer: string;
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
  )[];
  /** @maxLength 1024 */
  token: string;
  questions: {
    /** @format uuid */
    _id: string;
    _ref: "SurveyType";
  };
  /** @format date-time */
  completedAt?: string;
  responder: {
    /** @format uuid */
    _id: string;
    _ref: "User" | "AlarmHelper" | "StaticResponder" | "AlarmProfessionalResponder" | "Asset";
  };
}

export interface SurveyResponseOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  alarm: {
    /** @format uuid */
    _id: string;
    _ref: "Alarm";
  };
  answers?: (
    | {
        type: "multipleChoice";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        };
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "likertScale";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        };
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "followUp";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        }[];
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "openEnded";
        /** @maxLength 1024 */
        answer: string;
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
  )[];
  /** @maxLength 1024 */
  token: string;
  questions: {
    /** @format uuid */
    _id: string;
    _ref: "SurveyType";
  };
  /** @format date-time */
  completedAt?: string;
  responder: {
    /** @format uuid */
    _id: string;
    _ref: "User" | "AlarmHelper" | "StaticResponder" | "AlarmProfessionalResponder" | "Asset";
  };
}

export interface SurveyResponseUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  alarm?: {
    /** @format uuid */
    _id: string;
    _ref: "Alarm";
  };
  answers?: (
    | {
        type: "multipleChoice";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        };
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "likertScale";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        };
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "followUp";
        answer: {
          value: number;
          /** @maxLength 1024 */
          label: string;
        }[];
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
    | {
        type: "openEnded";
        /** @maxLength 1024 */
        answer: string;
        question: {
          /** @format uuid */
          _id: string;
          _ref: "SurveyQuestion";
        };
      }
  )[];
  /** @maxLength 1024 */
  token?: string;
  questions?: {
    /** @format uuid */
    _id: string;
    _ref: "SurveyType";
  };
  /** @format date-time */
  completedAt?: string;
  responder?: {
    /** @format uuid */
    _id: string;
    _ref: "User" | "AlarmHelper" | "StaticResponder" | "AlarmProfessionalResponder" | "Asset";
  };
}

export interface SurveyTypeCreateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  questions: {
    /** @format uuid */
    _id: string;
    _ref: "SurveyQuestion";
  }[];
  userType: "alarmCreator" | "alarmResponder" | "alarmCenter" | "AlarmProfessionalResponder";
}

export interface SurveyTypeOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  questions: {
    /** @format uuid */
    _id: string;
    _ref: "SurveyQuestion";
  }[];
  userType: "alarmCreator" | "alarmResponder" | "alarmCenter" | "AlarmProfessionalResponder";
}

export interface SurveyTypeUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  questions?: {
    /** @format uuid */
    _id: string;
    _ref: "SurveyQuestion";
  }[];
  userType?: "alarmCreator" | "alarmResponder" | "alarmCenter" | "AlarmProfessionalResponder";
}

export interface TriggerProccessingDto {
  secret: string;
}

export interface TwoFactorAuthStatusResponseDto {
  twoFactorMethods: {
    id: string;
    method: string;
  }[];
}

export interface TwoFactorGetSharedSecretResponseDto {
  secretBase32Encoded: string;
}

export interface UserCreateDto {
  /** @maxLength 1024 */
  username: string;
  /** @maxLength 1024 */
  name: string;
  properties: {
    /** @maxLength 1024 */
    phoneNumber?: string | null;
    /** @maxLength 1024 */
    smsNumber?: string | null;
    /** @maxLength 1024 */
    email?: string | null;
    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
    locale?: string | null;
    /** @maxLength 1024 */
    image?: string | null;
    rSim?: {
      /** @maxLength 1024 */
      euicc: string;
      primary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      secondary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      enabled: boolean;
    } | null;
  };
  /** @default [] */
  globalRoles?: string[];
  /** @default [] */
  specialPermissions?: string[];
  isServiceAccount?: boolean;
  type?: "default" | "serviceAccount" | "kiosk";
  userPolicies?: {
    disablePasswordlessLogin?: boolean;
  };
}

export interface UserCreateWithRelationDto {
  user: {
    /** @maxLength 1024 */
    username: string;
    /** @maxLength 1024 */
    name: string;
    properties: {
      /** @maxLength 1024 */
      phoneNumber?: string | null;
      /** @maxLength 1024 */
      smsNumber?: string | null;
      /** @maxLength 1024 */
      email?: string | null;
      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
      locale?: string | null;
      /** @maxLength 1024 */
      image?: string | null;
      rSim?: {
        /** @maxLength 1024 */
        euicc: string;
        primary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        secondary: {
          /** @maxLength 1024 */
          msisdn: string;
          /** @maxLength 1024 */
          iccid: string;
        };
        enabled: boolean;
      } | null;
    };
    /** @default [] */
    globalRoles?: string[];
    /** @default [] */
    specialPermissions?: string[];
    isServiceAccount?: boolean;
    type?: "default" | "serviceAccount" | "kiosk";
    userPolicies?: {
      disablePasswordlessLogin?: boolean;
    };
  };
  relation: {
    customer: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    };
    roles: string[];
    assetGroupRestrictions: {
      /** @format uuid */
      _id: string;
      _ref: "AssetGroup";
    }[];
  };
}

export interface UserOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  /** @maxLength 1024 */
  username: string;
  /** @maxLength 1024 */
  name: string;
  properties: {
    /** @maxLength 1024 */
    phoneNumber?: string | null;
    /** @maxLength 1024 */
    smsNumber?: string | null;
    /** @maxLength 1024 */
    email?: string | null;
    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
    locale?: string | null;
    /** @maxLength 1024 */
    image?: string | null;
    rSim?: {
      /** @maxLength 1024 */
      euicc: string;
      primary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      secondary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      enabled: boolean;
    } | null;
  };
  /** @default [] */
  globalRoles?: string[];
  /** @default [] */
  specialPermissions?: string[];
  isServiceAccount?: boolean;
  /** @format date-time */
  lastActiveAt?: string;
  type?: "default" | "serviceAccount" | "kiosk";
  userPolicies?: {
    disablePasswordlessLogin?: boolean;
  };
}

export interface UserUpdateDto {
  /** @maxLength 1024 */
  username?: string;
  /** @maxLength 1024 */
  name?: string;
  properties?: {
    /** @maxLength 1024 */
    phoneNumber?: string | null;
    /** @maxLength 1024 */
    smsNumber?: string | null;
    /** @maxLength 1024 */
    email?: string | null;
    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
    locale?: string | null;
    /** @maxLength 1024 */
    image?: string | null;
    rSim?: {
      /** @maxLength 1024 */
      euicc: string;
      primary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      secondary: {
        /** @maxLength 1024 */
        msisdn: string;
        /** @maxLength 1024 */
        iccid: string;
      };
      enabled: boolean;
    } | null;
  };
  /** @default [] */
  globalRoles?: string[];
  /** @default [] */
  specialPermissions?: string[];
  isServiceAccount?: boolean;
  /** @format date-time */
  lastActiveAt?: string;
  type?: "default" | "serviceAccount" | "kiosk";
  userPolicies?: {
    disablePasswordlessLogin?: boolean;
  };
}

export interface ValidateSpecialPermissionRequestDto {
  specialPermission: string;
}

export interface WebhookCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** @maxLength 1024 */
  migrationKey?: string | null;
  webhookQuery: {
    operation: "create" | "update" | "remove";
    entityTypes: string[];
    filter: Record<string, any>;
  };
  /** @default {} */
  deliveryStats?: {
    /**
     * @format date-time
     * @default null
     */
    lastDeliveryDate?: string | null;
    /** @default 0 */
    numDeliverySucceeded?: number;
    /** @default 0 */
    numDeliveryFailed?: number;
  };
  deliveryConfig: {
    /** @format uri */
    url: string;
    method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
    /** @default null */
    headers?: Record<string, any>;
  };
  /**
   * @maxLength 1024
   * @default null
   */
  transformer?: string | null;
  /** @default true */
  enableWebhook?: boolean;
}

export interface WebhookDeliveryUpdateDto {
  succeeded: boolean;
}

export interface WebhookOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** @maxLength 1024 */
  migrationKey?: string | null;
  webhookQuery: {
    operation: "create" | "update" | "remove";
    entityTypes: string[];
    filter: Record<string, any>;
  };
  /** @default {} */
  deliveryStats?: {
    /**
     * @format date-time
     * @default null
     */
    lastDeliveryDate?: string | null;
    /** @default 0 */
    numDeliverySucceeded?: number;
    /** @default 0 */
    numDeliveryFailed?: number;
  };
  deliveryConfig: {
    /** @format uri */
    url: string;
    method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
    /** @default null */
    headers?: Record<string, any>;
  };
  /**
   * @maxLength 1024
   * @default null
   */
  transformer?: string | null;
  /** @default true */
  enableWebhook?: boolean;
}

export interface WebhookUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  } | null;
  /** @maxLength 1024 */
  migrationKey?: string | null;
  webhookQuery?: {
    operation: "create" | "update" | "remove";
    entityTypes: string[];
    filter: Record<string, any>;
  };
  /** @default {} */
  deliveryStats?: {
    /**
     * @format date-time
     * @default null
     */
    lastDeliveryDate?: string | null;
    /** @default 0 */
    numDeliverySucceeded?: number;
    /** @default 0 */
    numDeliveryFailed?: number;
  };
  deliveryConfig?: {
    /** @format uri */
    url: string;
    method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
    /** @default null */
    headers?: Record<string, any>;
  };
  /**
   * @maxLength 1024
   * @default null
   */
  transformer?: string | null;
  /** @default true */
  enableWebhook?: boolean;
}

export interface ZoneCreateDto {
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  type: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
  location:
    | {
        type: "Polygon";
        coordinates: number[][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "MultiPolygon";
        coordinates: number[][][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      };
  /** @maxLength 1024 */
  name: string;
  /**
   * @maxLength 1024
   * @default null
   */
  description?: string | null;
  /** @default [] */
  staticResponders?: {
    staticResponder: {
      /** @format uuid */
      _id: string;
      _ref: "StaticResponder";
    };
    priority: number;
  }[];
  /** @default null */
  responsePriority?: number | null;
  syncToAms?: boolean;
}

export interface ZoneOutputDto {
  /** @format uuid */
  _id: string;
  /** @format date-time */
  createdAt: string;
  /** @format date-time */
  updatedAt: string;
  customer: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  type: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
  location:
    | {
        type: "Polygon";
        coordinates: number[][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "MultiPolygon";
        coordinates: number[][][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      };
  /** @maxLength 1024 */
  name: string;
  /**
   * @maxLength 1024
   * @default null
   */
  description?: string | null;
  /** @default [] */
  staticResponders?: {
    staticResponder: {
      /** @format uuid */
      _id: string;
      _ref: "StaticResponder";
    };
    priority: number;
  }[];
  /** @default null */
  responsePriority?: number | null;
  assetsInside: {
    /** @format uuid */
    _id: string;
    _ref: "Asset";
  }[];
  syncToAms?: boolean;
}

export interface ZoneUpdateDto {
  customer?: {
    /** @format uuid */
    _id: string;
    _ref: "Customer";
  };
  /** @default {"type":"parents","extra":[]} */
  share?: {
    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
    extra?: {
      /** @format uuid */
      _id: string;
      _ref: "Customer";
    }[];
  };
  type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
  location?:
    | {
        type: "Polygon";
        coordinates: number[][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      }
    | {
        type: "MultiPolygon";
        coordinates: number[][][][];
        properties: {
          accuracy?: number;
          heading?: number;
          headingAccuracy?: number;
          speed?: number;
          speedAccuracy?: number;
          altitude?: number;
          altitudeAccuracy?: number;
          address?: {
            /** @maxLength 1024 */
            countryCode?: string | null;
            /** @maxLength 1024 */
            state?: string | null;
            /** @maxLength 1024 */
            city?: string | null;
            /** @maxLength 1024 */
            streetName?: string | null;
            /** @maxLength 1024 */
            streetNumber?: string | null;
            floor?: number | null;
            /** @maxLength 1024 */
            formattedAddress?: string;
          };
          /** @format date-time */
          dateTime?: string;
          /** @maxLength 1024 */
          provider?: string;
        };
      };
  /** @maxLength 1024 */
  name?: string;
  /**
   * @maxLength 1024
   * @default null
   */
  description?: string | null;
  /** @default [] */
  staticResponders?: {
    staticResponder: {
      /** @format uuid */
      _id: string;
      _ref: "StaticResponder";
    };
    priority: number;
  }[];
  /** @default null */
  responsePriority?: number | null;
  syncToAms?: boolean;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "https://api.x-guard.dev" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title @x-guard/xgac-node-main-api
 * @version 1.28.2
 * @baseUrl https://api.x-guard.dev
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name AppControllerInfo
   * @request GET:/
   */
  appControllerInfo = (params: RequestParams = {}) =>
    this.request<void, any>({
      path: `/`,
      method: "GET",
      ...params,
    });

  alarmProfessionalResponders = {
    /**
     * @description Paginates AlarmProfessionalResponder
     *
     * @tags AlarmProfessionalResponder
     * @name AlarmProfessionalRespondersControllerPaginate
     * @request GET:/alarm-professional-responders
     * @secure
     */
    alarmProfessionalRespondersControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            type: "sequrix";
            active: boolean;
            status: "invited" | "accepted" | "rejected" | "started" | "onLocation" | "finished" | "error" | "canceled";
            alarm: {
              /** @format uuid */
              _id: string;
              _ref: "Alarm";
            };
            asset: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            };
            staticResponder: {
              /** @format uuid */
              _id: string;
              _ref: "StaticResponder";
            };
            statistics: {
              /** @format date-time */
              invitedAt: string;
              /** @format date-time */
              acceptedAt: string | null;
              /** @format date-time */
              rejectedAt: string | null;
              /** @format date-time */
              startedAt: string | null;
              /** @format date-time */
              onLocationAt: string | null;
              /** @format date-time */
              finishedAt: string | null;
            };
            meta: {
              /** @maxLength 4096 */
              comment?: string;
              photos?: {
                /** @format date-time */
                timestamp: string;
                /** @maxLength 1024 */
                url: string;
              }[];
              /** @maxLength 1024 */
              overridePhoneNumber?: string;
            };
            supersededBy: {
              /** @format uuid */
              _id: string;
              _ref: "AlarmProfessionalResponder";
            } | null;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/alarm-professional-responders`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a AlarmProfessionalResponder by id
     *
     * @tags AlarmProfessionalResponder
     * @name AlarmProfessionalRespondersControllerFindById
     * @request GET:/alarm-professional-responders/{id}
     * @secure
     */
    alarmProfessionalRespondersControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AlarmProfessionalRespondersOutputDto, any>({
        path: `/alarm-professional-responders/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags AlarmProfessionalResponder
     * @name AlarmProfessionalRespondersControllerSequrixCallback
     * @request POST:/alarm-professional-responders/sequrix/callback
     * @secure
     */
    alarmProfessionalRespondersControllerSequrixCallback: (
      data: SequrixCallbackRequestDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarm-professional-responders/sequrix/callback`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  alarms = {
    /**
     * @description Creates a Alarm
     *
     * @tags Alarm
     * @name AlarmsControllerCreate
     * @request POST:/alarms
     * @secure
     */
    alarmsControllerCreate: (
      data: AlarmCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AlarmOutputDto, any>({
        path: `/alarms`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Alarm
     *
     * @tags Alarm
     * @name AlarmsControllerPaginate
     * @request GET:/alarms
     * @secure
     */
    alarmsControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
              /** @maxLength 1024 */
              name: string;
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
            asset: {
              /** @format uuid */
              _id: string;
              /** @format date-time */
              createdAt: string;
              /** @format date-time */
              updatedAt: string;
              telluId?: number | null;
              migrationFinalised?: boolean | null;
              /** @format date-time */
              migrationFinalisedDate?: string | null;
              customer: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              };
              /** @default {"type":"parents","extra":[]} */
              share?: {
                type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                extra?: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Customer";
                }[];
              };
              user: {
                /** @format uuid */
                _id: string;
                _ref: "User";
              } | null;
              /** @maxLength 1024 */
              name: string;
              /** @maxLength 1024 */
              externalId?: string | null;
              propertiesOwnership?: "override" | "inherit";
              /** @default {} */
              properties?: {
                /** @maxLength 1024 */
                phoneNumber?: string | null;
                /** @maxLength 1024 */
                smsNumber?: string | null;
                /** @maxLength 1024 */
                email?: string | null;
                /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                locale?: string | null;
                /** @maxLength 1024 */
                image?: string | null;
                rSim?: {
                  /** @maxLength 1024 */
                  euicc: string;
                  primary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  secondary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  enabled: boolean;
                } | null;
              };
              /** @default null */
              position?: {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
              available: boolean;
              /** @default true */
              allowContactOnOwnAlarms?: boolean;
              app?: {
                mode: "off" | "on" | "risk";
              } | null;
              insideZones: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              }[];
              assetGroups: {
                /** @format uuid */
                _id: string;
                _ref: "AssetGroup";
              }[];
              /** @default [] */
              staticResponders?: {
                staticResponder: {
                  /** @format uuid */
                  _id: string;
                  _ref: "StaticResponder";
                };
                priority: number;
              }[];
              /** @format date-time */
              lastObservationAt?: string;
              /** @default false */
              invisibleResponder?: boolean;
              /** @maxLength 4096 */
              description?: string;
              type?: "default" | "kiosk";
            };
            ack: {
              needed: boolean;
              value: boolean;
              ackedBy: {
                /** @format uuid */
                _id: string;
                _ref: "User";
                /** @maxLength 1024 */
                name: string;
              } | null;
              /** @maxLength 1024 */
              comment: string | null;
              /** @format date-time */
              ackedAt: string | null;
            };
            position: {
              type: "Point";
              coordinates: number[];
              properties: {
                accuracy?: number;
                heading?: number;
                headingAccuracy?: number;
                speed?: number;
                speedAccuracy?: number;
                altitude?: number;
                altitudeAccuracy?: number;
                address?: {
                  /** @maxLength 1024 */
                  countryCode?: string | null;
                  /** @maxLength 1024 */
                  state?: string | null;
                  /** @maxLength 1024 */
                  city?: string | null;
                  /** @maxLength 1024 */
                  streetName?: string | null;
                  /** @maxLength 1024 */
                  streetNumber?: string | null;
                  floor?: number | null;
                  /** @maxLength 1024 */
                  formattedAddress?: string;
                };
                /** @format date-time */
                dateTime?: string;
                /** @maxLength 1024 */
                provider?: string;
              };
            };
            severity: "red" | "orange" | "green";
            /** @maxLength 4096 */
            procedure: string | null;
            meta?: {
              observationTrigger?: {
                /** @format uuid */
                _id: string;
                /** @format date-time */
                createdAt: string;
                /** @format date-time */
                updatedAt: string;
                telluId?: number | null;
                migrationFinalised?: boolean | null;
                /** @format date-time */
                migrationFinalisedDate?: string | null;
                customer: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Customer";
                };
                /** @default {"type":"parents","extra":[]} */
                share?: {
                  type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                  extra?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  }[];
                };
                /** @maxLength 1024 */
                name: string;
                eventCodes: string[];
                /** @maxLength 1024 */
                description: string;
                status: "active" | "inactive";
                actions: (
                  | {
                      type: "alarm";
                      config: {
                        severity: "red" | "orange" | "green";
                        type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
                        /** @maxLength 4096 */
                        procedure: string | null;
                        /** @maxLength 1024 */
                        name: string;
                      };
                    }
                  | {
                      type: "sms";
                      config: {
                        toRaw: string[];
                        toContactList: {
                          /** @format uuid */
                          _id: string;
                          _ref: "ContactList";
                        }[];
                        toTriggerUser: boolean;
                        /** @maxLength 1024 */
                        message: string;
                      };
                    }
                  | {
                      type: "email";
                      config: {
                        toRaw: string[];
                        toContactList: {
                          /** @format uuid */
                          _id: string;
                          _ref: "ContactList";
                        }[];
                        toTriggerUser: boolean;
                        /** @maxLength 1024 */
                        message: string;
                        /** @maxLength 1024 */
                        subject: string;
                        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                        locale?: string;
                      };
                    }
                )[];
                filter:
                  | {
                      type: "operator/and";
                      operands: (
                        | {
                            type: "operator/or";
                            operands: (
                              | {
                                  type: "operator/not";
                                  operand:
                                    | {
                                        type: "constraint/asset";
                                        assets: {
                                          /** @format uuid */
                                          _id: string;
                                          _ref: "Asset";
                                        }[];
                                      }
                                    | {
                                        type: "constraint/assetGroup";
                                        groups: {
                                          /** @format uuid */
                                          _id: string;
                                          _ref: "AssetGroup";
                                        }[];
                                      }
                                    | {
                                        type: "constraint/zone";
                                        zone: {
                                          /** @format uuid */
                                          _id: string;
                                          _ref: "Zone";
                                        };
                                      }
                                    | {
                                        type: "constraint/schedule";
                                        schedule: {
                                          entries: {
                                            daysOfWeek: number[];
                                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                            timeStart: string;
                                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                            timeEnd: string;
                                          }[];
                                        };
                                      };
                                }
                              | {
                                  type: "constraint/asset";
                                  assets: {
                                    /** @format uuid */
                                    _id: string;
                                    _ref: "Asset";
                                  }[];
                                }
                              | {
                                  type: "constraint/assetGroup";
                                  groups: {
                                    /** @format uuid */
                                    _id: string;
                                    _ref: "AssetGroup";
                                  }[];
                                }
                              | {
                                  type: "constraint/zone";
                                  zone: {
                                    /** @format uuid */
                                    _id: string;
                                    _ref: "Zone";
                                  };
                                }
                              | {
                                  type: "constraint/schedule";
                                  schedule: {
                                    entries: {
                                      daysOfWeek: number[];
                                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                      timeStart: string;
                                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                      timeEnd: string;
                                    }[];
                                  };
                                }
                            )[];
                          }
                        | {
                            type: "constraint/asset";
                            assets: {
                              /** @format uuid */
                              _id: string;
                              _ref: "Asset";
                            }[];
                          }
                        | {
                            type: "constraint/assetGroup";
                            groups: {
                              /** @format uuid */
                              _id: string;
                              _ref: "AssetGroup";
                            }[];
                          }
                        | {
                            type: "constraint/zone";
                            zone: {
                              /** @format uuid */
                              _id: string;
                              _ref: "Zone";
                            };
                          }
                        | {
                            type: "constraint/schedule";
                            schedule: {
                              entries: {
                                daysOfWeek: number[];
                                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                timeStart: string;
                                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                timeEnd: string;
                              }[];
                            };
                          }
                      )[];
                    }
                  | {
                      type: "constraint/asset";
                      assets: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Asset";
                      }[];
                    }
                  | {
                      type: "constraint/assetGroup";
                      groups: {
                        /** @format uuid */
                        _id: string;
                        _ref: "AssetGroup";
                      }[];
                    }
                  | {
                      type: "constraint/zone";
                      zone: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Zone";
                      };
                    }
                  | {
                      type: "constraint/schedule";
                      schedule: {
                        entries: {
                          daysOfWeek: number[];
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeStart: string;
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeEnd: string;
                        }[];
                      };
                    };
              };
              observation?: {
                /** @format uuid */
                _id: string;
                /** @maxLength 1024 */
                event: string;
                asset: {
                  /** @format uuid */
                  _id: string;
                  /** @format date-time */
                  createdAt: string;
                  /** @format date-time */
                  updatedAt: string;
                  telluId?: number | null;
                  migrationFinalised?: boolean | null;
                  /** @format date-time */
                  migrationFinalisedDate?: string | null;
                  customer: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  /** @default {"type":"parents","extra":[]} */
                  share?: {
                    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                    extra?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    }[];
                  };
                  user: {
                    /** @format uuid */
                    _id: string;
                    _ref: "User";
                  } | null;
                  /** @maxLength 1024 */
                  name: string;
                  /** @maxLength 1024 */
                  externalId?: string | null;
                  propertiesOwnership?: "override" | "inherit";
                  /** @default {} */
                  properties?: {
                    /** @maxLength 1024 */
                    phoneNumber?: string | null;
                    /** @maxLength 1024 */
                    smsNumber?: string | null;
                    /** @maxLength 1024 */
                    email?: string | null;
                    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                    locale?: string | null;
                    /** @maxLength 1024 */
                    image?: string | null;
                    rSim?: {
                      /** @maxLength 1024 */
                      euicc: string;
                      primary: {
                        /** @maxLength 1024 */
                        msisdn: string;
                        /** @maxLength 1024 */
                        iccid: string;
                      };
                      secondary: {
                        /** @maxLength 1024 */
                        msisdn: string;
                        /** @maxLength 1024 */
                        iccid: string;
                      };
                      enabled: boolean;
                    } | null;
                  };
                  /** @default null */
                  position?: {
                    type: "Point";
                    coordinates: number[];
                    properties: {
                      accuracy?: number;
                      heading?: number;
                      headingAccuracy?: number;
                      speed?: number;
                      speedAccuracy?: number;
                      altitude?: number;
                      altitudeAccuracy?: number;
                      address?: {
                        /** @maxLength 1024 */
                        countryCode?: string | null;
                        /** @maxLength 1024 */
                        state?: string | null;
                        /** @maxLength 1024 */
                        city?: string | null;
                        /** @maxLength 1024 */
                        streetName?: string | null;
                        /** @maxLength 1024 */
                        streetNumber?: string | null;
                        floor?: number | null;
                        /** @maxLength 1024 */
                        formattedAddress?: string;
                      };
                      /** @format date-time */
                      dateTime?: string;
                      /** @maxLength 1024 */
                      provider?: string;
                    };
                  };
                  available: boolean;
                  /** @default true */
                  allowContactOnOwnAlarms?: boolean;
                  app?: {
                    mode: "off" | "on" | "risk";
                  } | null;
                  insideZones: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  }[];
                  assetGroups: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[];
                  /** @default [] */
                  staticResponders?: {
                    staticResponder: {
                      /** @format uuid */
                      _id: string;
                      _ref: "StaticResponder";
                    };
                    priority: number;
                  }[];
                  /** @format date-time */
                  lastObservationAt?: string;
                  /** @default false */
                  invisibleResponder?: boolean;
                  /** @maxLength 4096 */
                  description?: string;
                  type?: "default" | "kiosk";
                  _ref: "Asset";
                };
                customer: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Customer";
                };
                device: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Device";
                };
                user: {
                  /** @format uuid */
                  _id: string;
                  _ref: "User";
                };
                position?: {
                  type: "Point";
                  coordinates: number[];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                };
                beacons?:
                  | {
                      /** @maxLength 1024 */
                      code: string;
                      rssi: number;
                      battery?: number;
                      /** @format date-time */
                      dateTime: string;
                      beacon: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Beacon";
                      } | null;
                      calc: number | null;
                      valid: boolean;
                    }[]
                  | {
                      /** @maxLength 1024 */
                      code: string;
                      rssi: number;
                      battery?: number;
                      /** @format date-time */
                      dateTime: string;
                    }[];
                meta?: {
                  asset?: {
                    /** @format uuid */
                    _id: string;
                    /** @format date-time */
                    createdAt: string;
                    /** @format date-time */
                    updatedAt: string;
                    telluId?: number | null;
                    migrationFinalised?: boolean | null;
                    /** @format date-time */
                    migrationFinalisedDate?: string | null;
                    customer: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    /** @default {"type":"parents","extra":[]} */
                    share?: {
                      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                      extra?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      }[];
                    };
                    user: {
                      /** @format uuid */
                      _id: string;
                      _ref: "User";
                    } | null;
                    /** @maxLength 1024 */
                    name: string;
                    /** @maxLength 1024 */
                    externalId?: string | null;
                    propertiesOwnership?: "override" | "inherit";
                    /** @default {} */
                    properties?: {
                      /** @maxLength 1024 */
                      phoneNumber?: string | null;
                      /** @maxLength 1024 */
                      smsNumber?: string | null;
                      /** @maxLength 1024 */
                      email?: string | null;
                      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                      locale?: string | null;
                      /** @maxLength 1024 */
                      image?: string | null;
                      rSim?: {
                        /** @maxLength 1024 */
                        euicc: string;
                        primary: {
                          /** @maxLength 1024 */
                          msisdn: string;
                          /** @maxLength 1024 */
                          iccid: string;
                        };
                        secondary: {
                          /** @maxLength 1024 */
                          msisdn: string;
                          /** @maxLength 1024 */
                          iccid: string;
                        };
                        enabled: boolean;
                      } | null;
                    };
                    /** @default null */
                    position?: {
                      type: "Point";
                      coordinates: number[];
                      properties: {
                        accuracy?: number;
                        heading?: number;
                        headingAccuracy?: number;
                        speed?: number;
                        speedAccuracy?: number;
                        altitude?: number;
                        altitudeAccuracy?: number;
                        address?: {
                          /** @maxLength 1024 */
                          countryCode?: string | null;
                          /** @maxLength 1024 */
                          state?: string | null;
                          /** @maxLength 1024 */
                          city?: string | null;
                          /** @maxLength 1024 */
                          streetName?: string | null;
                          /** @maxLength 1024 */
                          streetNumber?: string | null;
                          floor?: number | null;
                          /** @maxLength 1024 */
                          formattedAddress?: string;
                        };
                        /** @format date-time */
                        dateTime?: string;
                        /** @maxLength 1024 */
                        provider?: string;
                      };
                    };
                    available: boolean;
                    /** @default true */
                    allowContactOnOwnAlarms?: boolean;
                    app?: {
                      mode: "off" | "on" | "risk";
                    } | null;
                    insideZones: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    }[];
                    assetGroups: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                    /** @default [] */
                    staticResponders?: {
                      staticResponder: {
                        /** @format uuid */
                        _id: string;
                        _ref: "StaticResponder";
                      };
                      priority: number;
                    }[];
                    /** @format date-time */
                    lastObservationAt?: string;
                    /** @default false */
                    invisibleResponder?: boolean;
                    /** @maxLength 4096 */
                    description?: string;
                    type?: "default" | "kiosk";
                  };
                  battery?: number;
                  rssi?: number;
                  snr?: number;
                  /** @format date-time */
                  dateTime?: string;
                  /** @format date-time */
                  deviceDateTime?: string;
                  /** @format date-time */
                  gatewayDateTime?: string;
                  insideGpsZones?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  }[];
                  insideIpsZones?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  }[];
                  zone?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  };
                  callRequest?: boolean;
                  forceLoraCustomer?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  knnResult?: {
                    distance: number;
                    debugInfo?: any;
                  };
                  available?: boolean;
                };
                deltas?: {
                  main: {
                    /** @format date-time */
                    start: string;
                    /** @format date-time */
                    end: string;
                    value?: number;
                  };
                  steps?: {
                    gps?: {
                      /** @format date-time */
                      start: string;
                      /** @format date-time */
                      end: string;
                      value?: number;
                    };
                    ips?: {
                      /** @format date-time */
                      start: string;
                      /** @format date-time */
                      end: string;
                      value?: number;
                    };
                    context?: {
                      /** @format date-time */
                      start: string;
                      /** @format date-time */
                      end: string;
                      value?: number;
                    };
                  };
                };
                validPosition?: {
                  value: boolean;
                  /** @maxLength 1024 */
                  reason?: string;
                };
                isSystemEvent?: boolean;
              };
              frozenInfo?: {
                staticResponse: {
                  type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
                  priority: number;
                  zone?: {
                    /** @format uuid */
                    _id: string;
                    customer?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
                    location:
                      | {
                          type: "Point";
                          coordinates: number[];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        }
                      | {
                          type: "Polygon";
                          coordinates: number[][][];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        }
                      | {
                          type: "MultiPolygon";
                          coordinates: number[][][][];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        };
                    /** @maxLength 1024 */
                    name: string;
                    staticResponders: {
                      staticResponder: {
                        /** @format uuid */
                        _id: string;
                        _ref: "StaticResponder";
                      };
                      priority: number;
                    }[];
                  };
                  responders: {
                    info: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset" | "StaticResponder";
                      /** @maxLength 1024 */
                      name: string;
                      /** @maxLength 1024 */
                      image: string | null;
                      position: {
                        type: "Point";
                        coordinates: number[];
                        properties: {
                          accuracy?: number;
                          heading?: number;
                          headingAccuracy?: number;
                          speed?: number;
                          speedAccuracy?: number;
                          altitude?: number;
                          altitudeAccuracy?: number;
                          address?: {
                            /** @maxLength 1024 */
                            countryCode?: string | null;
                            /** @maxLength 1024 */
                            state?: string | null;
                            /** @maxLength 1024 */
                            city?: string | null;
                            /** @maxLength 1024 */
                            streetName?: string | null;
                            /** @maxLength 1024 */
                            streetNumber?: string | null;
                            floor?: number | null;
                            /** @maxLength 1024 */
                            formattedAddress?: string;
                          };
                          /** @format date-time */
                          dateTime?: string;
                          /** @maxLength 1024 */
                          provider?: string;
                        };
                      };
                      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                      locale: string | null;
                      /** @maxLength 1024 */
                      contactCode: string | null;
                    };
                    distanceKm: number | null;
                    actions: {
                      /** @maxLength 1024 */
                      call: string | null;
                      /** @maxLength 1024 */
                      sms: string | null;
                      /** @maxLength 1024 */
                      email: string | null;
                      notification: boolean;
                    };
                    priority: number;
                    professionalResponder?: {
                      available: boolean;
                      inProgress: boolean;
                    };
                  }[];
                }[];
                dynamicResponse: {
                  type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
                  priority: number;
                  zone?: {
                    /** @format uuid */
                    _id: string;
                    customer?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
                    location:
                      | {
                          type: "Point";
                          coordinates: number[];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        }
                      | {
                          type: "Polygon";
                          coordinates: number[][][];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        }
                      | {
                          type: "MultiPolygon";
                          coordinates: number[][][][];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        };
                    /** @maxLength 1024 */
                    name: string;
                    staticResponders: {
                      staticResponder: {
                        /** @format uuid */
                        _id: string;
                        _ref: "StaticResponder";
                      };
                      priority: number;
                    }[];
                  };
                  responders: {
                    info: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset" | "StaticResponder";
                      /** @maxLength 1024 */
                      name: string;
                      /** @maxLength 1024 */
                      image: string | null;
                      position: {
                        type: "Point";
                        coordinates: number[];
                        properties: {
                          accuracy?: number;
                          heading?: number;
                          headingAccuracy?: number;
                          speed?: number;
                          speedAccuracy?: number;
                          altitude?: number;
                          altitudeAccuracy?: number;
                          address?: {
                            /** @maxLength 1024 */
                            countryCode?: string | null;
                            /** @maxLength 1024 */
                            state?: string | null;
                            /** @maxLength 1024 */
                            city?: string | null;
                            /** @maxLength 1024 */
                            streetName?: string | null;
                            /** @maxLength 1024 */
                            streetNumber?: string | null;
                            floor?: number | null;
                            /** @maxLength 1024 */
                            formattedAddress?: string;
                          };
                          /** @format date-time */
                          dateTime?: string;
                          /** @maxLength 1024 */
                          provider?: string;
                        };
                      };
                      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                      locale: string | null;
                      /** @maxLength 1024 */
                      contactCode: string | null;
                    };
                    distanceKm: number | null;
                    actions: {
                      /** @maxLength 1024 */
                      call: string | null;
                      /** @maxLength 1024 */
                      sms: string | null;
                      /** @maxLength 1024 */
                      email: string | null;
                      notification: boolean;
                    };
                    priority: number;
                    professionalResponder?: {
                      available: boolean;
                      inProgress: boolean;
                    };
                  }[];
                }[];
                professionalResponders?: {
                  /** @format uuid */
                  _id: string;
                  /** @format date-time */
                  createdAt: string;
                  /** @format date-time */
                  updatedAt: string;
                  telluId?: number | null;
                  migrationFinalised?: boolean | null;
                  /** @format date-time */
                  migrationFinalisedDate?: string | null;
                  customer: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  /** @default {"type":"parents","extra":[]} */
                  share?: {
                    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                    extra?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    }[];
                  };
                  type: "sequrix";
                  active: boolean;
                  status:
                    | "invited"
                    | "accepted"
                    | "rejected"
                    | "started"
                    | "onLocation"
                    | "finished"
                    | "error"
                    | "canceled";
                  alarm: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Alarm";
                  };
                  asset: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  };
                  staticResponder: {
                    /** @format uuid */
                    _id: string;
                    /** @format date-time */
                    createdAt: string;
                    /** @format date-time */
                    updatedAt: string;
                    telluId?: number | null;
                    migrationFinalised?: boolean | null;
                    /** @format date-time */
                    migrationFinalisedDate?: string | null;
                    customer: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    /** @default {"type":"parents","extra":[]} */
                    share?: {
                      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                      extra?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      }[];
                    };
                    /** @maxLength 1024 */
                    name: string;
                    /** @maxLength 1024 */
                    externalId: string | null;
                    contact: {
                      /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
                      email: string | null;
                      /** @maxLength 1024 */
                      phone: string | null;
                      /** @maxLength 1024 */
                      sms: string | null;
                      /** @maxLength 1024 */
                      camera: string | null;
                    };
                    schedule: {
                      entries: {
                        daysOfWeek: number[];
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeStart: string;
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeEnd: string;
                      }[];
                    } | null;
                    /** @maxLength 1024 */
                    migrationKey?: string;
                    integrations?: {
                      sequrix: {
                        enabled: boolean;
                        /** @maxLength 1024 */
                        objectCode: string;
                      };
                    };
                    statistics?: {
                      /**
                       * @maxItems 10
                       * @minItems 0
                       */
                      acceptSpeeds: {
                        /** @format date-time */
                        timestamp: string;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        speedMs: number;
                      }[];
                      /**
                       * @maxItems 10
                       * @minItems 0
                       */
                      onLocationSpeeds: {
                        /** @format date-time */
                        timestamp: string;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        speedMs: number;
                      }[];
                      /**
                       * @maxItems 10
                       * @minItems 0
                       */
                      finishSpeeds: {
                        /** @format date-time */
                        timestamp: string;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        speedMs: number;
                      }[];
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      amountRejected: number;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      amountAccepted: number;
                    };
                    _ref: "StaticResponder";
                  };
                  statistics: {
                    /** @format date-time */
                    invitedAt: string;
                    /** @format date-time */
                    acceptedAt: string | null;
                    /** @format date-time */
                    rejectedAt: string | null;
                    /** @format date-time */
                    startedAt: string | null;
                    /** @format date-time */
                    onLocationAt: string | null;
                    /** @format date-time */
                    finishedAt: string | null;
                  };
                  meta: {
                    /** @maxLength 4096 */
                    comment?: string;
                    photos?: {
                      /** @format date-time */
                      timestamp: string;
                      /** @maxLength 1024 */
                      url: string;
                    }[];
                    /** @maxLength 1024 */
                    overridePhoneNumber?: string;
                  };
                  supersededBy: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AlarmProfessionalResponder";
                  } | null;
                }[];
              };
            };
            alarmCenter: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
              /** @maxLength 1024 */
              name: string;
            } | null;
            /** @format date-time */
            lastMergedAt?: string;
            /** @format date-time */
            lastPublishedToStreamAt?: string;
            mergeHistory?: {
              /** @format uuid */
              _id: string;
              /** @maxLength 1024 */
              name: string;
              /** @format date-time */
              createdAt: string;
              position: {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
              meta?: {
                observationTrigger?: {
                  /** @format uuid */
                  _id: string;
                  /** @format date-time */
                  createdAt: string;
                  /** @format date-time */
                  updatedAt: string;
                  telluId?: number | null;
                  migrationFinalised?: boolean | null;
                  /** @format date-time */
                  migrationFinalisedDate?: string | null;
                  customer: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  /** @default {"type":"parents","extra":[]} */
                  share?: {
                    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                    extra?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    }[];
                  };
                  /** @maxLength 1024 */
                  name: string;
                  eventCodes: string[];
                  /** @maxLength 1024 */
                  description: string;
                  status: "active" | "inactive";
                  actions: (
                    | {
                        type: "alarm";
                        config: {
                          severity: "red" | "orange" | "green";
                          type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
                          /** @maxLength 4096 */
                          procedure: string | null;
                          /** @maxLength 1024 */
                          name: string;
                        };
                      }
                    | {
                        type: "sms";
                        config: {
                          toRaw: string[];
                          toContactList: {
                            /** @format uuid */
                            _id: string;
                            _ref: "ContactList";
                          }[];
                          toTriggerUser: boolean;
                          /** @maxLength 1024 */
                          message: string;
                        };
                      }
                    | {
                        type: "email";
                        config: {
                          toRaw: string[];
                          toContactList: {
                            /** @format uuid */
                            _id: string;
                            _ref: "ContactList";
                          }[];
                          toTriggerUser: boolean;
                          /** @maxLength 1024 */
                          message: string;
                          /** @maxLength 1024 */
                          subject: string;
                          /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                          locale?: string;
                        };
                      }
                  )[];
                  filter:
                    | {
                        type: "constraint/asset";
                        assets: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Asset";
                        }[];
                      }
                    | {
                        type: "constraint/assetGroup";
                        groups: {
                          /** @format uuid */
                          _id: string;
                          _ref: "AssetGroup";
                        }[];
                      }
                    | {
                        type: "constraint/zone";
                        zone: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Zone";
                        };
                      }
                    | {
                        type: "constraint/schedule";
                        schedule: {
                          entries: {
                            daysOfWeek: number[];
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeStart: string;
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeEnd: string;
                          }[];
                        };
                      };
                };
                observation?: {
                  /** @format uuid */
                  _id: string;
                  /** @maxLength 1024 */
                  event: string;
                  asset: {
                    /** @format uuid */
                    _id: string;
                    /** @format date-time */
                    createdAt: string;
                    /** @format date-time */
                    updatedAt: string;
                    telluId?: number | null;
                    migrationFinalised?: boolean | null;
                    /** @format date-time */
                    migrationFinalisedDate?: string | null;
                    customer: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    /** @default {"type":"parents","extra":[]} */
                    share?: {
                      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                      extra?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      }[];
                    };
                    user: {
                      /** @format uuid */
                      _id: string;
                      _ref: "User";
                    } | null;
                    /** @maxLength 1024 */
                    name: string;
                    /** @maxLength 1024 */
                    externalId?: string | null;
                    propertiesOwnership?: "override" | "inherit";
                    /** @default {} */
                    properties?: {
                      /** @maxLength 1024 */
                      phoneNumber?: string | null;
                      /** @maxLength 1024 */
                      smsNumber?: string | null;
                      /** @maxLength 1024 */
                      email?: string | null;
                      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                      locale?: string | null;
                      /** @maxLength 1024 */
                      image?: string | null;
                      rSim?: {
                        /** @maxLength 1024 */
                        euicc: string;
                        primary: {
                          /** @maxLength 1024 */
                          msisdn: string;
                          /** @maxLength 1024 */
                          iccid: string;
                        };
                        secondary: {
                          /** @maxLength 1024 */
                          msisdn: string;
                          /** @maxLength 1024 */
                          iccid: string;
                        };
                        enabled: boolean;
                      } | null;
                    };
                    /** @default null */
                    position?: {
                      type: "Point";
                      coordinates: number[];
                      properties: {
                        accuracy?: number;
                        heading?: number;
                        headingAccuracy?: number;
                        speed?: number;
                        speedAccuracy?: number;
                        altitude?: number;
                        altitudeAccuracy?: number;
                        address?: {
                          /** @maxLength 1024 */
                          countryCode?: string | null;
                          /** @maxLength 1024 */
                          state?: string | null;
                          /** @maxLength 1024 */
                          city?: string | null;
                          /** @maxLength 1024 */
                          streetName?: string | null;
                          /** @maxLength 1024 */
                          streetNumber?: string | null;
                          floor?: number | null;
                          /** @maxLength 1024 */
                          formattedAddress?: string;
                        };
                        /** @format date-time */
                        dateTime?: string;
                        /** @maxLength 1024 */
                        provider?: string;
                      };
                    };
                    available: boolean;
                    /** @default true */
                    allowContactOnOwnAlarms?: boolean;
                    app?: {
                      mode: "off" | "on" | "risk";
                    } | null;
                    insideZones: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    }[];
                    assetGroups: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                    /** @default [] */
                    staticResponders?: {
                      staticResponder: {
                        /** @format uuid */
                        _id: string;
                        _ref: "StaticResponder";
                      };
                      priority: number;
                    }[];
                    /** @format date-time */
                    lastObservationAt?: string;
                    /** @default false */
                    invisibleResponder?: boolean;
                    /** @maxLength 4096 */
                    description?: string;
                    type?: "default" | "kiosk";
                    _ref: "Asset";
                  };
                  customer: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  device: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Device";
                  };
                  user: {
                    /** @format uuid */
                    _id: string;
                    _ref: "User";
                  };
                  position?: {
                    type: "Point";
                    coordinates: number[];
                    properties: {
                      accuracy?: number;
                      heading?: number;
                      headingAccuracy?: number;
                      speed?: number;
                      speedAccuracy?: number;
                      altitude?: number;
                      altitudeAccuracy?: number;
                      address?: {
                        /** @maxLength 1024 */
                        countryCode?: string | null;
                        /** @maxLength 1024 */
                        state?: string | null;
                        /** @maxLength 1024 */
                        city?: string | null;
                        /** @maxLength 1024 */
                        streetName?: string | null;
                        /** @maxLength 1024 */
                        streetNumber?: string | null;
                        floor?: number | null;
                        /** @maxLength 1024 */
                        formattedAddress?: string;
                      };
                      /** @format date-time */
                      dateTime?: string;
                      /** @maxLength 1024 */
                      provider?: string;
                    };
                  };
                  beacons?:
                    | {
                        /** @maxLength 1024 */
                        code: string;
                        rssi: number;
                        battery?: number;
                        /** @format date-time */
                        dateTime: string;
                        beacon: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Beacon";
                        } | null;
                        calc: number | null;
                        valid: boolean;
                      }[]
                    | {
                        /** @maxLength 1024 */
                        code: string;
                        rssi: number;
                        battery?: number;
                        /** @format date-time */
                        dateTime: string;
                      }[];
                  meta?: {
                    asset?: {
                      /** @format uuid */
                      _id: string;
                      /** @format date-time */
                      createdAt: string;
                      /** @format date-time */
                      updatedAt: string;
                      telluId?: number | null;
                      migrationFinalised?: boolean | null;
                      /** @format date-time */
                      migrationFinalisedDate?: string | null;
                      customer: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      };
                      /** @default {"type":"parents","extra":[]} */
                      share?: {
                        type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                        extra?: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Customer";
                        }[];
                      };
                      user: {
                        /** @format uuid */
                        _id: string;
                        _ref: "User";
                      } | null;
                      /** @maxLength 1024 */
                      name: string;
                      /** @maxLength 1024 */
                      externalId?: string | null;
                      propertiesOwnership?: "override" | "inherit";
                      /** @default {} */
                      properties?: {
                        /** @maxLength 1024 */
                        phoneNumber?: string | null;
                        /** @maxLength 1024 */
                        smsNumber?: string | null;
                        /** @maxLength 1024 */
                        email?: string | null;
                        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                        locale?: string | null;
                        /** @maxLength 1024 */
                        image?: string | null;
                        rSim?: {
                          /** @maxLength 1024 */
                          euicc: string;
                          primary: {
                            /** @maxLength 1024 */
                            msisdn: string;
                            /** @maxLength 1024 */
                            iccid: string;
                          };
                          secondary: {
                            /** @maxLength 1024 */
                            msisdn: string;
                            /** @maxLength 1024 */
                            iccid: string;
                          };
                          enabled: boolean;
                        } | null;
                      };
                      /** @default null */
                      position?: {
                        type: "Point";
                        coordinates: number[];
                        properties: {
                          accuracy?: number;
                          heading?: number;
                          headingAccuracy?: number;
                          speed?: number;
                          speedAccuracy?: number;
                          altitude?: number;
                          altitudeAccuracy?: number;
                          address?: {
                            /** @maxLength 1024 */
                            countryCode?: string | null;
                            /** @maxLength 1024 */
                            state?: string | null;
                            /** @maxLength 1024 */
                            city?: string | null;
                            /** @maxLength 1024 */
                            streetName?: string | null;
                            /** @maxLength 1024 */
                            streetNumber?: string | null;
                            floor?: number | null;
                            /** @maxLength 1024 */
                            formattedAddress?: string;
                          };
                          /** @format date-time */
                          dateTime?: string;
                          /** @maxLength 1024 */
                          provider?: string;
                        };
                      };
                      available: boolean;
                      /** @default true */
                      allowContactOnOwnAlarms?: boolean;
                      app?: {
                        mode: "off" | "on" | "risk";
                      } | null;
                      insideZones: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Zone";
                      }[];
                      assetGroups: {
                        /** @format uuid */
                        _id: string;
                        _ref: "AssetGroup";
                      }[];
                      /** @default [] */
                      staticResponders?: {
                        staticResponder: {
                          /** @format uuid */
                          _id: string;
                          _ref: "StaticResponder";
                        };
                        priority: number;
                      }[];
                      /** @format date-time */
                      lastObservationAt?: string;
                      /** @default false */
                      invisibleResponder?: boolean;
                      /** @maxLength 4096 */
                      description?: string;
                      type?: "default" | "kiosk";
                    };
                    battery?: number;
                    rssi?: number;
                    snr?: number;
                    /** @format date-time */
                    dateTime?: string;
                    /** @format date-time */
                    deviceDateTime?: string;
                    /** @format date-time */
                    gatewayDateTime?: string;
                    insideGpsZones?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    }[];
                    insideIpsZones?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    }[];
                    zone?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    };
                    callRequest?: boolean;
                    forceLoraCustomer?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    knnResult?: {
                      distance: number;
                      debugInfo?: any;
                    };
                    available?: boolean;
                  };
                  deltas?: {
                    main: {
                      /** @format date-time */
                      start: string;
                      /** @format date-time */
                      end: string;
                      value?: number;
                    };
                    steps?: {
                      gps?: {
                        /** @format date-time */
                        start: string;
                        /** @format date-time */
                        end: string;
                        value?: number;
                      };
                      ips?: {
                        /** @format date-time */
                        start: string;
                        /** @format date-time */
                        end: string;
                        value?: number;
                      };
                      context?: {
                        /** @format date-time */
                        start: string;
                        /** @format date-time */
                        end: string;
                        value?: number;
                      };
                    };
                  };
                  validPosition?: {
                    value: boolean;
                    /** @maxLength 1024 */
                    reason?: string;
                  };
                  isSystemEvent?: boolean;
                };
                frozenInfo?: {
                  staticResponse: {
                    type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
                    priority: number;
                    zone?: {
                      /** @format uuid */
                      _id: string;
                      customer?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      };
                      type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
                      location:
                        | {
                            type: "Point";
                            coordinates: number[];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          }
                        | {
                            type: "Polygon";
                            coordinates: number[][][];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          }
                        | {
                            type: "MultiPolygon";
                            coordinates: number[][][][];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          };
                      /** @maxLength 1024 */
                      name: string;
                      staticResponders: {
                        staticResponder: {
                          /** @format uuid */
                          _id: string;
                          _ref: "StaticResponder";
                        };
                        priority: number;
                      }[];
                    };
                    responders: {
                      info: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Asset" | "StaticResponder";
                        /** @maxLength 1024 */
                        name: string;
                        /** @maxLength 1024 */
                        image: string | null;
                        position: {
                          type: "Point";
                          coordinates: number[];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        };
                        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                        locale: string | null;
                        /** @maxLength 1024 */
                        contactCode: string | null;
                      };
                      distanceKm: number | null;
                      actions: {
                        /** @maxLength 1024 */
                        call: string | null;
                        /** @maxLength 1024 */
                        sms: string | null;
                        /** @maxLength 1024 */
                        email: string | null;
                        notification: boolean;
                      };
                      priority: number;
                      professionalResponder?: {
                        available: boolean;
                        inProgress: boolean;
                      };
                    }[];
                  }[];
                  dynamicResponse: {
                    type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
                    priority: number;
                    zone?: {
                      /** @format uuid */
                      _id: string;
                      customer?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      };
                      type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
                      location:
                        | {
                            type: "Point";
                            coordinates: number[];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          }
                        | {
                            type: "Polygon";
                            coordinates: number[][][];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          }
                        | {
                            type: "MultiPolygon";
                            coordinates: number[][][][];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          };
                      /** @maxLength 1024 */
                      name: string;
                      staticResponders: {
                        staticResponder: {
                          /** @format uuid */
                          _id: string;
                          _ref: "StaticResponder";
                        };
                        priority: number;
                      }[];
                    };
                    responders: {
                      info: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Asset" | "StaticResponder";
                        /** @maxLength 1024 */
                        name: string;
                        /** @maxLength 1024 */
                        image: string | null;
                        position: {
                          type: "Point";
                          coordinates: number[];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        };
                        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                        locale: string | null;
                        /** @maxLength 1024 */
                        contactCode: string | null;
                      };
                      distanceKm: number | null;
                      actions: {
                        /** @maxLength 1024 */
                        call: string | null;
                        /** @maxLength 1024 */
                        sms: string | null;
                        /** @maxLength 1024 */
                        email: string | null;
                        notification: boolean;
                      };
                      priority: number;
                      professionalResponder?: {
                        available: boolean;
                        inProgress: boolean;
                      };
                    }[];
                  }[];
                  professionalResponders?: {
                    /** @format uuid */
                    _id: string;
                    /** @format date-time */
                    createdAt: string;
                    /** @format date-time */
                    updatedAt: string;
                    telluId?: number | null;
                    migrationFinalised?: boolean | null;
                    /** @format date-time */
                    migrationFinalisedDate?: string | null;
                    customer: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    /** @default {"type":"parents","extra":[]} */
                    share?: {
                      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                      extra?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      }[];
                    };
                    type: "sequrix";
                    active: boolean;
                    status:
                      | "invited"
                      | "accepted"
                      | "rejected"
                      | "started"
                      | "onLocation"
                      | "finished"
                      | "error"
                      | "canceled";
                    alarm: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Alarm";
                    };
                    asset: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    };
                    staticResponder: {
                      /** @format uuid */
                      _id: string;
                      /** @format date-time */
                      createdAt: string;
                      /** @format date-time */
                      updatedAt: string;
                      telluId?: number | null;
                      migrationFinalised?: boolean | null;
                      /** @format date-time */
                      migrationFinalisedDate?: string | null;
                      customer: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      };
                      /** @default {"type":"parents","extra":[]} */
                      share?: {
                        type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                        extra?: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Customer";
                        }[];
                      };
                      /** @maxLength 1024 */
                      name: string;
                      /** @maxLength 1024 */
                      externalId: string | null;
                      contact: {
                        /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
                        email: string | null;
                        /** @maxLength 1024 */
                        phone: string | null;
                        /** @maxLength 1024 */
                        sms: string | null;
                        /** @maxLength 1024 */
                        camera: string | null;
                      };
                      schedule: {
                        entries: {
                          daysOfWeek: number[];
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeStart: string;
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeEnd: string;
                        }[];
                      } | null;
                      /** @maxLength 1024 */
                      migrationKey?: string;
                      integrations?: {
                        sequrix: {
                          enabled: boolean;
                          /** @maxLength 1024 */
                          objectCode: string;
                        };
                      };
                      statistics?: {
                        /**
                         * @maxItems 10
                         * @minItems 0
                         */
                        acceptSpeeds: {
                          /** @format date-time */
                          timestamp: string;
                          /**
                           * @min 0
                           * @exclusiveMin false
                           */
                          speedMs: number;
                        }[];
                        /**
                         * @maxItems 10
                         * @minItems 0
                         */
                        onLocationSpeeds: {
                          /** @format date-time */
                          timestamp: string;
                          /**
                           * @min 0
                           * @exclusiveMin false
                           */
                          speedMs: number;
                        }[];
                        /**
                         * @maxItems 10
                         * @minItems 0
                         */
                        finishSpeeds: {
                          /** @format date-time */
                          timestamp: string;
                          /**
                           * @min 0
                           * @exclusiveMin false
                           */
                          speedMs: number;
                        }[];
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        amountRejected: number;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        amountAccepted: number;
                      };
                      _ref: "StaticResponder";
                    };
                    statistics: {
                      /** @format date-time */
                      invitedAt: string;
                      /** @format date-time */
                      acceptedAt: string | null;
                      /** @format date-time */
                      rejectedAt: string | null;
                      /** @format date-time */
                      startedAt: string | null;
                      /** @format date-time */
                      onLocationAt: string | null;
                      /** @format date-time */
                      finishedAt: string | null;
                    };
                    meta: {
                      /** @maxLength 4096 */
                      comment?: string;
                      photos?: {
                        /** @format date-time */
                        timestamp: string;
                        /** @maxLength 1024 */
                        url: string;
                      }[];
                      /** @maxLength 1024 */
                      overridePhoneNumber?: string;
                    };
                    supersededBy: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AlarmProfessionalResponder";
                    } | null;
                  }[];
                };
              };
            }[];
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/alarms`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Alarm by id
     *
     * @tags Alarm
     * @name AlarmsControllerFindById
     * @request GET:/alarms/{id}
     * @secure
     */
    alarmsControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AlarmOutputDto, any>({
        path: `/alarms/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerAcknowledge
     * @request POST:/alarms/{id}/acknowledge
     * @secure
     */
    alarmsControllerAcknowledge: (
      id: string,
      data: AlarmAcknowledgeDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/acknowledge`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerPostAlarmEvent
     * @request POST:/alarms/{id}/alarm-events
     * @secure
     */
    alarmsControllerPostAlarmEvent: (
      id: string,
      data: AlarmEventCreateBase,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/alarm-events`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Paginates AlarmEvent
     *
     * @tags Alarm
     * @name AlarmsControllerPaginateAlarmEvents
     * @request GET:/alarms/{id}/alarm-events
     * @secure
     */
    alarmsControllerPaginateAlarmEvents: (
      id: string,
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
              /** @maxLength 1024 */
              name: string;
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            alarm: {
              /** @format uuid */
              _id: string;
              _ref: "Alarm";
            };
            user: {
              /** @format uuid */
              _id: string;
              /** @format date-time */
              createdAt: string;
              /** @format date-time */
              updatedAt: string;
              telluId?: number | null;
              migrationFinalised?: boolean | null;
              /** @format date-time */
              migrationFinalisedDate?: string | null;
              /** @maxLength 1024 */
              username: string;
              /** @maxLength 1024 */
              name: string;
              properties: {
                /** @maxLength 1024 */
                phoneNumber?: string | null;
                /** @maxLength 1024 */
                smsNumber?: string | null;
                /** @maxLength 1024 */
                email?: string | null;
                /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                locale?: string | null;
                /** @maxLength 1024 */
                image?: string | null;
                rSim?: {
                  /** @maxLength 1024 */
                  euicc: string;
                  primary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  secondary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  enabled: boolean;
                } | null;
              };
              /** @default [] */
              globalRoles?: string[];
              /** @default [] */
              specialPermissions?: string[];
              isServiceAccount?: boolean;
              /** @format date-time */
              lastActiveAt?: string;
              type?: "default" | "serviceAccount" | "kiosk";
              userPolicies?: {
                disablePasswordlessLogin?: boolean;
              };
            };
            /** @maxLength 4096 */
            text: string;
            type: "user" | "system" | "action" | "hidden";
            /** @maxLength 1024 */
            action: string;
            meta?: Record<string, any>;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/alarms/${id}/alarm-events`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerPostHelper
     * @request POST:/alarms/{id}/alarm-helpers
     * @secure
     */
    alarmsControllerPostHelper: (
      id: string,
      data: AlarmHelperDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/alarm-helpers`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerPatchHelper
     * @request PATCH:/alarms/{id}/alarm-helpers
     * @secure
     */
    alarmsControllerPatchHelper: (
      id: string,
      data: AlarmHelperDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/alarm-helpers`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerPutHelper
     * @request PUT:/alarms/{id}/alarm-helpers
     * @secure
     */
    alarmsControllerPutHelper: (
      id: string,
      data: AlarmHelperPutDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/alarm-helpers`,
        method: "PUT",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Paginates AlarmHelper
     *
     * @tags Alarm
     * @name AlarmsControllerGetHelpers
     * @request GET:/alarms/{id}/alarm-helpers
     * @secure
     */
    alarmsControllerGetHelpers: (
      id: string,
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            telluId?: number | null;
            migrationFinalised?: boolean | null;
            /** @format date-time */
            migrationFinalisedDate?: string | null;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
              /** @maxLength 1024 */
              name: string;
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            asset: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
              customer: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              };
              user: {
                /** @format uuid */
                _id: string;
                _ref: "User";
              } | null;
              /** @maxLength 1024 */
              name: string;
              /** @maxLength 1024 */
              externalId?: string | null;
              /** @default {} */
              properties?: {
                /** @maxLength 1024 */
                phoneNumber?: string | null;
                /** @maxLength 1024 */
                smsNumber?: string | null;
                /** @maxLength 1024 */
                email?: string | null;
                /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                locale?: string | null;
                /** @maxLength 1024 */
                image?: string | null;
                rSim?: {
                  /** @maxLength 1024 */
                  euicc: string;
                  primary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  secondary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  enabled: boolean;
                } | null;
              };
              /** @default null */
              position?: {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
              available: boolean;
              app?: {
                mode: "off" | "on" | "risk";
              } | null;
              insideZones: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              }[];
              /** @default [] */
              staticResponders?: {
                staticResponder: {
                  /** @format uuid */
                  _id: string;
                  _ref: "StaticResponder";
                };
                priority: number;
              }[];
              type?: "default" | "kiosk";
              /** @format date-time */
              updatedAt: string;
            };
            alarm: {
              /** @format uuid */
              _id: string;
              _ref: "Alarm";
            };
            confirmedHelping: boolean | null;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/alarms/${id}/alarm-helpers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerCreateAlarmPushSubscription
     * @request POST:/alarms/{id}/alarm-push-subscriptions
     * @secure
     */
    alarmsControllerCreateAlarmPushSubscription: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/alarm-push-subscriptions`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerDeleteAlarmPushSubscription
     * @request DELETE:/alarms/{id}/alarm-push-subscriptions
     * @secure
     */
    alarmsControllerDeleteAlarmPushSubscription: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/alarm-push-subscriptions`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerEscalate
     * @request PATCH:/alarms/{id}/escalate
     * @secure
     */
    alarmsControllerEscalate: (
      id: string,
      data: AlarmUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/escalate`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Paginates Overlay
     *
     * @tags Alarm
     * @name AlarmsControllerGetOverlays
     * @request GET:/alarms/{id}/overlays
     * @secure
     */
    alarmsControllerGetOverlays: (
      id: string,
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @format uri */
            imageUrl: string | null;
            /** @default 0 */
            level?: number;
            points: {
              sw: {
                lat: number;
                lng: number;
              };
              ne: {
                lat: number;
                lng: number;
              };
              nw: {
                lat: number;
                lng: number;
              };
              se: {
                lat: number;
                lng: number;
              };
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/alarms/${id}/overlays`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerGetProfessionalResponders
     * @request GET:/alarms/{id}/professional-responder
     * @secure
     */
    alarmsControllerGetProfessionalResponders: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uuid */
          _id: string;
          /** @format date-time */
          createdAt: string;
          /** @format date-time */
          updatedAt: string;
          telluId?: number | null;
          migrationFinalised?: boolean | null;
          /** @format date-time */
          migrationFinalisedDate?: string | null;
          customer: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          /** @default {"type":"parents","extra":[]} */
          share?: {
            type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
            extra?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
          };
          type: "sequrix";
          active: boolean;
          status: "invited" | "accepted" | "rejected" | "started" | "onLocation" | "finished" | "error" | "canceled";
          alarm: {
            /** @format uuid */
            _id: string;
            _ref: "Alarm";
          };
          asset: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          };
          staticResponder: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            telluId?: number | null;
            migrationFinalised?: boolean | null;
            /** @format date-time */
            migrationFinalisedDate?: string | null;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            externalId: string | null;
            contact: {
              /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
              email: string | null;
              /** @maxLength 1024 */
              phone: string | null;
              /** @maxLength 1024 */
              sms: string | null;
              /** @maxLength 1024 */
              camera: string | null;
            };
            schedule: {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            } | null;
            /** @maxLength 1024 */
            migrationKey?: string;
            integrations?: {
              sequrix: {
                enabled: boolean;
                /** @maxLength 1024 */
                objectCode: string;
              };
            };
            statistics?: {
              /**
               * @maxItems 10
               * @minItems 0
               */
              acceptSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              onLocationSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              finishSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountRejected: number;
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountAccepted: number;
            };
            _ref: "StaticResponder";
          };
          statistics: {
            /** @format date-time */
            invitedAt: string;
            /** @format date-time */
            acceptedAt: string | null;
            /** @format date-time */
            rejectedAt: string | null;
            /** @format date-time */
            startedAt: string | null;
            /** @format date-time */
            onLocationAt: string | null;
            /** @format date-time */
            finishedAt: string | null;
          };
          meta: {
            /** @maxLength 4096 */
            comment?: string;
            photos?: {
              /** @format date-time */
              timestamp: string;
              /** @maxLength 1024 */
              url: string;
            }[];
            /** @maxLength 1024 */
            overridePhoneNumber?: string;
          };
          supersededBy: {
            /** @format uuid */
            _id: string;
            _ref: "AlarmProfessionalResponder";
          } | null;
        }[],
        any
      >({
        path: `/alarms/${id}/professional-responder`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerCreateProfessionalResponder
     * @request POST:/alarms/{id}/professional-responder
     * @secure
     */
    alarmsControllerCreateProfessionalResponder: (
      id: string,
      data: AlarmCreateProfessionalResponderDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/professional-responder`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerGetProfessionalResponder
     * @request GET:/alarms/{id}/professional-responder/{aprId}
     * @secure
     */
    alarmsControllerGetProfessionalResponder: (
      id: string,
      aprId: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uuid */
          _id: string;
          /** @format date-time */
          createdAt: string;
          /** @format date-time */
          updatedAt: string;
          telluId?: number | null;
          migrationFinalised?: boolean | null;
          /** @format date-time */
          migrationFinalisedDate?: string | null;
          customer: {
            /** @format uuid */
            _id: string;
            _ref: "Customer";
          };
          /** @default {"type":"parents","extra":[]} */
          share?: {
            type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
            extra?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
          };
          type: "sequrix";
          active: boolean;
          status: "invited" | "accepted" | "rejected" | "started" | "onLocation" | "finished" | "error" | "canceled";
          alarm: {
            /** @format uuid */
            _id: string;
            _ref: "Alarm";
          };
          asset: {
            /** @format uuid */
            _id: string;
            _ref: "Asset";
          };
          staticResponder: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            telluId?: number | null;
            migrationFinalised?: boolean | null;
            /** @format date-time */
            migrationFinalisedDate?: string | null;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            externalId: string | null;
            contact: {
              /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
              email: string | null;
              /** @maxLength 1024 */
              phone: string | null;
              /** @maxLength 1024 */
              sms: string | null;
              /** @maxLength 1024 */
              camera: string | null;
            };
            schedule: {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            } | null;
            /** @maxLength 1024 */
            migrationKey?: string;
            integrations?: {
              sequrix: {
                enabled: boolean;
                /** @maxLength 1024 */
                objectCode: string;
              };
            };
            statistics?: {
              /**
               * @maxItems 10
               * @minItems 0
               */
              acceptSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              onLocationSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              finishSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountRejected: number;
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountAccepted: number;
            };
            _ref: "StaticResponder";
          };
          statistics: {
            /** @format date-time */
            invitedAt: string;
            /** @format date-time */
            acceptedAt: string | null;
            /** @format date-time */
            rejectedAt: string | null;
            /** @format date-time */
            startedAt: string | null;
            /** @format date-time */
            onLocationAt: string | null;
            /** @format date-time */
            finishedAt: string | null;
          };
          meta: {
            /** @maxLength 4096 */
            comment?: string;
            photos?: {
              /** @format date-time */
              timestamp: string;
              /** @maxLength 1024 */
              url: string;
            }[];
            /** @maxLength 1024 */
            overridePhoneNumber?: string;
          };
          supersededBy: {
            /** @format uuid */
            _id: string;
            _ref: "AlarmProfessionalResponder";
          } | null;
        },
        any
      >({
        path: `/alarms/${id}/professional-responder/${aprId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerUpdateProfessionalResponder
     * @request PATCH:/alarms/{id}/professional-responder/{aprId}
     * @secure
     */
    alarmsControllerUpdateProfessionalResponder: (
      id: string,
      aprId: string,
      data: AlarmPatchProfessionalResponderDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/${id}/professional-responder/${aprId}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerAlarmResponseDynamic
     * @request GET:/alarms/{id}/response/dynamic
     * @secure
     */
    alarmsControllerAlarmResponseDynamic: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AlarmResponseResponseDto, any>({
        path: `/alarms/${id}/response/dynamic`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerAlarmResponseStatic
     * @request GET:/alarms/{id}/response/static
     * @secure
     */
    alarmsControllerAlarmResponseStatic: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AlarmResponseResponseDto, any>({
        path: `/alarms/${id}/response/static`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerCount
     * @request GET:/alarms/count
     * @secure
     */
    alarmsControllerCount: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AlarmCountResponseDto, any>({
        path: `/alarms/count`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Alarm
     * @name AlarmsControllerMassAcknowledge
     * @request POST:/alarms/mass-acknowledge
     * @secure
     */
    alarmsControllerMassAcknowledge: (
      data: AlarmMassAcknowledgeDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/alarms/mass-acknowledge`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Searches Alarm
     *
     * @tags Alarm
     * @name AlarmsControllerSearch
     * @request GET:/alarms/search
     * @secure
     */
    alarmsControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
              /** @maxLength 1024 */
              name: string;
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
            asset: {
              /** @format uuid */
              _id: string;
              /** @format date-time */
              createdAt: string;
              /** @format date-time */
              updatedAt: string;
              telluId?: number | null;
              migrationFinalised?: boolean | null;
              /** @format date-time */
              migrationFinalisedDate?: string | null;
              customer: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              };
              /** @default {"type":"parents","extra":[]} */
              share?: {
                type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                extra?: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Customer";
                }[];
              };
              user: {
                /** @format uuid */
                _id: string;
                _ref: "User";
              } | null;
              /** @maxLength 1024 */
              name: string;
              /** @maxLength 1024 */
              externalId?: string | null;
              propertiesOwnership?: "override" | "inherit";
              /** @default {} */
              properties?: {
                /** @maxLength 1024 */
                phoneNumber?: string | null;
                /** @maxLength 1024 */
                smsNumber?: string | null;
                /** @maxLength 1024 */
                email?: string | null;
                /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                locale?: string | null;
                /** @maxLength 1024 */
                image?: string | null;
                rSim?: {
                  /** @maxLength 1024 */
                  euicc: string;
                  primary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  secondary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  enabled: boolean;
                } | null;
              };
              /** @default null */
              position?: {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
              available: boolean;
              /** @default true */
              allowContactOnOwnAlarms?: boolean;
              app?: {
                mode: "off" | "on" | "risk";
              } | null;
              insideZones: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              }[];
              assetGroups: {
                /** @format uuid */
                _id: string;
                _ref: "AssetGroup";
              }[];
              /** @default [] */
              staticResponders?: {
                staticResponder: {
                  /** @format uuid */
                  _id: string;
                  _ref: "StaticResponder";
                };
                priority: number;
              }[];
              /** @format date-time */
              lastObservationAt?: string;
              /** @default false */
              invisibleResponder?: boolean;
              /** @maxLength 4096 */
              description?: string;
              type?: "default" | "kiosk";
            };
            ack: {
              needed: boolean;
              value: boolean;
              ackedBy: {
                /** @format uuid */
                _id: string;
                _ref: "User";
                /** @maxLength 1024 */
                name: string;
              } | null;
              /** @maxLength 1024 */
              comment: string | null;
              /** @format date-time */
              ackedAt: string | null;
            };
            position: {
              type: "Point";
              coordinates: number[];
              properties: {
                accuracy?: number;
                heading?: number;
                headingAccuracy?: number;
                speed?: number;
                speedAccuracy?: number;
                altitude?: number;
                altitudeAccuracy?: number;
                address?: {
                  /** @maxLength 1024 */
                  countryCode?: string | null;
                  /** @maxLength 1024 */
                  state?: string | null;
                  /** @maxLength 1024 */
                  city?: string | null;
                  /** @maxLength 1024 */
                  streetName?: string | null;
                  /** @maxLength 1024 */
                  streetNumber?: string | null;
                  floor?: number | null;
                  /** @maxLength 1024 */
                  formattedAddress?: string;
                };
                /** @format date-time */
                dateTime?: string;
                /** @maxLength 1024 */
                provider?: string;
              };
            };
            severity: "red" | "orange" | "green";
            /** @maxLength 4096 */
            procedure: string | null;
            meta?: {
              observationTrigger?: {
                /** @format uuid */
                _id: string;
                /** @format date-time */
                createdAt: string;
                /** @format date-time */
                updatedAt: string;
                telluId?: number | null;
                migrationFinalised?: boolean | null;
                /** @format date-time */
                migrationFinalisedDate?: string | null;
                customer: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Customer";
                };
                /** @default {"type":"parents","extra":[]} */
                share?: {
                  type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                  extra?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  }[];
                };
                /** @maxLength 1024 */
                name: string;
                eventCodes: string[];
                /** @maxLength 1024 */
                description: string;
                status: "active" | "inactive";
                actions: (
                  | {
                      type: "alarm";
                      config: {
                        severity: "red" | "orange" | "green";
                        type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
                        /** @maxLength 4096 */
                        procedure: string | null;
                        /** @maxLength 1024 */
                        name: string;
                      };
                    }
                  | {
                      type: "sms";
                      config: {
                        toRaw: string[];
                        toContactList: {
                          /** @format uuid */
                          _id: string;
                          _ref: "ContactList";
                        }[];
                        toTriggerUser: boolean;
                        /** @maxLength 1024 */
                        message: string;
                      };
                    }
                  | {
                      type: "email";
                      config: {
                        toRaw: string[];
                        toContactList: {
                          /** @format uuid */
                          _id: string;
                          _ref: "ContactList";
                        }[];
                        toTriggerUser: boolean;
                        /** @maxLength 1024 */
                        message: string;
                        /** @maxLength 1024 */
                        subject: string;
                        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                        locale?: string;
                      };
                    }
                )[];
                filter:
                  | {
                      type: "operator/and";
                      operands: (
                        | {
                            type: "operator/or";
                            operands: (
                              | {
                                  type: "operator/not";
                                  operand:
                                    | {
                                        type: "constraint/asset";
                                        assets: {
                                          /** @format uuid */
                                          _id: string;
                                          _ref: "Asset";
                                        }[];
                                      }
                                    | {
                                        type: "constraint/assetGroup";
                                        groups: {
                                          /** @format uuid */
                                          _id: string;
                                          _ref: "AssetGroup";
                                        }[];
                                      }
                                    | {
                                        type: "constraint/zone";
                                        zone: {
                                          /** @format uuid */
                                          _id: string;
                                          _ref: "Zone";
                                        };
                                      }
                                    | {
                                        type: "constraint/schedule";
                                        schedule: {
                                          entries: {
                                            daysOfWeek: number[];
                                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                            timeStart: string;
                                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                            timeEnd: string;
                                          }[];
                                        };
                                      };
                                }
                              | {
                                  type: "constraint/asset";
                                  assets: {
                                    /** @format uuid */
                                    _id: string;
                                    _ref: "Asset";
                                  }[];
                                }
                              | {
                                  type: "constraint/assetGroup";
                                  groups: {
                                    /** @format uuid */
                                    _id: string;
                                    _ref: "AssetGroup";
                                  }[];
                                }
                              | {
                                  type: "constraint/zone";
                                  zone: {
                                    /** @format uuid */
                                    _id: string;
                                    _ref: "Zone";
                                  };
                                }
                              | {
                                  type: "constraint/schedule";
                                  schedule: {
                                    entries: {
                                      daysOfWeek: number[];
                                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                      timeStart: string;
                                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                      timeEnd: string;
                                    }[];
                                  };
                                }
                            )[];
                          }
                        | {
                            type: "constraint/asset";
                            assets: {
                              /** @format uuid */
                              _id: string;
                              _ref: "Asset";
                            }[];
                          }
                        | {
                            type: "constraint/assetGroup";
                            groups: {
                              /** @format uuid */
                              _id: string;
                              _ref: "AssetGroup";
                            }[];
                          }
                        | {
                            type: "constraint/zone";
                            zone: {
                              /** @format uuid */
                              _id: string;
                              _ref: "Zone";
                            };
                          }
                        | {
                            type: "constraint/schedule";
                            schedule: {
                              entries: {
                                daysOfWeek: number[];
                                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                timeStart: string;
                                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                timeEnd: string;
                              }[];
                            };
                          }
                      )[];
                    }
                  | {
                      type: "constraint/asset";
                      assets: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Asset";
                      }[];
                    }
                  | {
                      type: "constraint/assetGroup";
                      groups: {
                        /** @format uuid */
                        _id: string;
                        _ref: "AssetGroup";
                      }[];
                    }
                  | {
                      type: "constraint/zone";
                      zone: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Zone";
                      };
                    }
                  | {
                      type: "constraint/schedule";
                      schedule: {
                        entries: {
                          daysOfWeek: number[];
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeStart: string;
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeEnd: string;
                        }[];
                      };
                    };
              };
              observation?: {
                /** @format uuid */
                _id: string;
                /** @maxLength 1024 */
                event: string;
                asset: {
                  /** @format uuid */
                  _id: string;
                  /** @format date-time */
                  createdAt: string;
                  /** @format date-time */
                  updatedAt: string;
                  telluId?: number | null;
                  migrationFinalised?: boolean | null;
                  /** @format date-time */
                  migrationFinalisedDate?: string | null;
                  customer: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  /** @default {"type":"parents","extra":[]} */
                  share?: {
                    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                    extra?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    }[];
                  };
                  user: {
                    /** @format uuid */
                    _id: string;
                    _ref: "User";
                  } | null;
                  /** @maxLength 1024 */
                  name: string;
                  /** @maxLength 1024 */
                  externalId?: string | null;
                  propertiesOwnership?: "override" | "inherit";
                  /** @default {} */
                  properties?: {
                    /** @maxLength 1024 */
                    phoneNumber?: string | null;
                    /** @maxLength 1024 */
                    smsNumber?: string | null;
                    /** @maxLength 1024 */
                    email?: string | null;
                    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                    locale?: string | null;
                    /** @maxLength 1024 */
                    image?: string | null;
                    rSim?: {
                      /** @maxLength 1024 */
                      euicc: string;
                      primary: {
                        /** @maxLength 1024 */
                        msisdn: string;
                        /** @maxLength 1024 */
                        iccid: string;
                      };
                      secondary: {
                        /** @maxLength 1024 */
                        msisdn: string;
                        /** @maxLength 1024 */
                        iccid: string;
                      };
                      enabled: boolean;
                    } | null;
                  };
                  /** @default null */
                  position?: {
                    type: "Point";
                    coordinates: number[];
                    properties: {
                      accuracy?: number;
                      heading?: number;
                      headingAccuracy?: number;
                      speed?: number;
                      speedAccuracy?: number;
                      altitude?: number;
                      altitudeAccuracy?: number;
                      address?: {
                        /** @maxLength 1024 */
                        countryCode?: string | null;
                        /** @maxLength 1024 */
                        state?: string | null;
                        /** @maxLength 1024 */
                        city?: string | null;
                        /** @maxLength 1024 */
                        streetName?: string | null;
                        /** @maxLength 1024 */
                        streetNumber?: string | null;
                        floor?: number | null;
                        /** @maxLength 1024 */
                        formattedAddress?: string;
                      };
                      /** @format date-time */
                      dateTime?: string;
                      /** @maxLength 1024 */
                      provider?: string;
                    };
                  };
                  available: boolean;
                  /** @default true */
                  allowContactOnOwnAlarms?: boolean;
                  app?: {
                    mode: "off" | "on" | "risk";
                  } | null;
                  insideZones: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  }[];
                  assetGroups: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[];
                  /** @default [] */
                  staticResponders?: {
                    staticResponder: {
                      /** @format uuid */
                      _id: string;
                      _ref: "StaticResponder";
                    };
                    priority: number;
                  }[];
                  /** @format date-time */
                  lastObservationAt?: string;
                  /** @default false */
                  invisibleResponder?: boolean;
                  /** @maxLength 4096 */
                  description?: string;
                  type?: "default" | "kiosk";
                  _ref: "Asset";
                };
                customer: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Customer";
                };
                device: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Device";
                };
                user: {
                  /** @format uuid */
                  _id: string;
                  _ref: "User";
                };
                position?: {
                  type: "Point";
                  coordinates: number[];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                };
                beacons?:
                  | {
                      /** @maxLength 1024 */
                      code: string;
                      rssi: number;
                      battery?: number;
                      /** @format date-time */
                      dateTime: string;
                      beacon: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Beacon";
                      } | null;
                      calc: number | null;
                      valid: boolean;
                    }[]
                  | {
                      /** @maxLength 1024 */
                      code: string;
                      rssi: number;
                      battery?: number;
                      /** @format date-time */
                      dateTime: string;
                    }[];
                meta?: {
                  asset?: {
                    /** @format uuid */
                    _id: string;
                    /** @format date-time */
                    createdAt: string;
                    /** @format date-time */
                    updatedAt: string;
                    telluId?: number | null;
                    migrationFinalised?: boolean | null;
                    /** @format date-time */
                    migrationFinalisedDate?: string | null;
                    customer: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    /** @default {"type":"parents","extra":[]} */
                    share?: {
                      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                      extra?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      }[];
                    };
                    user: {
                      /** @format uuid */
                      _id: string;
                      _ref: "User";
                    } | null;
                    /** @maxLength 1024 */
                    name: string;
                    /** @maxLength 1024 */
                    externalId?: string | null;
                    propertiesOwnership?: "override" | "inherit";
                    /** @default {} */
                    properties?: {
                      /** @maxLength 1024 */
                      phoneNumber?: string | null;
                      /** @maxLength 1024 */
                      smsNumber?: string | null;
                      /** @maxLength 1024 */
                      email?: string | null;
                      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                      locale?: string | null;
                      /** @maxLength 1024 */
                      image?: string | null;
                      rSim?: {
                        /** @maxLength 1024 */
                        euicc: string;
                        primary: {
                          /** @maxLength 1024 */
                          msisdn: string;
                          /** @maxLength 1024 */
                          iccid: string;
                        };
                        secondary: {
                          /** @maxLength 1024 */
                          msisdn: string;
                          /** @maxLength 1024 */
                          iccid: string;
                        };
                        enabled: boolean;
                      } | null;
                    };
                    /** @default null */
                    position?: {
                      type: "Point";
                      coordinates: number[];
                      properties: {
                        accuracy?: number;
                        heading?: number;
                        headingAccuracy?: number;
                        speed?: number;
                        speedAccuracy?: number;
                        altitude?: number;
                        altitudeAccuracy?: number;
                        address?: {
                          /** @maxLength 1024 */
                          countryCode?: string | null;
                          /** @maxLength 1024 */
                          state?: string | null;
                          /** @maxLength 1024 */
                          city?: string | null;
                          /** @maxLength 1024 */
                          streetName?: string | null;
                          /** @maxLength 1024 */
                          streetNumber?: string | null;
                          floor?: number | null;
                          /** @maxLength 1024 */
                          formattedAddress?: string;
                        };
                        /** @format date-time */
                        dateTime?: string;
                        /** @maxLength 1024 */
                        provider?: string;
                      };
                    };
                    available: boolean;
                    /** @default true */
                    allowContactOnOwnAlarms?: boolean;
                    app?: {
                      mode: "off" | "on" | "risk";
                    } | null;
                    insideZones: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    }[];
                    assetGroups: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                    /** @default [] */
                    staticResponders?: {
                      staticResponder: {
                        /** @format uuid */
                        _id: string;
                        _ref: "StaticResponder";
                      };
                      priority: number;
                    }[];
                    /** @format date-time */
                    lastObservationAt?: string;
                    /** @default false */
                    invisibleResponder?: boolean;
                    /** @maxLength 4096 */
                    description?: string;
                    type?: "default" | "kiosk";
                  };
                  battery?: number;
                  rssi?: number;
                  snr?: number;
                  /** @format date-time */
                  dateTime?: string;
                  /** @format date-time */
                  deviceDateTime?: string;
                  /** @format date-time */
                  gatewayDateTime?: string;
                  insideGpsZones?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  }[];
                  insideIpsZones?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  }[];
                  zone?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  };
                  callRequest?: boolean;
                  forceLoraCustomer?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  knnResult?: {
                    distance: number;
                    debugInfo?: any;
                  };
                  available?: boolean;
                };
                deltas?: {
                  main: {
                    /** @format date-time */
                    start: string;
                    /** @format date-time */
                    end: string;
                    value?: number;
                  };
                  steps?: {
                    gps?: {
                      /** @format date-time */
                      start: string;
                      /** @format date-time */
                      end: string;
                      value?: number;
                    };
                    ips?: {
                      /** @format date-time */
                      start: string;
                      /** @format date-time */
                      end: string;
                      value?: number;
                    };
                    context?: {
                      /** @format date-time */
                      start: string;
                      /** @format date-time */
                      end: string;
                      value?: number;
                    };
                  };
                };
                validPosition?: {
                  value: boolean;
                  /** @maxLength 1024 */
                  reason?: string;
                };
                isSystemEvent?: boolean;
              };
              frozenInfo?: {
                staticResponse: {
                  type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
                  priority: number;
                  zone?: {
                    /** @format uuid */
                    _id: string;
                    customer?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
                    location:
                      | {
                          type: "Point";
                          coordinates: number[];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        }
                      | {
                          type: "Polygon";
                          coordinates: number[][][];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        }
                      | {
                          type: "MultiPolygon";
                          coordinates: number[][][][];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        };
                    /** @maxLength 1024 */
                    name: string;
                    staticResponders: {
                      staticResponder: {
                        /** @format uuid */
                        _id: string;
                        _ref: "StaticResponder";
                      };
                      priority: number;
                    }[];
                  };
                  responders: {
                    info: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset" | "StaticResponder";
                      /** @maxLength 1024 */
                      name: string;
                      /** @maxLength 1024 */
                      image: string | null;
                      position: {
                        type: "Point";
                        coordinates: number[];
                        properties: {
                          accuracy?: number;
                          heading?: number;
                          headingAccuracy?: number;
                          speed?: number;
                          speedAccuracy?: number;
                          altitude?: number;
                          altitudeAccuracy?: number;
                          address?: {
                            /** @maxLength 1024 */
                            countryCode?: string | null;
                            /** @maxLength 1024 */
                            state?: string | null;
                            /** @maxLength 1024 */
                            city?: string | null;
                            /** @maxLength 1024 */
                            streetName?: string | null;
                            /** @maxLength 1024 */
                            streetNumber?: string | null;
                            floor?: number | null;
                            /** @maxLength 1024 */
                            formattedAddress?: string;
                          };
                          /** @format date-time */
                          dateTime?: string;
                          /** @maxLength 1024 */
                          provider?: string;
                        };
                      };
                      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                      locale: string | null;
                      /** @maxLength 1024 */
                      contactCode: string | null;
                    };
                    distanceKm: number | null;
                    actions: {
                      /** @maxLength 1024 */
                      call: string | null;
                      /** @maxLength 1024 */
                      sms: string | null;
                      /** @maxLength 1024 */
                      email: string | null;
                      notification: boolean;
                    };
                    priority: number;
                    professionalResponder?: {
                      available: boolean;
                      inProgress: boolean;
                    };
                  }[];
                }[];
                dynamicResponse: {
                  type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
                  priority: number;
                  zone?: {
                    /** @format uuid */
                    _id: string;
                    customer?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
                    location:
                      | {
                          type: "Point";
                          coordinates: number[];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        }
                      | {
                          type: "Polygon";
                          coordinates: number[][][];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        }
                      | {
                          type: "MultiPolygon";
                          coordinates: number[][][][];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        };
                    /** @maxLength 1024 */
                    name: string;
                    staticResponders: {
                      staticResponder: {
                        /** @format uuid */
                        _id: string;
                        _ref: "StaticResponder";
                      };
                      priority: number;
                    }[];
                  };
                  responders: {
                    info: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset" | "StaticResponder";
                      /** @maxLength 1024 */
                      name: string;
                      /** @maxLength 1024 */
                      image: string | null;
                      position: {
                        type: "Point";
                        coordinates: number[];
                        properties: {
                          accuracy?: number;
                          heading?: number;
                          headingAccuracy?: number;
                          speed?: number;
                          speedAccuracy?: number;
                          altitude?: number;
                          altitudeAccuracy?: number;
                          address?: {
                            /** @maxLength 1024 */
                            countryCode?: string | null;
                            /** @maxLength 1024 */
                            state?: string | null;
                            /** @maxLength 1024 */
                            city?: string | null;
                            /** @maxLength 1024 */
                            streetName?: string | null;
                            /** @maxLength 1024 */
                            streetNumber?: string | null;
                            floor?: number | null;
                            /** @maxLength 1024 */
                            formattedAddress?: string;
                          };
                          /** @format date-time */
                          dateTime?: string;
                          /** @maxLength 1024 */
                          provider?: string;
                        };
                      };
                      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                      locale: string | null;
                      /** @maxLength 1024 */
                      contactCode: string | null;
                    };
                    distanceKm: number | null;
                    actions: {
                      /** @maxLength 1024 */
                      call: string | null;
                      /** @maxLength 1024 */
                      sms: string | null;
                      /** @maxLength 1024 */
                      email: string | null;
                      notification: boolean;
                    };
                    priority: number;
                    professionalResponder?: {
                      available: boolean;
                      inProgress: boolean;
                    };
                  }[];
                }[];
                professionalResponders?: {
                  /** @format uuid */
                  _id: string;
                  /** @format date-time */
                  createdAt: string;
                  /** @format date-time */
                  updatedAt: string;
                  telluId?: number | null;
                  migrationFinalised?: boolean | null;
                  /** @format date-time */
                  migrationFinalisedDate?: string | null;
                  customer: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  /** @default {"type":"parents","extra":[]} */
                  share?: {
                    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                    extra?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    }[];
                  };
                  type: "sequrix";
                  active: boolean;
                  status:
                    | "invited"
                    | "accepted"
                    | "rejected"
                    | "started"
                    | "onLocation"
                    | "finished"
                    | "error"
                    | "canceled";
                  alarm: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Alarm";
                  };
                  asset: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  };
                  staticResponder: {
                    /** @format uuid */
                    _id: string;
                    /** @format date-time */
                    createdAt: string;
                    /** @format date-time */
                    updatedAt: string;
                    telluId?: number | null;
                    migrationFinalised?: boolean | null;
                    /** @format date-time */
                    migrationFinalisedDate?: string | null;
                    customer: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    /** @default {"type":"parents","extra":[]} */
                    share?: {
                      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                      extra?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      }[];
                    };
                    /** @maxLength 1024 */
                    name: string;
                    /** @maxLength 1024 */
                    externalId: string | null;
                    contact: {
                      /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
                      email: string | null;
                      /** @maxLength 1024 */
                      phone: string | null;
                      /** @maxLength 1024 */
                      sms: string | null;
                      /** @maxLength 1024 */
                      camera: string | null;
                    };
                    schedule: {
                      entries: {
                        daysOfWeek: number[];
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeStart: string;
                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                        timeEnd: string;
                      }[];
                    } | null;
                    /** @maxLength 1024 */
                    migrationKey?: string;
                    integrations?: {
                      sequrix: {
                        enabled: boolean;
                        /** @maxLength 1024 */
                        objectCode: string;
                      };
                    };
                    statistics?: {
                      /**
                       * @maxItems 10
                       * @minItems 0
                       */
                      acceptSpeeds: {
                        /** @format date-time */
                        timestamp: string;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        speedMs: number;
                      }[];
                      /**
                       * @maxItems 10
                       * @minItems 0
                       */
                      onLocationSpeeds: {
                        /** @format date-time */
                        timestamp: string;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        speedMs: number;
                      }[];
                      /**
                       * @maxItems 10
                       * @minItems 0
                       */
                      finishSpeeds: {
                        /** @format date-time */
                        timestamp: string;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        speedMs: number;
                      }[];
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      amountRejected: number;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      amountAccepted: number;
                    };
                    _ref: "StaticResponder";
                  };
                  statistics: {
                    /** @format date-time */
                    invitedAt: string;
                    /** @format date-time */
                    acceptedAt: string | null;
                    /** @format date-time */
                    rejectedAt: string | null;
                    /** @format date-time */
                    startedAt: string | null;
                    /** @format date-time */
                    onLocationAt: string | null;
                    /** @format date-time */
                    finishedAt: string | null;
                  };
                  meta: {
                    /** @maxLength 4096 */
                    comment?: string;
                    photos?: {
                      /** @format date-time */
                      timestamp: string;
                      /** @maxLength 1024 */
                      url: string;
                    }[];
                    /** @maxLength 1024 */
                    overridePhoneNumber?: string;
                  };
                  supersededBy: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AlarmProfessionalResponder";
                  } | null;
                }[];
              };
            };
            alarmCenter: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
              /** @maxLength 1024 */
              name: string;
            } | null;
            /** @format date-time */
            lastMergedAt?: string;
            /** @format date-time */
            lastPublishedToStreamAt?: string;
            mergeHistory?: {
              /** @format uuid */
              _id: string;
              /** @maxLength 1024 */
              name: string;
              /** @format date-time */
              createdAt: string;
              position: {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
              meta?: {
                observationTrigger?: {
                  /** @format uuid */
                  _id: string;
                  /** @format date-time */
                  createdAt: string;
                  /** @format date-time */
                  updatedAt: string;
                  telluId?: number | null;
                  migrationFinalised?: boolean | null;
                  /** @format date-time */
                  migrationFinalisedDate?: string | null;
                  customer: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  /** @default {"type":"parents","extra":[]} */
                  share?: {
                    type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                    extra?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    }[];
                  };
                  /** @maxLength 1024 */
                  name: string;
                  eventCodes: string[];
                  /** @maxLength 1024 */
                  description: string;
                  status: "active" | "inactive";
                  actions: (
                    | {
                        type: "alarm";
                        config: {
                          severity: "red" | "orange" | "green";
                          type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
                          /** @maxLength 4096 */
                          procedure: string | null;
                          /** @maxLength 1024 */
                          name: string;
                        };
                      }
                    | {
                        type: "sms";
                        config: {
                          toRaw: string[];
                          toContactList: {
                            /** @format uuid */
                            _id: string;
                            _ref: "ContactList";
                          }[];
                          toTriggerUser: boolean;
                          /** @maxLength 1024 */
                          message: string;
                        };
                      }
                    | {
                        type: "email";
                        config: {
                          toRaw: string[];
                          toContactList: {
                            /** @format uuid */
                            _id: string;
                            _ref: "ContactList";
                          }[];
                          toTriggerUser: boolean;
                          /** @maxLength 1024 */
                          message: string;
                          /** @maxLength 1024 */
                          subject: string;
                          /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                          locale?: string;
                        };
                      }
                  )[];
                  filter:
                    | {
                        type: "constraint/asset";
                        assets: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Asset";
                        }[];
                      }
                    | {
                        type: "constraint/assetGroup";
                        groups: {
                          /** @format uuid */
                          _id: string;
                          _ref: "AssetGroup";
                        }[];
                      }
                    | {
                        type: "constraint/zone";
                        zone: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Zone";
                        };
                      }
                    | {
                        type: "constraint/schedule";
                        schedule: {
                          entries: {
                            daysOfWeek: number[];
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeStart: string;
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeEnd: string;
                          }[];
                        };
                      };
                };
                observation?: {
                  /** @format uuid */
                  _id: string;
                  /** @maxLength 1024 */
                  event: string;
                  asset: {
                    /** @format uuid */
                    _id: string;
                    /** @format date-time */
                    createdAt: string;
                    /** @format date-time */
                    updatedAt: string;
                    telluId?: number | null;
                    migrationFinalised?: boolean | null;
                    /** @format date-time */
                    migrationFinalisedDate?: string | null;
                    customer: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    /** @default {"type":"parents","extra":[]} */
                    share?: {
                      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                      extra?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      }[];
                    };
                    user: {
                      /** @format uuid */
                      _id: string;
                      _ref: "User";
                    } | null;
                    /** @maxLength 1024 */
                    name: string;
                    /** @maxLength 1024 */
                    externalId?: string | null;
                    propertiesOwnership?: "override" | "inherit";
                    /** @default {} */
                    properties?: {
                      /** @maxLength 1024 */
                      phoneNumber?: string | null;
                      /** @maxLength 1024 */
                      smsNumber?: string | null;
                      /** @maxLength 1024 */
                      email?: string | null;
                      /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                      locale?: string | null;
                      /** @maxLength 1024 */
                      image?: string | null;
                      rSim?: {
                        /** @maxLength 1024 */
                        euicc: string;
                        primary: {
                          /** @maxLength 1024 */
                          msisdn: string;
                          /** @maxLength 1024 */
                          iccid: string;
                        };
                        secondary: {
                          /** @maxLength 1024 */
                          msisdn: string;
                          /** @maxLength 1024 */
                          iccid: string;
                        };
                        enabled: boolean;
                      } | null;
                    };
                    /** @default null */
                    position?: {
                      type: "Point";
                      coordinates: number[];
                      properties: {
                        accuracy?: number;
                        heading?: number;
                        headingAccuracy?: number;
                        speed?: number;
                        speedAccuracy?: number;
                        altitude?: number;
                        altitudeAccuracy?: number;
                        address?: {
                          /** @maxLength 1024 */
                          countryCode?: string | null;
                          /** @maxLength 1024 */
                          state?: string | null;
                          /** @maxLength 1024 */
                          city?: string | null;
                          /** @maxLength 1024 */
                          streetName?: string | null;
                          /** @maxLength 1024 */
                          streetNumber?: string | null;
                          floor?: number | null;
                          /** @maxLength 1024 */
                          formattedAddress?: string;
                        };
                        /** @format date-time */
                        dateTime?: string;
                        /** @maxLength 1024 */
                        provider?: string;
                      };
                    };
                    available: boolean;
                    /** @default true */
                    allowContactOnOwnAlarms?: boolean;
                    app?: {
                      mode: "off" | "on" | "risk";
                    } | null;
                    insideZones: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    }[];
                    assetGroups: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                    /** @default [] */
                    staticResponders?: {
                      staticResponder: {
                        /** @format uuid */
                        _id: string;
                        _ref: "StaticResponder";
                      };
                      priority: number;
                    }[];
                    /** @format date-time */
                    lastObservationAt?: string;
                    /** @default false */
                    invisibleResponder?: boolean;
                    /** @maxLength 4096 */
                    description?: string;
                    type?: "default" | "kiosk";
                    _ref: "Asset";
                  };
                  customer: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  };
                  device: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Device";
                  };
                  user: {
                    /** @format uuid */
                    _id: string;
                    _ref: "User";
                  };
                  position?: {
                    type: "Point";
                    coordinates: number[];
                    properties: {
                      accuracy?: number;
                      heading?: number;
                      headingAccuracy?: number;
                      speed?: number;
                      speedAccuracy?: number;
                      altitude?: number;
                      altitudeAccuracy?: number;
                      address?: {
                        /** @maxLength 1024 */
                        countryCode?: string | null;
                        /** @maxLength 1024 */
                        state?: string | null;
                        /** @maxLength 1024 */
                        city?: string | null;
                        /** @maxLength 1024 */
                        streetName?: string | null;
                        /** @maxLength 1024 */
                        streetNumber?: string | null;
                        floor?: number | null;
                        /** @maxLength 1024 */
                        formattedAddress?: string;
                      };
                      /** @format date-time */
                      dateTime?: string;
                      /** @maxLength 1024 */
                      provider?: string;
                    };
                  };
                  beacons?:
                    | {
                        /** @maxLength 1024 */
                        code: string;
                        rssi: number;
                        battery?: number;
                        /** @format date-time */
                        dateTime: string;
                        beacon: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Beacon";
                        } | null;
                        calc: number | null;
                        valid: boolean;
                      }[]
                    | {
                        /** @maxLength 1024 */
                        code: string;
                        rssi: number;
                        battery?: number;
                        /** @format date-time */
                        dateTime: string;
                      }[];
                  meta?: {
                    asset?: {
                      /** @format uuid */
                      _id: string;
                      /** @format date-time */
                      createdAt: string;
                      /** @format date-time */
                      updatedAt: string;
                      telluId?: number | null;
                      migrationFinalised?: boolean | null;
                      /** @format date-time */
                      migrationFinalisedDate?: string | null;
                      customer: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      };
                      /** @default {"type":"parents","extra":[]} */
                      share?: {
                        type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                        extra?: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Customer";
                        }[];
                      };
                      user: {
                        /** @format uuid */
                        _id: string;
                        _ref: "User";
                      } | null;
                      /** @maxLength 1024 */
                      name: string;
                      /** @maxLength 1024 */
                      externalId?: string | null;
                      propertiesOwnership?: "override" | "inherit";
                      /** @default {} */
                      properties?: {
                        /** @maxLength 1024 */
                        phoneNumber?: string | null;
                        /** @maxLength 1024 */
                        smsNumber?: string | null;
                        /** @maxLength 1024 */
                        email?: string | null;
                        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                        locale?: string | null;
                        /** @maxLength 1024 */
                        image?: string | null;
                        rSim?: {
                          /** @maxLength 1024 */
                          euicc: string;
                          primary: {
                            /** @maxLength 1024 */
                            msisdn: string;
                            /** @maxLength 1024 */
                            iccid: string;
                          };
                          secondary: {
                            /** @maxLength 1024 */
                            msisdn: string;
                            /** @maxLength 1024 */
                            iccid: string;
                          };
                          enabled: boolean;
                        } | null;
                      };
                      /** @default null */
                      position?: {
                        type: "Point";
                        coordinates: number[];
                        properties: {
                          accuracy?: number;
                          heading?: number;
                          headingAccuracy?: number;
                          speed?: number;
                          speedAccuracy?: number;
                          altitude?: number;
                          altitudeAccuracy?: number;
                          address?: {
                            /** @maxLength 1024 */
                            countryCode?: string | null;
                            /** @maxLength 1024 */
                            state?: string | null;
                            /** @maxLength 1024 */
                            city?: string | null;
                            /** @maxLength 1024 */
                            streetName?: string | null;
                            /** @maxLength 1024 */
                            streetNumber?: string | null;
                            floor?: number | null;
                            /** @maxLength 1024 */
                            formattedAddress?: string;
                          };
                          /** @format date-time */
                          dateTime?: string;
                          /** @maxLength 1024 */
                          provider?: string;
                        };
                      };
                      available: boolean;
                      /** @default true */
                      allowContactOnOwnAlarms?: boolean;
                      app?: {
                        mode: "off" | "on" | "risk";
                      } | null;
                      insideZones: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Zone";
                      }[];
                      assetGroups: {
                        /** @format uuid */
                        _id: string;
                        _ref: "AssetGroup";
                      }[];
                      /** @default [] */
                      staticResponders?: {
                        staticResponder: {
                          /** @format uuid */
                          _id: string;
                          _ref: "StaticResponder";
                        };
                        priority: number;
                      }[];
                      /** @format date-time */
                      lastObservationAt?: string;
                      /** @default false */
                      invisibleResponder?: boolean;
                      /** @maxLength 4096 */
                      description?: string;
                      type?: "default" | "kiosk";
                    };
                    battery?: number;
                    rssi?: number;
                    snr?: number;
                    /** @format date-time */
                    dateTime?: string;
                    /** @format date-time */
                    deviceDateTime?: string;
                    /** @format date-time */
                    gatewayDateTime?: string;
                    insideGpsZones?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    }[];
                    insideIpsZones?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    }[];
                    zone?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    };
                    callRequest?: boolean;
                    forceLoraCustomer?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    knnResult?: {
                      distance: number;
                      debugInfo?: any;
                    };
                    available?: boolean;
                  };
                  deltas?: {
                    main: {
                      /** @format date-time */
                      start: string;
                      /** @format date-time */
                      end: string;
                      value?: number;
                    };
                    steps?: {
                      gps?: {
                        /** @format date-time */
                        start: string;
                        /** @format date-time */
                        end: string;
                        value?: number;
                      };
                      ips?: {
                        /** @format date-time */
                        start: string;
                        /** @format date-time */
                        end: string;
                        value?: number;
                      };
                      context?: {
                        /** @format date-time */
                        start: string;
                        /** @format date-time */
                        end: string;
                        value?: number;
                      };
                    };
                  };
                  validPosition?: {
                    value: boolean;
                    /** @maxLength 1024 */
                    reason?: string;
                  };
                  isSystemEvent?: boolean;
                };
                frozenInfo?: {
                  staticResponse: {
                    type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
                    priority: number;
                    zone?: {
                      /** @format uuid */
                      _id: string;
                      customer?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      };
                      type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
                      location:
                        | {
                            type: "Point";
                            coordinates: number[];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          }
                        | {
                            type: "Polygon";
                            coordinates: number[][][];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          }
                        | {
                            type: "MultiPolygon";
                            coordinates: number[][][][];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          };
                      /** @maxLength 1024 */
                      name: string;
                      staticResponders: {
                        staticResponder: {
                          /** @format uuid */
                          _id: string;
                          _ref: "StaticResponder";
                        };
                        priority: number;
                      }[];
                    };
                    responders: {
                      info: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Asset" | "StaticResponder";
                        /** @maxLength 1024 */
                        name: string;
                        /** @maxLength 1024 */
                        image: string | null;
                        position: {
                          type: "Point";
                          coordinates: number[];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        };
                        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                        locale: string | null;
                        /** @maxLength 1024 */
                        contactCode: string | null;
                      };
                      distanceKm: number | null;
                      actions: {
                        /** @maxLength 1024 */
                        call: string | null;
                        /** @maxLength 1024 */
                        sms: string | null;
                        /** @maxLength 1024 */
                        email: string | null;
                        notification: boolean;
                      };
                      priority: number;
                      professionalResponder?: {
                        available: boolean;
                        inProgress: boolean;
                      };
                    }[];
                  }[];
                  dynamicResponse: {
                    type: "nearby" | "personal" | "zone" | "police" | "alarmProfessionalResponder";
                    priority: number;
                    zone?: {
                      /** @format uuid */
                      _id: string;
                      customer?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      };
                      type?: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
                      location:
                        | {
                            type: "Point";
                            coordinates: number[];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          }
                        | {
                            type: "Polygon";
                            coordinates: number[][][];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          }
                        | {
                            type: "MultiPolygon";
                            coordinates: number[][][][];
                            properties: {
                              accuracy?: number;
                              heading?: number;
                              headingAccuracy?: number;
                              speed?: number;
                              speedAccuracy?: number;
                              altitude?: number;
                              altitudeAccuracy?: number;
                              address?: {
                                /** @maxLength 1024 */
                                countryCode?: string | null;
                                /** @maxLength 1024 */
                                state?: string | null;
                                /** @maxLength 1024 */
                                city?: string | null;
                                /** @maxLength 1024 */
                                streetName?: string | null;
                                /** @maxLength 1024 */
                                streetNumber?: string | null;
                                floor?: number | null;
                                /** @maxLength 1024 */
                                formattedAddress?: string;
                              };
                              /** @format date-time */
                              dateTime?: string;
                              /** @maxLength 1024 */
                              provider?: string;
                            };
                          };
                      /** @maxLength 1024 */
                      name: string;
                      staticResponders: {
                        staticResponder: {
                          /** @format uuid */
                          _id: string;
                          _ref: "StaticResponder";
                        };
                        priority: number;
                      }[];
                    };
                    responders: {
                      info: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Asset" | "StaticResponder";
                        /** @maxLength 1024 */
                        name: string;
                        /** @maxLength 1024 */
                        image: string | null;
                        position: {
                          type: "Point";
                          coordinates: number[];
                          properties: {
                            accuracy?: number;
                            heading?: number;
                            headingAccuracy?: number;
                            speed?: number;
                            speedAccuracy?: number;
                            altitude?: number;
                            altitudeAccuracy?: number;
                            address?: {
                              /** @maxLength 1024 */
                              countryCode?: string | null;
                              /** @maxLength 1024 */
                              state?: string | null;
                              /** @maxLength 1024 */
                              city?: string | null;
                              /** @maxLength 1024 */
                              streetName?: string | null;
                              /** @maxLength 1024 */
                              streetNumber?: string | null;
                              floor?: number | null;
                              /** @maxLength 1024 */
                              formattedAddress?: string;
                            };
                            /** @format date-time */
                            dateTime?: string;
                            /** @maxLength 1024 */
                            provider?: string;
                          };
                        };
                        /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                        locale: string | null;
                        /** @maxLength 1024 */
                        contactCode: string | null;
                      };
                      distanceKm: number | null;
                      actions: {
                        /** @maxLength 1024 */
                        call: string | null;
                        /** @maxLength 1024 */
                        sms: string | null;
                        /** @maxLength 1024 */
                        email: string | null;
                        notification: boolean;
                      };
                      priority: number;
                      professionalResponder?: {
                        available: boolean;
                        inProgress: boolean;
                      };
                    }[];
                  }[];
                  professionalResponders?: {
                    /** @format uuid */
                    _id: string;
                    /** @format date-time */
                    createdAt: string;
                    /** @format date-time */
                    updatedAt: string;
                    telluId?: number | null;
                    migrationFinalised?: boolean | null;
                    /** @format date-time */
                    migrationFinalisedDate?: string | null;
                    customer: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    };
                    /** @default {"type":"parents","extra":[]} */
                    share?: {
                      type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                      extra?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      }[];
                    };
                    type: "sequrix";
                    active: boolean;
                    status:
                      | "invited"
                      | "accepted"
                      | "rejected"
                      | "started"
                      | "onLocation"
                      | "finished"
                      | "error"
                      | "canceled";
                    alarm: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Alarm";
                    };
                    asset: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    };
                    staticResponder: {
                      /** @format uuid */
                      _id: string;
                      /** @format date-time */
                      createdAt: string;
                      /** @format date-time */
                      updatedAt: string;
                      telluId?: number | null;
                      migrationFinalised?: boolean | null;
                      /** @format date-time */
                      migrationFinalisedDate?: string | null;
                      customer: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Customer";
                      };
                      /** @default {"type":"parents","extra":[]} */
                      share?: {
                        type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                        extra?: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Customer";
                        }[];
                      };
                      /** @maxLength 1024 */
                      name: string;
                      /** @maxLength 1024 */
                      externalId: string | null;
                      contact: {
                        /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
                        email: string | null;
                        /** @maxLength 1024 */
                        phone: string | null;
                        /** @maxLength 1024 */
                        sms: string | null;
                        /** @maxLength 1024 */
                        camera: string | null;
                      };
                      schedule: {
                        entries: {
                          daysOfWeek: number[];
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeStart: string;
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeEnd: string;
                        }[];
                      } | null;
                      /** @maxLength 1024 */
                      migrationKey?: string;
                      integrations?: {
                        sequrix: {
                          enabled: boolean;
                          /** @maxLength 1024 */
                          objectCode: string;
                        };
                      };
                      statistics?: {
                        /**
                         * @maxItems 10
                         * @minItems 0
                         */
                        acceptSpeeds: {
                          /** @format date-time */
                          timestamp: string;
                          /**
                           * @min 0
                           * @exclusiveMin false
                           */
                          speedMs: number;
                        }[];
                        /**
                         * @maxItems 10
                         * @minItems 0
                         */
                        onLocationSpeeds: {
                          /** @format date-time */
                          timestamp: string;
                          /**
                           * @min 0
                           * @exclusiveMin false
                           */
                          speedMs: number;
                        }[];
                        /**
                         * @maxItems 10
                         * @minItems 0
                         */
                        finishSpeeds: {
                          /** @format date-time */
                          timestamp: string;
                          /**
                           * @min 0
                           * @exclusiveMin false
                           */
                          speedMs: number;
                        }[];
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        amountRejected: number;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        amountAccepted: number;
                      };
                      _ref: "StaticResponder";
                    };
                    statistics: {
                      /** @format date-time */
                      invitedAt: string;
                      /** @format date-time */
                      acceptedAt: string | null;
                      /** @format date-time */
                      rejectedAt: string | null;
                      /** @format date-time */
                      startedAt: string | null;
                      /** @format date-time */
                      onLocationAt: string | null;
                      /** @format date-time */
                      finishedAt: string | null;
                    };
                    meta: {
                      /** @maxLength 4096 */
                      comment?: string;
                      photos?: {
                        /** @format date-time */
                        timestamp: string;
                        /** @maxLength 1024 */
                        url: string;
                      }[];
                      /** @maxLength 1024 */
                      overridePhoneNumber?: string;
                    };
                    supersededBy: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AlarmProfessionalResponder";
                    } | null;
                  }[];
                };
              };
            }[];
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/alarms/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  assetGroups = {
    /**
     * @description Creates a AssetGroup
     *
     * @tags AssetGroup
     * @name AssetGroupsControllerCreate
     * @request POST:/asset-groups
     * @secure
     */
    assetGroupsControllerCreate: (
      data: AssetGroupCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetGroupOutputDto, any>({
        path: `/asset-groups`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates AssetGroup
     *
     * @tags AssetGroup
     * @name AssetGroupsControllerPaginate
     * @request GET:/asset-groups
     * @secure
     */
    assetGroupsControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            description?: string | null;
            assets: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            clientConfig?: {
              app?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
              kiosk?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/asset-groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a AssetGroup by id
     *
     * @tags AssetGroup
     * @name AssetGroupsControllerFindById
     * @request GET:/asset-groups/{id}
     * @secure
     */
    assetGroupsControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetGroupOutputDto, any>({
        path: `/asset-groups/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a AssetGroup
     *
     * @tags AssetGroup
     * @name AssetGroupsControllerUpdate
     * @request PATCH:/asset-groups/{id}
     * @secure
     */
    assetGroupsControllerUpdate: (
      id: string,
      data: AssetGroupUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetGroupOutputDto, any>({
        path: `/asset-groups/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a AssetGroup by id
     *
     * @tags AssetGroup
     * @name AssetGroupsControllerRemove
     * @request DELETE:/asset-groups/{id}
     * @secure
     */
    assetGroupsControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/asset-groups/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssetGroup
     * @name AssetGroupsControllerAddAssets
     * @request POST:/asset-groups/{id}/assets
     * @secure
     */
    assetGroupsControllerAddAssets: (
      id: string,
      data: AssetGroupAddAssetsDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/asset-groups/${id}/assets`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags AssetGroup
     * @name AssetGroupsControllerMassUpdate
     * @request POST:/asset-groups/mass-update
     * @secure
     */
    assetGroupsControllerMassUpdate: (
      data: AssetGroupMassUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/asset-groups/mass-update`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Searches AssetGroup
     *
     * @tags AssetGroup
     * @name AssetGroupsControllerSearch
     * @request GET:/asset-groups/search
     * @secure
     */
    assetGroupsControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            description?: string | null;
            assets: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            clientConfig?: {
              app?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
              kiosk?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/asset-groups/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  assets = {
    /**
     * @description Creates a Asset
     *
     * @tags Asset
     * @name AssetsControllerCreate
     * @request POST:/assets
     * @secure
     */
    assetsControllerCreate: (
      data: AssetCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetOutputDto, any>({
        path: `/assets`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Asset
     *
     * @tags Asset
     * @name AssetsControllerPaginate
     * @request GET:/assets
     * @secure
     */
    assetsControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            user: {
              /** @format uuid */
              _id: string;
              _ref: "User";
            } | null;
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            externalId?: string | null;
            propertiesOwnership?: "override" | "inherit";
            /** @default {} */
            properties?: {
              /** @maxLength 1024 */
              phoneNumber?: string | null;
              /** @maxLength 1024 */
              smsNumber?: string | null;
              /** @maxLength 1024 */
              email?: string | null;
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale?: string | null;
              /** @maxLength 1024 */
              image?: string | null;
              rSim?: {
                /** @maxLength 1024 */
                euicc: string;
                primary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                secondary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                enabled: boolean;
              } | null;
            };
            /** @default null */
            position?: {
              type: "Point";
              coordinates: number[];
              properties: {
                accuracy?: number;
                heading?: number;
                headingAccuracy?: number;
                speed?: number;
                speedAccuracy?: number;
                altitude?: number;
                altitudeAccuracy?: number;
                address?: {
                  /** @maxLength 1024 */
                  countryCode?: string | null;
                  /** @maxLength 1024 */
                  state?: string | null;
                  /** @maxLength 1024 */
                  city?: string | null;
                  /** @maxLength 1024 */
                  streetName?: string | null;
                  /** @maxLength 1024 */
                  streetNumber?: string | null;
                  floor?: number | null;
                  /** @maxLength 1024 */
                  formattedAddress?: string;
                };
                /** @format date-time */
                dateTime?: string;
                /** @maxLength 1024 */
                provider?: string;
              };
            };
            available: boolean;
            /** @default true */
            allowContactOnOwnAlarms?: boolean;
            app?: {
              mode: "off" | "on" | "risk";
            } | null;
            insideZones: {
              /** @format uuid */
              _id: string;
              _ref: "Zone";
            }[];
            assetGroups: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
            /** @default [] */
            staticResponders?: {
              staticResponder: {
                /** @format uuid */
                _id: string;
                _ref: "StaticResponder";
              };
              priority: number;
            }[];
            /** @format date-time */
            lastObservationAt?: string;
            /** @default false */
            invisibleResponder?: boolean;
            /** @maxLength 4096 */
            description?: string;
            type?: "default" | "kiosk";
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/assets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Asset by id
     *
     * @tags Asset
     * @name AssetsControllerFindById
     * @request GET:/assets/{id}
     * @secure
     */
    assetsControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetOutputDto, any>({
        path: `/assets/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a Asset
     *
     * @tags Asset
     * @name AssetsControllerUpdate
     * @request PATCH:/assets/{id}
     * @secure
     */
    assetsControllerUpdate: (
      id: string,
      data: AssetUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<AssetOutputDto, any>({
        path: `/assets/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a Asset by id
     *
     * @tags Asset
     * @name AssetsControllerRemove
     * @request DELETE:/assets/{id}
     * @secure
     */
    assetsControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/assets/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Asset
     * @name AssetsControllerDeleteViaApp
     * @request DELETE:/assets/app
     * @secure
     */
    assetsControllerDeleteViaApp: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/assets/app`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Asset
     * @name AssetsControllerDeleteAppUser
     * @request DELETE:/assets/app-user
     * @secure
     */
    assetsControllerDeleteAppUser: (
      data: AssetAppUserDeleteDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/assets/app-user`,
        method: "DELETE",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Asset
     * @name AssetsControllerAppMode
     * @request PATCH:/assets/app/mode
     * @secure
     */
    assetsControllerAppMode: (
      data: AssetAppUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/assets/app/mode`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Asset
     * @name AssetsControllerAddPhoto
     * @request POST:/assets/app/photo
     * @secure
     */
    assetsControllerAddPhoto: (
      query?: {
        /** Asset id parameter, optional */
        id?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageUploadDto, any>({
        path: `/assets/app/photo`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Asset
     * @name AssetsControllerAtsResponse
     * @request POST:/assets/ats-response
     * @secure
     */
    assetsControllerAtsResponse: (
      data: AssetGetAtsResponseDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/assets/ats-response`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Asset
     * @name AssetsControllerMoveAsset
     * @request POST:/assets/move
     * @secure
     */
    assetsControllerMoveAsset: (
      data: AssetMoveDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/assets/move`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Searches Asset
     *
     * @tags Asset
     * @name AssetsControllerSearch
     * @request GET:/assets/search
     * @secure
     */
    assetsControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            user: {
              /** @format uuid */
              _id: string;
              _ref: "User";
            } | null;
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            externalId?: string | null;
            propertiesOwnership?: "override" | "inherit";
            /** @default {} */
            properties?: {
              /** @maxLength 1024 */
              phoneNumber?: string | null;
              /** @maxLength 1024 */
              smsNumber?: string | null;
              /** @maxLength 1024 */
              email?: string | null;
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale?: string | null;
              /** @maxLength 1024 */
              image?: string | null;
              rSim?: {
                /** @maxLength 1024 */
                euicc: string;
                primary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                secondary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                enabled: boolean;
              } | null;
            };
            /** @default null */
            position?: {
              type: "Point";
              coordinates: number[];
              properties: {
                accuracy?: number;
                heading?: number;
                headingAccuracy?: number;
                speed?: number;
                speedAccuracy?: number;
                altitude?: number;
                altitudeAccuracy?: number;
                address?: {
                  /** @maxLength 1024 */
                  countryCode?: string | null;
                  /** @maxLength 1024 */
                  state?: string | null;
                  /** @maxLength 1024 */
                  city?: string | null;
                  /** @maxLength 1024 */
                  streetName?: string | null;
                  /** @maxLength 1024 */
                  streetNumber?: string | null;
                  floor?: number | null;
                  /** @maxLength 1024 */
                  formattedAddress?: string;
                };
                /** @format date-time */
                dateTime?: string;
                /** @maxLength 1024 */
                provider?: string;
              };
            };
            available: boolean;
            /** @default true */
            allowContactOnOwnAlarms?: boolean;
            app?: {
              mode: "off" | "on" | "risk";
            } | null;
            insideZones: {
              /** @format uuid */
              _id: string;
              _ref: "Zone";
            }[];
            assetGroups: {
              /** @format uuid */
              _id: string;
              _ref: "AssetGroup";
            }[];
            /** @default [] */
            staticResponders?: {
              staticResponder: {
                /** @format uuid */
                _id: string;
                _ref: "StaticResponder";
              };
              priority: number;
            }[];
            /** @format date-time */
            lastObservationAt?: string;
            /** @default false */
            invisibleResponder?: boolean;
            /** @maxLength 4096 */
            description?: string;
            type?: "default" | "kiosk";
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/assets/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  beacons = {
    /**
     * @description Creates a Beacon
     *
     * @tags Beacon
     * @name BeaconsControllerCreate
     * @request POST:/beacons
     * @secure
     */
    beaconsControllerCreate: (
      data: BeaconCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BeaconOutputDto, any>({
        path: `/beacons`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Beacon
     *
     * @tags Beacon
     * @name BeaconsControllerPaginate
     * @request GET:/beacons
     * @secure
     */
    beaconsControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            codes: string[];
            /**
             * @min 0
             * @exclusiveMin false
             */
            rssiAddend: number;
            type: "default" | "tag";
            state: {
              lastSeenBy?: {
                /** @format uuid */
                _id: string;
                _ref: "Asset";
              } | null;
              /** @format date-time */
              lastSeenAt?: string | null;
              battery?: number | null;
            };
            zone?: {
              /** @format uuid */
              _id: string;
              _ref: "Zone";
            } | null;
            location?:
              | {
                  type: "Point";
                  coordinates: number[];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "Polygon";
                  coordinates: number[][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "MultiPolygon";
                  coordinates: number[][][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/beacons`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Beacon by id
     *
     * @tags Beacon
     * @name BeaconsControllerFindById
     * @request GET:/beacons/{id}
     * @secure
     */
    beaconsControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BeaconOutputDto, any>({
        path: `/beacons/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a Beacon
     *
     * @tags Beacon
     * @name BeaconsControllerUpdate
     * @request PATCH:/beacons/{id}
     * @secure
     */
    beaconsControllerUpdate: (
      id: string,
      data: BeaconUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BeaconOutputDto, any>({
        path: `/beacons/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a Beacon by id
     *
     * @tags Beacon
     * @name BeaconsControllerRemove
     * @request DELETE:/beacons/{id}
     * @secure
     */
    beaconsControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/beacons/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Beacon
     * @name BeaconsControllerObserve
     * @request POST:/beacons/observe
     * @secure
     */
    beaconsControllerObserve: (
      data: ObserveBeaconsDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ObserveBeaconsResponse, any>({
        path: `/beacons/observe`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Searches Beacon
     *
     * @tags Beacon
     * @name BeaconsControllerSearch
     * @request GET:/beacons/search
     * @secure
     */
    beaconsControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            codes: string[];
            /**
             * @min 0
             * @exclusiveMin false
             */
            rssiAddend: number;
            type: "default" | "tag";
            state: {
              lastSeenBy?: {
                /** @format uuid */
                _id: string;
                _ref: "Asset";
              } | null;
              /** @format date-time */
              lastSeenAt?: string | null;
              battery?: number | null;
            };
            zone?: {
              /** @format uuid */
              _id: string;
              _ref: "Zone";
            } | null;
            location?:
              | {
                  type: "Point";
                  coordinates: number[];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "Polygon";
                  coordinates: number[][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "MultiPolygon";
                  coordinates: number[][][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/beacons/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  clientConfig = {
    /**
     * @description Creates a ClientConfigGroup
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerCreateGroup
     * @request POST:/client-config/groups
     * @secure
     */
    clientConfigControllerCreateGroup: (
      data: ClientConfigGroupCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientConfigGroupOutputDto, any>({
        path: `/client-config/groups`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates ClientConfigGroup
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerPaginateGroups
     * @request GET:/client-config/groups
     * @secure
     */
    clientConfigControllerPaginateGroups: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            name: string;
            clientConfig: {
              app?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
              kiosk?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
            };
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            } | null;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/client-config/groups`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a ClientConfigGroup by id
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerFindGroupById
     * @request GET:/client-config/groups/{id}
     * @secure
     */
    clientConfigControllerFindGroupById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientConfigGroupOutputDto, any>({
        path: `/client-config/groups/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a ClientConfigGroup
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerUpdateGroup
     * @request PATCH:/client-config/groups/{id}
     * @secure
     */
    clientConfigControllerUpdateGroup: (
      id: string,
      data: ClientConfigGroupUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientConfigGroupOutputDto, any>({
        path: `/client-config/groups/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a ClientConfigGroup by id
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerRemoveGroup
     * @request DELETE:/client-config/groups/{id}
     * @secure
     */
    clientConfigControllerRemoveGroup: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client-config/groups/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Creates a ClientConfigKey
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerCreateKey
     * @request POST:/client-config/keys
     * @secure
     */
    clientConfigControllerCreateKey: (
      data: ClientConfigKeyCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientConfigKeyOutputDto, any>({
        path: `/client-config/keys`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates ClientConfigKey
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerPaginateKeys
     * @request GET:/client-config/keys
     * @secure
     */
    clientConfigControllerPaginateKeys: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            platform: "app" | "kiosk";
            editable: boolean;
            key: string;
            defaultValue: any;
            version: number;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/client-config/keys`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a ClientConfigKey by id
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerFindKeyById
     * @request GET:/client-config/keys/{id}
     * @secure
     */
    clientConfigControllerFindKeyById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientConfigKeyOutputDto, any>({
        path: `/client-config/keys/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a ClientConfigKey
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerUpdateKey
     * @request PATCH:/client-config/keys/{id}
     * @secure
     */
    clientConfigControllerUpdateKey: (
      id: string,
      data: ClientConfigKeyUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ClientConfigKeyOutputDto, any>({
        path: `/client-config/keys/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a ClientConfigKey by id
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerRemoveKey
     * @request DELETE:/client-config/keys/{id}
     * @secure
     */
    clientConfigControllerRemoveKey: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/client-config/keys/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerResolveAppAssetGroupConfigV0
     * @request GET:/client-config/resolve/app/0/assetGroup/{id}
     * @secure
     */
    clientConfigControllerResolveAppAssetGroupConfigV0: (id: string, params: RequestParams = {}) =>
      this.request<ClientConfigResolveAppV0Dto, any>({
        path: `/client-config/resolve/app/0/assetGroup/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerResolveAppCustomerConfigV0
     * @request GET:/client-config/resolve/app/0/customer/{id}
     * @secure
     */
    clientConfigControllerResolveAppCustomerConfigV0: (id: string, params: RequestParams = {}) =>
      this.request<ClientConfigResolveAppV0Dto, any>({
        path: `/client-config/resolve/app/0/customer/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerResolveAppDefaultConfigV0
     * @request GET:/client-config/resolve/app/0/default
     * @secure
     */
    clientConfigControllerResolveAppDefaultConfigV0: (params: RequestParams = {}) =>
      this.request<ClientConfigResolveAppV0Dto, any>({
        path: `/client-config/resolve/app/0/default`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerResolveAppDeviceConfigV0
     * @request GET:/client-config/resolve/app/0/device/{id}
     * @secure
     */
    clientConfigControllerResolveAppDeviceConfigV0: (id: string, params: RequestParams = {}) =>
      this.request<ClientConfigResolveAppV0Dto, any>({
        path: `/client-config/resolve/app/0/device/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerResolveKioskAssetGroupConfigV0
     * @request GET:/client-config/resolve/kiosk/0/assetGroup/{id}
     * @secure
     */
    clientConfigControllerResolveKioskAssetGroupConfigV0: (id: string, params: RequestParams = {}) =>
      this.request<ClientConfigResolveKioskV0Dto, any>({
        path: `/client-config/resolve/kiosk/0/assetGroup/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerResolveKioskCustomerConfigV0
     * @request GET:/client-config/resolve/kiosk/0/customer/{id}
     * @secure
     */
    clientConfigControllerResolveKioskCustomerConfigV0: (id: string, params: RequestParams = {}) =>
      this.request<ClientConfigResolveKioskV0Dto, any>({
        path: `/client-config/resolve/kiosk/0/customer/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerResolveKioskDefaultConfigV0
     * @request GET:/client-config/resolve/kiosk/0/default
     * @secure
     */
    clientConfigControllerResolveKioskDefaultConfigV0: (params: RequestParams = {}) =>
      this.request<ClientConfigResolveKioskV0Dto, any>({
        path: `/client-config/resolve/kiosk/0/default`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ClientConfigGroup, ClientConfigKey
     * @name ClientConfigControllerResolveKioskDeviceConfigV0
     * @request GET:/client-config/resolve/kiosk/0/device/{id}
     * @secure
     */
    clientConfigControllerResolveKioskDeviceConfigV0: (id: string, params: RequestParams = {}) =>
      this.request<ClientConfigResolveKioskV0Dto, any>({
        path: `/client-config/resolve/kiosk/0/device/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  contactLists = {
    /**
     * @description Creates a ContactList
     *
     * @tags ContactList
     * @name ContactListsControllerCreate
     * @request POST:/contact-lists
     * @secure
     */
    contactListsControllerCreate: (
      data: ContactListCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContactListOutputDto, any>({
        path: `/contact-lists`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates ContactList
     *
     * @tags ContactList
     * @name ContactListsControllerPaginate
     * @request GET:/contact-lists
     * @secure
     */
    contactListsControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            /**
             * @maxLength 1024
             * @default null
             */
            description?: string | null;
            contacts: {
              /** @maxLength 1024 */
              name: string;
              /** @maxLength 1024 */
              phone: string | null;
              /** @maxLength 1024 */
              email: string | null;
            }[];
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/contact-lists`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a ContactList by id
     *
     * @tags ContactList
     * @name ContactListsControllerFindById
     * @request GET:/contact-lists/{id}
     * @secure
     */
    contactListsControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContactListOutputDto, any>({
        path: `/contact-lists/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a ContactList
     *
     * @tags ContactList
     * @name ContactListsControllerUpdate
     * @request PATCH:/contact-lists/{id}
     * @secure
     */
    contactListsControllerUpdate: (
      id: string,
      data: ContactListUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ContactListOutputDto, any>({
        path: `/contact-lists/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a ContactList by id
     *
     * @tags ContactList
     * @name ContactListsControllerRemove
     * @request DELETE:/contact-lists/{id}
     * @secure
     */
    contactListsControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/contact-lists/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  contact = {
    /**
     * No description
     *
     * @tags Contact
     * @name ContactControllerEmail
     * @request POST:/contact/email
     * @secure
     */
    contactControllerEmail: (
      data: EmailContactDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/contact/email`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Contact
     * @name ContactControllerSms
     * @request POST:/contact/sms
     * @secure
     */
    contactControllerSms: (
      data: SmsContactDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/contact/sms`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  context = {
    /**
     * No description
     *
     * @tags Context
     * @name ContextControllerContext
     * @request GET:/context
     * @secure
     */
    contextControllerContext: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<GetContextResponse, any>({
        path: `/context`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  customerAlarmCenterRelations = {
    /**
     * @description Creates a CustomerAlarmCenterRelation
     *
     * @tags CustomerAlarmCenterRelation
     * @name CustomerAlarmCenterRelationsControllerCreate
     * @request POST:/customer-alarm-center-relations
     * @secure
     */
    customerAlarmCenterRelationsControllerCreate: (
      data: CustomerAlarmCenterRelationCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerAlarmCenterRelationOutputDto, any>({
        path: `/customer-alarm-center-relations`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates CustomerAlarmCenterRelation
     *
     * @tags CustomerAlarmCenterRelation
     * @name CustomerAlarmCenterRelationsControllerPaginate
     * @request GET:/customer-alarm-center-relations
     * @secure
     */
    customerAlarmCenterRelationsControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            /** @maxLength 1024 */
            migrationKey?: string;
            alarmCenter: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            enabled: boolean;
            zones:
              | {
                  /** @format uuid */
                  _id: string;
                  _ref: "Zone";
                }[]
              | null;
            priority?: number;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/customer-alarm-center-relations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a CustomerAlarmCenterRelation by id
     *
     * @tags CustomerAlarmCenterRelation
     * @name CustomerAlarmCenterRelationsControllerFindById
     * @request GET:/customer-alarm-center-relations/{id}
     * @secure
     */
    customerAlarmCenterRelationsControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerAlarmCenterRelationOutputDto, any>({
        path: `/customer-alarm-center-relations/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a CustomerAlarmCenterRelation
     *
     * @tags CustomerAlarmCenterRelation
     * @name CustomerAlarmCenterRelationsControllerUpdate
     * @request PATCH:/customer-alarm-center-relations/{id}
     * @secure
     */
    customerAlarmCenterRelationsControllerUpdate: (
      id: string,
      data: CustomerAlarmCenterRelationUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerAlarmCenterRelationOutputDto, any>({
        path: `/customer-alarm-center-relations/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a CustomerAlarmCenterRelation by id
     *
     * @tags CustomerAlarmCenterRelation
     * @name CustomerAlarmCenterRelationsControllerRemove
     * @request DELETE:/customer-alarm-center-relations/{id}
     * @secure
     */
    customerAlarmCenterRelationsControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/customer-alarm-center-relations/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags CustomerAlarmCenterRelation
     * @name CustomerAlarmCenterRelationsControllerPopulate
     * @request GET:/customer-alarm-center-relations/populated
     * @secure
     */
    customerAlarmCenterRelationsControllerPopulate: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerAlarmCenterRelationOutputDto, any>({
        path: `/customer-alarm-center-relations/populated`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  customerUserRelations = {
    /**
     * @description Creates a CustomerUserRelation
     *
     * @tags CustomerUserRelation
     * @name CustomerUserRelationsControllerCreate
     * @request POST:/customer-user-relations
     * @secure
     */
    customerUserRelationsControllerCreate: (
      data: CustomerUserRelationCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserRelationOutputDto, any>({
        path: `/customer-user-relations`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates CustomerUserRelation
     *
     * @tags CustomerUserRelation
     * @name CustomerUserRelationsControllerPaginate
     * @request GET:/customer-user-relations
     * @secure
     */
    customerUserRelationsControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            /** @maxLength 1024 */
            migrationKey?: string;
            user: {
              /** @format uuid */
              _id: string;
              _ref: "User";
            };
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            roles: string[];
            assetGroupRestrictions?:
              | {
                  /** @format uuid */
                  _id: string;
                  _ref: "AssetGroup";
                }[]
              | null;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/customer-user-relations`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a CustomerUserRelation by id
     *
     * @tags CustomerUserRelation
     * @name CustomerUserRelationsControllerFindById
     * @request GET:/customer-user-relations/{id}
     * @secure
     */
    customerUserRelationsControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserRelationOutputDto, any>({
        path: `/customer-user-relations/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a CustomerUserRelation
     *
     * @tags CustomerUserRelation
     * @name CustomerUserRelationsControllerUpdate
     * @request PATCH:/customer-user-relations/{id}
     * @secure
     */
    customerUserRelationsControllerUpdate: (
      id: string,
      data: CustomerUserRelationUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerUserRelationOutputDto, any>({
        path: `/customer-user-relations/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a CustomerUserRelation by id
     *
     * @tags CustomerUserRelation
     * @name CustomerUserRelationsControllerRemove
     * @request DELETE:/customer-user-relations/{id}
     * @secure
     */
    customerUserRelationsControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/customer-user-relations/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  customers = {
    /**
     * @description Creates a Customer
     *
     * @tags Customer
     * @name CustomersControllerCreate
     * @request POST:/customers
     * @secure
     */
    customersControllerCreate: (
      data: CustomerCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerOutputDto, any>({
        path: `/customers`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Customer
     *
     * @tags Customer
     * @name CustomersControllerPaginate
     * @request GET:/customers
     * @secure
     */
    customersControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            /** @maxLength 1024 */
            name: string;
            /** @default null */
            parent?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            } | null;
            parents: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
            children: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale: string;
            /**
             * @maxLength 1024
             * @default "Europe/Amsterdam"
             */
            timeZone?: string;
            /** @default null */
            alarmCenter?: {
              enabled: boolean;
              /** @maxLength 1024 */
              queueName?: string;
              /** @maxLength 1024 */
              callbackPhoneNumber?: string;
            } | null;
            survey?: {
              enabled: boolean;
            };
            /** @default null */
            responsePartner?: {
              enabled: boolean;
            } | null;
            /** @default [] */
            responsePartners?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
            /** @default [] */
            billingContracts?: string[];
            preferences?: {
              alarmAgeLimit?: {
                type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                value: number;
              } | null;
              mergeConfig?: {
                enabled: boolean;
                onlyMergeWhenSame: {
                  name: boolean;
                  severity: boolean;
                  type: boolean;
                };
                maxTimeBetweenAlarmsMs: number;
                retriggerAtsAfterMs: number;
              } | null;
              disableAutomaticGenerationOfButtonZones?: boolean | null;
            };
            loraConfig?:
              | (
                  | {
                      type: "adeunis.arf8123aa";
                      config: {
                        /** @maxLength 1024 */
                        fieldTest: string;
                      };
                    }
                  | {
                      type: "iotracker.v2";
                      config: {
                        /** @maxLength 1024 */
                        beaconScan: string;
                        /** @maxLength 1024 */
                        gps: string;
                        /** @maxLength 1024 */
                        preMandown: string;
                        /** @maxLength 1024 */
                        mandown: string;
                        /** @maxLength 1024 */
                        movement: string;
                        /** @maxLength 1024 */
                        alarmSeverity: string;
                        /** @maxLength 1024 */
                        alive: string;
                      };
                    }
                  | {
                      type: "milesight.ws101";
                      config: {
                        /** @maxLength 1024 */
                        beaconScan: string;
                        /** @maxLength 1024 */
                        alarmSeverity: string;
                        /** @maxLength 1024 */
                        alive: string;
                      };
                    }
                  | {
                      type: "sensative.1301001";
                      config: {
                        /** @maxLength 1024 */
                        alarmSeverity: string;
                        /** @maxLength 1024 */
                        alive: string;
                        /** @maxLength 1024 */
                        floodDetected: string;
                        /** @maxLength 1024 */
                        noFloodDetected: string;
                      };
                    }
                )[]
              | null;
            kioskConfig?: {
              /** @default false */
              managed?: boolean;
              zone?: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              } | null;
              exposeAssets?:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[]
                | null;
              exposeLabels?:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                    countLabel?: boolean;
                  }[]
                | null;
              schedule?: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              } | null;
              homepage?: {
                incidentCounter?: {
                  /** @default true */
                  enabled?: boolean;
                  /** @format date-time */
                  countFromDate?: string | null;
                  /**
                   * @min 0
                   * @exclusiveMin false
                   * @default 0
                   */
                  threshold?: number;
                };
                /** @default true */
                anonymous?: boolean;
                /** @format uri */
                logo?: string | null;
              };
              alarm?: {
                /** @default true */
                enabled?: boolean;
                focus?: {
                  red?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  orange?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  green?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                };
                /** @default true */
                showPositionLabels?: boolean;
                /** @default true */
                anonymous?: boolean;
                acknowledge?: {
                  enabled?: boolean;
                  /** @pattern ^[0-9]{4,6}$ */
                  pin?: string | null;
                  messagePresets?: string[] | null;
                };
                mode?: "zoneBased" | "customerBased" | null;
              };
              manageAvailability?: {
                /** @default true */
                enabled?: boolean;
                /** @pattern ^[0-9]{4,6}$ */
                pin?: string | null;
                /** @default true */
                showAppBanner?: boolean;
                /** @default false */
                forceShowSearch?: boolean;
                /** @default false */
                forceShowLabelFilter?: boolean;
              };
              hardware?: {
                /**
                 * @min 0
                 * @exclusiveMin false
                 * @max 100
                 * @exclusiveMax false
                 */
                screenBrightness?: number | null;
              };
            };
            type?: "default" | "bhvk_paid" | "bhvk_trial";
            userPolicies?: {
              disablePasswordlessLogin?: boolean;
            };
            clientConfig?: {
              app?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
              kiosk?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/customers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Customer by id
     *
     * @tags Customer
     * @name CustomersControllerFindById
     * @request GET:/customers/{id}
     * @secure
     */
    customersControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerOutputDto, any>({
        path: `/customers/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a Customer
     *
     * @tags Customer
     * @name CustomersControllerUpdate
     * @request PATCH:/customers/{id}
     * @secure
     */
    customersControllerUpdate: (
      id: string,
      data: CustomerUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<CustomerOutputDto, any>({
        path: `/customers/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a Customer by id
     *
     * @tags Customer
     * @name CustomersControllerRemove
     * @request DELETE:/customers/{id}
     * @secure
     */
    customersControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/customers/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomersControllerAddPhoto
     * @request POST:/customers/kiosk/photo
     * @secure
     */
    customersControllerAddPhoto: (
      query: {
        /** customer id parameter */
        id: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageUploadDto, any>({
        path: `/customers/kiosk/photo`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Customer
     * @name CustomersControllerCheckMigrationStatus
     * @request POST:/customers/migration-status
     * @secure
     */
    customersControllerCheckMigrationStatus: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/customers/migration-status`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Searches Customer
     *
     * @tags Customer
     * @name CustomersControllerSearch
     * @request GET:/customers/search
     * @secure
     */
    customersControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            /** @maxLength 1024 */
            name: string;
            /** @default null */
            parent?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            } | null;
            parents: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
            children: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale: string;
            /**
             * @maxLength 1024
             * @default "Europe/Amsterdam"
             */
            timeZone?: string;
            /** @default null */
            alarmCenter?: {
              enabled: boolean;
              /** @maxLength 1024 */
              queueName?: string;
              /** @maxLength 1024 */
              callbackPhoneNumber?: string;
            } | null;
            survey?: {
              enabled: boolean;
            };
            /** @default null */
            responsePartner?: {
              enabled: boolean;
            } | null;
            /** @default [] */
            responsePartners?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            }[];
            /** @default [] */
            billingContracts?: string[];
            preferences?: {
              alarmAgeLimit?: {
                type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                value: number;
              } | null;
              mergeConfig?: {
                enabled: boolean;
                onlyMergeWhenSame: {
                  name: boolean;
                  severity: boolean;
                  type: boolean;
                };
                maxTimeBetweenAlarmsMs: number;
                retriggerAtsAfterMs: number;
              } | null;
              disableAutomaticGenerationOfButtonZones?: boolean | null;
            };
            loraConfig?:
              | (
                  | {
                      type: "adeunis.arf8123aa";
                      config: {
                        /** @maxLength 1024 */
                        fieldTest: string;
                      };
                    }
                  | {
                      type: "iotracker.v2";
                      config: {
                        /** @maxLength 1024 */
                        beaconScan: string;
                        /** @maxLength 1024 */
                        gps: string;
                        /** @maxLength 1024 */
                        preMandown: string;
                        /** @maxLength 1024 */
                        mandown: string;
                        /** @maxLength 1024 */
                        movement: string;
                        /** @maxLength 1024 */
                        alarmSeverity: string;
                        /** @maxLength 1024 */
                        alive: string;
                      };
                    }
                  | {
                      type: "milesight.ws101";
                      config: {
                        /** @maxLength 1024 */
                        beaconScan: string;
                        /** @maxLength 1024 */
                        alarmSeverity: string;
                        /** @maxLength 1024 */
                        alive: string;
                      };
                    }
                  | {
                      type: "sensative.1301001";
                      config: {
                        /** @maxLength 1024 */
                        alarmSeverity: string;
                        /** @maxLength 1024 */
                        alive: string;
                        /** @maxLength 1024 */
                        floodDetected: string;
                        /** @maxLength 1024 */
                        noFloodDetected: string;
                      };
                    }
                )[]
              | null;
            kioskConfig?: {
              /** @default false */
              managed?: boolean;
              zone?: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              } | null;
              exposeAssets?:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[]
                | null;
              exposeLabels?:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                    countLabel?: boolean;
                  }[]
                | null;
              schedule?: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              } | null;
              homepage?: {
                incidentCounter?: {
                  /** @default true */
                  enabled?: boolean;
                  /** @format date-time */
                  countFromDate?: string | null;
                  /**
                   * @min 0
                   * @exclusiveMin false
                   * @default 0
                   */
                  threshold?: number;
                };
                /** @default true */
                anonymous?: boolean;
                /** @format uri */
                logo?: string | null;
              };
              alarm?: {
                /** @default true */
                enabled?: boolean;
                focus?: {
                  red?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  orange?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  green?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                };
                /** @default true */
                showPositionLabels?: boolean;
                /** @default true */
                anonymous?: boolean;
                acknowledge?: {
                  enabled?: boolean;
                  /** @pattern ^[0-9]{4,6}$ */
                  pin?: string | null;
                  messagePresets?: string[] | null;
                };
                mode?: "zoneBased" | "customerBased" | null;
              };
              manageAvailability?: {
                /** @default true */
                enabled?: boolean;
                /** @pattern ^[0-9]{4,6}$ */
                pin?: string | null;
                /** @default true */
                showAppBanner?: boolean;
                /** @default false */
                forceShowSearch?: boolean;
                /** @default false */
                forceShowLabelFilter?: boolean;
              };
              hardware?: {
                /**
                 * @min 0
                 * @exclusiveMin false
                 * @max 100
                 * @exclusiveMax false
                 */
                screenBrightness?: number | null;
              };
            };
            type?: "default" | "bhvk_paid" | "bhvk_trial";
            userPolicies?: {
              disablePasswordlessLogin?: boolean;
            };
            clientConfig?: {
              app?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
              kiosk?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/customers/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  devices = {
    /**
     * @description Creates a Device
     *
     * @tags Device
     * @name DevicesControllerCreate
     * @request POST:/devices
     * @secure
     */
    devicesControllerCreate: (
      data: DeviceCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceOutputDto, any>({
        path: `/devices`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Device
     *
     * @tags Device
     * @name DevicesControllerPaginate
     * @request GET:/devices
     * @secure
     */
    devicesControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            asset: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            } | null;
            /** @maxLength 1024 */
            name?: string;
            /** @maxLength 1024 */
            deviceId: string;
            type: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
            loraConfig?:
              | {
                  type: "adeunis.arf8123aa";
                  config: {
                    /** @maxLength 1024 */
                    fieldTest: string;
                  };
                }
              | {
                  type: "iotracker.v2";
                  config: {
                    /** @maxLength 1024 */
                    beaconScan: string;
                    /** @maxLength 1024 */
                    gps: string;
                    /** @maxLength 1024 */
                    preMandown: string;
                    /** @maxLength 1024 */
                    mandown: string;
                    /** @maxLength 1024 */
                    movement: string;
                    /** @maxLength 1024 */
                    alarmSeverity: string;
                    /** @maxLength 1024 */
                    alive: string;
                  };
                }
              | {
                  type: "milesight.ws101";
                  config: {
                    /** @maxLength 1024 */
                    beaconScan: string;
                    /** @maxLength 1024 */
                    alarmSeverity: string;
                    /** @maxLength 1024 */
                    alive: string;
                  };
                }
              | {
                  type: "sensative.1301001";
                  config: {
                    /** @maxLength 1024 */
                    alarmSeverity: string;
                    /** @maxLength 1024 */
                    alive: string;
                    /** @maxLength 1024 */
                    floodDetected: string;
                    /** @maxLength 1024 */
                    noFloodDetected: string;
                  };
                }
              | null;
            kioskConfig?: {
              /** @default false */
              managed?: boolean;
              zone?: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              } | null;
              exposeAssets?:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[]
                | null;
              exposeLabels?:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                    countLabel?: boolean;
                  }[]
                | null;
              schedule?: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              } | null;
              homepage?: {
                incidentCounter?: {
                  /** @default true */
                  enabled?: boolean;
                  /** @format date-time */
                  countFromDate?: string | null;
                  /**
                   * @min 0
                   * @exclusiveMin false
                   * @default 0
                   */
                  threshold?: number;
                };
                /** @default true */
                anonymous?: boolean;
                /** @format uri */
                logo?: string | null;
              };
              alarm?: {
                /** @default true */
                enabled?: boolean;
                focus?: {
                  red?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  orange?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  green?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                };
                /** @default true */
                showPositionLabels?: boolean;
                /** @default true */
                anonymous?: boolean;
                acknowledge?: {
                  enabled?: boolean;
                  /** @pattern ^[0-9]{4,6}$ */
                  pin?: string | null;
                  messagePresets?: string[] | null;
                };
                mode?: "zoneBased" | "customerBased" | null;
              };
              manageAvailability?: {
                /** @default true */
                enabled?: boolean;
                /** @pattern ^[0-9]{4,6}$ */
                pin?: string | null;
                /** @default true */
                showAppBanner?: boolean;
                /** @default false */
                forceShowSearch?: boolean;
                /** @default false */
                forceShowLabelFilter?: boolean;
              };
              hardware?: {
                /**
                 * @min 0
                 * @exclusiveMin false
                 * @max 100
                 * @exclusiveMax false
                 */
                screenBrightness?: number | null;
              };
            };
            battery?: number;
            rssi?: number;
            snr?: number;
            clientConfig?: {
              app?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
              kiosk?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/devices`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Device by id
     *
     * @tags Device
     * @name DevicesControllerFindById
     * @request GET:/devices/{id}
     * @secure
     */
    devicesControllerFindById: (
      id: string,
      query?: {
        populateAsset?: boolean;
        populateAssetCheckUpdate?: boolean;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceOutputDto, any>({
        path: `/devices/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a Device
     *
     * @tags Device
     * @name DevicesControllerUpdate
     * @request PATCH:/devices/{id}
     * @secure
     */
    devicesControllerUpdate: (
      id: string,
      data: DeviceUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceOutputDto, any>({
        path: `/devices/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a Device by id
     *
     * @tags Device
     * @name DevicesControllerRemove
     * @request DELETE:/devices/{id}
     * @secure
     */
    devicesControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/devices/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DevicesControllerGetSyncToAmsZones
     * @request POST:/devices/ams-zones
     * @secure
     */
    devicesControllerGetSyncToAmsZones: (
      data: DeviceGetSyncToAmsZonesRequestDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DeviceGetSyncToAmsZonesResponseDto, any>({
        path: `/devices/ams-zones`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DevicesControllerSendReloginNotification
     * @request POST:/devices/relogin-notification
     * @secure
     */
    devicesControllerSendReloginNotification: (
      data: DeviceReloginNotificationDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/devices/relogin-notification`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Searches Device
     *
     * @tags Device
     * @name DevicesControllerSearch
     * @request GET:/devices/search
     * @secure
     */
    devicesControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            asset: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            } | null;
            /** @maxLength 1024 */
            name?: string;
            /** @maxLength 1024 */
            deviceId: string;
            type: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
            loraConfig?:
              | {
                  type: "adeunis.arf8123aa";
                  config: {
                    /** @maxLength 1024 */
                    fieldTest: string;
                  };
                }
              | {
                  type: "iotracker.v2";
                  config: {
                    /** @maxLength 1024 */
                    beaconScan: string;
                    /** @maxLength 1024 */
                    gps: string;
                    /** @maxLength 1024 */
                    preMandown: string;
                    /** @maxLength 1024 */
                    mandown: string;
                    /** @maxLength 1024 */
                    movement: string;
                    /** @maxLength 1024 */
                    alarmSeverity: string;
                    /** @maxLength 1024 */
                    alive: string;
                  };
                }
              | {
                  type: "milesight.ws101";
                  config: {
                    /** @maxLength 1024 */
                    beaconScan: string;
                    /** @maxLength 1024 */
                    alarmSeverity: string;
                    /** @maxLength 1024 */
                    alive: string;
                  };
                }
              | {
                  type: "sensative.1301001";
                  config: {
                    /** @maxLength 1024 */
                    alarmSeverity: string;
                    /** @maxLength 1024 */
                    alive: string;
                    /** @maxLength 1024 */
                    floodDetected: string;
                    /** @maxLength 1024 */
                    noFloodDetected: string;
                  };
                }
              | null;
            kioskConfig?: {
              /** @default false */
              managed?: boolean;
              zone?: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              } | null;
              exposeAssets?:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[]
                | null;
              exposeLabels?:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                    countLabel?: boolean;
                  }[]
                | null;
              schedule?: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              } | null;
              homepage?: {
                incidentCounter?: {
                  /** @default true */
                  enabled?: boolean;
                  /** @format date-time */
                  countFromDate?: string | null;
                  /**
                   * @min 0
                   * @exclusiveMin false
                   * @default 0
                   */
                  threshold?: number;
                };
                /** @default true */
                anonymous?: boolean;
                /** @format uri */
                logo?: string | null;
              };
              alarm?: {
                /** @default true */
                enabled?: boolean;
                focus?: {
                  red?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  orange?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  green?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                };
                /** @default true */
                showPositionLabels?: boolean;
                /** @default true */
                anonymous?: boolean;
                acknowledge?: {
                  enabled?: boolean;
                  /** @pattern ^[0-9]{4,6}$ */
                  pin?: string | null;
                  messagePresets?: string[] | null;
                };
                mode?: "zoneBased" | "customerBased" | null;
              };
              manageAvailability?: {
                /** @default true */
                enabled?: boolean;
                /** @pattern ^[0-9]{4,6}$ */
                pin?: string | null;
                /** @default true */
                showAppBanner?: boolean;
                /** @default false */
                forceShowSearch?: boolean;
                /** @default false */
                forceShowLabelFilter?: boolean;
              };
              hardware?: {
                /**
                 * @min 0
                 * @exclusiveMin false
                 * @max 100
                 * @exclusiveMax false
                 */
                screenBrightness?: number | null;
              };
            };
            battery?: number;
            rssi?: number;
            snr?: number;
            clientConfig?: {
              app?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
              kiosk?: (
                | {
                    type: "key";
                    key: string;
                    value: any;
                    editable?: boolean;
                  }
                | {
                    type: "group";
                    group: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ClientConfigGroup";
                    };
                  }
              )[];
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/devices/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Device
     * @name DevicesControllerGetWithoutApp
     * @request GET:/devices/without-app
     * @secure
     */
    devicesControllerGetWithoutApp: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/devices/without-app`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  fingerprints = {
    /**
     * @description Creates a Fingerprint
     *
     * @tags Fingerprint
     * @name FingerprintsControllerCreate
     * @request POST:/fingerprints
     * @secure
     */
    fingerprintsControllerCreate: (
      data: FingerprintCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FingerprintOutputDto, any>({
        path: `/fingerprints`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Fingerprint
     *
     * @tags Fingerprint
     * @name FingerprintsControllerPaginate
     * @request GET:/fingerprints
     * @secure
     */
    fingerprintsControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            zone: {
              /** @format uuid */
              _id: string;
              _ref: "Zone";
            };
            deviceInfo: {
              /** @maxLength 1024 */
              name: string;
              /** @maxLength 1024 */
              osName: string;
              /** @maxLength 1024 */
              osVersion: string;
              /** @maxLength 1024 */
              brand: string;
              /** @maxLength 1024 */
              model: string;
              /** @maxLength 1024 */
              appBundleId: string;
              /** @maxLength 1024 */
              appVersion: string;
            };
            /**
             * @maxLength 1024
             * @default null
             */
            description?: string | null;
            data: {
              /** @maxLength 1024 */
              code: string;
              rssi: number;
            }[][];
            beaconCodes?: string[];
            user: {
              /** @format uuid */
              _id: string;
              _ref: "User";
            };
            dataCount: number;
            enabled: boolean;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/fingerprints`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Fingerprint by id
     *
     * @tags Fingerprint
     * @name FingerprintsControllerFindById
     * @request GET:/fingerprints/{id}
     * @secure
     */
    fingerprintsControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FingerprintOutputDto, any>({
        path: `/fingerprints/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a Fingerprint
     *
     * @tags Fingerprint
     * @name FingerprintsControllerUpdate
     * @request PATCH:/fingerprints/{id}
     * @secure
     */
    fingerprintsControllerUpdate: (
      id: string,
      data: FingerprintUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<FingerprintOutputDto, any>({
        path: `/fingerprints/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a Fingerprint by id
     *
     * @tags Fingerprint
     * @name FingerprintsControllerRemove
     * @request DELETE:/fingerprints/{id}
     * @secure
     */
    fingerprintsControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/fingerprints/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fingerprint
     * @name FingerprintsControllerCreateBeaconsFromFingerprints
     * @request POST:/fingerprints/create-beacons
     * @secure
     */
    fingerprintsControllerCreateBeaconsFromFingerprints: (
      data: FingerPrintCustomerDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/fingerprints/create-beacons`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fingerprint
     * @name FingerprintsControllerFingerPrintBeaconCount
     * @request GET:/fingerprints/stats/beacon-count/{id}
     * @secure
     */
    fingerprintsControllerFingerPrintBeaconCount: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/fingerprints/stats/beacon-count/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fingerprint
     * @name FingerprintsControllerFindCommonBeacons
     * @request GET:/fingerprints/stats/common-beacons/{id}
     * @secure
     */
    fingerprintsControllerFindCommonBeacons: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/fingerprints/stats/common-beacons/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fingerprint
     * @name FingerprintsControllerFingerPrintDevices
     * @request GET:/fingerprints/stats/devices/{id}
     * @secure
     */
    fingerprintsControllerFingerPrintDevices: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/fingerprints/stats/devices/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fingerprint
     * @name FingerprintsControllerFindEnoughBeacons
     * @request GET:/fingerprints/stats/enough-beacons/{id}
     * @secure
     */
    fingerprintsControllerFindEnoughBeacons: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/fingerprints/stats/enough-beacons/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fingerprint
     * @name FingerprintsControllerFingerPrintProgress
     * @request GET:/fingerprints/stats/fingerprint-progress/{id}
     * @secure
     */
    fingerprintsControllerFingerPrintProgress: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/fingerprints/stats/fingerprint-progress/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fingerprint
     * @name FingerprintsControllerFindMissingBeacons
     * @request GET:/fingerprints/stats/missing-beacons/{id}
     * @secure
     */
    fingerprintsControllerFindMissingBeacons: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/fingerprints/stats/missing-beacons/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fingerprint
     * @name FingerprintsControllerFindFingerprintSimilarityByCustomer
     * @request POST:/fingerprints/stats/similarities
     * @secure
     */
    fingerprintsControllerFindFingerprintSimilarityByCustomer: (
      data: FingerPrintCustomerDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/fingerprints/stats/similarities`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  health = {
    /**
     * No description
     *
     * @tags Health
     * @name HealthControllerLastObservationHealth
     * @request POST:/health/last-observation
     * @secure
     */
    healthControllerLastObservationHealth: (
      data: LastObservationDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/health/last-observation`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Health
     * @name HealthControllerOpenAlarmsHealth
     * @request POST:/health/open-alarms
     * @secure
     */
    healthControllerOpenAlarmsHealth: (
      data: OpenAlarmDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/health/open-alarms`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Health
     * @name HealthControllerOpenAlarmsHealthAdmin
     * @request POST:/health/open-alarms/admin
     * @secure
     */
    healthControllerOpenAlarmsHealthAdmin: (
      data: OpenAlarmDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/health/open-alarms/admin`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Health
     * @name HealthControllerTriggerProcessing
     * @request POST:/health/trigger-processing
     * @secure
     */
    healthControllerTriggerProcessing: (
      data: TriggerProccessingDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/health/trigger-processing`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  observationTriggers = {
    /**
     * @description Creates a ObservationTrigger
     *
     * @tags ObservationTrigger
     * @name ObservationTriggersControllerCreate
     * @request POST:/observation-triggers
     * @secure
     */
    observationTriggersControllerCreate: (
      data: ObservationTriggerCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ObservationTriggerOutputDto, any>({
        path: `/observation-triggers`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates ObservationTrigger
     *
     * @tags ObservationTrigger
     * @name ObservationTriggersControllerPaginate
     * @request GET:/observation-triggers
     * @secure
     */
    observationTriggersControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            eventCodes: string[];
            /** @maxLength 1024 */
            description: string;
            status: "active" | "inactive";
            actions: (
              | {
                  type: "alarm";
                  config: {
                    severity: "red" | "orange" | "green";
                    type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
                    /** @maxLength 4096 */
                    procedure: string | null;
                    /** @maxLength 1024 */
                    name: string;
                  };
                }
              | {
                  type: "sms";
                  config: {
                    toRaw: string[];
                    toContactList: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ContactList";
                    }[];
                    toTriggerUser: boolean;
                    /** @maxLength 1024 */
                    message: string;
                  };
                }
              | {
                  type: "email";
                  config: {
                    toRaw: string[];
                    toContactList: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ContactList";
                    }[];
                    toTriggerUser: boolean;
                    /** @maxLength 1024 */
                    message: string;
                    /** @maxLength 1024 */
                    subject: string;
                    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                    locale?: string;
                  };
                }
            )[];
            filter:
              | {
                  type: "operator/and";
                  operands: (
                    | {
                        type: "operator/or";
                        operands: (
                          | {
                              type: "operator/not";
                              operand:
                                | {
                                    type: "constraint/asset";
                                    assets: {
                                      /** @format uuid */
                                      _id: string;
                                      _ref: "Asset";
                                    }[];
                                  }
                                | {
                                    type: "constraint/assetGroup";
                                    groups: {
                                      /** @format uuid */
                                      _id: string;
                                      _ref: "AssetGroup";
                                    }[];
                                  }
                                | {
                                    type: "constraint/zone";
                                    zone: {
                                      /** @format uuid */
                                      _id: string;
                                      _ref: "Zone";
                                    };
                                  }
                                | {
                                    type: "constraint/schedule";
                                    schedule: {
                                      entries: {
                                        daysOfWeek: number[];
                                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                        timeStart: string;
                                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                        timeEnd: string;
                                      }[];
                                    };
                                  };
                            }
                          | {
                              type: "constraint/asset";
                              assets: {
                                /** @format uuid */
                                _id: string;
                                _ref: "Asset";
                              }[];
                            }
                          | {
                              type: "constraint/assetGroup";
                              groups: {
                                /** @format uuid */
                                _id: string;
                                _ref: "AssetGroup";
                              }[];
                            }
                          | {
                              type: "constraint/zone";
                              zone: {
                                /** @format uuid */
                                _id: string;
                                _ref: "Zone";
                              };
                            }
                          | {
                              type: "constraint/schedule";
                              schedule: {
                                entries: {
                                  daysOfWeek: number[];
                                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                  timeStart: string;
                                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                  timeEnd: string;
                                }[];
                              };
                            }
                        )[];
                      }
                    | {
                        type: "constraint/asset";
                        assets: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Asset";
                        }[];
                      }
                    | {
                        type: "constraint/assetGroup";
                        groups: {
                          /** @format uuid */
                          _id: string;
                          _ref: "AssetGroup";
                        }[];
                      }
                    | {
                        type: "constraint/zone";
                        zone: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Zone";
                        };
                      }
                    | {
                        type: "constraint/schedule";
                        schedule: {
                          entries: {
                            daysOfWeek: number[];
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeStart: string;
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeEnd: string;
                          }[];
                        };
                      }
                  )[];
                }
              | {
                  type: "constraint/asset";
                  assets: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  }[];
                }
              | {
                  type: "constraint/assetGroup";
                  groups: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[];
                }
              | {
                  type: "constraint/zone";
                  zone: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  };
                }
              | {
                  type: "constraint/schedule";
                  schedule: {
                    entries: {
                      daysOfWeek: number[];
                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                      timeStart: string;
                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                      timeEnd: string;
                    }[];
                  };
                };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/observation-triggers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a ObservationTrigger by id
     *
     * @tags ObservationTrigger
     * @name ObservationTriggersControllerFindById
     * @request GET:/observation-triggers/{id}
     * @secure
     */
    observationTriggersControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ObservationTriggerOutputDto, any>({
        path: `/observation-triggers/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a ObservationTrigger
     *
     * @tags ObservationTrigger
     * @name ObservationTriggersControllerUpdate
     * @request PATCH:/observation-triggers/{id}
     * @secure
     */
    observationTriggersControllerUpdate: (
      id: string,
      data: ObservationTriggerUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ObservationTriggerOutputDto, any>({
        path: `/observation-triggers/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a ObservationTrigger by id
     *
     * @tags ObservationTrigger
     * @name ObservationTriggersControllerRemove
     * @request DELETE:/observation-triggers/{id}
     * @secure
     */
    observationTriggersControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/observation-triggers/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ObservationTrigger
     * @name ObservationTriggersControllerGetForEvent
     * @request GET:/observation-triggers/for-code/{event}
     * @secure
     */
    observationTriggersControllerGetForEvent: (
      event: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ObservationTriggerOutputDto[], any>({
        path: `/observation-triggers/for-code/${event}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Searches ObservationTrigger
     *
     * @tags ObservationTrigger
     * @name ObservationTriggersControllerSearch
     * @request GET:/observation-triggers/search
     * @secure
     */
    observationTriggersControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            eventCodes: string[];
            /** @maxLength 1024 */
            description: string;
            status: "active" | "inactive";
            actions: (
              | {
                  type: "alarm";
                  config: {
                    severity: "red" | "orange" | "green";
                    type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
                    /** @maxLength 4096 */
                    procedure: string | null;
                    /** @maxLength 1024 */
                    name: string;
                  };
                }
              | {
                  type: "sms";
                  config: {
                    toRaw: string[];
                    toContactList: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ContactList";
                    }[];
                    toTriggerUser: boolean;
                    /** @maxLength 1024 */
                    message: string;
                  };
                }
              | {
                  type: "email";
                  config: {
                    toRaw: string[];
                    toContactList: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ContactList";
                    }[];
                    toTriggerUser: boolean;
                    /** @maxLength 1024 */
                    message: string;
                    /** @maxLength 1024 */
                    subject: string;
                    /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                    locale?: string;
                  };
                }
            )[];
            filter:
              | {
                  type: "operator/and";
                  operands: (
                    | {
                        type: "operator/or";
                        operands: (
                          | {
                              type: "operator/not";
                              operand:
                                | {
                                    type: "constraint/asset";
                                    assets: {
                                      /** @format uuid */
                                      _id: string;
                                      _ref: "Asset";
                                    }[];
                                  }
                                | {
                                    type: "constraint/assetGroup";
                                    groups: {
                                      /** @format uuid */
                                      _id: string;
                                      _ref: "AssetGroup";
                                    }[];
                                  }
                                | {
                                    type: "constraint/zone";
                                    zone: {
                                      /** @format uuid */
                                      _id: string;
                                      _ref: "Zone";
                                    };
                                  }
                                | {
                                    type: "constraint/schedule";
                                    schedule: {
                                      entries: {
                                        daysOfWeek: number[];
                                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                        timeStart: string;
                                        /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                        timeEnd: string;
                                      }[];
                                    };
                                  };
                            }
                          | {
                              type: "constraint/asset";
                              assets: {
                                /** @format uuid */
                                _id: string;
                                _ref: "Asset";
                              }[];
                            }
                          | {
                              type: "constraint/assetGroup";
                              groups: {
                                /** @format uuid */
                                _id: string;
                                _ref: "AssetGroup";
                              }[];
                            }
                          | {
                              type: "constraint/zone";
                              zone: {
                                /** @format uuid */
                                _id: string;
                                _ref: "Zone";
                              };
                            }
                          | {
                              type: "constraint/schedule";
                              schedule: {
                                entries: {
                                  daysOfWeek: number[];
                                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                  timeStart: string;
                                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                                  timeEnd: string;
                                }[];
                              };
                            }
                        )[];
                      }
                    | {
                        type: "constraint/asset";
                        assets: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Asset";
                        }[];
                      }
                    | {
                        type: "constraint/assetGroup";
                        groups: {
                          /** @format uuid */
                          _id: string;
                          _ref: "AssetGroup";
                        }[];
                      }
                    | {
                        type: "constraint/zone";
                        zone: {
                          /** @format uuid */
                          _id: string;
                          _ref: "Zone";
                        };
                      }
                    | {
                        type: "constraint/schedule";
                        schedule: {
                          entries: {
                            daysOfWeek: number[];
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeStart: string;
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeEnd: string;
                          }[];
                        };
                      }
                  )[];
                }
              | {
                  type: "constraint/asset";
                  assets: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  }[];
                }
              | {
                  type: "constraint/assetGroup";
                  groups: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[];
                }
              | {
                  type: "constraint/zone";
                  zone: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  };
                }
              | {
                  type: "constraint/schedule";
                  schedule: {
                    entries: {
                      daysOfWeek: number[];
                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                      timeStart: string;
                      /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                      timeEnd: string;
                    }[];
                  };
                };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/observation-triggers/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  overlays = {
    /**
     * @description Creates a Overlay
     *
     * @tags Overlay
     * @name OverlaysControllerCreate
     * @request POST:/overlays
     * @secure
     */
    overlaysControllerCreate: (
      data: OverlayCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OverlayOutputDto, any>({
        path: `/overlays`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Overlay
     *
     * @tags Overlay
     * @name OverlaysControllerPaginate
     * @request GET:/overlays
     * @secure
     */
    overlaysControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @format uri */
            imageUrl: string | null;
            /** @default 0 */
            level?: number;
            points: {
              sw: {
                lat: number;
                lng: number;
              };
              ne: {
                lat: number;
                lng: number;
              };
              nw: {
                lat: number;
                lng: number;
              };
              se: {
                lat: number;
                lng: number;
              };
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/overlays`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Overlay by id
     *
     * @tags Overlay
     * @name OverlaysControllerFindById
     * @request GET:/overlays/{id}
     * @secure
     */
    overlaysControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OverlayOutputDto, any>({
        path: `/overlays/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a Overlay
     *
     * @tags Overlay
     * @name OverlaysControllerUpdate
     * @request PATCH:/overlays/{id}
     * @secure
     */
    overlaysControllerUpdate: (
      id: string,
      data: OverlayUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<OverlayOutputDto, any>({
        path: `/overlays/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a Overlay by id
     *
     * @tags Overlay
     * @name OverlaysControllerRemove
     * @request DELETE:/overlays/{id}
     * @secure
     */
    overlaysControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/overlays/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Overlay
     * @name OverlaysControllerAddPhoto
     * @request POST:/overlays/app/photo
     * @secure
     */
    overlaysControllerAddPhoto: (
      query: {
        /** Overlay id parameter */
        id: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageUploadDto, any>({
        path: `/overlays/app/photo`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Searches Overlay
     *
     * @tags Overlay
     * @name OverlaysControllerSearch
     * @request GET:/overlays/search
     * @secure
     */
    overlaysControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @format uri */
            imageUrl: string | null;
            /** @default 0 */
            level?: number;
            points: {
              sw: {
                lat: number;
                lng: number;
              };
              ne: {
                lat: number;
                lng: number;
              };
              nw: {
                lat: number;
                lng: number;
              };
              se: {
                lat: number;
                lng: number;
              };
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/overlays/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  reportTriggerRuns = {
    /**
     * @description Creates a ReportTriggerRun
     *
     * @tags ReportTriggerRun
     * @name ReportTriggerRunControllerCreate
     * @request POST:/report-trigger-runs
     * @secure
     */
    reportTriggerRunControllerCreate: (
      data: ReportTriggerRunCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReportTriggerRunOutputDto, any>({
        path: `/report-trigger-runs`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates ReportTriggerRun
     *
     * @tags ReportTriggerRun
     * @name ReportTriggerRunControllerPaginate
     * @request GET:/report-trigger-runs
     * @secure
     */
    reportTriggerRunControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            } | null;
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            reportTrigger: {
              /** @format uuid */
              _id: string;
              /** @format date-time */
              createdAt: string;
              /** @format date-time */
              updatedAt: string;
              telluId?: number | null;
              migrationFinalised?: boolean | null;
              /** @format date-time */
              migrationFinalisedDate?: string | null;
              customer: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              } | null;
              /** @default {"type":"parents","extra":[]} */
              share?: {
                type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                extra?: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Customer";
                }[];
              };
              config:
                | {
                    type: "alarmCenterStatus";
                    exclude?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Customer";
                    }[];
                    timeframe: {
                      type: "month" | "week" | "day" | "hour" | "minute";
                      value: number;
                      floorTo?: "month" | "week" | "day" | "hour" | "minute";
                    };
                    theme?: "bhvk" | "default";
                  }
                | {
                    type: "alarms";
                    excelFormat: "default" | "smc";
                    excelColumns?: (
                      | "customer"
                      | "name"
                      | "severity"
                      | "type"
                      | "asset"
                      | "createdAt"
                      | "timeOfFirstHelper"
                      | "timeUntilFirstHelper"
                      | "alarmCenter"
                      | "ackedAt"
                      | "ackMessage"
                      | "position"
                      | "assetPosition"
                      | "helperCount.true"
                      | "helperCount.null"
                      | "helperCount.false"
                      | "helperName.true"
                      | "helperName.null"
                      | "helperName.false"
                      | "alarmManager"
                      | "alarmId"
                      | "assetId"
                      | "hubspotId"
                      | "alarmDate"
                      | "customerName"
                      | "assetName"
                      | "simpleDescription"
                      | "contractDeliveryDate"
                      | "alarmName"
                      | "onHelpProtocol"
                      | "policeCalled"
                    )[];
                    filter?: {
                      assets?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Asset";
                      }[];
                      assetGroups?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "AssetGroup";
                      }[];
                    };
                    severities?: ("red" | "orange" | "green")[] | null;
                    alarmTypes?:
                      | ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[]
                      | null;
                    timeSeries: {
                      granularity: {
                        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                        value: number;
                      };
                      timeframe: {
                        type: "month" | "week" | "day" | "hour" | "minute";
                        value: number;
                        floorTo?: "month" | "week" | "day" | "hour" | "minute";
                      };
                    };
                    theme?: "bhvk" | "default";
                  }
                | {
                    type: "appUsage";
                    mode?: "hourlyUsage" | "license";
                    filter?: {
                      assets?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Asset";
                      }[];
                      assetGroups?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "AssetGroup";
                      }[];
                    };
                    timeSeries: {
                      granularity: {
                        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                        value: number;
                      };
                      timeframe: {
                        type: "month" | "week";
                        /**
                         * @min 1
                         * @max 1
                         */
                        value: number;
                        floorTo?: "month" | "week" | "day" | "hour" | "minute";
                      };
                    };
                    inactivityThreshold?:
                      | {
                          type: "minute";
                          value: number;
                        }
                      | {
                          type: "hour";
                          value: number;
                        }
                      | {
                          type: "day";
                          /**
                           * @min 1
                           * @exclusiveMin false
                           * @max 14
                           * @exclusiveMax false
                           */
                          value: number;
                        };
                    reduceEmailSize?: boolean;
                    theme?: "bhvk" | "default";
                  }
                | {
                    type: "assetHistory";
                    asset: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    };
                    timeSeries: {
                      granularity: {
                        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                        value: number;
                      };
                      timeframe: {
                        type: "month" | "week" | "day" | "hour" | "minute";
                        value: number;
                        floorTo?: "month" | "week" | "day" | "hour" | "minute";
                      };
                    };
                    aggregatorOptions: {
                      includeRaw: boolean;
                      includeSimple: boolean;
                    };
                    theme?: "bhvk" | "default";
                  }
                | {
                    type: "auditLog";
                    subject?: (
                      | "Alarm"
                      | "AlarmEvent"
                      | "AlarmHelper"
                      | "AlarmPushSubscription"
                      | "AlarmProfessionalResponder"
                      | "Asset"
                      | "AssetGroup"
                      | "AuditLog"
                      | "Beacon"
                      | "ContactList"
                      | "Customer"
                      | "CustomerAlarmCenterRelation"
                      | "CustomerUserRelation"
                      | "Device"
                      | "Observation"
                      | "ObservationTrigger"
                      | "Overlay"
                      | "ReportTrigger"
                      | "ReportTriggerRun"
                      | "Role"
                      | "StaticResponder"
                      | "Webhook"
                      | "User"
                      | "Zone"
                      | "Fingerprint"
                      | "Report"
                      | "all"
                    )[];
                    operation?: string[];
                    user?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "User";
                    }[];
                    reasonKey?: string[];
                    ids?: string[];
                    pagination: {
                      /** @format date-time */
                      after: string;
                      order: "asc" | "desc";
                      max: number;
                    };
                    exposeUsers?: boolean;
                    exposeHistory?: boolean;
                  }
                | {
                    type: "badReviewOnSurvey";
                  }
                | {
                    type: "gatewayAlive";
                    lastSeenThreshold: {
                      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                      value: number;
                    };
                  }
                | {
                    type: "inactivityMonitor";
                    lastSeenThreshold: {
                      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                      value: number;
                    };
                    assets?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    }[];
                    assetGroups?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                    appMode?: ("off" | "on" | "risk")[];
                    timeout?: {
                      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                      value: number;
                    };
                    theme?: "bhvk" | "default";
                  }
                | {
                    type: "newAlarm";
                    filter?: {
                      severities?: ("red" | "orange" | "green")[];
                      alarmTypes?: (
                        | "panic"
                        | "assistance"
                        | "test"
                        | "outage"
                        | "fire"
                        | "medical"
                        | "mandown"
                        | "other"
                      )[];
                      assets?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "Asset";
                      }[];
                      assetGroups?: {
                        /** @format uuid */
                        _id: string;
                        _ref: "AssetGroup";
                      }[];
                    };
                    procedure?: string;
                    info?: string;
                    hideOpenInAppButton?: boolean;
                    exposeAlarmCreatorProperties?: ("smsNumber" | "phoneNumber" | "email")[];
                    theme?: "bhvk" | "default";
                  }
                | {
                    type: "surveyInvitation";
                  }
                | {
                    type: "surveyOverview";
                    timeframe: {
                      type: "month" | "week" | "day" | "hour" | "minute";
                      value: number;
                      floorTo?: "month" | "week" | "day" | "hour" | "minute";
                    };
                  }
                | {
                    type: "unhealthyBeacons";
                    lastSeenThreshold: {
                      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                      value: number;
                    };
                    theme?: "bhvk" | "default";
                  }
                | {
                    type: "zoneOverview";
                    inactivityThreshold?: {
                      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                      value: number;
                    };
                    timeSeries: {
                      granularity: {
                        type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                        value: number;
                      };
                      timeframe: {
                        type: "month" | "week" | "day" | "hour" | "minute";
                        value: number;
                        floorTo?: "month" | "week" | "day" | "hour" | "minute";
                      };
                    };
                    minOccupancy?: {
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 1
                       * @exclusiveMax false
                       */
                      ratioThreshold: number;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      assetCount: number;
                    };
                    zones?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Zone";
                    }[];
                    assets?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    }[];
                    assetGroups?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                    schedule?:
                      | {
                          entries: {
                            daysOfWeek: number[];
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeStart: string;
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeEnd: string;
                            /**
                             * @min 0
                             * @exclusiveMin false
                             */
                            minOccupancy: number;
                          }[];
                        }
                      | {
                          entries: {
                            daysOfWeek: number[];
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeStart: string;
                            /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                            timeEnd: string;
                          }[];
                        };
                    theme?: "bhvk" | "default";
                    disableCompressTimeseries?: boolean;
                  };
              channelOverrides?: {
                unsubscribedEmails: string[];
              };
              channels: (
                | {
                    type: "email";
                    address?: string[];
                    contactLists?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ContactList";
                    }[];
                    getters?: {
                      /** @maxLength 1024 */
                      path: string;
                    }[];
                    assets?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    }[];
                    assetGroups?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                    config?: {
                      /** @maxLength 1024 */
                      subjectOverride?: string;
                      /** @maxLength 4096 */
                      headerOverride?: string;
                    };
                  }
                | {
                    type: "sms";
                    numbers: string[];
                    contactLists: {
                      /** @format uuid */
                      _id: string;
                      _ref: "ContactList";
                    }[];
                  }
                | {
                    type: "alarm";
                    config: {
                      severity: "red" | "orange" | "green";
                      type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
                      /** @maxLength 4096 */
                      procedure: string | null;
                      /** @maxLength 1024 */
                      name: string;
                    };
                  }
                | {
                    type: "observation";
                  }
              )[];
              /** @default null */
              scheduleTrigger?: {
                interval: {
                  type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                  value: number;
                };
                cooldown?: {
                  type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                  value: number;
                };
                /** @format date-time */
                nextAt: string | null;
                /** @format date-time */
                lastAt: string | null;
                /** @format date-time */
                lastAttemptedAt: string | null;
              };
              /** @default null */
              eventTrigger?: {
                events: string[];
                executionDelay?: {
                  type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                  value: number;
                };
                schedule?: {
                  entries: {
                    daysOfWeek: number[];
                    /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                    timeStart: string;
                    /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                    timeEnd: string;
                  }[];
                };
              } | null;
              enabled: boolean;
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale?: string;
              /** @maxLength 1024 */
              timezone?: string;
            };
            runReason: "schedule" | "event";
            /** @format date-time */
            generatedAt: string;
            output: Record<string, any>;
            channelResults: {
              success: boolean;
              data?: any;
            }[];
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/report-trigger-runs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a ReportTriggerRun by id
     *
     * @tags ReportTriggerRun
     * @name ReportTriggerRunControllerFindById
     * @request GET:/report-trigger-runs/{id}
     * @secure
     */
    reportTriggerRunControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReportTriggerRunOutputDto, any>({
        path: `/report-trigger-runs/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportTriggerRun
     * @name ReportTriggerRunControllerUpdateChannelResult
     * @request PATCH:/report-trigger-runs/{id}/channel-result/{channelIndex}
     * @secure
     */
    reportTriggerRunControllerUpdateChannelResult: (
      id: string,
      channelIndex: number,
      data: ReportTriggerChannelResultUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/report-trigger-runs/${id}/channel-result/${channelIndex}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  reportTriggers = {
    /**
     * @description Creates a ReportTrigger
     *
     * @tags ReportTrigger
     * @name ReportTriggersControllerCreate
     * @request POST:/report-triggers
     * @secure
     */
    reportTriggersControllerCreate: (
      data: ReportTriggerCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReportTriggerOutputDto, any>({
        path: `/report-triggers`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates ReportTrigger
     *
     * @tags ReportTrigger
     * @name ReportTriggersControllerPaginate
     * @request GET:/report-triggers
     * @secure
     */
    reportTriggersControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            } | null;
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            config:
              | {
                  type: "alarmCenterStatus";
                  exclude?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Customer";
                  }[];
                  timeframe: {
                    type: "month" | "week" | "day" | "hour" | "minute";
                    value: number;
                    floorTo?: "month" | "week" | "day" | "hour" | "minute";
                  };
                  theme?: "bhvk" | "default";
                }
              | {
                  type: "alarms";
                  excelFormat: "default" | "smc";
                  excelColumns?: (
                    | "customer"
                    | "name"
                    | "severity"
                    | "type"
                    | "asset"
                    | "createdAt"
                    | "timeOfFirstHelper"
                    | "timeUntilFirstHelper"
                    | "alarmCenter"
                    | "ackedAt"
                    | "ackMessage"
                    | "position"
                    | "assetPosition"
                    | "helperCount.true"
                    | "helperCount.null"
                    | "helperCount.false"
                    | "helperName.true"
                    | "helperName.null"
                    | "helperName.false"
                    | "alarmManager"
                    | "alarmId"
                    | "assetId"
                    | "hubspotId"
                    | "alarmDate"
                    | "customerName"
                    | "assetName"
                    | "simpleDescription"
                    | "contractDeliveryDate"
                    | "alarmName"
                    | "onHelpProtocol"
                    | "policeCalled"
                  )[];
                  filter?: {
                    assets?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    }[];
                    assetGroups?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                  };
                  severities?: ("red" | "orange" | "green")[] | null;
                  alarmTypes?:
                    | ("panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other")[]
                    | null;
                  timeSeries: {
                    granularity: {
                      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                      value: number;
                    };
                    timeframe: {
                      type: "month" | "week" | "day" | "hour" | "minute";
                      value: number;
                      floorTo?: "month" | "week" | "day" | "hour" | "minute";
                    };
                  };
                  theme?: "bhvk" | "default";
                }
              | {
                  type: "appUsage";
                  mode?: "hourlyUsage" | "license";
                  filter?: {
                    assets?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    }[];
                    assetGroups?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                  };
                  timeSeries: {
                    granularity: {
                      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                      value: number;
                    };
                    timeframe: {
                      type: "month" | "week";
                      /**
                       * @min 1
                       * @max 1
                       */
                      value: number;
                      floorTo?: "month" | "week" | "day" | "hour" | "minute";
                    };
                  };
                  inactivityThreshold?:
                    | {
                        type: "minute";
                        value: number;
                      }
                    | {
                        type: "hour";
                        value: number;
                      }
                    | {
                        type: "day";
                        /**
                         * @min 1
                         * @exclusiveMin false
                         * @max 14
                         * @exclusiveMax false
                         */
                        value: number;
                      };
                  reduceEmailSize?: boolean;
                  theme?: "bhvk" | "default";
                }
              | {
                  type: "assetHistory";
                  asset: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  };
                  timeSeries: {
                    granularity: {
                      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                      value: number;
                    };
                    timeframe: {
                      type: "month" | "week" | "day" | "hour" | "minute";
                      value: number;
                      floorTo?: "month" | "week" | "day" | "hour" | "minute";
                    };
                  };
                  aggregatorOptions: {
                    includeRaw: boolean;
                    includeSimple: boolean;
                  };
                  theme?: "bhvk" | "default";
                }
              | {
                  type: "auditLog";
                  subject?: (
                    | "Alarm"
                    | "AlarmEvent"
                    | "AlarmHelper"
                    | "AlarmPushSubscription"
                    | "AlarmProfessionalResponder"
                    | "Asset"
                    | "AssetGroup"
                    | "AuditLog"
                    | "Beacon"
                    | "ContactList"
                    | "Customer"
                    | "CustomerAlarmCenterRelation"
                    | "CustomerUserRelation"
                    | "Device"
                    | "Observation"
                    | "ObservationTrigger"
                    | "Overlay"
                    | "ReportTrigger"
                    | "ReportTriggerRun"
                    | "Role"
                    | "StaticResponder"
                    | "Webhook"
                    | "User"
                    | "Zone"
                    | "Fingerprint"
                    | "Report"
                    | "all"
                  )[];
                  operation?: string[];
                  user?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "User";
                  }[];
                  reasonKey?: string[];
                  ids?: string[];
                  pagination: {
                    /** @format date-time */
                    after: string;
                    order: "asc" | "desc";
                    max: number;
                  };
                  exposeUsers?: boolean;
                  exposeHistory?: boolean;
                }
              | {
                  type: "badReviewOnSurvey";
                }
              | {
                  type: "gatewayAlive";
                  lastSeenThreshold: {
                    type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                    value: number;
                  };
                }
              | {
                  type: "inactivityMonitor";
                  lastSeenThreshold: {
                    type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                    value: number;
                  };
                  assets?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  }[];
                  assetGroups?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[];
                  appMode?: ("off" | "on" | "risk")[];
                  timeout?: {
                    type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                    value: number;
                  };
                  theme?: "bhvk" | "default";
                }
              | {
                  type: "newAlarm";
                  filter?: {
                    severities?: ("red" | "orange" | "green")[];
                    alarmTypes?: (
                      | "panic"
                      | "assistance"
                      | "test"
                      | "outage"
                      | "fire"
                      | "medical"
                      | "mandown"
                      | "other"
                    )[];
                    assets?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "Asset";
                    }[];
                    assetGroups?: {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[];
                  };
                  procedure?: string;
                  info?: string;
                  hideOpenInAppButton?: boolean;
                  exposeAlarmCreatorProperties?: ("smsNumber" | "phoneNumber" | "email")[];
                  theme?: "bhvk" | "default";
                }
              | {
                  type: "surveyInvitation";
                }
              | {
                  type: "surveyOverview";
                  timeframe: {
                    type: "month" | "week" | "day" | "hour" | "minute";
                    value: number;
                    floorTo?: "month" | "week" | "day" | "hour" | "minute";
                  };
                }
              | {
                  type: "unhealthyBeacons";
                  lastSeenThreshold: {
                    type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                    value: number;
                  };
                  theme?: "bhvk" | "default";
                }
              | {
                  type: "zoneOverview";
                  inactivityThreshold?: {
                    type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                    value: number;
                  };
                  timeSeries: {
                    granularity: {
                      type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                      value: number;
                    };
                    timeframe: {
                      type: "month" | "week" | "day" | "hour" | "minute";
                      value: number;
                      floorTo?: "month" | "week" | "day" | "hour" | "minute";
                    };
                  };
                  minOccupancy?: {
                    /**
                     * @min 0
                     * @exclusiveMin false
                     * @max 1
                     * @exclusiveMax false
                     */
                    ratioThreshold: number;
                    /**
                     * @min 0
                     * @exclusiveMin false
                     */
                    assetCount: number;
                  };
                  zones?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Zone";
                  }[];
                  assets?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  }[];
                  assetGroups?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[];
                  schedule?:
                    | {
                        entries: {
                          daysOfWeek: number[];
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeStart: string;
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeEnd: string;
                          /**
                           * @min 0
                           * @exclusiveMin false
                           */
                          minOccupancy: number;
                        }[];
                      }
                    | {
                        entries: {
                          daysOfWeek: number[];
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeStart: string;
                          /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                          timeEnd: string;
                        }[];
                      };
                  theme?: "bhvk" | "default";
                  disableCompressTimeseries?: boolean;
                };
            channelOverrides?: {
              unsubscribedEmails: string[];
            };
            channels: (
              | {
                  type: "email";
                  address?: string[];
                  contactLists?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "ContactList";
                  }[];
                  getters?: {
                    /** @maxLength 1024 */
                    path: string;
                  }[];
                  assets?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "Asset";
                  }[];
                  assetGroups?: {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[];
                  config?: {
                    /** @maxLength 1024 */
                    subjectOverride?: string;
                    /** @maxLength 4096 */
                    headerOverride?: string;
                  };
                }
              | {
                  type: "sms";
                  numbers: string[];
                  contactLists: {
                    /** @format uuid */
                    _id: string;
                    _ref: "ContactList";
                  }[];
                }
              | {
                  type: "alarm";
                  config: {
                    severity: "red" | "orange" | "green";
                    type: "panic" | "assistance" | "test" | "outage" | "fire" | "medical" | "mandown" | "other";
                    /** @maxLength 4096 */
                    procedure: string | null;
                    /** @maxLength 1024 */
                    name: string;
                  };
                }
              | {
                  type: "observation";
                }
            )[];
            /** @default null */
            scheduleTrigger?: {
              interval: {
                type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                value: number;
              };
              cooldown?: {
                type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                value: number;
              };
              /** @format date-time */
              nextAt: string | null;
              /** @format date-time */
              lastAt: string | null;
              /** @format date-time */
              lastAttemptedAt: string | null;
            };
            /** @default null */
            eventTrigger?: {
              events: string[];
              executionDelay?: {
                type: "minute" | "hour" | "day" | "week" | "month" | "quarter" | "year";
                value: number;
              };
              schedule?: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              };
            } | null;
            enabled: boolean;
            /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
            locale?: string;
            /** @maxLength 1024 */
            timezone?: string;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/report-triggers`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a ReportTrigger by id
     *
     * @tags ReportTrigger
     * @name ReportTriggersControllerFindById
     * @request GET:/report-triggers/{id}
     * @secure
     */
    reportTriggersControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReportTriggerOutputDto, any>({
        path: `/report-triggers/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a ReportTrigger
     *
     * @tags ReportTrigger
     * @name ReportTriggersControllerUpdate
     * @request PATCH:/report-triggers/{id}
     * @secure
     */
    reportTriggersControllerUpdate: (
      id: string,
      data: ReportTriggerUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ReportTriggerOutputDto, any>({
        path: `/report-triggers/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a ReportTrigger by id
     *
     * @tags ReportTrigger
     * @name ReportTriggersControllerRemove
     * @request DELETE:/report-triggers/{id}
     * @secure
     */
    reportTriggersControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/report-triggers/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportTrigger
     * @name ReportTriggersControllerUnsubscribe
     * @request GET:/report-triggers/{id}/unsubscribe
     * @secure
     */
    reportTriggersControllerUnsubscribe: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/report-triggers/${id}/unsubscribe`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportTrigger
     * @name ReportTriggersControllerAdhocPopulate
     * @request POST:/report-triggers/adhoc-populate
     * @secure
     */
    reportTriggersControllerAdhocPopulate: (
      data: ReportTriggerAdhocPopulateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/report-triggers/adhoc-populate`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags ReportTrigger
     * @name ReportTriggersControllerToProcessSchedule
     * @request GET:/report-triggers/to-process
     * @secure
     */
    reportTriggersControllerToProcessSchedule: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/report-triggers/to-process`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  roles = {
    /**
     * @description Creates a Role
     *
     * @tags Role
     * @name RolesControllerCreate
     * @request POST:/roles
     * @secure
     */
    rolesControllerCreate: (
      data: RoleCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RoleOutputDto, any>({
        path: `/roles`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Role
     *
     * @tags Role
     * @name RolesControllerPaginate
     * @request GET:/roles
     * @secure
     */
    rolesControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            /** @maxLength 1024 */
            name: string;
            permissions: {
              subject:
                | "Alarm"
                | "AlarmEvent"
                | "AlarmHelper"
                | "AlarmPushSubscription"
                | "AlarmProfessionalResponder"
                | "Asset"
                | "AssetGroup"
                | "AuditLog"
                | "Beacon"
                | "ContactList"
                | "Customer"
                | "CustomerAlarmCenterRelation"
                | "CustomerUserRelation"
                | "Device"
                | "Observation"
                | "ObservationTrigger"
                | "Overlay"
                | "ReportTrigger"
                | "ReportTriggerRun"
                | "Role"
                | "StaticResponder"
                | "Webhook"
                | "User"
                | "Zone"
                | "Fingerprint"
                | "Report"
                | "all";
              actions: string[];
              limit?: {
                connectedCustomers?: boolean;
                connectedCustomersViaRelation?: boolean;
                alarmsEscalatedToMyAlarmCenter?: boolean;
                alarmAgeLimitOfCustomer?: boolean;
                ownUserId?: boolean;
                ownAssetId?: boolean;
                mode?: "default" | "asUser";
                fields?: string[];
                allowedRoles?: string[];
              };
            }[];
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/roles`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a Role
     *
     * @tags Role
     * @name RolesControllerUpdate
     * @request PATCH:/roles/{id}
     * @secure
     */
    rolesControllerUpdate: (
      id: string,
      data: RoleUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RoleOutputDto, any>({
        path: `/roles/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Role by id
     *
     * @tags Role
     * @name RolesControllerFindById
     * @request GET:/roles/{id}
     * @secure
     */
    rolesControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<RoleOutputDto, any>({
        path: `/roles/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a Role by id
     *
     * @tags Role
     * @name RolesControllerRemove
     * @request DELETE:/roles/{id}
     * @secure
     */
    rolesControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/roles/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Role
     * @name RolesControllerGetPermissionLimits
     * @request GET:/roles/permission-limits
     * @secure
     */
    rolesControllerGetPermissionLimits: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/roles/permission-limits`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  specials = {
    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerAddDevices
     * @request POST:/specials/bhv-knop/add-devices
     * @secure
     */
    bhvKnopControllerAddDevices: (
      data: BHVKnopAddDevicesDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/bhv-knop/add-devices`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerAddAddtionalResources
     * @request POST:/specials/bhv-knop/addon
     * @secure
     */
    bhvKnopControllerAddAddtionalResources: (
      data: BHVKnopAddAddtionalPaidResourcesDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/bhv-knop/addon`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerAssignHardware
     * @request POST:/specials/bhv-knop/assign-hardware
     * @secure
     */
    bhvKnopControllerAssignHardware: (
      data: BHVKnopAssignHardwareDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/bhv-knop/assign-hardware`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerAssetsWithDeviceList
     * @request GET:/specials/bhv-knop/device-list
     * @secure
     */
    bhvKnopControllerAssetsWithDeviceList: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/bhv-knop/device-list`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerGetNextSequenceNumber
     * @request POST:/specials/bhv-knop/next-sequence-number
     * @secure
     */
    bhvKnopControllerGetNextSequenceNumber: (
      data: BHVKnopGetNextSequenceNumberDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BHVKnopGetNextSequenceNumberResponseDto, any>({
        path: `/specials/bhv-knop/next-sequence-number`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerCreatePaid
     * @request POST:/specials/bhv-knop/paid
     * @secure
     */
    bhvKnopControllerCreatePaid: (
      data: BHVKnopCreateOrConvertToPaidDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BHVKnopCreateOrConvertToPaidResponseDto, any>({
        path: `/specials/bhv-knop/paid`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerRemoveDevices
     * @request POST:/specials/bhv-knop/remove-unpaid-devices
     * @secure
     */
    bhvKnopControllerRemoveDevices: (
      data: BHVKnopRemoveDevicesDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/bhv-knop/remove-unpaid-devices`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerGetSequenceNumbers
     * @request POST:/specials/bhv-knop/sequence-numbers
     * @secure
     */
    bhvKnopControllerGetSequenceNumbers: (
      data: BHVKnopGetSequenceNumbersDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BHVKnopGetSequenceNumbersResponseDto, any>({
        path: `/specials/bhv-knop/sequence-numbers`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerCreateTrial
     * @request POST:/specials/bhv-knop/trial
     * @secure
     */
    bhvKnopControllerCreateTrial: (
      data: BHVKnopCreateTrialDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<BHVKnopCreateTrialResponseDto, any>({
        path: `/specials/bhv-knop/trial`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags BHV Knop
     * @name BhvKnopControllerUpdateMonitoredZones
     * @request POST:/specials/bhv-knop/update-monitored-zones
     * @secure
     */
    bhvKnopControllerUpdateMonitoredZones: (
      data: BHVKnopUpdateMonitoredZonesDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/bhv-knop/update-monitored-zones`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Creates a KioskUser
     *
     * @tags KioskUser
     * @name KioskUserControllerCreate
     * @request POST:/specials/kiosk-user
     * @secure
     */
    kioskUserControllerCreate: (
      data: KioskUserCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<KioskUserOutputDto, any>({
        path: `/specials/kiosk-user`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates KioskUser
     *
     * @tags KioskUser
     * @name KioskUserControllerPaginate
     * @request GET:/specials/kiosk-user
     * @secure
     */
    kioskUserControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            _id: string;
            user: {
              /** @format uuid */
              _id: string;
              /** @format date-time */
              createdAt: string;
              /** @format date-time */
              updatedAt: string;
              telluId?: number | null;
              migrationFinalised?: boolean | null;
              /** @format date-time */
              migrationFinalisedDate?: string | null;
              /** @maxLength 1024 */
              username: string;
              /** @maxLength 1024 */
              name: string;
              properties: {
                /** @maxLength 1024 */
                phoneNumber?: string | null;
                /** @maxLength 1024 */
                smsNumber?: string | null;
                /** @maxLength 1024 */
                email?: string | null;
                /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                locale?: string | null;
                /** @maxLength 1024 */
                image?: string | null;
                rSim?: {
                  /** @maxLength 1024 */
                  euicc: string;
                  primary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  secondary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  enabled: boolean;
                } | null;
              };
              /** @default [] */
              globalRoles?: string[];
              /** @default [] */
              specialPermissions?: string[];
              isServiceAccount?: boolean;
              /** @format date-time */
              lastActiveAt?: string;
              type?: "default" | "serviceAccount" | "kiosk";
              userPolicies?: {
                disablePasswordlessLogin?: boolean;
              };
            };
            device: {
              /** @format uuid */
              _id: string;
              /** @format date-time */
              createdAt: string;
              /** @format date-time */
              updatedAt: string;
              telluId?: number | null;
              migrationFinalised?: boolean | null;
              /** @format date-time */
              migrationFinalisedDate?: string | null;
              customer: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              };
              /** @default {"type":"parents","extra":[]} */
              share?: {
                type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                extra?: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Customer";
                }[];
              };
              asset: {
                /** @format uuid */
                _id: string;
                _ref: "Asset";
              } | null;
              /** @maxLength 1024 */
              name?: string;
              /** @maxLength 1024 */
              deviceId: string;
              type: "appDevice" | "lora" | "loraGateway" | "flic" | "generic" | "kiosk";
              loraConfig?:
                | {
                    type: "adeunis.arf8123aa";
                    config: {
                      /** @maxLength 1024 */
                      fieldTest: string;
                    };
                  }
                | {
                    type: "iotracker.v2";
                    config: {
                      /** @maxLength 1024 */
                      beaconScan: string;
                      /** @maxLength 1024 */
                      gps: string;
                      /** @maxLength 1024 */
                      preMandown: string;
                      /** @maxLength 1024 */
                      mandown: string;
                      /** @maxLength 1024 */
                      movement: string;
                      /** @maxLength 1024 */
                      alarmSeverity: string;
                      /** @maxLength 1024 */
                      alive: string;
                    };
                  }
                | {
                    type: "milesight.ws101";
                    config: {
                      /** @maxLength 1024 */
                      beaconScan: string;
                      /** @maxLength 1024 */
                      alarmSeverity: string;
                      /** @maxLength 1024 */
                      alive: string;
                    };
                  }
                | {
                    type: "sensative.1301001";
                    config: {
                      /** @maxLength 1024 */
                      alarmSeverity: string;
                      /** @maxLength 1024 */
                      alive: string;
                      /** @maxLength 1024 */
                      floodDetected: string;
                      /** @maxLength 1024 */
                      noFloodDetected: string;
                    };
                  }
                | null;
              kioskConfig?: {
                /** @default false */
                managed?: boolean;
                zone?: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Zone";
                } | null;
                exposeAssets?:
                  | {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                    }[]
                  | null;
                exposeLabels?:
                  | {
                      /** @format uuid */
                      _id: string;
                      _ref: "AssetGroup";
                      countLabel?: boolean;
                    }[]
                  | null;
                schedule?: {
                  entries: {
                    daysOfWeek: number[];
                    /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                    timeStart: string;
                    /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                    timeEnd: string;
                  }[];
                } | null;
                homepage?: {
                  incidentCounter?: {
                    /** @default true */
                    enabled?: boolean;
                    /** @format date-time */
                    countFromDate?: string | null;
                    /**
                     * @min 0
                     * @exclusiveMin false
                     * @default 0
                     */
                    threshold?: number;
                  };
                  /** @default true */
                  anonymous?: boolean;
                  /** @format uri */
                  logo?: string | null;
                };
                alarm?: {
                  /** @default true */
                  enabled?: boolean;
                  focus?: {
                    red?: {
                      flash?: boolean | null;
                      sound?: {
                        enabled: boolean;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         * @max 100
                         * @exclusiveMax false
                         */
                        volume?: number | null;
                        sound?:
                          | "alarm"
                          | "alarmClock"
                          | "alarmRetro"
                          | "announcement"
                          | "attention"
                          | "bassPulse"
                          | "beepBeep"
                          | "beepBurglar"
                          | "beepContinuous"
                          | "beepHighSoft"
                          | "beepIncremental"
                          | "bell"
                          | "car"
                          | "chickens"
                          | "chime"
                          | "chirpSoft"
                          | "clockMelody"
                          | "countdownSonar"
                          | "doorbell"
                          | "escalate"
                          | "fancy"
                          | "fastAndNervous"
                          | "harp"
                          | "incomingPhone"
                          | "introduction"
                          | "intrusive"
                          | "invasion"
                          | "jungle"
                          | "lofiChords"
                          | "loopLow"
                          | "melodySoft"
                          | "notifySubtle"
                          | "onOff"
                          | "orchestral"
                          | "pling"
                          | "plingHigh"
                          | "plingLow"
                          | "pluckSong"
                          | "quick"
                          | "scanning"
                          | "shake"
                          | "silence"
                          | "sirenHigh"
                          | "sirenLow"
                          | "soft"
                          | "spaceship"
                          | "startup"
                          | "threeTones"
                          | "tornado"
                          | "wailing"
                          | "wakeup"
                          | null;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        stopAfterSeconds?: number | null;
                        stopAfterTouch?: boolean | null;
                      };
                    };
                    orange?: {
                      flash?: boolean | null;
                      sound?: {
                        enabled: boolean;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         * @max 100
                         * @exclusiveMax false
                         */
                        volume?: number | null;
                        sound?:
                          | "alarm"
                          | "alarmClock"
                          | "alarmRetro"
                          | "announcement"
                          | "attention"
                          | "bassPulse"
                          | "beepBeep"
                          | "beepBurglar"
                          | "beepContinuous"
                          | "beepHighSoft"
                          | "beepIncremental"
                          | "bell"
                          | "car"
                          | "chickens"
                          | "chime"
                          | "chirpSoft"
                          | "clockMelody"
                          | "countdownSonar"
                          | "doorbell"
                          | "escalate"
                          | "fancy"
                          | "fastAndNervous"
                          | "harp"
                          | "incomingPhone"
                          | "introduction"
                          | "intrusive"
                          | "invasion"
                          | "jungle"
                          | "lofiChords"
                          | "loopLow"
                          | "melodySoft"
                          | "notifySubtle"
                          | "onOff"
                          | "orchestral"
                          | "pling"
                          | "plingHigh"
                          | "plingLow"
                          | "pluckSong"
                          | "quick"
                          | "scanning"
                          | "shake"
                          | "silence"
                          | "sirenHigh"
                          | "sirenLow"
                          | "soft"
                          | "spaceship"
                          | "startup"
                          | "threeTones"
                          | "tornado"
                          | "wailing"
                          | "wakeup"
                          | null;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        stopAfterSeconds?: number | null;
                        stopAfterTouch?: boolean | null;
                      };
                    };
                    green?: {
                      flash?: boolean | null;
                      sound?: {
                        enabled: boolean;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         * @max 100
                         * @exclusiveMax false
                         */
                        volume?: number | null;
                        sound?:
                          | "alarm"
                          | "alarmClock"
                          | "alarmRetro"
                          | "announcement"
                          | "attention"
                          | "bassPulse"
                          | "beepBeep"
                          | "beepBurglar"
                          | "beepContinuous"
                          | "beepHighSoft"
                          | "beepIncremental"
                          | "bell"
                          | "car"
                          | "chickens"
                          | "chime"
                          | "chirpSoft"
                          | "clockMelody"
                          | "countdownSonar"
                          | "doorbell"
                          | "escalate"
                          | "fancy"
                          | "fastAndNervous"
                          | "harp"
                          | "incomingPhone"
                          | "introduction"
                          | "intrusive"
                          | "invasion"
                          | "jungle"
                          | "lofiChords"
                          | "loopLow"
                          | "melodySoft"
                          | "notifySubtle"
                          | "onOff"
                          | "orchestral"
                          | "pling"
                          | "plingHigh"
                          | "plingLow"
                          | "pluckSong"
                          | "quick"
                          | "scanning"
                          | "shake"
                          | "silence"
                          | "sirenHigh"
                          | "sirenLow"
                          | "soft"
                          | "spaceship"
                          | "startup"
                          | "threeTones"
                          | "tornado"
                          | "wailing"
                          | "wakeup"
                          | null;
                        /**
                         * @min 0
                         * @exclusiveMin false
                         */
                        stopAfterSeconds?: number | null;
                        stopAfterTouch?: boolean | null;
                      };
                    };
                  };
                  /** @default true */
                  showPositionLabels?: boolean;
                  /** @default true */
                  anonymous?: boolean;
                  acknowledge?: {
                    enabled?: boolean;
                    /** @pattern ^[0-9]{4,6}$ */
                    pin?: string | null;
                    messagePresets?: string[] | null;
                  };
                  mode?: "zoneBased" | "customerBased" | null;
                };
                manageAvailability?: {
                  /** @default true */
                  enabled?: boolean;
                  /** @pattern ^[0-9]{4,6}$ */
                  pin?: string | null;
                  /** @default true */
                  showAppBanner?: boolean;
                  /** @default false */
                  forceShowSearch?: boolean;
                  /** @default false */
                  forceShowLabelFilter?: boolean;
                };
                hardware?: {
                  /**
                   * @min 0
                   * @exclusiveMin false
                   * @max 100
                   * @exclusiveMax false
                   */
                  screenBrightness?: number | null;
                };
              };
              battery?: number;
              rssi?: number;
              snr?: number;
              clientConfig?: {
                app?: (
                  | {
                      type: "key";
                      key: string;
                      value: any;
                      editable?: boolean;
                    }
                  | {
                      type: "group";
                      group: {
                        /** @format uuid */
                        _id: string;
                        _ref: "ClientConfigGroup";
                      };
                    }
                )[];
                kiosk?: (
                  | {
                      type: "key";
                      key: string;
                      value: any;
                      editable?: boolean;
                    }
                  | {
                      type: "group";
                      group: {
                        /** @format uuid */
                        _id: string;
                        _ref: "ClientConfigGroup";
                      };
                    }
                )[];
              };
            };
            asset: {
              /** @format uuid */
              _id: string;
              /** @format date-time */
              createdAt: string;
              /** @format date-time */
              updatedAt: string;
              telluId?: number | null;
              migrationFinalised?: boolean | null;
              /** @format date-time */
              migrationFinalisedDate?: string | null;
              customer: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              };
              /** @default {"type":"parents","extra":[]} */
              share?: {
                type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
                extra?: {
                  /** @format uuid */
                  _id: string;
                  _ref: "Customer";
                }[];
              };
              user: {
                /** @format uuid */
                _id: string;
                _ref: "User";
              } | null;
              /** @maxLength 1024 */
              name: string;
              /** @maxLength 1024 */
              externalId?: string | null;
              propertiesOwnership?: "override" | "inherit";
              /** @default {} */
              properties?: {
                /** @maxLength 1024 */
                phoneNumber?: string | null;
                /** @maxLength 1024 */
                smsNumber?: string | null;
                /** @maxLength 1024 */
                email?: string | null;
                /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
                locale?: string | null;
                /** @maxLength 1024 */
                image?: string | null;
                rSim?: {
                  /** @maxLength 1024 */
                  euicc: string;
                  primary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  secondary: {
                    /** @maxLength 1024 */
                    msisdn: string;
                    /** @maxLength 1024 */
                    iccid: string;
                  };
                  enabled: boolean;
                } | null;
              };
              /** @default null */
              position?: {
                type: "Point";
                coordinates: number[];
                properties: {
                  accuracy?: number;
                  heading?: number;
                  headingAccuracy?: number;
                  speed?: number;
                  speedAccuracy?: number;
                  altitude?: number;
                  altitudeAccuracy?: number;
                  address?: {
                    /** @maxLength 1024 */
                    countryCode?: string | null;
                    /** @maxLength 1024 */
                    state?: string | null;
                    /** @maxLength 1024 */
                    city?: string | null;
                    /** @maxLength 1024 */
                    streetName?: string | null;
                    /** @maxLength 1024 */
                    streetNumber?: string | null;
                    floor?: number | null;
                    /** @maxLength 1024 */
                    formattedAddress?: string;
                  };
                  /** @format date-time */
                  dateTime?: string;
                  /** @maxLength 1024 */
                  provider?: string;
                };
              };
              available: boolean;
              /** @default true */
              allowContactOnOwnAlarms?: boolean;
              app?: {
                mode: "off" | "on" | "risk";
              } | null;
              insideZones: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              }[];
              assetGroups: {
                /** @format uuid */
                _id: string;
                _ref: "AssetGroup";
              }[];
              /** @default [] */
              staticResponders?: {
                staticResponder: {
                  /** @format uuid */
                  _id: string;
                  _ref: "StaticResponder";
                };
                priority: number;
              }[];
              /** @format date-time */
              lastObservationAt?: string;
              /** @default false */
              invisibleResponder?: boolean;
              /** @maxLength 4096 */
              description?: string;
              type?: "default" | "kiosk";
            };
            kioskConfig: {
              /** @default false */
              managed?: boolean;
              zone: {
                /** @format uuid */
                _id: string;
                _ref: "Zone";
              } | null;
              exposeAssets:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                  }[]
                | null;
              exposeLabels:
                | {
                    /** @format uuid */
                    _id: string;
                    _ref: "AssetGroup";
                    countLabel?: boolean;
                  }[]
                | null;
              schedule: {
                entries: {
                  daysOfWeek: number[];
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeStart: string;
                  /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                  timeEnd: string;
                }[];
              } | null;
              homepage: {
                incidentCounter: {
                  /** @default true */
                  enabled?: boolean;
                  /** @format date-time */
                  countFromDate: string | null;
                  /**
                   * @min 0
                   * @exclusiveMin false
                   * @default 0
                   */
                  threshold?: number;
                };
                /** @default true */
                anonymous?: boolean;
                /** @format uri */
                logo: string | null;
              };
              alarm: {
                /** @default true */
                enabled?: boolean;
                focus?: {
                  red?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  orange?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                  green?: {
                    flash?: boolean | null;
                    sound?: {
                      enabled: boolean;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       * @max 100
                       * @exclusiveMax false
                       */
                      volume?: number | null;
                      sound?:
                        | "alarm"
                        | "alarmClock"
                        | "alarmRetro"
                        | "announcement"
                        | "attention"
                        | "bassPulse"
                        | "beepBeep"
                        | "beepBurglar"
                        | "beepContinuous"
                        | "beepHighSoft"
                        | "beepIncremental"
                        | "bell"
                        | "car"
                        | "chickens"
                        | "chime"
                        | "chirpSoft"
                        | "clockMelody"
                        | "countdownSonar"
                        | "doorbell"
                        | "escalate"
                        | "fancy"
                        | "fastAndNervous"
                        | "harp"
                        | "incomingPhone"
                        | "introduction"
                        | "intrusive"
                        | "invasion"
                        | "jungle"
                        | "lofiChords"
                        | "loopLow"
                        | "melodySoft"
                        | "notifySubtle"
                        | "onOff"
                        | "orchestral"
                        | "pling"
                        | "plingHigh"
                        | "plingLow"
                        | "pluckSong"
                        | "quick"
                        | "scanning"
                        | "shake"
                        | "silence"
                        | "sirenHigh"
                        | "sirenLow"
                        | "soft"
                        | "spaceship"
                        | "startup"
                        | "threeTones"
                        | "tornado"
                        | "wailing"
                        | "wakeup"
                        | null;
                      /**
                       * @min 0
                       * @exclusiveMin false
                       */
                      stopAfterSeconds?: number | null;
                      stopAfterTouch?: boolean | null;
                    };
                  };
                };
                /** @default true */
                showPositionLabels?: boolean;
                /** @default true */
                anonymous?: boolean;
                acknowledge?: {
                  enabled: boolean;
                  /** @pattern ^[0-9]{4,6}$ */
                  pin: string | null;
                  messagePresets: string[] | null;
                };
                mode?: "zoneBased" | "customerBased" | null;
              };
              manageAvailability: {
                /** @default true */
                enabled?: boolean;
                /** @pattern ^[0-9]{4,6}$ */
                pin: string | null;
                /** @default true */
                showAppBanner?: boolean;
                /** @default false */
                forceShowSearch?: boolean;
                /** @default false */
                forceShowLabelFilter?: boolean;
              };
              hardware: {
                /**
                 * @min 0
                 * @exclusiveMin false
                 * @max 100
                 * @exclusiveMax false
                 */
                screenBrightness: number | null;
              };
            };
            password?: string;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/specials/kiosk-user`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a KioskUser by id
     *
     * @tags KioskUser
     * @name KioskUserControllerFindById
     * @request GET:/specials/kiosk-user/{id}
     * @secure
     */
    kioskUserControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<KioskUserOutputDto, any>({
        path: `/specials/kiosk-user/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a KioskUser
     *
     * @tags KioskUser
     * @name KioskUserControllerUpdate
     * @request PATCH:/specials/kiosk-user/{id}
     * @secure
     */
    kioskUserControllerUpdate: (
      id: string,
      data: KioskUserUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<KioskUserOutputDto, any>({
        path: `/specials/kiosk-user/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a KioskUser by id
     *
     * @tags KioskUser
     * @name KioskUserControllerRemove
     * @request DELETE:/specials/kiosk-user/{id}
     * @secure
     */
    kioskUserControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/kiosk-user/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags KioskUser
     * @name KioskUserControllerMoveKioskUser
     * @request POST:/specials/kiosk-user/{id}/move
     * @secure
     */
    kioskUserControllerMoveKioskUser: (
      id: string,
      data: KioskMoveCustomerDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/kiosk-user/${id}/move`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags KioskUser
     * @name KioskUserControllerObserveAsset
     * @request POST:/specials/kiosk-user/{id}/observe-asset
     * @secure
     */
    kioskUserControllerObserveAsset: (
      id: string,
      data: KioskUserObserveAsset,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/kiosk-user/${id}/observe-asset`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags KioskUser
     * @name KioskUserControllerRegenerateCredentials
     * @request POST:/specials/kiosk-user/{id}/regenerate-credentials
     * @secure
     */
    kioskUserControllerRegenerateCredentials: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/specials/kiosk-user/${id}/regenerate-credentials`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Gets the createdAt value of latest non-green alarm
     *
     * @tags KioskUser
     * @name KioskUserControllerGetIncidentScoreboardValue
     * @request GET:/specials/kiosk-user/{id}/safetyScoreboard
     * @secure
     */
    kioskUserControllerGetIncidentScoreboardValue: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<KioskUserSafetyScoreboardOutputDto, any>({
        path: `/specials/kiosk-user/${id}/safetyScoreboard`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags KioskUser
     * @name KioskUserControllerAddPhoto
     * @request POST:/specials/kiosk-user/photo
     * @secure
     */
    kioskUserControllerAddPhoto: (
      query: {
        /** Device id parameter */
        id: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageUploadDto, any>({
        path: `/specials/kiosk-user/photo`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  staticResponders = {
    /**
     * @description Creates a StaticResponder
     *
     * @tags StaticResponder
     * @name StaticRespondersControllerCreate
     * @request POST:/static-responders
     * @secure
     */
    staticRespondersControllerCreate: (
      data: StaticResponderCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StaticResponderOutputDto, any>({
        path: `/static-responders`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates StaticResponder
     *
     * @tags StaticResponder
     * @name StaticRespondersControllerPaginate
     * @request GET:/static-responders
     * @secure
     */
    staticRespondersControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            externalId: string | null;
            contact: {
              /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
              email: string | null;
              /** @maxLength 1024 */
              phone: string | null;
              /** @maxLength 1024 */
              sms: string | null;
              /** @maxLength 1024 */
              camera: string | null;
            };
            schedule: {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            } | null;
            /** @maxLength 1024 */
            migrationKey?: string;
            integrations?: {
              sequrix: {
                enabled: boolean;
                /** @maxLength 1024 */
                objectCode: string;
              };
            };
            statistics?: {
              /**
               * @maxItems 10
               * @minItems 0
               */
              acceptSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              onLocationSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              finishSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountRejected: number;
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountAccepted: number;
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/static-responders`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a StaticResponder by id
     *
     * @tags StaticResponder
     * @name StaticRespondersControllerFindById
     * @request GET:/static-responders/{id}
     * @secure
     */
    staticRespondersControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StaticResponderOutputDto, any>({
        path: `/static-responders/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a StaticResponder
     *
     * @tags StaticResponder
     * @name StaticRespondersControllerUpdate
     * @request PATCH:/static-responders/{id}
     * @secure
     */
    staticRespondersControllerUpdate: (
      id: string,
      data: StaticResponderUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<StaticResponderOutputDto, any>({
        path: `/static-responders/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a StaticResponder by id
     *
     * @tags StaticResponder
     * @name StaticRespondersControllerRemove
     * @request DELETE:/static-responders/{id}
     * @secure
     */
    staticRespondersControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/static-responders/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Searches StaticResponder
     *
     * @tags StaticResponder
     * @name StaticRespondersControllerSearch
     * @request GET:/static-responders/search
     * @secure
     */
    staticRespondersControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            /** @maxLength 1024 */
            name: string;
            /** @maxLength 1024 */
            externalId: string | null;
            contact: {
              /** @pattern ^([\w+\-.]+@([\w-]+\.)+[\w-]{2,}[, ]*?)+?$ */
              email: string | null;
              /** @maxLength 1024 */
              phone: string | null;
              /** @maxLength 1024 */
              sms: string | null;
              /** @maxLength 1024 */
              camera: string | null;
            };
            schedule: {
              entries: {
                daysOfWeek: number[];
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeStart: string;
                /** @pattern ^(?:[01]\d|2[0-3]):[0-5]\d|^24:00$ */
                timeEnd: string;
              }[];
            } | null;
            /** @maxLength 1024 */
            migrationKey?: string;
            integrations?: {
              sequrix: {
                enabled: boolean;
                /** @maxLength 1024 */
                objectCode: string;
              };
            };
            statistics?: {
              /**
               * @maxItems 10
               * @minItems 0
               */
              acceptSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              onLocationSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @maxItems 10
               * @minItems 0
               */
              finishSpeeds: {
                /** @format date-time */
                timestamp: string;
                /**
                 * @min 0
                 * @exclusiveMin false
                 */
                speedMs: number;
              }[];
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountRejected: number;
              /**
               * @min 0
               * @exclusiveMin false
               */
              amountAccepted: number;
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/static-responders/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  surveyQuestions = {
    /**
     * @description Paginates SurveyQuestion
     *
     * @tags SurveyQuestion
     * @name SurveyQuestionsControllerPaginate
     * @request GET:/survey-questions
     * @secure
     */
    surveyQuestionsControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            /** @maxLength 1024 */
            question: string;
            type: "multipleChoice" | "followUp" | "openEnded" | "likertScale";
            options?: {
              value: number;
              /** @maxLength 1024 */
              label: string;
              variant: "primary" | "danger" | "success";
            }[];
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/survey-questions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a SurveyQuestion
     *
     * @tags SurveyQuestion
     * @name SurveyQuestionsControllerCreate
     * @request POST:/survey-questions
     * @secure
     */
    surveyQuestionsControllerCreate: (
      data: SurveyQuestionCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyQuestionOutputDto, any>({
        path: `/survey-questions`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a SurveyQuestion by id
     *
     * @tags SurveyQuestion
     * @name SurveyQuestionsControllerFindById
     * @request GET:/survey-questions/{id}
     * @secure
     */
    surveyQuestionsControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyQuestionOutputDto, any>({
        path: `/survey-questions/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a SurveyQuestion
     *
     * @tags SurveyQuestion
     * @name SurveyQuestionsControllerUpdate
     * @request PATCH:/survey-questions/{id}
     * @secure
     */
    surveyQuestionsControllerUpdate: (
      id: string,
      data: SurveyQuestionUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyQuestionOutputDto, any>({
        path: `/survey-questions/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a SurveyQuestion by id
     *
     * @tags SurveyQuestion
     * @name SurveyQuestionsControllerRemove
     * @request DELETE:/survey-questions/{id}
     * @secure
     */
    surveyQuestionsControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/survey-questions/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  surveyResponses = {
    /**
     * @description Paginates SurveyResponse
     *
     * @tags SurveyResponse
     * @name SurveyResponsesControllerPaginate
     * @request GET:/survey-responses
     * @secure
     */
    surveyResponsesControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            alarm: {
              /** @format uuid */
              _id: string;
              _ref: "Alarm";
            };
            answers?: (
              | {
                  type: "multipleChoice";
                  answer: {
                    value: number;
                    /** @maxLength 1024 */
                    label: string;
                  };
                  question: {
                    /** @format uuid */
                    _id: string;
                    _ref: "SurveyQuestion";
                  };
                }
              | {
                  type: "likertScale";
                  answer: {
                    value: number;
                    /** @maxLength 1024 */
                    label: string;
                  };
                  question: {
                    /** @format uuid */
                    _id: string;
                    _ref: "SurveyQuestion";
                  };
                }
              | {
                  type: "followUp";
                  answer: {
                    value: number;
                    /** @maxLength 1024 */
                    label: string;
                  }[];
                  question: {
                    /** @format uuid */
                    _id: string;
                    _ref: "SurveyQuestion";
                  };
                }
              | {
                  type: "openEnded";
                  /** @maxLength 1024 */
                  answer: string;
                  question: {
                    /** @format uuid */
                    _id: string;
                    _ref: "SurveyQuestion";
                  };
                }
            )[];
            /** @maxLength 1024 */
            token: string;
            questions: {
              /** @format uuid */
              _id: string;
              _ref: "SurveyType";
            };
            /** @format date-time */
            completedAt?: string;
            responder: {
              /** @format uuid */
              _id: string;
              _ref: "User" | "AlarmHelper" | "StaticResponder" | "AlarmProfessionalResponder" | "Asset";
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/survey-responses`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a SurveyResponse
     *
     * @tags SurveyResponse
     * @name SurveyResponsesControllerCreate
     * @request POST:/survey-responses
     * @secure
     */
    surveyResponsesControllerCreate: (
      data: SurveyResponseCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyResponseOutputDto, any>({
        path: `/survey-responses`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a SurveyResponse by id
     *
     * @tags SurveyResponse
     * @name SurveyResponsesControllerFindById
     * @request GET:/survey-responses/{id}
     * @secure
     */
    surveyResponsesControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyResponseOutputDto, any>({
        path: `/survey-responses/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a SurveyResponse
     *
     * @tags SurveyResponse
     * @name SurveyResponsesControllerUpdate
     * @request PATCH:/survey-responses/{id}
     * @secure
     */
    surveyResponsesControllerUpdate: (
      id: string,
      data: SurveyResponseUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyResponseOutputDto, any>({
        path: `/survey-responses/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a SurveyResponse by id
     *
     * @tags SurveyResponse
     * @name SurveyResponsesControllerRemove
     * @request DELETE:/survey-responses/{id}
     * @secure
     */
    surveyResponsesControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/survey-responses/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags SurveyResponse
     * @name SurveyResponsesControllerGetSurveyResponseQuestionsByToken
     * @request GET:/survey-responses/questions/{token}
     * @secure
     */
    surveyResponsesControllerGetSurveyResponseQuestionsByToken: (
      token: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/survey-responses/questions/${token}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Partially updates a SurveyResponse
     *
     * @tags SurveyResponse
     * @name SurveyResponsesControllerSubmitResponse
     * @request PATCH:/survey-responses/submit
     * @secure
     */
    surveyResponsesControllerSubmitResponse: (
      id: string,
      data: SurveyResponseAnswerUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyResponseOutputDto, any>({
        path: `/survey-responses/submit`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  surveyTypes = {
    /**
     * @description Paginates SurveyType
     *
     * @tags SurveyType
     * @name SurveyTypesControllerPaginate
     * @request GET:/survey-types
     * @secure
     */
    surveyTypesControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer?: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            questions: {
              /** @format uuid */
              _id: string;
              _ref: "SurveyQuestion";
            }[];
            userType: "alarmCreator" | "alarmResponder" | "alarmCenter" | "AlarmProfessionalResponder";
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/survey-types`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Creates a SurveyType
     *
     * @tags SurveyType
     * @name SurveyTypesControllerCreate
     * @request POST:/survey-types
     * @secure
     */
    surveyTypesControllerCreate: (
      data: SurveyTypeCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyTypeOutputDto, any>({
        path: `/survey-types`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a SurveyType by id
     *
     * @tags SurveyType
     * @name SurveyTypesControllerFindById
     * @request GET:/survey-types/{id}
     * @secure
     */
    surveyTypesControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyTypeOutputDto, any>({
        path: `/survey-types/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a SurveyType
     *
     * @tags SurveyType
     * @name SurveyTypesControllerUpdate
     * @request PATCH:/survey-types/{id}
     * @secure
     */
    surveyTypesControllerUpdate: (
      id: string,
      data: SurveyTypeUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<SurveyTypeOutputDto, any>({
        path: `/survey-types/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a SurveyType by id
     *
     * @tags SurveyType
     * @name SurveyTypesControllerRemove
     * @request DELETE:/survey-types/{id}
     * @secure
     */
    surveyTypesControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/survey-types/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),
  };
  users = {
    /**
     * @description Creates a User
     *
     * @tags User
     * @name UsersControllerCreate
     * @request POST:/users
     * @secure
     */
    usersControllerCreate: (
      data: UserCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserOutputDto, any>({
        path: `/users`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates User
     *
     * @tags User
     * @name UsersControllerPaginate
     * @request GET:/users
     * @secure
     */
    usersControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            /** @maxLength 1024 */
            username: string;
            /** @maxLength 1024 */
            name: string;
            properties: {
              /** @maxLength 1024 */
              phoneNumber?: string | null;
              /** @maxLength 1024 */
              smsNumber?: string | null;
              /** @maxLength 1024 */
              email?: string | null;
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale?: string | null;
              /** @maxLength 1024 */
              image?: string | null;
              rSim?: {
                /** @maxLength 1024 */
                euicc: string;
                primary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                secondary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                enabled: boolean;
              } | null;
            };
            /** @default [] */
            globalRoles?: string[];
            /** @default [] */
            specialPermissions?: string[];
            isServiceAccount?: boolean;
            /** @format date-time */
            lastActiveAt?: string;
            type?: "default" | "serviceAccount" | "kiosk";
            userPolicies?: {
              disablePasswordlessLogin?: boolean;
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/users`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a User by id
     *
     * @tags User
     * @name UsersControllerFindById
     * @request GET:/users/{id}
     * @secure
     */
    usersControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserOutputDto, any>({
        path: `/users/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a User
     *
     * @tags User
     * @name UsersControllerUpdate
     * @request PATCH:/users/{id}
     * @secure
     */
    usersControllerUpdate: (
      id: string,
      data: UserUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<UserOutputDto, any>({
        path: `/users/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a User by id
     *
     * @tags User
     * @name UsersControllerRemove
     * @request DELETE:/users/{id}
     * @secure
     */
    usersControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerValidateSpecialPermission
     * @request POST:/users/{id}/validate-special-permission
     * @secure
     */
    usersControllerValidateSpecialPermission: (
      id: string,
      data: ValidateSpecialPermissionRequestDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/${id}/validate-special-permission`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerPost2FaDisable
     * @request POST:/users/2fa/disable/{id}
     * @secure
     */
    usersControllerPost2FaDisable: (
      id: string,
      data: DisableTwoFactorAuthRequestDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<DisableTwoFactorAuthResponseDto, any>({
        path: `/users/2fa/disable/${id}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerPost2FaEnable
     * @request POST:/users/2fa/enable/{id}
     * @secure
     */
    usersControllerPost2FaEnable: (
      id: string,
      data: EnableTwoFactorAuthRequestDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<EnableTwoFactorAuthResponseDto, any>({
        path: `/users/2fa/enable/${id}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerGet2FaSharedSecret
     * @request GET:/users/2fa/shared-secret/{id}
     * @secure
     */
    usersControllerGet2FaSharedSecret: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TwoFactorGetSharedSecretResponseDto, any>({
        path: `/users/2fa/shared-secret/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerGet2FaStatus
     * @request GET:/users/2fa/status/{id}
     * @secure
     */
    usersControllerGet2FaStatus: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<TwoFactorAuthStatusResponseDto, any>({
        path: `/users/2fa/status/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerAddPhoto
     * @request POST:/users/app/photo
     * @secure
     */
    usersControllerAddPhoto: (
      query?: {
        /** User id parameter, optional */
        id?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ImageUploadDto, any>({
        path: `/users/app/photo`,
        method: "POST",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerCheckUsername
     * @request POST:/users/check-username
     * @secure
     */
    usersControllerCheckUsername: (
      data: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/check-username`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerCreateUserAndReturnAccessToken
     * @request GET:/users/obtain-access-token/{id}
     * @secure
     */
    usersControllerCreateUserAndReturnAccessToken: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/obtain-access-token/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerGetResetPassword
     * @request GET:/users/reset-password/{id}
     * @secure
     */
    usersControllerGetResetPassword: (
      id: string,
      query: {
        bhvk: boolean;
        admin_role_assigned: boolean;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/reset-password/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Searches User
     *
     * @tags User
     * @name UsersControllerSearch
     * @request GET:/users/search
     * @secure
     */
    usersControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            /** @maxLength 1024 */
            username: string;
            /** @maxLength 1024 */
            name: string;
            properties: {
              /** @maxLength 1024 */
              phoneNumber?: string | null;
              /** @maxLength 1024 */
              smsNumber?: string | null;
              /** @maxLength 1024 */
              email?: string | null;
              /** Locale, accepts the following values. 'en, nl, no, fr, de', default to en otherwise. */
              locale?: string | null;
              /** @maxLength 1024 */
              image?: string | null;
              rSim?: {
                /** @maxLength 1024 */
                euicc: string;
                primary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                secondary: {
                  /** @maxLength 1024 */
                  msisdn: string;
                  /** @maxLength 1024 */
                  iccid: string;
                };
                enabled: boolean;
              } | null;
            };
            /** @default [] */
            globalRoles?: string[];
            /** @default [] */
            specialPermissions?: string[];
            isServiceAccount?: boolean;
            /** @format date-time */
            lastActiveAt?: string;
            type?: "default" | "serviceAccount" | "kiosk";
            userPolicies?: {
              disablePasswordlessLogin?: boolean;
            };
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/users/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerGetSetPasswordToBase
     * @request GET:/users/to-base-password/{id}
     * @secure
     */
    usersControllerGetSetPasswordToBase: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/to-base-password/${id}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerPostWithRelation
     * @request POST:/users/with-relation
     * @secure
     */
    usersControllerPostWithRelation: (
      data: UserCreateWithRelationDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/with-relation`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name UsersControllerGetWithoutApp
     * @request GET:/users/without-app
     * @secure
     */
    usersControllerGetWithoutApp: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/users/without-app`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  validators = {
    /**
     * No description
     *
     * @tags Validator
     * @name ValidatorControllerValidate
     * @request POST:/validators/validate
     * @secure
     */
    validatorControllerValidate: (
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/validators/validate`,
        method: "POST",
        query: query,
        secure: true,
        ...params,
      }),
  };
  webhooks = {
    /**
     * @description Creates a Webhook
     *
     * @tags Webhook
     * @name WebhookControllerCreate
     * @request POST:/webhooks
     * @secure
     */
    webhookControllerCreate: (
      data: WebhookCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebhookOutputDto, any>({
        path: `/webhooks`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Webhook
     *
     * @tags Webhook
     * @name WebhookControllerPaginate
     * @request GET:/webhooks
     * @secure
     */
    webhookControllerPaginate: (
      query?: {
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            } | null;
            /** @maxLength 1024 */
            migrationKey?: string | null;
            webhookQuery: {
              operation: "create" | "update" | "remove";
              entityTypes: string[];
              filter: Record<string, any>;
            };
            /** @default {} */
            deliveryStats?: {
              /**
               * @format date-time
               * @default null
               */
              lastDeliveryDate?: string | null;
              /** @default 0 */
              numDeliverySucceeded?: number;
              /** @default 0 */
              numDeliveryFailed?: number;
            };
            deliveryConfig: {
              /** @format uri */
              url: string;
              method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
              /** @default null */
              headers?: Record<string, any>;
            };
            /**
             * @maxLength 1024
             * @default null
             */
            transformer?: string | null;
            /** @default true */
            enableWebhook?: boolean;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/webhooks`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Webhook by id
     *
     * @tags Webhook
     * @name WebhookControllerFindById
     * @request GET:/webhooks/{id}
     * @secure
     */
    webhookControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebhookOutputDto, any>({
        path: `/webhooks/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a Webhook
     *
     * @tags Webhook
     * @name WebhookControllerUpdate
     * @request PATCH:/webhooks/{id}
     * @secure
     */
    webhookControllerUpdate: (
      id: string,
      data: WebhookUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<WebhookOutputDto, any>({
        path: `/webhooks/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a Webhook by id
     *
     * @tags Webhook
     * @name WebhookControllerRemove
     * @request DELETE:/webhooks/{id}
     * @secure
     */
    webhookControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/webhooks/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Webhook
     * @name WebhookControllerDeliveryStatUpdate
     * @request POST:/webhooks/delivery/{id}
     * @secure
     */
    webhookControllerDeliveryStatUpdate: (
      id: string,
      data: WebhookDeliveryUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/webhooks/delivery/${id}`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Searches Webhook
     *
     * @tags Webhook
     * @name WebhookControllerSearch
     * @request GET:/webhooks/search
     * @secure
     */
    webhookControllerSearch: (
      query: {
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            } | null;
            /** @maxLength 1024 */
            migrationKey?: string | null;
            webhookQuery: {
              operation: "create" | "update" | "remove";
              entityTypes: string[];
              filter: Record<string, any>;
            };
            /** @default {} */
            deliveryStats?: {
              /**
               * @format date-time
               * @default null
               */
              lastDeliveryDate?: string | null;
              /** @default 0 */
              numDeliverySucceeded?: number;
              /** @default 0 */
              numDeliveryFailed?: number;
            };
            deliveryConfig: {
              /** @format uri */
              url: string;
              method: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
              /** @default null */
              headers?: Record<string, any>;
            };
            /**
             * @maxLength 1024
             * @default null
             */
            transformer?: string | null;
            /** @default true */
            enableWebhook?: boolean;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/webhooks/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  zones = {
    /**
     * @description Creates a Zone
     *
     * @tags Zone
     * @name ZonesControllerCreate
     * @request POST:/zones
     * @secure
     */
    zonesControllerCreate: (
      data: ZoneCreateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ZoneOutputDto, any>({
        path: `/zones`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Paginates Zone
     *
     * @tags Zone
     * @name ZonesControllerPaginate
     * @request GET:/zones
     * @secure
     */
    zonesControllerPaginate: (
      query?: {
        includeFingerprints?: boolean;
        /** Number of documents to skip */
        $offset?: number;
        /** Maximum number of documents to return */
        $max?: number;
        /** Projection to include in the response */
        $select?: string;
        /** Fields to exclude from the response */
        $omit?: string;
        /** Comma delimited list of exposed fields to sort by */
        $sort?: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            type: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
            location:
              | {
                  type: "Polygon";
                  coordinates: number[][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "MultiPolygon";
                  coordinates: number[][][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                };
            /** @maxLength 1024 */
            name: string;
            /**
             * @maxLength 1024
             * @default null
             */
            description?: string | null;
            /** @default [] */
            staticResponders?: {
              staticResponder: {
                /** @format uuid */
                _id: string;
                _ref: "StaticResponder";
              };
              priority: number;
            }[];
            /** @default null */
            responsePriority?: number | null;
            assetsInside: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            syncToAms?: boolean;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/zones`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Finds a Zone by id
     *
     * @tags Zone
     * @name ZonesControllerFindById
     * @request GET:/zones/{id}
     * @secure
     */
    zonesControllerFindById: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ZoneOutputDto, any>({
        path: `/zones/${id}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Partially updates a Zone
     *
     * @tags Zone
     * @name ZonesControllerUpdate
     * @request PATCH:/zones/{id}
     * @secure
     */
    zonesControllerUpdate: (
      id: string,
      data: ZoneUpdateDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ZoneOutputDto, any>({
        path: `/zones/${id}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Removes a Zone by id
     *
     * @tags Zone
     * @name ZonesControllerRemove
     * @request DELETE:/zones/{id}
     * @secure
     */
    zonesControllerRemove: (
      id: string,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/zones/${id}`,
        method: "DELETE",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zone
     * @name ZonesControllerGeoIntersectsPointPost
     * @request POST:/zones/intersect
     * @secure
     */
    zonesControllerGeoIntersectsPointPost: (
      data: IntersectsPointDto,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<ZoneOutputDto[], any>({
        path: `/zones/intersect`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Zone
     * @name ZonesControllerReverseGeocode
     * @request GET:/zones/reverse-geocode/{lat}/{lng}
     * @secure
     */
    zonesControllerReverseGeocode: (
      lat: number,
      lng: number,
      query?: {
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/zones/reverse-geocode/${lat}/${lng}`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),

    /**
     * @description Searches Zone
     *
     * @tags Zone
     * @name ZonesControllerSearch
     * @request GET:/zones/search
     * @secure
     */
    zonesControllerSearch: (
      query: {
        includeFingerprints?: boolean;
        /** The search query string */
        q: string;
        /** @format uuid */
        customer?: string;
        /**
         * User to impersonate during the request
         * @format uuid
         */
        asUser?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          result: {
            /** @format uuid */
            _id: string;
            /** @format date-time */
            createdAt: string;
            /** @format date-time */
            updatedAt: string;
            customer: {
              /** @format uuid */
              _id: string;
              _ref: "Customer";
            };
            /** @default {"type":"parents","extra":[]} */
            share?: {
              type: "publicRead" | "private" | "inherit" | "parents" | "children" | "family";
              extra?: {
                /** @format uuid */
                _id: string;
                _ref: "Customer";
              }[];
            };
            type: "default" | "responseArea" | "policeDepartment" | "assetMonitoredArea" | "fingerprinted";
            location:
              | {
                  type: "Polygon";
                  coordinates: number[][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                }
              | {
                  type: "MultiPolygon";
                  coordinates: number[][][][];
                  properties: {
                    accuracy?: number;
                    heading?: number;
                    headingAccuracy?: number;
                    speed?: number;
                    speedAccuracy?: number;
                    altitude?: number;
                    altitudeAccuracy?: number;
                    address?: {
                      /** @maxLength 1024 */
                      countryCode?: string | null;
                      /** @maxLength 1024 */
                      state?: string | null;
                      /** @maxLength 1024 */
                      city?: string | null;
                      /** @maxLength 1024 */
                      streetName?: string | null;
                      /** @maxLength 1024 */
                      streetNumber?: string | null;
                      floor?: number | null;
                      /** @maxLength 1024 */
                      formattedAddress?: string;
                    };
                    /** @format date-time */
                    dateTime?: string;
                    /** @maxLength 1024 */
                    provider?: string;
                  };
                };
            /** @maxLength 1024 */
            name: string;
            /**
             * @maxLength 1024
             * @default null
             */
            description?: string | null;
            /** @default [] */
            staticResponders?: {
              staticResponder: {
                /** @format uuid */
                _id: string;
                _ref: "StaticResponder";
              };
              priority: number;
            }[];
            /** @default null */
            responsePriority?: number | null;
            assetsInside: {
              /** @format uuid */
              _id: string;
              _ref: "Asset";
            }[];
            syncToAms?: boolean;
          }[];
          total: number;
          max: number;
          offset: number;
        },
        any
      >({
        path: `/zones/search`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
